import React from 'react';
import { Spin } from 'antd';
import Modal from '../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import IconWithText from '../../../../Components/Text';
import Icon from '../../../../Icon';

const OptionsModal = (
  {
    setModal,
    isMobile,
    modal,
  }
) => {

  const {t} = useTranslation();

  return (
    <Modal handleOk={null}
           {...(isMobile ? { transitionName: '' } : null)}
           handleClose={() => setModal(null)}
           title={t('Collection actions')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal"
           open={modal === 'collection_options'}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Rename or remove your collection.')
            }
          </p>
          <ul className="action-dropdown">
            <li className="action-dropdown-item"
                onClick={() => setModal('collection_rename')}
            >
              <IconWithText text={t('Rename')}
                            size={24}
                            icon={() => <Icon type="rename_collection" role="icon" />}
              />
            </li>
            <li className="action-dropdown-item red"
                onClick={() => setModal('collection_delete')}
            >
              <IconWithText text={t('Remove')}
                            size={24}
                            icon={() => <Icon type="trash" role="icon" />}
              />
            </li>
          </ul>
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default OptionsModal;
