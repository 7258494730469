import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Gps = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon icon-speaker icon-speaker-outline')}
           fill="none"
      >
        <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
              d="M15.2065 11.6667C16.5872 11.6667 17.7065 10.5474 17.7065 9.16673C17.7065 7.78602 16.5872 6.66673 15.2065 6.66673M10.4809 15.2084C10.1377 16.1794 9.21164 16.8751 8.12313 16.8751C6.74242 16.8751 5.62313 15.7558 5.62313 14.3751V13.1251M5.62496 5.20839V13.1251M15.2065 4.55062V13.7828C15.2065 14.9049 14.1202 15.7063 13.0481 15.3753L3.46477 12.4164C2.76623 12.2007 2.28979 11.555 2.28979 10.8239V7.50953C2.28979 6.77845 2.76623 6.13272 3.46477 5.91704L13.0481 2.95813C14.1202 2.62712 15.2065 3.4286 15.2065 4.55062Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon icon-speaker icon-speaker-bold')}
           fill="none"
      >
        <path stroke={theme === 'light' ? "#225AEA" : "white"}
              d="M15.2065 11.6667C16.5872 11.6667 17.7065 10.5474 17.7065 9.16673C17.7065 7.78602 16.5872 6.66673 15.2065 6.66673M10.4809 15.2084C10.1377 16.1794 9.21164 16.8751 8.12313 16.8751C6.74242 16.8751 5.62313 15.7558 5.62313 14.3751V13.1251M5.62496 5.20839V13.1251M15.2065 4.55062V13.7828C15.2065 14.9049 14.1202 15.7063 13.0481 15.3753L3.46477 12.4164C2.76623 12.2007 2.28979 11.555 2.28979 10.8239V7.50953C2.28979 6.77845 2.76623 6.13272 3.46477 5.91704L13.0481 2.95813C14.1202 2.62712 15.2065 3.4286 15.2065 4.55062Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    );
}

export default Gps;
