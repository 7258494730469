import React from 'react';

const DiscountApplied = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.29297 9.30115V3.95817C2.29297 3.0377 3.03916 2.2915 3.95964 2.2915H9.30261C9.74464 2.2915 10.1686 2.4671 10.4811 2.77966L17.1561 9.45466C17.807 10.1055 17.807 11.1608 17.1561 11.8117L11.8131 17.1547C11.1623 17.8055 10.107 17.8055 9.45612 17.1547L2.78112 10.4797C2.46856 10.1671 2.29297 9.74317 2.29297 9.30115Z"
        stroke="#16895A" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M6.8763 6.24984C6.8763 6.59502 6.59648 6.87484 6.2513 6.87484C5.90612 6.87484 5.6263 6.59502 5.6263 6.24984C5.6263 5.90466 5.90612 5.62484 6.2513 5.62484C6.59648 5.62484 6.8763 5.90466 6.8763 6.24984Z"
        stroke="#16895A" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};

export default DiscountApplied;
