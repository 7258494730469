import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Home = () =>
  (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path d="M16.0423 16.5632V4.29175C16.0423 3.18718 15.1469 2.29175 14.0423 2.29175H5.95898C4.85441 2.29175 3.95898 3.18718 3.95898 4.29175V16.5632C3.95898 17.3639 4.85313 17.8399 5.51738 17.3928L8.88387 15.1268C9.55904 14.6723 10.4423 14.6723 11.1174 15.1268L14.4839 17.3928C15.1482 17.8399 16.0423 17.3639 16.0423 16.5632Z"
            stroke="#225AEA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  )

export default Home;
