import React from 'react';
import { useSelector } from 'react-redux';

const ShopifyConnectNewDont = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
            d="M9.37501 16.0417H3.95834C3.03786 16.0417 2.29167 15.2955 2.29167 14.375V4.79167C2.29167 3.87119 3.03786 3.125 3.95834 3.125H7.44137C7.99862 3.125 8.51901 3.4035 8.82812 3.86717L9.50523 4.88283C9.81434 5.3465 10.3347 5.625 10.892 5.625H16.0417C16.9621 5.625 17.7083 6.37119 17.7083 7.29167V8.54167M15 11.4583V14.1667M15 14.1667V16.875M15 14.1667H12.2917M15 14.1667H17.7083"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShopifyConnectNewDont;
