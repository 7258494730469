import React from 'react'

const ArrowSliderPrev = ({className}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       className={className}
       fill="none"
  >
    <path d="M11.3221 13.3334L8.57806 10.5893C8.25262 10.2639 8.25263 9.73626 8.57806 9.41083L11.3221 6.66675"
          stroke="#225AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
)

export default ArrowSliderPrev
