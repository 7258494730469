import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import acc from 'accounting';
import dayjs from "dayjs";
import cls from 'classname';
import {Divider, Spin, Table} from 'antd';
import utils from '../../../Utils/utils'
import { getColumns } from './AdminTrackerTableColumns';
import Chart from '../../../Components/Charts/Chart';
import Icon from '../../../Icon';
import DatabaseProductsPagination from "../../ProductDatabasePage/components/DatabaseProductsPagination";
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const AdminTrackerTable = (props) => {
  const {
    fetching,
    minDate,
    maxDate,
    loading,
    onSort,
    sortOrder,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    data,
    localData,
    totalProducts,
    report,
    withSummary,
    setVisible,
    setModal,
    setRecordToCalc,
    getExpandableChart,
    selectedStoreStats,
  } = props;

  const tableRef = useRef(null);
  const { t } = useTranslation();

  const [expandedRow, setExpandedRow] = useState(null);

  const chartData = useSelector(store => store.adminStoreTable.expandableChartData);
  const chartLoading = useSelector(store => store.adminStoreTable.expandableChartLoading);
  const theme = useSelector(store => store.nav.theme);


  useEffect(() => {
    if(expandedRow) setExpandedRow(null)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[loading])

  //table scroll top on page number change
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.scrollTop = 0;
    }
  }, [pageNumber]);

  async function handleExpandRow(expanded, record){
    if(!expanded || (expanded && expandedRow === record.id)) return setExpandedRow([]);
    if (expanded) {
      await getExpandableChart({store_id: record?.store?.id, product_id: record?.id, date_range: {min: minDate, max: maxDate}});
      setExpandedRow(record.id)
    }
  }

  return (
      <div className={cls('product-database-table-wrapper', {
        'product-database-table-wrapper-hidden': fetching,
      })}>

        <DatabaseProductsPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalProducts={totalProducts}
          disabled={false}
          withOutTooltip={true}
        />

        <div id={'product-database-table-body'}
             className={cls('product-database-table-body sales-tracker-table-body', {
               'product-database-table-body_simple': report !== 'sales',
               'product-database-table-body--dark': theme === 'dark',
             })}
        >
          <Table
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table
                  {...other}
                  ref={tableRef}
                  className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            className="list-table"
            loading={loading}
            rowClassName={'product-database-table-row'}
            rowKey={(record) => record?.id || record?.date}
            dataSource={localData?.length ? localData : data}
            columns={getColumns({
              sortOrder,
              t,
              report,
              setVisible,
              setModal,
              setRecordToCalc,
              handleExpandRow,
            })}
            pagination={false}
            scroll={{
              x: 'max-content',
              y: `calc(100dvh - 58px - 48px${withSummary ? ' - 40px' : ''})`,
            }}
            sticky={true}
            sortDirections={['descend', 'ascend', null]}
            onChange={(pagination, filters, sorter) => {
              onSort(sorter);
            }}
            expandable={
              report === 'sales'
                ? {
                  expandedRowKeys: [expandedRow],
                  expandedRowRender: record => (
                    <Spin size="large" spinning={chartLoading}>
                      <div id={`expandable-chart-info-${record.id}`} className={'expandable-chart-info'}>
                        <span>Updated {utils.durationAsString(new Date().toISOString(), chartData?.last_updated, t)}</span>
                        <Icon type={'attention_outline'}
                              role={'button'}
                              width={16}
                              height={16}
                              tooltipProps={{
                                overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                                placement: "top",
                                title: () => (
                                  <div className={'expandable-chart-info-tooltip'}>
                                    <div className={'expandable-chart-info-tooltip-title'}>
                                      {t('Dashboard data syncs every 2-3 hours. However, delays may occur when high volumes are being processed.')}
                                    </div>
                                    <Divider style={{margin: '12px 0'}}/>
                                    <div className={'expandable-chart-info-tooltip-text'}>
                                      <p>
                                        {
                                          t('Last updated on')
                                        }
                                      </p>
                                      <p>
                                        {dayjs(chartData?.last_updated).format('lll')} UTC
                                      </p>
                                    </div>
                                  </div>
                                ),
                                destroyTooltipOnHide: true,
                                getPopupContainer: () => document.getElementById(`expandable-chart-info-${record.id}`),
                              }}
                        />
                      </div>
                      <Chart height={'280px'} type={'revenue'} data={chartData?.chart?.length ? [...chartData?.chart] : []} loading={chartLoading}/>
                    </Spin>
                  ),
                  onExpand: handleExpandRow,
                  expandIcon: ({ expanded, onExpand, record}) => (
                    <Icon type="min_max_input_divider"
                          width={7}
                          height={10}
                          role="button"
                          btnType={expanded ?
                            report !== 'sales' ? record?.month_sales : record?.sales ?
                              'icon-btn-expand-row active'
                              :
                              'icon-btn-expand-row active empty'
                            :
                            report !== 'sales' ? record?.month_sales : record?.sales ?
                              'icon-btn-expand-row'
                              :
                              'icon-btn-expand-row empty'
                          }
                          onClick={e => onExpand(record, e)}
                    />
                  ),
                } : false
            }
            summary={(currentData) => {
              if (!withSummary) return null;
              const sumSales = data?.reduce((accumulator, currentValue) => accumulator + currentValue.sales, 0) || 0;
              const sumRevenue = data?.reduce((accumulator, currentValue) => accumulator + currentValue.revenue, 0) || 0;

              return (
                <Table.Summary fixed="top">
                  <Table.Summary.Row>
                    {report === 'sales' && <Table.Summary.Cell index={0} colSpan={1}/>}
                    <Table.Summary.Cell index={report === 'sales' ? 1 : 0} colSpan={report === 'sales' ? 2 : 1}>{t('Summary')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={report === 'sales' ? 3 : 1} colSpan={1}>{report === 'sales' ? selectedStoreStats?.sales : sumSales}</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>${acc.format(report === 'sales' ? selectedStoreStats?.revenue : sumRevenue, 2, ',', '.')}</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={report === 'sales' ? 4 : (report === 'sales' ? 1 : 2)}>
                      {['sales', 'product'].includes(report) ? '' : acc.format(selectedStoreStats?.unique, 0, ',', '.')}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </div>
      </div>
  );
};

export default AdminTrackerTable;
