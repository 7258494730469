import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spin, Divider } from 'antd';
import { openNotificationWithIcon } from '../../Components/Notification';
import Modal from '../../Components/Modal';
import Creators from './reducer';
import ButtonComponent from '../../Components/Button';
import { DefaultMsg } from '../../Components/Notification/notification-message';
import './styles.less';

const ModalShopifyDelete = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    isVisible,
    closeModal,
    deleteStore,
    ...props
  }) => {

  const { loading, deleteErrors, deleteResult } = shopifyStore;
  const { t } = useTranslation();

  useEffect(() => {
    if(Boolean(deleteResult) || deleteErrors){
      if(Boolean(deleteResult)){
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_delete',
          message: (
            <DefaultMsg text={null}
                        icon="notification_success"
                        title={`${deleteResult?.store?.name || 'Your store'} was successfully disconnected`}
            />
          ),
        });
        closeModal()
      }
      if(deleteErrors){
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_delete',
          message: (
            <DefaultMsg text={'Something went wrong, kindly try again.'}
                        icon="notification_warning"
                        title={'Something went wrong'}
            />
          ),
        });
      }
      props.deleteShopifyStoreReset()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[deleteResult, deleteErrors])

  const handleOk = () => {
    if(loading) return
    props.deleteShopifyStore({id: deleteStore?.id, store: deleteStore})
  };

  return (
    <Modal handleOk={null}
           handleClose={closeModal}
           title={`Disconnecting this store?`}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={isVisible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            Are you sure you want to disconnect <span className={'bold'}>{deleteStore?.name}</span> from your Dropship account?
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={closeModal}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={false}
                           onClick={handleOk}
                           text={t('Disconnect')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  deleteShopifyStore: (store_id) => dispatch(Creators.deleteShopifyStoreRequest(store_id)),
  deleteShopifyStoreReset: () => dispatch(Creators.deleteShopifyStoreReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyDelete);
