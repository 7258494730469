import React from 'react';

export const Trash = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path fill="#D71313"
          d="M4.69041 16.1488L5.43886 16.1005L4.69041 16.1488ZM15.3083 16.1488L14.5598 16.1005L15.3083 16.1488ZM2.29102 4.0415C1.8768 4.0415 1.54102 4.37729 1.54102 4.7915C1.54102 5.20572 1.8768 5.5415 2.29102 5.5415V4.0415ZM17.7077 5.5415C18.1219 5.5415 18.4577 5.20572 18.4577 4.7915C18.4577 4.37729 18.1219 4.0415 17.7077 4.0415V5.5415ZM8.87435 8.95817C8.87435 8.54396 8.53856 8.20817 8.12435 8.20817C7.71014 8.20817 7.37435 8.54396 7.37435 8.95817H8.87435ZM7.37435 13.5415C7.37435 13.9557 7.71014 14.2915 8.12435 14.2915C8.53856 14.2915 8.87435 13.9557 8.87435 13.5415H7.37435ZM12.6243 8.95817C12.6243 8.54396 12.2886 8.20817 11.8743 8.20817C11.4601 8.20817 11.1243 8.54396 11.1243 8.95817H12.6243ZM11.1243 13.5415C11.1243 13.9557 11.4601 14.2915 11.8743 14.2915C12.2886 14.2915 12.6243 13.9557 12.6243 13.5415H11.1243ZM12.5014 4.97845C12.6046 5.37959 13.0135 5.62108 13.4146 5.51783C13.8158 5.41459 14.0573 5.0057 13.954 4.60456L12.5014 4.97845ZM3.20924 4.83979L3.94197 16.1971L5.43886 16.1005L4.70613 4.74322L3.20924 4.83979ZM6.35362 18.4582H13.6451V16.9582H6.35362V18.4582ZM16.0567 16.1971L16.7895 4.83979L15.2926 4.74322L14.5598 16.1005L16.0567 16.1971ZM16.041 4.0415H3.95768V5.5415H16.041V4.0415ZM2.29102 5.5415H3.95768V4.0415H2.29102V5.5415ZM16.041 5.5415H17.7077V4.0415H16.041V5.5415ZM13.6451 18.4582C14.9194 18.4582 15.9747 17.4687 16.0567 16.1971L14.5598 16.1005C14.5287 16.5829 14.1284 16.9582 13.6451 16.9582V18.4582ZM3.94197 16.1971C4.02401 17.4687 5.07934 18.4582 6.35362 18.4582V16.9582C5.87027 16.9582 5.46997 16.5829 5.43886 16.1005L3.94197 16.1971ZM7.37435 8.95817V13.5415H8.87435V8.95817H7.37435ZM11.1243 8.95817V13.5415H12.6243V8.95817H11.1243ZM9.99937 3.0415C11.2021 3.0415 12.2145 3.86408 12.5014 4.97845L13.954 4.60456C13.5008 2.84355 11.9031 1.5415 9.99937 1.5415V3.0415ZM7.49738 4.97845C7.78419 3.86408 8.79666 3.0415 9.99937 3.0415V1.5415C8.09567 1.5415 6.49797 2.84355 6.04472 4.60456L7.49738 4.97845Z"
    />
  </svg>
);

export default Trash;
