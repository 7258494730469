import React from 'react';
import utils from '../../../../Utils/utils';

const UniversityCourseChapterSkeleton = ({isMobile}) => {
  return (
    <div className={'product-database-page university-page course-chapter-page'}>
      <div className={'page-top-line-wrapper'}>
        <div className="custom-breadcrumbs">
          <span className={'link skeleton'}
                style={{ width: 55, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 20, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 72, height: 20 }}
          />
        </div>
      </div>
      <div className="course-chapter-page-content">
        <div className={'course-chapter-info-wrapper'}
             style={{border: 'none', padding: 0}}
        >
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: 24 }}
          />
        </div>
        <div className="course-chapter-page-chapter">
          <div className="course-chapter-page-chapter-wrapper">
            <h3 className={'course-chapter-page-chapter-title'}>
              <span className={'link skeleton'}
                    style={{ width: 200, height: 20 }}
              />
            </h3>
            {
              [1,2,3,4,5].map(el => (
                <div key={el}
                     className={'university-course-chapter-card-wrapper'}
                >
                  <div className="university-course-chapter-card-content">
                    <div className="university-course-chapter-card-info">
                      <div className="text">
                        <h4 className="title">
                          <span className={'link skeleton'}
                                style={{ width: utils.randomNum(80, 120), height: 20 }}
                          />
                        </h4>
                        <p className="description">
                          <span className={'link skeleton'}
                                style={{ width: '100%', height: 20 }}
                          />
                          <span className={'link skeleton'}
                                style={{ width: utils.randomNum(30, 70), height: 20, marginTop: 2 }}
                          />
                        </p>
                      </div>
                      <div className={'stat'}>
                        <span className={'link skeleton'}
                              style={{ width: 20, height: 20 }}
                        />
                        <span className={'link skeleton'}
                              style={{ width: 150, height: 20 }}
                        />
                      </div>
                    </div>
                    <span className={'link skeleton'}
                          style={{ width: 20, height: 20, marginLeft: 'auto' }}
                    />
                  </div>
                </div>
              ))
            }
          </div>
          <div className="course-chapter-page-progress-wrapper">
            <div className="course-chapter-page-progress">
              <div className="course-chapter-page-progress-info">
                <div className={'course-chapter-page-progress-info-subtitle'}>
                  <span className={'link skeleton'}
                        style={{ width: 90, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 110, height: 20 }}
                  />
                </div>
                <span className={'link skeleton'}
                      style={{ width: '100%', height: 8, borderRadius: 128 }}
                />
              </div>
            </div>
            <div className="course-chapter-page-instructor">
              <h4 className="course-chapter-page-instructor-title">
                <span className={'link skeleton'}
                      style={{ width: 20, height: 20 }}
                />
                <span className={'link skeleton'}
                      style={{ width: 110, height: 20 }}
                />
              </h4>
              <div className="course-chapter-page-instructor-info">
                <div className="course-chapter-page-instructor-image">
                  <span className={'link skeleton'}
                        style={{ width: '100%', height: '100%', borderRadius: 50 }}
                  />
                </div>
                <div className="course-chapter-page-instructor-bio">
                  <div className="course-chapter-page-instructor-name">
                    <span className={'link skeleton'}
                          style={{ width: 110, height: 20 }}
                    />
                  </div>
                  <div className="course-chapter-page-instructor-subtitle">
                    <span className={'link skeleton'}
                          style={{ width: 140, height: 20 }}
                    />
                  </div>
                </div>
              </div>
              <div className="course-chapter-page-instructor-text">
                <span className={'link skeleton'}
                      style={{ width: '100%', height: 20 }}
                />
                <span className={'link skeleton'}
                      style={{ width: '100%', height: 20, marginTop: 2 }}
                />
                <span className={'link skeleton'}
                      style={{ width: '90%', height: 20, marginTop: 2 }}
                />
                <span className={'link skeleton'}
                      style={{ width: '20%', height: 20, marginTop: 2 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityCourseChapterSkeleton;
