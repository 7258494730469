import React from 'react';

const StartTracking = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_3190_52051)">
        <path
          d="M9.99967 1.45825V6.87492M18.5413 9.99992H13.1247M9.99967 13.1249V18.5416M6.87467 9.99992H1.45801M9.99967 16.0416C6.66295 16.0416 3.95801 13.3366 3.95801 9.99992C3.95801 6.6632 6.66295 3.95825 9.99967 3.95825C13.3364 3.95825 16.0413 6.6632 16.0413 9.99992C16.0413 13.3366 13.3364 16.0416 9.99967 16.0416Z"
          stroke="#225AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3190_52051">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StartTracking;
