import React from 'react';
import { useTranslation } from 'react-i18next';
import actions from '../../Layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const AccountSettings = ({closeDropdown}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nav = useSelector(state => state?.nav);

  return (
    <div className={'user-dropdown-inner-settings'}
         onClick={() => {
           if (nav?.isMobile && nav?.isShowMenu) dispatch(actions.changeNavSetProp('isShowMenu', false));
           navigate('/setting/account');
           closeDropdown(false);
         }}
    >
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
      >
        <path stroke="#434C69"
              d="M6.56508 4.34515L5.97903 4.20991C5.48344 4.09554 4.96389 4.24454 4.60425 4.60419C4.2446 4.96383 4.0956 5.48338 4.20997 5.97897L4.34521 6.56502C4.50109 7.24052 4.22255 7.94199 3.64572 8.32654L2.93312 8.80161C2.53243 9.06874 2.29175 9.51845 2.29175 10C2.29175 10.4816 2.53243 10.9313 2.93312 11.1984L3.64572 11.6735C4.22255 12.0581 4.50109 12.7595 4.34521 13.435L4.20997 14.0211C4.0956 14.5167 4.2446 15.0362 4.60425 15.3959C4.96389 15.7555 5.48344 15.9045 5.97903 15.7901L6.56508 15.6549C7.24058 15.499 7.94205 15.7776 8.3266 16.3544L8.80167 17.067C9.0688 17.4677 9.51851 17.7084 10.0001 17.7084C10.4817 17.7084 10.9314 17.4677 11.1985 17.067L11.6736 16.3544C12.0581 15.7776 12.7596 15.499 13.4351 15.6549L14.0211 15.7901C14.5167 15.9045 15.0363 15.7555 15.3959 15.3959C15.7556 15.0362 15.9046 14.5167 15.7902 14.0211L15.655 13.435C15.4991 12.7595 15.7776 12.0581 16.3544 11.6735L17.067 11.1984C17.4677 10.9313 17.7084 10.4816 17.7084 10C17.7084 9.51845 17.4677 9.06874 17.067 8.80161L16.3544 8.32654C15.7776 7.94199 15.4991 7.24052 15.655 6.56502L15.7902 5.97897C15.9046 5.48338 15.7556 4.96383 15.3959 4.60419C15.0363 4.24454 14.5167 4.09554 14.0211 4.20991L13.4351 4.34515C12.7596 4.50103 12.0581 4.22249 11.6736 3.64566L11.1985 2.93306C10.9314 2.53237 10.4817 2.29169 10.0001 2.29169C9.51851 2.29169 9.0688 2.53236 8.80167 2.93306L8.3266 3.64566C7.94205 4.22249 7.24058 4.50103 6.56508 4.34515Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
        <path stroke="#434C69"
              d="M12.2917 10C12.2917 11.2657 11.2657 12.2917 10.0001 12.2917C8.73443 12.2917 7.70841 11.2657 7.70841 10C7.70841 8.73437 8.73443 7.70835 10.0001 7.70835C11.2657 7.70835 12.2917 8.73437 12.2917 10Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
      <span>
        {
          t('Account settings')
        }
      </span>
    </div>
  );
};

export default AccountSettings;
