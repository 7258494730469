/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import { checkSaturationIndex } from '../../../Utils/utils';
import { Divider } from 'antd';
import './OverviewBlock.less';

const acc = require('accounting');

const listDataFirst = [
  {
    id: 1,
    name: 'category',
    label: 'Category',
    prefix: '',
    suffix: '',
    precision: 0,
    delimiter: ',',
  },
  {
    id: 2,
    name: 'price',
    label: 'Price',
    prefix: '$',
    suffix: '',
    precision: 2,
    delimiter: ',',
  },
  {
    id: 3,
    name: 'competitors_count',
    label: 'Competitors',
    prefix: '',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
  {
    id: 4,
    name: 'saturation',
    label: 'Saturation',
    prefix: '',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
  {
    id: 5,
    name: 'score',
    label: 'Score',
    prefix: '',
    suffix: '/10',
    precision: 0,
    delimiter: '.',
  },
];

const listDataSecond = [
  {
    id: 6,
    name: 'p_c_ratio',
    label: 'P/C Ratio',
    prefix: '',
    suffix: 'X',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 7,
    name: 'BECPA',
    label: 'BECPA',
    prefix: '$',
    suffix: '',
    precision: 2,
    delimiter: ',',
  },
  {
    id: 8,
    name: 'BEROAS',
    label: 'BEROAS',
    prefix: '',
    suffix: '',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 9,
    name: 'profit_margin',
    label: 'Profit Margin',
    prefix: '',
    suffix: '%',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 10,
    name: 'pot_profit',
    label: 'POT. Profit',
    prefix: '≈ $',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
];

const listDataSkeletonFirst = [
  {
    first: 61,
    second: 65
  },
  {
    first: 33,
    second: 56
  },
  {
    first: 81,
    second: 21
  },
  {
    first: 67,
    second: 100
  },
];

const listDataSkeletonSecond = [
  {
    first: 60,
    second: 45
  },
  {
    first: 45,
    second: 56
  },
  {
    first: 55,
    second: 36
  },
  {
    first: 83,
    second: 59
  },
];

const OverviewBlock = (
  {
    skeleton,
    theme,
    isMobile,
    product,
  }) => {

  const { t } = useTranslation();

  function checkSaturation(value) {
    if (value) {
      let result = '';
      switch (true) {
        case value > 65:
          result = 'high';
          break;

        case value < 36:
          result = 'low';
          break;

        default:
          result = 'medium';
          break;
      }
      return result;
    }
    return value ?? '-';
  }

  const renderHeader = (
    <>
      <span className={cls('title', {
        'link skeleton': skeleton
      })}
            style={skeleton ? { width: 102, height: 28 } : {}}
      >
        {skeleton ? null : t('Overview')}
      </span>
        <span className={cls('text', {
          'link skeleton': skeleton
        })}
              style={skeleton ? { width: 159, height: 20 } : {}}
        >
          {skeleton ? null : `Product ID: ${product?.id}`}
        </span>
    </>
  );

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeader}>
        <div className={'overview-card-block'}>
          <div className="overview-card-wrapper">
            {
              listDataSkeletonFirst.map((el, index) => {
                return (
                  <div key={el?.first * index}
                       className={cls('overview-card-wrap', {
                         'order-last': index === 4,
                       })}
                  >
                    <span className={'link skeleton'}
                          style={{ width: el?.first, height: 20 }}
                    />
                    <span className={'link skeleton'}
                          style={{ width: el?.second, height: 20 }}
                    />
                    {
                      index < 4 ?
                        <div className="divider" />
                        :
                        null
                    }
                  </div>
                );
              })
            }
          </div>
          {
            isMobile ?
              null
              :
              <Divider type={'horizontal'}
                       style={{ margin: '8px auto' }}
              />
          }
          <div className="overview-card-wrapper">
            {
              listDataSkeletonSecond.map((el, index) => {
                return (
                  <div key={el?.first * index}
                       className={cls('overview-card-wrap', {
                         'order-last': index === 4,
                       })}
                  >
                    <span className={'link skeleton'}
                          style={{ width: el?.first, height: 20 }}
                    />
                    <span className={'link skeleton'}
                          style={{ width: el?.second, height: 20 }}
                    />
                    {
                      index < 4 ?
                        <div className="divider" />
                        :
                        null
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </ProductDetailsCard>
    );
  }

  return (
    <ProductDetailsCard header={renderHeader}>
      <div className={'overview-card-block'}>
        <div className="overview-card-wrapper">
          {
            (isMobile ? [...listDataFirst.filter((el, i) => i !== 4)] : [...listDataFirst]).map((el) => {
              const value = product[el.name] ?? '';

              const integerValue = product[el.name]
                ? `${el.prefix}${acc.formatNumber(value, el.precision, ',', el.delimiter)}${
                  el.suffix
                }`
                : product[el.name] ?? '-';
              return (
                <div key={el.id}
                     className={cls('overview-card-wrap', {
                       'order-last':
                         isMobile && (el.name === 'score' || el.name === 'pot_profit'),
                     })}
                >
                  <span className="overview-card-label">
                    {t(el.label)}
                    {
                      el.name === 'score' ?
                        (
                          <Icon type="attention_outline"
                                role="button"
                                titleText={t('The product score is calculated through mathematical functions setup to rate each product based on multiple factors such as the numbers breakdown, saturation, benefits, drawbacks, etc. Rated from 0 being the worst to 10 being the best.')}
                                tooltipProps={{
                                  placement: 'top',
                                  trigger: isMobile ? 'click' : 'hover',
                                  overlayClassName: cls('details-tooltip', 'score'),
                                  getPopupContainer: (trigger) => trigger.parentNode,
                                }}
                          />
                        )
                        :
                        null
                    }
                  </span>
                  <span className="overview-card-value">
                    {
                      el.name === 'saturation' ?
                        (
                          <>
                        <span className={checkSaturation(value)}>
                          {checkSaturation(value)}
                        </span>
                            <Icon type="ellipse"
                                  role="icon"
                                  className={cls('saturation-icon', {
                                    disabled: !value,
                                  })}
                                  outline={checkSaturationIndex(value)}
                                  theme={theme}
                            />
                          </>
                        )
                        :
                        el.name === 'cost' ?
                          (value || '-')
                          :
                          el?.name === 'category' ?
                            'Random'
                            :
                            el?.name === 'score' ?
                              (
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 8
                                }}>
                                  {integerValue}
                                  <div className={'score-item-wrapper'}>
                                    {
                                      Array.from({length: 10}, (v, i) => i).map(elem => (
                                        <span key={elem}
                                              className={cls('score-item', {
                                                active: elem + 1 <= product?.score && product?.score !== 0
                                              })}
                                        />
                                      ))
                                    }
                                  </div>
                                </div>
                              )
                              :
                              (integerValue)
                    }
                  </span>
                  {
                    el?.id < 5 ?
                      <div className="divider" />
                      :
                      null
                  }
                </div>
              );
            })
          }
        </div>
        {
          isMobile ?
            null
            :
            <Divider type={'horizontal'}
                     style={{ margin: '8px auto' }}
            />
        }
        <div className="overview-card-wrapper">
          {
            (isMobile ? [...listDataSecond.filter((el, i) => i !== 4)] : [...listDataSecond]).map((el) => {
              const value = product[el.name] ?? '';

              const integerValue = product[el.name]
                ? `${el.prefix}${acc.formatNumber(value, el.precision, ',', el.delimiter)}${
                  el.suffix
                }`
                : product[el.name] ?? '-';
              return (
                <div key={el.id}
                     className={cls('overview-card-wrap', {
                       'order-last':
                         isMobile && (el.name === 'score' || el.name === 'pot_profit'),
                     })}
                >
                  <span className="overview-card-label">
                    {t(el.label)}
                    {
                      el.name === 'score' ?
                        (
                          <Icon type="attention_outline"
                                role="button"
                                titleText={t('The product score is calculated through mathematical functions setup to rate each product based on multiple factors such as the numbers breakdown, saturation, benefits, drawbacks, etc. Rated from 0 being the worst to 10 being the best.')}
                                tooltipProps={{
                                  placement: 'top',
                                  trigger: isMobile ? 'click' : 'hover',
                                  overlayClassName: cls('details-tooltip', 'score'),
                                  getPopupContainer: (trigger) => trigger.parentNode,
                                }}
                          />
                        )
                        :
                        null
                    }
                  </span>
                  <span className="overview-card-value">
                    {
                      el.name === 'saturation' ?
                        (
                          <>
                            <span className={checkSaturation(value)}>
                              {checkSaturation(value)}
                            </span>
                            <Icon type="ellipse"
                                  role="icon"
                                  className={cls('saturation-icon', {
                                    disabled: !value,
                                  })}
                                  outline={checkSaturationIndex(value)}
                                  theme={theme}
                            />
                          </>
                        )
                        :
                        el.name === 'cost' ?
                          (value || '-')
                          :
                          el?.name === 'category' ?
                            'Random'
                            :
                            (integerValue)
                    }
                  </span>
                  {
                    el?.id < 10 ?
                      <div className="divider" />
                      :
                      null
                  }
                </div>
              );
            })
          }
          {
            (isMobile ? [...listDataFirst.filter((el, i) => i === 4)] : []).map((el) => {
              const value = product[el.name] ?? '';

              const integerValue = product[el.name]
                ? `${el.prefix}${acc.formatNumber(value, el.precision, ',', el.delimiter)}${
                  el.suffix
                }`
                : product[el.name] ?? '-';
              return (
                <div key={el.id}
                     className={cls('overview-card-wrap wide', {
                       'order-last':
                         isMobile && (el.name === 'score' || el.name === 'pot_profit'),
                     })}
                >
                  <span className="overview-card-label">
                    {t(el.label)}
                    {
                      el.name === 'score' ?
                        (
                          <Icon type="attention_outline"
                                role="button"
                                titleText={t('The product score is calculated through mathematical functions setup to rate each product based on multiple factors such as the numbers breakdown, saturation, benefits, drawbacks, etc. Rated from 0 being the worst to 10 being the best.')}
                                tooltipProps={{
                                  placement: 'top',
                                  trigger: isMobile ? 'click' : 'hover',
                                  overlayClassName: cls('details-tooltip', 'score'),
                                  getPopupContainer: (trigger) => trigger.parentNode,
                                }}
                          />
                        )
                        :
                        null
                    }
                  </span>
                  <span className="overview-card-value">
                    {
                      el.name === 'saturation' ?
                        (
                          <>
                        <span className={checkSaturation(value)}>
                          {checkSaturation(value)}
                        </span>
                            <Icon type="ellipse"
                                  role="icon"
                                  className={cls('saturation-icon', {
                                    disabled: !value,
                                  })}
                                  outline={checkSaturationIndex(value)}
                                  theme={theme}
                            />
                          </>
                        )
                        :
                        el.name === 'cost' ?
                          (value || '-')
                          :
                          el?.name === 'category' ?
                            'Random'
                            :
                            el?.name === 'score' ?
                              (
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 8
                                }}>
                                  {integerValue}
                                  <div className={'score-item-wrapper'}>
                                    {
                                      Array.from({length: 10}, (v, i) => i).map(elem => (
                                        <span key={elem}
                                              className={cls('score-item', {
                                                active: elem + 1 <= product?.score && product?.score !== 0
                                              })}
                                        />
                                      ))
                                    }
                                  </div>
                                </div>
                              )
                              :
                              (integerValue)
                    }
                  </span>
                  {
                    el?.id < 5 ?
                      <div className="divider" />
                      :
                      null
                  }
                </div>
              );
            })
          }
          {
            (isMobile ? [...listDataSecond.filter((el, i) => i === 4)] : []).map((el) => {
              const value = product[el.name] ?? '';

              const integerValue = product[el.name]
                ? `${el.prefix}${acc.formatNumber(value, el.precision, ',', el.delimiter)}${
                  el.suffix
                }`
                : product[el.name] ?? '-';
              return (
                <div key={el.id}
                     className={cls('overview-card-wrap wide', {
                       'order-last':
                         isMobile && (el.name === 'score' || el.name === 'pot_profit'),
                     })}
                >
                  <span className="overview-card-label">
                    {t(el.label)}
                    {
                      el.name === 'score' ?
                        (
                          <Icon type="attention_outline"
                                role="button"
                                titleText={t('The product score is calculated through mathematical functions setup to rate each product based on multiple factors such as the numbers breakdown, saturation, benefits, drawbacks, etc. Rated from 0 being the worst to 10 being the best.')}
                                tooltipProps={{
                                  placement: 'top',
                                  trigger: isMobile ? 'click' : 'hover',
                                  overlayClassName: cls('details-tooltip', 'score'),
                                  getPopupContainer: (trigger) => trigger.parentNode,
                                }}
                          />
                        )
                        :
                        null
                    }
                  </span>
                  <span className="overview-card-value">
                    {
                      el.name === 'saturation' ?
                        (
                          <>
                            <span className={checkSaturation(value)}>
                              {checkSaturation(value)}
                            </span>
                            <Icon type="ellipse"
                                  role="icon"
                                  className={cls('saturation-icon', {
                                    disabled: !value,
                                  })}
                                  outline={checkSaturationIndex(value)}
                                  theme={theme}
                            />
                          </>
                        )
                        :
                        el.name === 'cost' ?
                          (value || '-')
                          :
                          el?.name === 'category' ?
                            'Random'
                            :
                            (integerValue)
                    }
                  </span>
                  {
                    el?.id < 10 ?
                      <div className="divider" />
                      :
                      null
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </ProductDetailsCard>
  );
};

export default OverviewBlock;
