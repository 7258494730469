import React from 'react';

const ShopifyConnectNewHave = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.4812 5.8503C18.4665 5.74939 18.3785 5.69405 18.3051 5.68754C18.2318 5.68103 16.8072 5.65987 16.8072 5.65987C16.8072 5.65987 15.6141 4.50422 15.4968 4.38541C15.3794 4.26821 15.1496 4.30402 15.0599 4.33006C15.0583 4.33006 14.8366 4.40005 14.4601 4.51562C14.3982 4.31216 14.3053 4.06313 14.1732 3.81247C13.7495 3.00352 13.1268 2.57544 12.3754 2.57381C12.3738 2.57381 12.3738 2.57381 12.3722 2.57381C12.32 2.57381 12.2678 2.5787 12.2157 2.58358C12.1929 2.55754 12.1717 2.53149 12.1472 2.50545C11.8196 2.1555 11.4007 1.98623 10.8971 2.00088C9.92725 2.02855 8.96069 2.72844 8.17669 3.97198C7.62577 4.84603 7.20525 5.94471 7.08626 6.79598C5.97301 7.14104 5.1939 7.38194 5.17597 7.38682C4.61364 7.56261 4.59571 7.58051 4.52237 8.1095C4.47184 8.50828 3 19.871 3 19.871L15.3305 22L20.6751 20.6735C20.6751 20.6735 18.4942 5.95122 18.4812 5.8503ZM13.8424 4.70605C13.5588 4.79395 13.236 4.89324 12.8856 5.00229C12.8791 4.51236 12.8204 3.82874 12.5906 3.23953C13.3273 3.37788 13.6892 4.20962 13.8424 4.70605ZM12.2401 5.20249C11.5947 5.4027 10.8889 5.6208 10.1815 5.83891C10.3804 5.07879 10.7585 4.3203 11.2214 3.82386C11.3942 3.63831 11.6354 3.43322 11.9207 3.31603C12.1896 3.87432 12.2467 4.66699 12.2401 5.20249ZM10.9183 2.64543C11.1464 2.64055 11.3371 2.69101 11.5018 2.79843C11.2394 2.93353 10.9867 3.12885 10.7487 3.38276C10.1326 4.0436 9.65994 5.0674 9.4725 6.05702C8.88572 6.23769 8.31035 6.41673 7.78225 6.5795C8.11476 5.02345 9.42197 2.68775 10.9183 2.64543Z"
            fill="#95BF47" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.3053 5.68747C18.2319 5.68096 16.8074 5.6598 16.8074 5.6598C16.8074 5.6598 15.6142 4.50416 15.4969 4.38534C15.4529 4.34139 15.3942 4.3186 15.3306 4.30884V21.9983L20.6736 20.6718C20.6736 20.6718 18.4927 5.95115 18.4797 5.85024C18.4683 5.74932 18.3786 5.69398 18.3053 5.68747Z"
            fill="#5E8E3E" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.3689 8.43186L11.7479 10.7513C11.7479 10.7513 11.0551 10.4371 10.2353 10.4876C9.03238 10.5641 9.01934 11.321 9.03075 11.5114C9.09595 12.5482 11.8277 12.7745 11.9826 15.2046C12.1032 17.1155 10.9671 18.4225 9.33066 18.5266C7.36658 18.6503 6.2843 17.4931 6.2843 17.4931L6.69994 15.7254C6.69994 15.7254 7.78874 16.5458 8.65913 16.4904C9.22798 16.4546 9.43172 15.9924 9.41053 15.6652C9.32577 14.3126 7.09927 14.3924 6.9591 12.169C6.84011 10.2988 8.07072 8.40256 10.7862 8.23166C11.8343 8.16492 12.3689 8.43186 12.3689 8.43186Z"
            fill="white" />
    </svg>
  );
};

export default ShopifyConnectNewHave;
