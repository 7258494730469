import React from 'react';
import { Divider } from 'antd';

const AccountSkeleton = ({isMobile}) => {
  return (
    <div className="account-subpage">
      <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          <span className="link skeleton" style={{ width: 153, height: 20 }} />
        </span>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className="account-subpage-block-name">
          <div className="input-wrapper">
          <span className="input-label">
            <span className="link skeleton" style={{ width: 68, height: 20 }} />
          </span>
            <span className="link skeleton" style={{ width: '100%', height: 40 }} />
          </div>
          <div className="input-wrapper">
          <span className="input-label">
            <span className="link skeleton" style={{ width: 68, height: 20 }} />
          </span>
            <span className="link skeleton" style={{ width: '100%', height: 40 }} />
          </div>
        </div>
        <div className="account-subpage-block-email">
          <div className="input-wrapper">
          <span className="input-label">
            <span className="link skeleton" style={{ width: 90, height: 20 }} />
          </span>
            <span className="link skeleton" style={{ width: '100%', height: 40 }} />
          </div>
          <div className="btn-wrapper"
               style={{marginBottom: 10}}
          >
            <span className="link skeleton" style={{ width: 76, height: 20 }} />
            <span className="link skeleton" style={{ width: 143, height: 20 }} />
          </div>
        </div>
      </div>

      <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          <span className="link skeleton" style={{ width: 62, height: 20 }} />
        </span>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className="account-subpage-block-email">
          <div className="input-wrapper">
          <span className="input-label">
            <span className="link skeleton" style={{ width: 64, height: 20 }} />
          </span>
            <span className="link skeleton" style={{ width: '100%', height: 40 }} />
          </div>
          <div className="btn-wrapper"
               style={{marginBottom: 10}}
          >
            <span className="link skeleton" style={{ width: 117, height: 20 }} />
          </div>
        </div>
      </div>

      <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          <span className="link skeleton" style={{ width: 90, height: 20 }} />
        </span>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className="account-subpage-block-preferences">
          <div className="preference-wrapper">
            <div className="preference-text">
            <span className="preference-title">
              <span className="link skeleton" style={{ width: 46, height: 20 }} />
            </span>
              <p className="preference-subtitle">
                <span className="link skeleton" style={{ width: 300, height: 20 }} />
              </p>

            </div>
            <span className="link skeleton" style={{ width: isMobile ? '100%' : 120, height: 40 }} />
          </div>
          <div className="preference-wrapper">
          <div className="preference-text">
            <span className="preference-title">
              <span className="link skeleton" style={{ width: 64, height: 20 }} />
            </span>
              <p className="preference-subtitle">
                <span className="link skeleton" style={{ width: 280, height: 20 }} />
              </p>
            </div>
            <span className="link skeleton" style={{ width: isMobile ? '100%' : 120, height: 40 }} />
          </div>
        </div>
      </div>

      <div className="account-subpage-block">
      <span className="account-subpage-block-title">
        <span className="link skeleton" style={{ width: 154, height: 20 }} />
      </span>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className="account-subpage-block-accounts">
          <div className="account-wrapper">
          <span className="account-title">
            <span className="link skeleton" style={{ width: 20, height: 20 }} />
            <span className="link skeleton" style={{ width: 47, height: 20 }} />
          </span>
            <span className="account-domain">
            <span className="link skeleton" style={{ width: 150, height: 20 }} />
          </span>
            <span className={'account-action'}>
              <span className="link skeleton" style={{ width: 56, height: 20 }} />
            </span>
          </div>
          <div className="account-wrapper">
          <span className="account-title">
            <span className="link skeleton" style={{ width: 20, height: 20 }} />
            <span className="link skeleton" style={{ width: 47, height: 20 }} />
          </span>
            <span className="account-domain">
            <span className="link skeleton" style={{ width: 150, height: 20 }} />
          </span>
            <span className={'account-action'}>
              <span className="link skeleton" style={{ width: 56, height: 20 }} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSkeleton;
