import React, { useEffect, useState } from 'react';
import { Divider, Spin } from 'antd';
import ButtonComponent from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../../ProductDatabasePage/reducer';
import InputComponent from '../../../Components/Input';

const SaveFilterPresetModal = (
  {
    setModal,
    isMobile,
    visible,
    saveFilterPreset,
    filters,
    category,
    loading,
  }
) => {

  const { t } = useTranslation();
  const saveError = useSelector(store => store[category].createError);
  const saveResult = useSelector(store => store[category].createResult);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (saveResult) {
      setModal(null);
      dispatch(Creators.resetCreateResult())
    }
    dispatch(Creators.resetCreateError())
  }, [dispatch, searchValue, saveResult])

  useEffect(() => {
    setSearchValue('')
  }, [visible]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('Create new preset')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Give your preset a name to easily distinguish it from others inn the list.')
            }
          </p>
          <InputComponent value={searchValue}
                          placeholder={t('Enter name for preset...')}
                          onChange={(e) => setSearchValue(e.target.value)}
                          className="input--collections-modal"
          />
          {
            saveError &&
            <p style={{color: '#D71313', fontSize: 12, lineHeight: '16px', marginTop: 8}}>
              {saveError}
            </p>
          }
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModal(null);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={!searchValue}
                           onClick={() => {
                             saveFilterPreset({name: searchValue.trim(), value: {...filters}})
                           }}
                           text={t('Create preset')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default SaveFilterPresetModal;
