import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const University = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="19"
           height="16"
           viewBox="0 0 19 16"
           fill="none"
           className={cls(className, 'icon icon-university icon-university-outline')}
      >
        <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
              d="M17.5416 5.5L8.99992 9.875L1.08325 5.5L8.99992 1.125L17.5416 5.5ZM17.5416 5.5V10.7083M14.2083 7.34896V11.1548C14.2083 11.7766 13.8621 12.3466 13.3105 12.6335L9.76884 14.4752C9.28686 14.7258 8.71298 14.7258 8.231 14.4752L4.68933 12.6335C4.13769 12.3466 3.79159 11.7766 3.79159 11.1548V7.24413"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="19"
           height="16"
           viewBox="0 0 19 16"
           fill="none"
           className={cls(className, 'icon icon-university icon-university-bold')}
      >
        <path stroke={theme === 'light' ? "#225AEA" : "white"}
              d="M17.5416 5.5L8.99992 9.875L1.08325 5.5L8.99992 1.125L17.5416 5.5ZM17.5416 5.5V10.7083M14.2083 7.34896V11.1548C14.2083 11.7766 13.8621 12.3466 13.3105 12.6335L9.76884 14.4752C9.28686 14.7258 8.71298 14.7258 8.231 14.4752L4.68933 12.6335C4.13769 12.3466 3.79159 11.7766 3.79159 11.1548V7.24413"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    );
}

export default University;
