import React, { useEffect, useRef } from 'react';
import CollectionsActions from '../../reducer';
import { Spin } from 'antd';
import Collection from '../collection';
import './styles.less';

const CollectionsComponent = (
  {
    collections = [],
    loading = false,
    getNextCollections,
    next,
    dispatch,
    setModal,
  }) => {

  const collectionsRef = useRef();

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextCollections();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const collectionsContent = collectionsRef.current;

    if (collectionsContent) collectionsContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (collectionsContent) collectionsContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SET AS DEFAULT HANDLER
  const setAsDefaultHandler = ({ collectionID, collectionName }) => {
    dispatch(
      CollectionsActions.setCollectionAsDefaultRequest({
        collectionID,
        collectionName,
      }),
    );
  };

  return (
    <div className={'collections-page-list'}>
      <Spin spinning={loading}>
        <div className={'collections-page-list-wrapper'}
        ref={collectionsRef}>
          {
            collections.map((value, i) => (
              <Collection {...value}
                          setAsDefaultFunc={setAsDefaultHandler}
                          key={`col-${value.id}-${i}`}
                          setModal={setModal}
              />
            ))
          }
        < /div>
      </Spin>
    </div>
);
};

export default CollectionsComponent;
