import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Account = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-account icon-account-outline')}
      >
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M13.125 5.41666C13.125 7.14255 11.7259 8.54166 10 8.54166C8.27413 8.54166 6.87502 7.14255 6.87502 5.41666C6.87502 3.69077 8.27413 2.29166 10 2.29166C11.7259 2.29166 13.125 3.69077 13.125 5.41666Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M10 11.0417C7.17406 11.0417 5.03001 12.7203 4.15208 15.0941C3.81141 16.0152 4.59862 16.875 5.58071 16.875H14.4193C15.4014 16.875 16.1886 16.0152 15.848 15.0941C14.97 12.7203 12.826 11.0417 10 11.0417Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-account icon-account-bold')}
      >
        <path stroke={theme === 'light' ? '#225AEA' : 'white'}
              d="M13.125 5.41666C13.125 7.14255 11.7259 8.54166 10 8.54166C8.27413 8.54166 6.87502 7.14255 6.87502 5.41666C6.87502 3.69077 8.27413 2.29166 10 2.29166C11.7259 2.29166 13.125 3.69077 13.125 5.41666Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
        <path stroke={theme === 'light' ? '#225AEA' : 'white'}
              d="M10 11.0417C7.17406 11.0417 5.03001 12.7203 4.15208 15.0941C3.81141 16.0152 4.59862 16.875 5.58071 16.875H14.4193C15.4014 16.875 16.1886 16.0152 15.848 15.0941C14.97 12.7203 12.826 11.0417 10 11.0417Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    );

};

export default Account;
