import React, { useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../Icon';
import IconWithText from '../../../../Components/Text';
import { useSelector } from 'react-redux';

const ActionsComponent = (
  {
    setAsDefaultFunc,
    isDefault,
    isGeneral,
    handleResend,
    collection,
    saveCollection,
    setModal,
  }) => {

  const isMobile = useSelector(state => state?.nav?.isMobile);

  const dropdown = useRef();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="collections-page-list-item-info">
      <div className="collection-item_set-as-default"
           onClick={() =>
             !isDefault ?
               setAsDefaultFunc({ collectionID: collection?.id, collectionName: collection?.name })
               :
               null
           }
      >
        <Icon type="favorite"
              outline={isDefault}
              role="icon"
        />
      </div>
      <span className="collections-page-list-item-info-name"
            onClick={() => handleResend({
              collectionID: collection?.id,
              collectionName: collection?.name,
              collectionCount: collection?.count,
            })}
      >
        {collection?.name}
      </span>
      <span className="collections-page-list-item-info-count">
        {collection?.count}
      </span>
      {
        !isGeneral ?
          isMobile ?
            <div className={'collections-page-list-item-info-action'}
                 onClick={() => {
                   saveCollection({
                     collectionID: collection?.id,
                     collectionName: collection?.name,
                     collectionCount: collection?.count,
                   });
                   setModal('collection_options');
                 }}
            >
              <span>
                <Icon type="three_dots" role="icon" />
              </span>
            </div>
            :
            (
              <div ref={dropdown}
                   className={'collections-page-list-item-info-action'}
              >
                <Dropdown placement="bottomRight"
                          getPopupContainer={() => dropdown.current}
                          destroyPopupOnHide={true}
                          open={visible}
                          onOpenChange={(flag) => {
                            setVisible(flag);
                          }}
                          dropdownRender={() => (
                            <ul className="action-dropdown">
                              <li className="action-dropdown-item"
                                  onClick={() => {
                                    setVisible(false);
                                    saveCollection({
                                      collectionID: collection?.id,
                                      collectionName: collection?.name,
                                      collectionCount: collection?.count,
                                    });
                                    setModal('collection_rename');
                                  }}
                              >
                                <IconWithText text={t('Rename')}
                                              size={24}
                                              icon={() => <Icon type="rename_collection" role="icon" />}
                                />
                              </li>
                              <li className="action-dropdown-item red"
                                  onClick={() => {
                                    setVisible(false);
                                    saveCollection({
                                      collectionID: collection?.id,
                                      collectionName: collection?.name,
                                      collectionCount: collection?.count,
                                    });
                                    setModal('collection_delete');
                                  }}
                              >
                                <IconWithText text={t('Remove')}
                                              size={24}
                                              icon={() => <Icon type="trash" role="icon" />}
                                />
                              </li>
                            </ul>
                          )}
                          trigger={['click']}
                >
                <span onClick={(e) => {
                  e.preventDefault();
                }}
                >
                  <Icon type="three_dots" role="icon" />
                </span>
                </Dropdown>
              </div>
            )
          :
          null
      }
    </div>
  );
};

export default ActionsComponent;
