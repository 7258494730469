import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import SaveToCollectionMsg from '../../../Components/Notification/notification-message';

const fromCollectionListKey = `open-create-${Date.now()}`;
export const openCollectionListNotification = ({ message, collection }) => {
  openNotificationWithIcon({
    key: fromCollectionListKey,
    title: 'Product was successfully saved',
    icon: 'bookmark',
    className: 'notification notification--create-collection',
    message: (
      <SaveToCollectionMsg text={'This product'}
                           collectionName={collection?.name}
                           isSave={true}
                           isRestore={false}
                           btnText={'Go to collection'}
                           btnOnClick={() => window.open(`/collections/product-list/${collection?.id}`, '_self')}
      />
    ),
  });
};
