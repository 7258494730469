import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import SaveToCollectionMsg, { DefaultMsg } from '../../../Components/Notification/notification-message';

const createCollectionKey = `open-create-${Date.now()}`;
export const openCreateCollectionNotification = ({ message, collection }) => {
  openNotificationWithIcon({
    key: createCollectionKey,
    style: message?.includes?.('created') ? { width: '400px' } : {},
    className: 'notification notification--create-collection',
    message: message?.includes?.('created') ?
      (
        <DefaultMsg text={null}
                    icon="notification_success"
                    title={'Collection was successfully created'}
        />
      )
        :
      (
      <SaveToCollectionMsg text={'This product'}
                           collectionName={collection?.name}
                           isSave={true}
                           isRestore={false}
                           btnText={'Go to collection'}
                           btnOnClick={() => window.open(`/collections/product-list/${collection?.id}`, '_self')}
      />
    ),
  });
};
