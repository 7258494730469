/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import './SuppliersBlock.less';

const SuppliersBlock = (
  {
    skeleton,
    isMobile,
    product,
    onOpenUpgradePlan,
  }) => {

  /* Alibaba, AliExpress */
  const { t } = useTranslation();

  const suppliersData = product?.suppliers;

  const isEmptyAliexpress = !suppliersData?.aliexpress?.length;
  const isEmptyAlibaba = !suppliersData?.alibaba?.length;
  const isLocked = product?.is_locked;

  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (isEmptyAliexpress && selected === 'AliExpress') {
      setSelected('Alibaba');
    } else {
      if (isEmptyAlibaba && selected === 'Alibaba') setSelected('AliExpress');
      if (!isEmptyAliexpress && selected === 'Alibaba') setSelected('AliExpress');
      setSelected(isEmptyAliexpress ? isEmptyAlibaba ? null : 'Alibaba' : 'AliExpress')
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isEmptyAliexpress, isEmptyAlibaba, product?.product_id]);

  const handleChangeSelected = (platform) => {
    if (platform === selected) return;
    setSelected(platform);
  };

  const createTitle = () => {
    if (selected === 'AliExpress' && !isEmptyAliexpress) {
      return 'Suppliers';
    } else if (selected === 'Alibaba' && !isEmptyAlibaba) {
      return 'Suppliers';
    }
    return 'No Suppliers Found';
  };

  const renderHeader = (
    <>
      <span className={cls('title', {
        'skeleton link': skeleton
      })}
            style={skeleton ? {width: 84, height: 28} : {}}
      >
        {skeleton ? null : t(createTitle())}
      </span>
      {
        skeleton ?
          <span className={'link skeleton'}
                style={{ width: 184, height: 40 }}
          />
          :
          (!isEmptyAliexpress || !isEmptyAlibaba) ?
            (
              <div className={'product-details-card-header-toggle'}
                   style={{ cursor: (!isEmptyAliexpress && !isEmptyAlibaba) ? 'pointer' : 'not-allowed' }}
                   onClick={() => (!isEmptyAliexpress && !isEmptyAlibaba) && handleChangeSelected(selected === 'Alibaba' ? 'AliExpress' : 'Alibaba')}
              >
              <span className={cls('product-details-card-header-toggle-option', {
                active: selected === 'AliExpress',
              })}
              >
                Aliexpress
              </span>
                <span className={cls('product-details-card-header-toggle-option', {
                  active: selected === 'Alibaba',
                })}
                >
                Alibaba
              </span>
              </div>
            )
            :
            null
      }
    </>
  );

  const handleFeedback = (rating) => {
    return (
      <span>
        {
          rating <= 50 ?
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path stroke="#DF7009"
                    d="M7.64258 12.8514C8.94433 11.5496 11.0549 11.5496 12.3566 12.8514M15.45 4.54947C18.4603 7.55977 18.4603 12.4404 15.45 15.4507C12.4397 18.461 7.55902 18.461 4.54874 15.4507C1.53844 12.4404 1.53844 7.55975 4.54874 4.54947C7.55903 1.53917 12.4397 1.53917 15.45 4.54947ZM8.12435 7.91675C8.12435 8.26193 7.9378 8.54175 7.70768 8.54175C7.47756 8.54175 7.29102 8.26193 7.29102 7.91675C7.29102 7.57157 7.47756 7.29175 7.70768 7.29175C7.9378 7.29175 8.12435 7.57157 8.12435 7.91675ZM12.7077 7.91675C12.7077 8.26193 12.5211 8.54175 12.291 8.54175C12.0609 8.54175 11.8743 8.26193 11.8743 7.91675C11.8743 7.57157 12.0609 7.29175 12.291 7.29175C12.5211 7.29175 12.7077 7.57157 12.7077 7.91675Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
              />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path stroke="#16895A"
                    d="M12.3566 12.3571C11.0549 13.6589 8.94432 13.6589 7.64258 12.3571M15.45 4.54947C18.4603 7.55977 18.4603 12.4404 15.45 15.4507C12.4397 18.461 7.55902 18.461 4.54874 15.4507C1.53844 12.4404 1.53844 7.55975 4.54874 4.54947C7.55903 1.53917 12.4397 1.53917 15.45 4.54947ZM8.12435 7.91675C8.12435 8.26193 7.9378 8.54175 7.70768 8.54175C7.47756 8.54175 7.29102 8.26193 7.29102 7.91675C7.29102 7.57157 7.47756 7.29175 7.70768 7.29175C7.9378 7.29175 8.12435 7.57157 8.12435 7.91675ZM12.7077 7.91675C12.7077 8.26193 12.5211 8.54175 12.291 8.54175C12.0609 8.54175 11.8743 8.26193 11.8743 7.91675C11.8743 7.57157 12.0609 7.29175 12.291 7.29175C12.5211 7.29175 12.7077 7.57157 12.7077 7.91675Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
              />
            </svg>
        }
        <span className={cls('feedback', {
          negative: rating <= 50,
        })}>
          {rating}%
        </span>
        {' '}{t('positive feedback')}
      </span>
    );
  };

  const handleRating = (rating) => {
    let val = Math.floor(rating);
    let decimal = Number((rating - val).toFixed(1));
    if (rating === 0) {
      return (
        <span>{rating} {t('stars')}</span>
      );
    } else {
      return (
        <span>
          <span className="stars-wrapper">
            {
              Array.from({ length: val + (decimal === 0 ? 0 : 1) }, (v, i) => i).map(star => (
                <span className={'star'}
                      key={star}
                >
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="20"
                       height="20"
                       viewBox="0 0 20 20"
                       fill="none"
                  >
                    <path fill="#F6B60E"
                          d="M11.1351 1.54567C10.6788 0.595779 9.32337 0.595781 8.867 1.54567L6.89318 5.65397C6.89318 5.65397 6.89085 5.65714 6.88717 5.65762L2.34582 6.25218C1.29933 6.38919 0.87261 7.67965 1.64569 8.40853L4.9658 11.5388C4.96729 11.5402 4.96716 11.5418 4.96716 11.5418L4.13336 16.014C3.93881 17.0575 5.0432 17.8459 5.96756 17.3476L9.99642 15.1759C9.99922 15.1743 10.0029 15.1743 10.0057 15.1759L14.0346 17.3476C14.9589 17.8459 16.0633 17.0575 15.8688 16.014L15.035 11.5418C15.035 11.5418 15.0348 11.5402 15.0363 11.5388L18.3564 8.40852C19.1295 7.67965 18.7028 6.38919 17.6563 6.25218L13.115 5.65762C13.1113 5.65714 13.1089 5.65397 13.1089 5.65397L11.1351 1.54567Z"
                    />
                  </svg>
                  {
                    star === val && decimal > 0 ?
                      <div className="block"
                           style={{width: `${(1 - decimal) * 100}%`}}
                      />
                      :
                      null
                  }
              </span>
              ))
            }
          </span>
          {` ${rating} ${t(rating === 1 ? 'star' : 'stars')}`}
        </span>
      );
    }
  };

  const renderCard = (el) => {
    if (skeleton) {
      return (
        <div key={el}
             className={'suppliers-card'}
        >
          <div className="suppliers-card-info-wrap">
            <span className={'link skeleton'}
                  style={{ width: 24, height: 24, borderRadius: 50, marginRight: 8 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 140, height: 20 }}
            />
          </div>
          <span className="suppliers-card-value">
            <span className={'link skeleton'}
                  style={{ width: 80, height: 20 }}
            />
          </span>
          <span className="suppliers-card-value small">
            <span className={'link skeleton'}
                  style={{ width: 40, height: 20 }}
            />
          </span>
          <span className="suppliers-card-value small">
            <span className={'link skeleton'}
                  style={{ width: 70, height: 20 }}
            />
          </span>
          <div className={'suppliers-card-rating'}
               style={{display: 'flex', alignItems: 'center'}}
          >
            <span className={'link skeleton'}
                  style={{ width: 20, height: 20, borderRadius: 50, marginRight: 8 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 150, height: 20 }}
            />
          </div>
        </div>
      );
    }
    return (
      <div key={el.name + el.id}
           className={cls('suppliers-card', { locked: el.is_locked })}
      >
        <div className="suppliers-card-info-wrap">
          <Icon role="icon" type={`supplier_${selected.toLowerCase()}`} />
          <a href={el?.link || '#'}
             target="_blank"
             rel="noopener noreferrer"
             className="suppliers-card-link"
          >
            {el?.name}
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="16"
                 height="16"
                 viewBox="0 0 16 16"
                 fill="none"
            >
              <path stroke="#225AEA"
                    d="M12.1667 9.33333V11.3667C12.1667 12.1134 12.1667 12.4868 12.0213 12.772C11.8935 13.0229 11.6895 13.2268 11.4387 13.3547C11.1534 13.5 10.7801 13.5 10.0333 13.5H4.63333C3.8866 13.5 3.51323 13.5 3.22801 13.3547C2.97713 13.2268 2.77316 13.0229 2.64532 12.772C2.5 12.4868 2.5 12.1134 2.5 11.3667V5.91667C2.5 5.21706 2.5 4.86726 2.62801 4.59659C2.7599 4.31774 2.9844 4.09324 3.26326 3.96135C3.53392 3.83333 3.88373 3.83333 4.58333 3.83333H6.16667M9.16667 2.5H13.5M13.5 2.5V6.83333M13.5 2.5L7.33333 8.66667"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
        <span className="suppliers-card-value">
            {`$${acc.formatNumber(el?.cost_min, 2, '', ',')}${
              el?.cost_max ?
                ` - $${acc.formatNumber(el?.cost_max, 2, '', ',')}`
                :
                ''
            }`
            }
          </span>
        <span className="suppliers-card-value small">
            {
              selected === 'AliExpress' ?
                `$${
                  el?.shipping ? acc.formatNumber(el?.shipping, 2, '', ',') : 0
                }`
                :
                `${el?.min_orders} ${t(el?.min_orders === 1 ? 'Unit' : 'Units')}`
            }
          </span>
        <span className="suppliers-card-value small">
          {el?.years_open}{' '}{el?.years_open === 1 ? t('year') : t('years')}
        </span>
        {
          selected === 'AliExpress' ?
            <div className={'suppliers-card-feedback'}>
              {
                handleFeedback(el?.rating)
              }
            </div>
            :
            <div className={'suppliers-card-rating'}>
              {
                handleRating(el?.rating)
              }
            </div>
        }
      </div>
    );
  };

  return (
    <ProductDetailsCard header={renderHeader}
                        headerClassName="suppliers-header"
                        withLine={!isMobile}
                        isLocked={!skeleton && isLocked && suppliersData?.[selected.toLowerCase()]?.length > 1}
                        onOpenUpgradePlan={onOpenUpgradePlan}
    >
      {
        skeleton ?
          <div className="competitors-card-list-header">
            <span className={'link skeleton'}
                  style={{ width: 50, height: 20 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 40, height: 20, marginLeft: '43%' }}
            />
            <span className={'link skeleton'}
                  style={{ width: 60, height: 20, marginLeft: '9%' }}
            />
            <span className={'link skeleton'}
                  style={{ width: 55, height: 20, marginLeft: '4%' }}
            />
            <span className={'link skeleton'}
                  style={{ width: 70, height: 20, marginLeft: '4%' }}
            />
          </div>
          :
          <div className="competitors-card-list-header">
        <span>
          {t('Supplier')}
        </span>
            <span>
          {t('Cost')}
        </span>
            <span className={'small'}>
          {t(selected === 'AliExpress' ? 'Shipping' : 'Min. Order')}
        </span>
            <span className={'small'}>
          {t('Opened')}
        </span>
            <span>
          {t(selected === 'AliExpress' ? 'Feedback' : 'Rating')}
        </span>
          </div>
      }
      {
        skeleton ?
          [1, 2, 3].map(renderCard)
          :
          selected && (createTitle() === 'Suppliers') && (suppliersData?.alibaba?.length || suppliersData?.aliexpress?.length) ?
            suppliersData?.[selected.toLowerCase()]?.map(renderCard)
            :
            <div className={'videos-card empty'}>
              <Icon role="icon" type="competitors_empty" />
            </div>
      }
    </ProductDetailsCard>
  );
};

export default SuppliersBlock;
