// SET AS DEFAULT COLLECTION
import { call, put } from 'redux-saga/effects';
import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import { DefaultMsg } from '../../../Components/Notification/notification-message';
import { EventHandler } from '../../../Utils/event-handler';
import Creators, { CollectionsTypes as constants } from '../reducer';
import * as services from '../service';

const actions = {
  setCollectionAsDefaultActions: {
    request: Creators.setCollectionAsDefaultRequest,
    success: Creators.setCollectionAsDefaultSuccess,
    errors: Creators.setCollectionAsDefaultFailure,
  },
};

const eventsOptions = {
  [constants.SET_COLLECTION_AS_DEFAULT_REQUEST]: {
    api: services.setCollectionAsDefault,
    actions: actions.setCollectionAsDefaultActions,
  },
};

export function* saveAsDefaultGenerator(action) {
  const provider = eventsOptions[action.type];
  const params = action.payload;

  const key = `open${Date.now()}`;
  const openNotification = ({ text, title = '', icon=null }) => {

    openNotificationWithIcon({
      key,
      className: 'notification notification--save-to-default',
      message: <DefaultMsg text={text} title={title} icon={icon}/>,
    });
  };

  try {
    const response = yield call(provider.api, params);

    if (response.data) {
      yield put(provider.actions.success(response.data));

      // CALL NOTIFICATION
      yield call(openNotification, {
        title: 'Default collection was changed',
        icon: 'set_as_default_collection',
        text: (
          <span>
            The default collection has been set to "{params.collectionName}".
          </span>
        ),
      });
    } else {
      yield put(
        provider.actions.errors({ errors: 'saveAsDefaultGenerator error' }),
      );
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default saveAsDefaultGenerator;
