import React from 'react';

const SuppliersVerified = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_595_20349)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.70376 1.42247C7.41972 0.706508 8.58053 0.706507 9.29649 1.42247L10.1132 2.23914C10.2694 2.39542 10.4814 2.48321 10.7024 2.48321H11.6835C12.696 2.48321 13.5168 3.30402 13.5168 4.31655V5.2976C13.5168 5.51861 13.6046 5.73057 13.7609 5.88685L14.5775 6.70352C15.2935 7.41948 15.2935 8.58028 14.5775 9.29624L13.7609 10.1129C13.6046 10.2692 13.5168 10.4812 13.5168 10.7022V11.6832C13.5168 12.6957 12.696 13.5165 11.6835 13.5165H10.7024C10.4814 13.5165 10.2694 13.6043 10.1132 13.7606L9.29649 14.5773C8.58053 15.2933 7.41972 15.2933 6.70376 14.5773L5.88709 13.7606C5.73081 13.6043 5.51885 13.5165 5.29784 13.5165H4.31679C3.30427 13.5165 2.48346 12.6957 2.48346 11.6832V10.7022C2.48346 10.4812 2.39566 10.2692 2.23938 10.1129L1.42271 9.29624C0.706752 8.58028 0.706752 7.41948 1.42271 6.70352L2.23938 5.88685C2.39566 5.73057 2.48346 5.51861 2.48346 5.2976V4.31655C2.48346 3.30402 3.30427 2.48321 4.31679 2.48321H5.29784C5.51885 2.48321 5.73081 2.39542 5.88709 2.23914L6.70376 1.42247ZM10.2907 6.57864C10.4484 6.3519 10.3923 6.04032 10.1656 5.88268C9.93889 5.72505 9.6273 5.78107 9.46967 6.0078L7.185 9.29393L6.11739 8.35338C5.91018 8.17083 5.59423 8.19082 5.41169 8.39803C5.22915 8.60523 5.24914 8.92118 5.45634 9.10372L6.94483 10.4151C7.05181 10.5093 7.19393 10.5534 7.33547 10.5363C7.47701 10.5191 7.6045 10.4424 7.68588 10.3253L10.2907 6.57864Z"
              fill="#F6B60E" />
      </g>
      <defs>
        <clipPath id="clip0_595_20349">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuppliersVerified;
