import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classname';

const Integrations = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-integrations icon-integrations-outline')}
      >
        <path
          d="M7.29036 5.20866H5.6237C4.70322 5.20866 3.95703 5.95485 3.95703 6.87533V11.8753C3.95703 13.7163 5.44942 15.2087 7.29036 15.2087H9.9987M7.29036 5.20866V2.29199M7.29036 5.20866H12.707M12.707 5.20866H14.3737C15.2942 5.20866 16.0404 5.95485 16.0404 6.87533V11.8753C16.0404 13.7163 14.548 15.2087 12.707 15.2087H9.9987M12.707 5.20866V2.29199M9.9987 15.2087V17.7087"
          stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
    :
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-integrations icon-integrations-bold')}
    >
      <path
        d="M7.29036 5.20866H5.6237C4.70322 5.20866 3.95703 5.95485 3.95703 6.87533V11.8753C3.95703 13.7163 5.44942 15.2087 7.29036 15.2087H9.9987M7.29036 5.20866V2.29199M7.29036 5.20866H12.707M12.707 5.20866H14.3737C15.2942 5.20866 16.0404 5.95485 16.0404 6.87533V11.8753C16.0404 13.7163 14.548 15.2087 12.707 15.2087H9.9987M12.707 5.20866V2.29199M9.9987 15.2087V17.7087"
        stroke={theme === 'light' ? '#225AEA' : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
};

export default Integrations;
