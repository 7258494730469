import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Spin } from 'antd';

const DatabasePaginationOptionsModal = (
  {
    setModal,
    isMobile,
    visible,
    pageSize,
    setPageSize,
    optionsSelect,
    setPageNumber
  }
) => {
  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(false)}
           title={t('Page size options')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal products-pagination-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('You are able to change products per page from here.')
            }
          </p>
          <ul className="page-size-dropdown-list">
            {
              optionsSelect.map(el => (
                <li className={'page-size-dropdown-list-item'}
                    key={el}
                    onClick={() => {
                      setPageSize(el);
                      setPageNumber(1);
                      setModal(false);
                    }}
                >
                  {el}
                  {
                    pageSize === el ?
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           viewBox="0 0 16 16"
                           fill="none"
                           className={'checkmark'}
                      >
                        <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                              stroke="#225AEA"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                      </svg>
                      :
                      null
                  }
                </li>
              ))
            }
          </ul>
        </div>
      </Spin>
    </Modal>
  );
};

export default DatabasePaginationOptionsModal;
