import React from 'react';
import { Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '../../../../Components/Button';
import InputComponent from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';

const CreateModalComponent = (
  {
    isMobile,
    closeCreateCollectionModalHandler,
    isCreateModalVisible,
    inputCollectionName,
    createCollectionHandler,
    loading,
    setInputCollectionName,
  }) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={closeCreateCollectionModalHandler}
           title={t('Create new collection')}
           isMobile={isMobile}
           width={450}
           className="collection-create-modal collection-modal"
           open={isCreateModalVisible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Name your collection to make it easier to distinguish from others in the list.')
            }
          </p>
          <InputComponent value={inputCollectionName}
                          placeholder={t('Enter name for collection...')}
                          onChange={(e) => setInputCollectionName(e.target.value)}
                          className="input--collections-modal"
          />
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={closeCreateCollectionModalHandler}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={!inputCollectionName}
                           onClick={createCollectionHandler}
                           text={t('Create collection')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default CreateModalComponent;
