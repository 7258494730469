import React from 'react';

const CompetitorsDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
      <g filter="url(#filter0_dd_3098_45644)">
        <path
          d="M4 11C4 6.58172 7.58172 3 12 3C16.4183 3 20 6.58172 20 11C20 12.8487 19.3729 14.5509 18.3199 15.9056L21.7072 19.2929C22.0977 19.6834 22.0977 20.3166 21.7072 20.7071C21.3166 21.0976 20.6835 21.0976 20.2929 20.7071L16.9057 17.3198C15.551 18.3729 13.8487 19 12 19C7.58172 19 4 15.4183 4 11Z"
          fill="#F9F9FC" />
      </g>
      <defs>
        <filter id="filter0_dd_3098_45644" x="-3" y="-3" width="34" height="35" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3098_45644" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3098_45644" result="effect2_dropShadow_3098_45644" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3098_45644" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CompetitorsDashboard;
