import React, {useEffect} from 'react';
import ButtonComponent from "../../Components/Button";
import Modal from "../../Components/Modal";
import Creators from "./reducer";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { Divider, Spin } from 'antd';
import { openNotificationWithIcon } from '../../Components/Notification';
import { DefaultMsg } from '../../Components/Notification/notification-message';


const ModalAutoDsDisconnect = (
  {
    isVisibleModal,
    changeVisibleModalAutoDS,
    isMobile,
    autoDS,
    deleteResult,
    deleteErrors,
    resetErrors,
    deleteStore
  }) => {

  const {t} = useTranslation();

  const closeModalAutoDS = () => changeVisibleModalAutoDS({isVisibleModal: null, importProductId: null})

  const handleClose = () => closeModalAutoDS();

  useEffect(() => {
    if(deleteResult || deleteErrors){
      if(deleteResult){
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_delete',
          message: (
            <DefaultMsg text={null}
                        icon="notification_success"
                        title={`AutoDS was successfully disconnected`}
            />
          ),
        });
        closeModalAutoDS();
      }
      if(deleteErrors){
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_delete',
          message: (
            <DefaultMsg text={'Something went wrong, kindly try again.'}
                        icon="notification_warning"
                        title={'Something went wrong'}
            />
          ),
        });
      }
      resetErrors();
    }
  }, [deleteResult, deleteErrors])

  return (
    <Modal handleOk={null}
           handleClose={handleClose}
           title={`Disconnect AutoDS`}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={isVisibleModal === 'disconnect'}
           destroyOnClose
    >
      <Spin spinning={autoDS?.loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(`Are you sure you want to disconnect your AutoDS account? Without AutoDS account you won’t be able to import products in just a few clicks.`)
            }
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={handleClose}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={false}
                           onClick={() => {
                             deleteStore();
                           }}
                           text={t('Disconnect')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  autoDS: state.autoDS,
  deleteResult: state.autoDS?.deleteResult,
  deleteErrors: state.autoDS?.deleteErrors,
  isVisibleModal: state.autoDS.isVisibleModal,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  resetErrors: () => dispatch(Creators.resetState()),
  deleteStore: () => dispatch(Creators.deleteAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDsDisconnect);
