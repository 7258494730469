import React from 'react';
import {useNavigate} from "react-router-dom";
import ButtonComponent from "../../../Components/Button";
import Icon from "../../../Icon";
import cls from "classname";
import { useTranslation } from 'react-i18next';
import './UniversityCourseChapterCard.less';

const UniversityCourseChapterCard = (
  {
    info = {
      title: '',
      lessons_count: 1,
      thumbnail: null,
      completed: true,
    },
    onClick,
    isMobile
  }) => {

  const {title, lessons_count, completed, is_blocked, description, completed_lessons_count} = info;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const redirectHandler = () => navigate('/setting/plan');

  return (
    <div className={cls('university-course-chapter-card-wrapper', {
      'blocked': is_blocked
    })}
         onClick={() => is_blocked ? redirectHandler() : onClick()}
    >
      <div className="university-course-chapter-card-content">
        <div className="university-course-chapter-card-info">
          <div className="text">
            <h4 className="title">
              {title}
            </h4>
            {
              description ?
                <p className={'description'}>
                  {description}
                </p>
                :
                null
            }
          </div>
          <div className={cls('stat', {
            completed: completed
          })}>
            {
              completed ?
                <Icon type={'university_watched'}
                      role={'icon'}
                />
                :
                <Icon type={'watch_tutorial'}
                      role={'icon'}
                />
            }
            <span>
              {`${completed_lessons_count}/${lessons_count} ${t('lessons completed')}`}
            </span>
          </div>
        </div>
            <ButtonComponent onClick={() => null}
                             className={'university-course-chapter-card-button'}
            >
              <Icon type="arrow_forward_university" role="icon" width={11} height={11}/>
            </ButtonComponent>
      </div>
    </div>
  );
};

export default UniversityCourseChapterCard;
