import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Input } from 'antd';
import utils from '../../Utils/utils';
import cls from 'classname';
import Icon from '../../Icon';
import ButtonComponent from '../Button';
import InputComponent from '../Input';

function SignUpEmailForm({ error, ...props }) {

  const [errorText, setErrorText] = useState(null);

  const [isVisibleBtn, setIsVisibleBtn] = useState(false);
  const [isBackError, setIsBackError] = useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        if (error.length > 100) {
          setErrorText(t('Server response error'));
        } else {
          setErrorText(error);
        }
      } else {
        setErrorText(Object.values(error)[0]);
      }
      setIsBackError(true);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!error]);

  const onFinish = values => {
    const data = { ...values };
    delete data.accept;
    delete data.confirm;
    props.onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    let newError = null;
    let errorList = form.getFieldsError();

    errorList.forEach(field => {
      if (field.errors.length && !newError) newError = field.errors[0];
    });
    setIsBackError(false);
    setErrorText(newError);
  }

  function handleChange(e) {
    const { name, value } = e.target;

    if (utils.validateEmail(name === 'email' ? value : form.getFieldValue('email'))
      && form.getFieldValue('password')?.length >= 6
      && form.getFieldValue('first_name') && form.getFieldValue('last_name')) {
      setIsVisibleBtn(true);
    } else {
      setIsVisibleBtn(false);
    }
  }

  return (
    <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onSubmit"
          className="login-form sign-up-new-form"
          layout="vertical"
          requiredMark={false}
    >

      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text', { 'error-text_empty': !errorText })}>
          {
            isBackError
              ? errorText.includes('social') ?
                <span>{errorText}</span>
                : (<span>{errorText + ` ${t('Kindly try another one or')} `} <Link
                  to="/login">{t('Sign In')}</Link></span>)
              : <span>{errorText}</span>
          }

        </div>}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12} style={{ paddingRight: 6 }}>
          <Form.Item name="first_name"
                     rules={[
                       { required: true, message: 'Kindly insert your full name' },
                       {
                         type: 'string',
                         message: 'You can only use letters to insert your name',
                         pattern: /^[a-zA-Z ]+$/,
                         transform(value) {
                           if (value) return value.trim();
                         },
                       },
                     ]}
          >
            <InputComponent placeholder={t('First Name')}
                            onChange={e => {
                              handleChange(e);
                              handleClearError(e, 'first_name');
                            }}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={{ paddingLeft: 6 }}>
          <Form.Item name="last_name"
                     rules={[
                       { required: true, message: 'Kindly insert your full name' },
                       {
                         type: 'string',
                         message: 'You can only use letters to insert your name',
                         pattern: /^[a-zA-Z ]+$/,
                         transform(value) {
                           if (value) return value.trim();
                         },
                       },
                     ]}
          >
            <InputComponent placeholder={t('Last Name')}
                            name="last_name"
                            onChange={e => {
                              handleChange(e);
                              handleClearError(e, 'last_name');
                            }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="email"
                 validateStatus={isBackError ? 'error' : null}
                 rules={[
                   { required: true, message: 'Please insert your Email' },
                   { type: 'email', message: 'The email you entered is incorrect' },
                 ]}
      >
        <InputComponent placeholder={'Email address'}
                        name="email"
                        type="email"
                        onChange={e => {
                          handleChange(e);
                          handleClearError(e, 'email');
                        }}
        />
      </Form.Item>
      <Form.Item name="password"
                 rules={[{ required: true, message: 'Please insert your password' }]}
      >
        <Input.Password size="large"
                        placeholder={'Password with 6+ characters'}
                        autoComplete="current-password"
                        visibilityToggle
                        prefix={null}
                        onChange={e => {
                          handleChange(e);
                          handleClearError(e, 'password');
                        }}
                        iconRender={visible => (
                          visible
                            ? <Icon type="eye" color={'#C5CCE3'} />
                            : <Icon type="eye_invisible" color={'#C5CCE3'} />
                        )
                        }
        />
      </Form.Item>

      <ButtonComponent htmlType="submit"
                       className="login-form-submit"
                       disabled={!isVisibleBtn}
                       style={{ width: '100%' }}
      >
        Create Account
      </ButtonComponent>
    </Form>
  );
}

export default SignUpEmailForm;
