import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col } from 'antd';
import ProductDetailsCard from './ProductDetailsCard';
import './BenefitsBlock.less';

const skeletonList = [1, 2, 3, 4, 5, 6];

const BenefitsBlock = (
  {
    skeleton,
    product,
    isMobile
  }) => {

  const { t } = useTranslation();

  const [view, setView] = useState('benefits');

  const benefitsList = skeleton ? skeletonList : product?.benefits || [];
  const drawbacksList = skeleton ? skeletonList : product?.drawbacks || [];

  const renderHeader = (
    <>
      <span className={cls('title', { 'skeleton link': skeleton })}
            style={skeleton ? { width: 259, height: 28 } : {}}
      >
        {skeleton ? null : t('Benefits and Drawbacks')}
      </span>
      {
        skeleton ?
          <span className={'link skeleton'}
                style={{ width: isMobile ? '100%' : 183, height: 40, marginLeft: 'auto', marginBottom: 8 }}
          />
          :
          null
      }
    </>
  );

  const renderCard = (data, className) => (
    <Col xs={24} sm={12} lg={8} key={data?.id || data}>
      <div className={cls('benefit-drawback-card')}>
        {
          skeleton ?
            <span className={'link skeleton'}
                  style={{ width: 40, height: 40, marginRight: 16 }}
            />
            :
            <div className={cls('benefit-drawback-card-img-wrap', className)}
                 dangerouslySetInnerHTML={{ __html: data?.icon }}
            />
        }
        <div style={{ flex: '1 1' }}
             className={'benefit-drawback-card-text-wrapper'}
        >
          <span className={cls('benefit-drawback-card-label', { 'skeleton link': skeleton })}
                style={skeleton ? { width: 103, height: 20 } : {}}
          >
            {skeleton ? null : data?.title}
          </span>
          <span className={cls('benefit-drawback-card-text', { 'skeleton link': skeleton })}
                style={skeleton ? { width: 212, height: 20 } : {}}
          >
            {skeleton ? null : data?.description}
          </span>
        </div>
      </div>
    </Col>
  );

  return (
    <ProductDetailsCard header={renderHeader}
                        withLine={false}
                        headerToggle={skeleton ?
                          null
                          :
                          {
                            options: [
                              {
                                title: t('Benefits'),
                                key: 'benefits',
                              },
                              {
                                title: t('Drawbacks'),
                                key: 'drawbacks',
                              },
                            ],
                            handler: [view, setView],
                          }}
    >
      <Row gutter={[{ xs: 0, sm: 0, md: 16 }, 24]}>
        {
          [...(view === 'benefits' ? benefitsList : drawbacksList)].map(el => renderCard(el, view === 'drawbacks' ? 'drawback' : null))
        }
      </Row>
    </ProductDetailsCard>
  );
};

export default BenefitsBlock;
