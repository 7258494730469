import React, { useRef, useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OnboardingCreators from '../../Redux/OnboardingRedux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { Spin } from 'antd';
import PlanCard from './PlanCard';

const AvailablePlans = (
  {
    userInfo,
    plansList = [],
    setYearlySubscription,
    onBoarding,
    chargeLoading,
    userLoading,
    setSwitchPlan,
    createStripeSession,
    chargeLink,
    getRecharge,
  },
) => {

  const {
    isYearlySubscription,
    isRestoredCancel,
    cancelSubFetching,
    cancelTrialFetching,
    stripeLoading,
  } = onBoarding;

  const currentSubscription = userInfo?.subscriptions?.[0];
  const priceType = isYearlySubscription ? 'yearly' : 'monthly';

  const { t } = useTranslation();
  const windowRef = useRef(null);

  const [modal, setModal] = useState('switch_plan');

  function onChangeSwitch(state) {
    if (state === isYearlySubscription) return;
    setYearlySubscription(state);
  }

  function handleSelectPlan(plan) {
    const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];
    if (userInfo?.use_stripe && !Boolean(currentSubscription)) {
      setSwitchPlan({[switchCategoryId]: plan});
      createStripeSession({ plan_pricing: plan?.prices?.[priceType]?.id })
    } else if (currentSubscription?.payment_status?.id === 2) {
      if (chargeLink) windowRef.current.focus();
      else getRecharge(currentSubscription?.id)
    } else {
      if (currentSubscription?.expire_date && currentSubscription?.next_payment_date === null && plan?.prices?.[priceType]?.id === currentSubscription?.plan?.id) {
        setModal('undo_cancel_subscription');
      } else if (currentSubscription?.scheduled_plan?.id === plan?.prices?.[priceType]?.id){
        setModal('delete_subscription');
      } else {
        setSwitchPlan({[switchCategoryId]: plan})
        setModal('switch_subscription');
      }
    }
  }

  return (
    <Spin spinning={
      (isRestoredCancel && cancelSubFetching && cancelTrialFetching) ||
      stripeLoading || chargeLoading || userLoading}
    >
      <div className="plan-subpage-block-custom">
        <div className="plan-subpage-block-custom-title-wrapper">
          <span className="plan-subpage-block-custom-title">
            {t('Available plans')}
          </span>
          <div className={'switch-block-settings'}
               onClick={() => onChangeSwitch(!isYearlySubscription)}
          >
            <span className={cls('value', {
              active: !isYearlySubscription,
            })}
            >
              {t('Monthly')}
            </span>
            <span className={cls('value', {
              active: isYearlySubscription,
            })}
            >
              {t('Yearly')}
              <span className="value-tag">
                {t('Save 40%')}
              </span>
            </span>
          </div>
        </div>
        <div className="plan-subpage-block-custom-plans">
          {
            plansList?.filter(el => el?.status)
              ?.map((el, index) => (
                <PlanCard key={index}
                          plan={el}
                          onSelectPlan={handleSelectPlan}
                          setModal={setModal}
                />
              ))
          }
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  onBoarding: state?.onBoarding,
  chargeLoading: state.setting.chargeLoading,
  chargeLink: state.setting.chargeLink,
  userLoading: state.users.fetching,
  plansList: state?.plan?.plans,
});

const mapDispatchToProps = (dispatch) => ({
  setYearlySubscription: (data) => dispatch(OnboardingCreators.setYearlySubscription(data)),
  setSwitchPlan: (plan) => dispatch(OnboardingCreators.setSwitchPlan(plan)),
  createStripeSession: (value) => dispatch(OnboardingCreators.createStripeSessionRequest(value)),
  getRecharge: (data) => dispatch(SettingCreators.getRechargeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailablePlans);
