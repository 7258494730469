import React from 'react';
import { useSelector } from 'react-redux';

const Mastercard = () => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path d="M0.667969 0.667969H27.3346V27.3346H0.667969V0.667969Z" fill={theme === 'light' ? 'black' : 'white'} />
    </svg>
  );
};

export default Mastercard;
