import React, { useEffect, useRef, useState } from 'react';
import Creators from './reducer';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import UniversityVideoSkeleton from './components/skeletons/UniversityVideoSkeleton';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import ReactPlayer from 'react-player';
import UniversityPlaylistItem from './components/UniversityPlaylistItem';
import './UniversityVideoPage.less';

const UniversityVideoPage = (
  {
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    chapterInfo,
    saveVideoProgress,
    isShort,
    setIsShort,
    theme,
    isMobile,
  },
) => {

  const { courseID, chapter, video } = useParams();

  const videos = chapterInfo?.lessons || [];

  const [firstLoading, setFirstLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const watchedProgress = useRef(0);

  const { state } = useLocation();

  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  useEffect(() => {
    document.title = 'University - Dropship';
    setCurrentVideo(videos?.filter(el => el?.id === video)?.[0]);
    if (isShort) {
      if (breadCrumbs.length < 2) {
        addBreadCrumb({
          name: state ? state?.name : currentVideo?.title ? currentVideo?.title : video,
        });
      }
    } else {
      if (breadCrumbs.length > 4) {
        removeBreadCrumb();
      } else if (breadCrumbs.length < 4) {
        if (breadCrumbs.length === 1) {
          addBreadCrumb({
            name: courseID,
            link: `/dropship-university/${courseID}`,
          });
          addBreadCrumb({
            name: chapter,
            link: `/dropship-university/${courseID}/${chapter}`,
          });
        } else if (breadCrumbs.length === 2) {
          addBreadCrumb({
            name: state ? state?.name : chapter,
          });
        }
      }
    }
    if (firstLoading) {
      setTimeout(() => {
        setFirstLoading(false);
      }, 1000);
    }
    return () => setIsShort(false);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    setCurrentIndex(videos.findIndex(el => el?.id === currentVideo?.id));
    watchedProgress.current = 0;
    if (isShort) {
      removeBreadCrumb();
      addBreadCrumb({
        name: currentVideo?.title,
      });
    }
    return () => {
      if (currentVideo?.id) saveVideoProgress({
        id: currentVideo?.id,
        progress: watchedProgress.current >= 99 ? 100 : watchedProgress.current,
      });
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [currentVideo?.id]);

  const handlePlay = () => {
    if (currentIndex < videos?.length - 1) {
      setCurrentVideo(videos[currentIndex + 1]);
    } else {
      saveVideoProgress({
        id: currentVideo?.id,
        progress: watchedProgress.current >= 99 ? 100 : watchedProgress.current,
      });
    }
  };

  const handleProgress = (state) => {
    let playedSec = Math.floor(state?.playedSeconds);
    let durationInSec = currentVideo?.duration;
    watchedProgress.current = Math.floor((playedSec * 100) / durationInSec);
  };

  const getStartTime = (progress) => {
    let durationInSec = currentVideo?.duration;
    return Math.floor((durationInSec * progress) / 100);
  };

  if (firstLoading) return <UniversityVideoSkeleton isMobile={isMobile} />;

  return (
    <div className={'product-database-page university-page university-video-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
      />
      <div className="university-video-page-player-wrapper">
        <div className="video-wrapper">
          <ReactPlayer width={'100%'}
                       height={'100%'}
                       controls={true}
                       url={currentVideo?.video}
                       onEnded={handlePlay}
                       onProgress={handleProgress}
                       playing={true}
                       config={{
                         wistia: {
                           options: {
                             time: currentVideo?.progress < 100 ? getStartTime(currentVideo?.progress) : 0,
                           },
                         },
                         youtube: {
                           playerVars: {
                             start: currentVideo?.progress < 100 ? getStartTime(currentVideo?.progress) : 0,
                           },
                         },
                       }}
          />
        </div>
        {
          isMobile ?
            <div className="university-video-page-content-wrapper">
              <h2 className="university-video-page-title">
                {currentVideo?.title}
              </h2>
              <div className="university-video-page-text">
                {currentVideo?.description.split('\r\n').map((el, index) => (
                  <div key={index}>{/^[http|https]/.test(el) ?
                    <a href={el} target="_blank" rel="noopener noreferrer">{el}</a> : el}<br /></div>))}
              </div>
            </div>
            :
            <div className="playlist-wrapper">
              <p className="title-wrapper">
            <span className="title">
              {chapterInfo?.title}
            </span>
                <span className="lessons-quantity">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 16 16"
                   fill="none"
              >
                <path stroke="#661AC6"
                      d="M8.00085 1.8335L8.00065 4.16683M8.00085 11.8335V14.1668M1.83398 8.00063H4.16732M11.834 8.00063H14.1673M3.6403 3.63953L5.29008 5.28959M10.7114 10.7106L12.3613 12.3605M3.64049 12.361L5.2904 10.7111M10.7116 5.28992L12.3615 3.64"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
              </svg>
              <span className={'count'}>
                {chapterInfo?.completed_lessons_count}/{chapterInfo?.lessons_count}
              </span>
              completed
            </span>
              </p>
              <div className="playlist">
                {
                  videos.map((el, index) => (
                    <UniversityPlaylistItem data={el}
                                            key={el?.id}
                                            current={currentVideo}
                                            onClick={() => setCurrentVideo(el)}
                                            index={index}
                                            theme={theme}
                    />
                  ))
                }
              </div>
            </div>
        }
      </div>
      {
        isMobile ?
          <div className="playlist-wrapper">
            <p className="title-wrapper">
            <span className="title">
              {chapterInfo?.title}
            </span>
              <span className="lessons-quantity">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 16 16"
                   fill="none"
              >
                <path stroke="#661AC6"
                      d="M8.00085 1.8335L8.00065 4.16683M8.00085 11.8335V14.1668M1.83398 8.00063H4.16732M11.834 8.00063H14.1673M3.6403 3.63953L5.29008 5.28959M10.7114 10.7106L12.3613 12.3605M3.64049 12.361L5.2904 10.7111M10.7116 5.28992L12.3615 3.64"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
              </svg>
              <span className={'count'}>
                {chapterInfo?.completed_lessons_count}/{chapterInfo?.lessons_count}
              </span>
              completed
            </span>
            </p>
            <div className="playlist">
              {
                videos.map((el, index) => (
                  <UniversityPlaylistItem data={el}
                                          key={el?.id}
                                          current={currentVideo}
                                          onClick={() => setCurrentVideo(el)}
                                          index={index}
                                          theme={theme}
                  />
                ))
              }
            </div>
          </div>
          :
          <div className="university-video-page-content-wrapper">
            <h2 className="university-video-page-title">
              {currentVideo?.title}
            </h2>
            <div className="university-video-page-text">
              {currentVideo?.description.split('\r\n').map((el, index) => (<div key={index}>{/^[http|https]/.test(el) ?
                <a href={el} target="_blank" rel="noopener noreferrer">{el}</a> : el}<br /></div>))}
            </div>
          </div>
      }
      {
        isMobile ?
          <div style={{ height: 16 }} />
          :
          null
      }
      {/*<Divider type={'horizontal'} style={{margin: '40px 32px 32px'}} />*/}
      {/*<div>*/}
      {/*  Related videos*/}
      {/*</div>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  chapterInfo: state.universityPage.selectedChapter,
  isShort: state.universityPage.isShortPath,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  saveVideoProgress: (data) => dispatch(Creators.saveVideoProgressRequest(data)),
  setIsShort: (data) => dispatch(Creators.setIsShort(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversityVideoPage);
