import React from 'react';

const IntegrationsSkeleton = ({isMobile}) => {
  return (
    <div>
      Skeleton
    </div>
  );
};

export default IntegrationsSkeleton;
