import React from 'react';
import utils from '../../../../Utils/utils';
import cls from 'classname';
import Icon from '../../../../Icon';

const ImagesComponent = (
  {
    products,
    handleResend,
    id,
    name,
  }) => {

  return (
    <div className={'collections-page-list-item-images'}
         onClick={() => {
           handleResend({ collectionID: id, collectionName: name });
         }}
    >
      {
        [0,1,2,3].map(el => (
          <div className={cls("collections-page-list-item-image", {
            left: el === 0,
            right: el === 1
          })}
               key={el}
          >
            {
              products[el]?.image ?
                <img src={products[el]?.image}
                     onError={utils.addDefaultSrcCollections}
                />
                :
                <Icon type={'empty_collection'}
                      role={'icon'}
                />
            }
          </div>
        ))
      }
    </div>
  );
};

export default ImagesComponent;
