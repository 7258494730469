import React from 'react';
import { useSelector } from 'react-redux';

const ThreeDots = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path fill={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M9.99935 10.8332C10.4596 10.8332 10.8327 10.4601 10.8327 9.99984C10.8327 9.5396 10.4596 9.1665 9.99935 9.1665C9.53911 9.1665 9.16602 9.5396 9.16602 9.99984C9.16602 10.4601 9.53911 10.8332 9.99935 10.8332Z"
      />
      <path fill={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M16.8743 10.8332C17.3346 10.8332 17.7077 10.4601 17.7077 9.99984C17.7077 9.5396 17.3346 9.1665 16.8743 9.1665C16.4141 9.1665 16.041 9.5396 16.041 9.99984C16.041 10.4601 16.4141 10.8332 16.8743 10.8332Z"
      />
      <path fill={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M3.12435 10.8332C3.58459 10.8332 3.95768 10.4601 3.95768 9.99984C3.95768 9.5396 3.58459 9.1665 3.12435 9.1665C2.66411 9.1665 2.29102 9.5396 2.29102 9.99984C2.29102 10.4601 2.66411 10.8332 3.12435 10.8332Z"
      />
      <path stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M9.99935 10.8332C10.4596 10.8332 10.8327 10.4601 10.8327 9.99984C10.8327 9.5396 10.4596 9.1665 9.99935 9.1665C9.53911 9.1665 9.16602 9.5396 9.16602 9.99984C9.16602 10.4601 9.53911 10.8332 9.99935 10.8332Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
      <path stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M16.8743 10.8332C17.3346 10.8332 17.7077 10.4601 17.7077 9.99984C17.7077 9.5396 17.3346 9.1665 16.8743 9.1665C16.4141 9.1665 16.041 9.5396 16.041 9.99984C16.041 10.4601 16.4141 10.8332 16.8743 10.8332Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
      <path stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            d="M3.12435 10.8332C3.58459 10.8332 3.95768 10.4601 3.95768 9.99984C3.95768 9.5396 3.58459 9.1665 3.12435 9.1665C2.66411 9.1665 2.29102 9.5396 2.29102 9.99984C2.29102 10.4601 2.66411 10.8332 3.12435 10.8332Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThreeDots;
