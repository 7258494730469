import React from 'react';

const Skype = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M10.5311 14.8154C7.85852 14.8154 6.63746 13.4614 6.63746 12.4683C6.6371 12.2314 6.73487 12.0048 6.90776 11.8419C7.08065 11.6789 7.31341 11.5939 7.55127 11.607C8.69551 11.607 8.3962 13.3139 10.5311 13.3139C11.6223 13.3139 12.2633 12.6606 12.2633 12.0469C12.2633 11.6781 12.0514 11.2566 11.331 11.088L8.94714 10.4901C7.03212 10.0054 6.69838 8.94645 6.69838 7.96391C6.69838 5.92505 8.57367 5.18749 10.3589 5.18749C12.0038 5.18749 13.9585 6.09101 13.9585 7.31326C13.9585 7.8401 13.5162 8.12196 13.0023 8.12196C12.0249 8.12196 12.1892 6.75745 10.2238 6.75745C9.24644 6.75745 8.73259 7.21317 8.73259 7.85064C8.73259 8.48811 9.49277 8.70411 10.1602 8.84899L11.919 9.24412C13.8473 9.67612 14.3611 10.8009 14.3611 11.8783C14.3611 13.5352 13.0712 14.8154 10.5284 14.8154H10.5311ZM17.9104 11.3093C17.9867 10.8744 18.0248 10.4337 18.0243 9.99222C18.0336 7.77454 17.0538 5.66687 15.349 4.23735C13.6441 2.80784 11.3897 2.20361 9.19347 2.58755C8.51841 2.20102 7.75279 1.9984 6.97385 2.00013C5.37497 2.00985 3.9012 2.86205 3.10177 4.23915C2.30234 5.61625 2.29716 7.31168 3.08818 8.69357C2.6468 11.1055 3.41997 13.5796 5.15829 15.3179C6.89661 17.0561 9.38013 17.8385 11.8078 17.4127C12.482 17.7989 13.2467 18.0015 14.0247 18.0001C15.6228 17.9899 17.0957 17.138 17.8949 15.7618C18.6942 14.3855 18.7001 12.691 17.9104 11.3093Z"
        fill="#00AFF0" />
    </svg>
  );
};

export default Skype;
