import React from 'react';
import { useSelector } from 'react-redux';


const Menu = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40"
         fill="none"
         className={'icon icon-menu'}
    >
      <path stroke={theme === 'light' ? '#434C69' : 'rgba(255, 255, 255, .36)'}
            d="M19.3751 13.959H13.9584C13.0379 13.959 12.2917 14.7052 12.2917 15.6257V24.3757C12.2917 25.2961 13.0379 26.0423 13.9584 26.0423H19.3751M19.3751 13.959H26.0417C26.9622 13.959 27.7084 14.7052 27.7084 15.6257V24.3757C27.7084 25.2961 26.9622 26.0423 26.0417 26.0423H19.3751M19.3751 13.959V26.0423"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
      />
      <path fill={theme === 'light' ? '#434C69' : 'rgba(255, 255, 255, .36)'}
            d="M15.1667 17.0827C15.1667 17.4509 15.4651 17.7493 15.8333 17.7493C16.2015 17.7493 16.5 17.4509 16.5 17.0827C16.5 16.7145 16.2015 16.416 15.8333 16.416C15.4651 16.416 15.1667 16.7145 15.1667 17.0827ZM15.1667 19.9993C15.1667 20.3675 15.4651 20.666 15.8333 20.666C16.2015 20.666 16.5 20.3675 16.5 19.9993C16.5 19.6312 16.2015 19.3327 15.8333 19.3327C15.4651 19.3327 15.1667 19.6312 15.1667 19.9993ZM15.1667 22.916C15.1667 23.2842 15.4651 23.5827 15.8333 23.5827C16.2015 23.5827 16.5 23.2842 16.5 22.916C16.5 22.5478 16.2015 22.2493 15.8333 22.2493C15.4651 22.2493 15.1667 22.5478 15.1667 22.916Z"
            stroke="#434C69"
            strokeWidth="0.5"
      />
    </svg>
  );
};

export default Menu;
