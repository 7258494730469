import React from 'react';

const NotificationSuccess = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path stroke="#16895A"
          d="M12.4993 7.9165L8.74935 12.4998L7.08268 10.8332M17.7077 9.99984C17.7077 14.257 14.2565 17.7082 9.99935 17.7082C5.74215 17.7082 2.29102 14.257 2.29102 9.99984C2.29102 5.74264 5.74215 2.2915 9.99935 2.2915C14.2565 2.2915 17.7077 5.74264 17.7077 9.99984Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
);

export default NotificationSuccess;
