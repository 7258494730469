import React, {memo, useEffect, useMemo, useRef, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import acc from 'accounting';
import dayjs from "dayjs";
import cls from 'classname';
import { Divider, Spin, Switch, Table } from 'antd';
import utils from '../../../Utils/utils'
import { getColumns } from './TrackerTableColumns';
import Chart from '../../../Components/Charts/Chart';
import Icon from '../../../Icon';
import TrackerTableSkeleton from "./TrackerTableSkeleton";
import DatabaseProductsPagination from "../../ProductDatabasePage/components/DatabaseProductsPagination";
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const TrackerTable = (props) => {
  const {
    fetching,
    minDate,
    maxDate,
    loading,
    onSort,
    sortOrder,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    data,
    localData,
    totalProducts,
    report,
    withSummary,
    setVisible,
    setModal,
    setRecordToCalc,
    setRecordToPriceHistory,
    connectProduct,
    deleteProduct,
    getExpandableChart,
    selectedStoreStats,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    isTrial,
    isMobile=false,
    autoDS
  } = props;

  const tableRef = useRef(null);
  const { t } = useTranslation();

  const [expandedRow, setExpandedRow] = useState(null);
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const [showRawData, setShowRawData] = useState(false);

  const userInfo = useSelector(store => store.auth.userInfo);
  const chartData = useSelector(store => store.salesTracker.expandableChartData);
  const chartLoading = useSelector(store => store.salesTracker.expandableChartLoading);
  const theme = useSelector(store => store.nav.theme);

  //gps icon onClick/tracking handler
  const handleToggleTracking = (data) => {
    connectProduct(data);
  };

  const scrollListener = useCallback(() => {
    setVisibleDropdown(null);
  }, [])

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', scrollListener);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if(expandedRow) setExpandedRow(null)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[loading])

  //table scroll top on page number change
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.scrollTop = 0;
    }
  }, [pageNumber]);

  function handleExpandToggle(record, state) {
    setShowRawData(state);
    getExpandableChart({
      store_id: record?.store?.id,
      product_id: record?.id,
      date_range: {min: minDate, max: maxDate},
      raw_data: userInfo?.raw_data_access && state
    });
  }

  async function handleExpandRow(expanded, record){
    if(!expanded || (expanded && expandedRow === record.id)) {
      setShowRawData(false);
      setExpandedRow([]);
      return
    };
    if (expanded) {
      await getExpandableChart({
        store_id: record?.store?.id,
        product_id: record?.id,
        date_range: {min: minDate, max: maxDate},
        raw_data: userInfo?.raw_data_access && showRawData
      });
      setExpandedRow(record.id)
    }
  }

  const columns = useMemo(() => {
    return getColumns({
      sortOrder,
      handleToggleTracking,
      t,
      report,
      setVisible,
      setModal,
      setRecordToCalc,
      setRecordToPriceHistory,
      deleteProduct,
      handleExpandRow,
      handleOpenModalShopifyConnect,
      handleOpenModalAutoDSConnect,
      isTrial,
      isMobile,
      visibleDropdown,
      setVisibleDropdown
    })
  }, [sortOrder, handleExpandRow, visibleDropdown, autoDS?.email, autoDS?.stores?.length])

  return (
    <>
      {fetching ? <TrackerTableSkeleton report={report}/> : null}

      <div className={cls('product-database-table-wrapper', {
        'product-database-table-wrapper-hidden': fetching,
      })}>

        <DatabaseProductsPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalProducts={totalProducts}
          disabled={false}
          withOutTooltip={true}
        />

        <div id={'product-database-table-body'}
             className={cls('product-database-table-body sales-tracker-table-body', {
               'product-database-table-body_simple': report !== 'sales',
               'product-database-table-body--dark': theme === 'dark',
             })}
        >
          <Table
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table
                  {...other}
                  ref={tableRef}
                  className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            className="list-table"
            loading={loading}
            rowClassName={'product-database-table-row'}
            rowKey={(record) => record?.id || record?.date}
            dataSource={localData?.length ? localData : data}
            columns={columns}
            pagination={false}
            scroll={{
              x: 1600,
              y: `calc(100dvh - 58px - 48px${withSummary ? ' - 40px' : ''})`,
            }}
            sticky={{
              offsetHeader: 0,
              offsetScroll: 0,
              getContainer: () => document.querySelector('.product-database-table-body')
            }}
            sortDirections={['descend', 'ascend', null]}
            onChange={(pagination, filters, sorter) => {
              onSort(sorter);
            }}
            expandable={
              report === 'sales'
                ? {
                  expandedRowKeys: [expandedRow],
                  expandedRowRender: record => (
                    <Spin size="large" spinning={chartLoading}>
                      <div id={`expandable-chart-info-${record.id}`} className={'expandable-chart-info with-toggle'}>
                        {
                          userInfo?.raw_data_access ?
                            <div className="switch-option">
                              <Switch checked={showRawData}
                                      onChange={(state) => handleExpandToggle(record, state)}
                              />
                              <p onClick={() => handleExpandToggle(record, !showRawData)}
                              >
                                {t('Raw Data')}
                              </p>
                            </div>
                            :
                            null
                        }
                        <span>Updated {utils.durationAsString(new Date().toISOString(), chartData?.last_updated, t)}</span>
                        <Icon type={'attention_outline'}
                              role={'button'}
                              width={16}
                              height={16}
                              tooltipProps={{
                                overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                                placement: "top",
                                title: () => (
                                  <div className={'expandable-chart-info-tooltip'}>
                                    <div className={'expandable-chart-info-tooltip-title'}>
                                      {t('Dashboard data syncs every 2-3 hours. However, delays may occur when high volumes are being processed.')}
                                    </div>
                                    <Divider style={{margin: '12px 0'}}/>
                                    <div className={'expandable-chart-info-tooltip-text'}>
                                      <p>
                                        {
                                          t('Last updated on')
                                        }
                                      </p>
                                      <p>
                                        {dayjs(chartData?.last_updated).format('lll')} UTC
                                      </p>
                                    </div>
                                  </div>
                                ),
                                destroyTooltipOnHide: true,
                                getPopupContainer: () => document.getElementById(`expandable-chart-info-${record.id}`),
                              }}
                        />
                      </div>
                      <Chart height={isMobile ? null : '280px'}
                             type={'revenue'}
                             data={chartData?.chart?.length ?
                               [...chartData.chart]
                               :
                               []}
                             loading={chartLoading}
                             isMobile={isMobile}
                             withRawData={showRawData}
                             rawData={chartData?.raw_data_chart?.length ? [...chartData?.raw_data_chart] : []}
                      />
                    </Spin>
                  ),
                  onExpand: handleExpandRow,
                  expandIcon: ({ expanded, onExpand, record}) => (
                    <Icon type="min_max_input_divider"
                          width={7}
                          height={10}
                          role="button"
                          btnType={expanded ?
                            report !== 'sales' ? record?.month_sales : record?.sales ?
                              'icon-btn-expand-row active'
                              :
                              'icon-btn-expand-row active empty'
                            :
                            report !== 'sales' ? record?.month_sales : record?.sales ?
                              'icon-btn-expand-row'
                              :
                              'icon-btn-expand-row empty'
                          }
                          onClick={e => onExpand(record, e)}
                    />
                  ),
                } : false
            }
            summary={(currentData) => {
              if (!withSummary) return null;
              const sumSales = data?.reduce((accumulator, currentValue) => accumulator + currentValue.sales, 0) || 0;
              const sumRevenue = data?.reduce((accumulator, currentValue) => accumulator + currentValue.revenue, 0) || 0;
              const sumReturnRevenue = data?.reduce((accumulator, currentValue) => accumulator + currentValue.returns_revenue, 0) || 0;
              const sumReturnSales = data?.reduce((accumulator, currentValue) => accumulator + (report === 'sales' ? currentValue.returns : currentValue.returns_sales), 0) || 0;
              const sumReturnUnique = data?.reduce((accumulator, currentValue) => accumulator + currentValue.returns_unique, 0) || 0;

              return (
                <Table.Summary fixed="top">
                  <Table.Summary.Row>
                    {report === 'sales' && <Table.Summary.Cell index={0} colSpan={1}/>}
                    <Table.Summary.Cell index={report === 'sales' ? 1 : 0} colSpan={report === 'sales' ? 3 : 1}>{t('Summary')}</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>{report === 'sales' ? selectedStoreStats?.sales : sumSales}</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>${acc.format(report === 'sales' ? selectedStoreStats?.revenue : sumRevenue, 2, ',', '.')}</Table.Summary.Cell>
                    {
                      report === 'sales' || report === 'product' ?
                        null
                        :
                        <Table.Summary.Cell colSpan={1}>
                          {acc.format(selectedStoreStats?.unique, 0, ',', '.')}
                        </Table.Summary.Cell>
                    }
                    <Table.Summary.Cell colSpan={1}>
                      {acc.format(sumReturnSales, 0, ',', '.')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={report === 'sales' ? 4 : 1}>
                      {
                        sumReturnRevenue > 0 ?
                          `$${acc.format(sumReturnRevenue, 2, ',', '.')}`
                          :
                          sumReturnRevenue === 0 ?
                            `$${acc.format(Math.abs(sumReturnRevenue), 2, ',', '.')}`
                            :
                            `-$${acc.format(Math.abs(sumReturnRevenue), 2, ',', '.')}`
                      }
                    </Table.Summary.Cell>
                    {
                      report === 'sales' || report === 'product' ?
                        null
                        :
                        <Table.Summary.Cell colSpan={1}>
                          {acc.format(sumReturnUnique, 0, ',', '.')}
                        </Table.Summary.Cell>
                    }
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default memo(TrackerTable, (prev, next) => {
    return prev?.fetching === next?.fetching &&
    prev?.minDate === next?.minDate &&
    prev?.maxDate === next?.maxDate &&
    prev?.loading === next?.loading &&
    prev?.pageSize === next?.pageSize &&
    prev?.pageNumber === next?.pageNumber &&
    JSON.stringify(prev?.data) === JSON.stringify(next?.data) &&
    JSON.stringify(prev?.autoDS?.email) === JSON.stringify(next?.autoDS?.email) &&
    JSON.stringify(prev?.autoDS?.stores?.length) === JSON.stringify(next?.autoDS?.stores?.length) &&
    JSON.stringify(prev?.localData) === JSON.stringify(next?.localData) &&
    prev?.totalProducts === next?.totalProducts &&
    prev?.report === next?.report &&
    prev?.withSummary === next?.withSummary &&
    JSON.stringify(prev?.selectedStoreStats) === JSON.stringify(next?.selectedStoreStats) &&
    prev?.isTrial === next?.isTrial &&
    prev?.isMobile === next?.isMobile
  }
);
