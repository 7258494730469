import React from 'react';

const ArrowSelectDown = () => (

  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path stroke="#707BA0"
          d="M6.66699 8.33301L9.41107 11.0771C9.73651 11.4025 10.2641 11.4025 10.5896 11.0771L13.3337 8.33301"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
);

export default ArrowSelectDown;
