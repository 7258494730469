import React  from 'react';
import { Row, Col } from 'antd';
import SwitchSubscriptionPeriodSkeleton from "../SwitchSubscriptionPeriod/SwitchSubscriptionPeriodSkeleton";
import PlanCardOnboardingSkeleton from "../PlanCardOnboarding/PlanCardOnboardingSkeleton";
import './styles.less';

const PlanSubPage = ({isMobile}) => {

  return (
    <div className="plan-subpage">

      <SwitchSubscriptionPeriodSkeleton />

      <Row gutter={[{ sm: 0, md: 16 }, { xs: 99, sm: 99, md: 0 }]}
           className="plan-wrapper"
      >
        {
          [1,2,3].map(el => (
              <Col xs={24} sm={24} md={8} key={el}>
                <PlanCardOnboardingSkeleton index={el} />
              </Col>
            ))
        }
      </Row>
    </div>
  );
}

export default PlanSubPage;
