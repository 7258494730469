import React from 'react';

export const ShopifyBtnIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M13.5299 4.43017C13.5197 4.35828 13.458 4.3172 13.4067 4.3172C13.3553 4.3172 12.3386 4.29666 12.3386 4.29666C12.3386 4.29666 11.4862 3.47506 11.404 3.38263C11.3219 3.30048 11.1576 3.32102 11.0959 3.34156C11.0959 3.34156 10.9316 3.3929 10.6646 3.47506C10.6235 3.33129 10.5516 3.1567 10.4592 2.97184C10.1614 2.39672 9.7095 2.08862 9.17547 2.08862C9.13439 2.08862 9.10358 2.08862 9.0625 2.09889C9.04196 2.07835 9.03169 2.05781 9.01115 2.04754C8.77494 1.80106 8.47711 1.67782 8.11766 1.68809C7.42957 1.70863 6.74149 2.21186 6.17664 3.09508C5.78638 3.72154 5.48855 4.50206 5.39613 5.10799C4.60534 5.35447 4.05076 5.52905 4.03022 5.52905C3.62969 5.65229 3.61942 5.66256 3.56807 6.04255C3.54753 6.33011 2.5 14.4331 2.5 14.4331L11.2911 15.9531L15.1012 15.0082C15.091 15.0082 13.5402 4.50206 13.5299 4.43017ZM10.223 3.61884C10.0176 3.68046 9.79166 3.75235 9.54518 3.83451C9.54518 3.48533 9.49383 2.99238 9.33978 2.58158C9.86355 2.66374 10.1203 3.2594 10.223 3.61884ZM9.08304 3.96802C8.62089 4.1118 8.11766 4.26585 7.61443 4.4199C7.75821 3.87559 8.02523 3.34156 8.35387 2.98211C8.47711 2.8486 8.6517 2.70482 8.84683 2.62266C9.05223 3.02319 9.09331 3.58803 9.08304 3.96802ZM8.1382 2.15024C8.30252 2.15024 8.43603 2.18105 8.549 2.26321C8.36414 2.35564 8.17928 2.49942 8.01496 2.68428C7.57335 3.1567 7.23445 3.88586 7.10094 4.59449C6.67987 4.728 6.26907 4.85124 5.89935 4.96421C6.14583 3.84478 7.0804 2.18105 8.1382 2.15024Z"
        fill="#95BF47" />
      <path
        d="M13.4066 4.31746C13.3553 4.31746 12.3386 4.29692 12.3386 4.29692C12.3386 4.29692 11.4861 3.47532 11.404 3.38289C11.3732 3.35208 11.3321 3.33154 11.291 3.33154V15.9533L15.1012 15.0085C15.1012 15.0085 13.5504 4.50232 13.5401 4.43043C13.5196 4.35854 13.458 4.31746 13.4066 4.31746Z"
        fill="#5E8E3E" />
      <path
        d="M9.17572 6.27836L8.73411 7.93183C8.73411 7.93183 8.24115 7.70589 7.65576 7.74697C6.79309 7.79832 6.79309 8.34262 6.79309 8.47613C6.84444 9.21557 8.78546 9.37989 8.89843 11.1155C8.98059 12.4814 8.17953 13.416 7.00876 13.4879C5.61204 13.5598 4.8418 12.7382 4.8418 12.7382L5.13963 11.475C5.13963 11.475 5.92014 12.0603 6.53634 12.0193C6.93687 11.9987 7.09092 11.6598 7.07038 11.4339C7.00876 10.4685 5.42718 10.5301 5.32448 8.93828C5.24232 7.60319 6.11527 6.25783 8.04602 6.13459C8.79573 6.08324 9.17572 6.27836 9.17572 6.27836Z"
        fill="#EDFFE0" />
    </svg>
;

export default ShopifyBtnIcon;
