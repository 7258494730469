import React from 'react';
import { Typography } from 'antd';
import SaveToCollection from '../../../Components/SaveToCollections/save-to-collections';
import './NameProductBlock.less';

const { Text } = Typography;

const NameProductBlock = (
  {
    skeleton,
    title,
    textLink,
    link,
    addToCollectionFunc,
    product,
    isMobile
  }) => {

  if (skeleton) {
    return (
      <div className={'product-details-page-name-block'}>
        <span className={'link skeleton'}
              style={{ width: 20, height: 20, marginRight: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 131, height: 28 }}
        />
        <span className={'link skeleton'}
              style={{ width: 74, height: 20, marginLeft: 'auto' }}
        />
      </div>);
  } else {
    return (
      <div className={'product-details-page-name-block'}>
        <SaveToCollection saveFunc={(isSaved) =>
          addToCollectionFunc(
            product?.product_id,
            isSaved,
            product?.title,
          )
        }
                          isSave={product.saved}
        />
        <Text className={'product-details-page-name-block-title'}
              style={{ width: isMobile ? 'calc(100% - 130px)' : 'calc(100% - 110px)' }}
              ellipsis={{ tooltip: title }}
        >
          {title}
        </Text>
        <a className={'link'}
           href={link || '#'}
           target="_blank"
           rel="noopener noreferrer"
        >
          {textLink}
        </a>
      </div>
    );
  }
};

export default NameProductBlock;
