import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { push, goBack } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Layout, Card, Spin, Carousel } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import LoginForm from '../../Components/Forms/LoginForm';
import Images from '../../Images';
import AuthActions from './reducer';
import ButtonComponent from '../../Components/Button';

function LoginPage(props) {
  const { auth, error, fetching, rememberMe, isMobile } = props;

  const navigate = useNavigate();
  const { hash } = useParams();
  const { t } = useTranslation();

  const otpRequired = error?.otp_required;

  function handleSubmit(data) {
    if (fetching) return;
    if (otpRequired) props.signIn(data.email, data.password, data?.otp_pass);
    else props.signIn(data.email, data.password);
  }

  const responseGoogle = useGoogleLogin({
    onSuccess: (response) => {
      if (response && response?.access_token) {
        props.socialAuth({
          access_token: response?.access_token,
          socialType: 'google',
          user: null,
          promo_offer_id: null,
        });
      }
    },
    onFailure: (response) => {
      if (response && response?.access_token) {
        props.socialAuth({
          access_token: response?.access_token,
          socialType: 'google',
          user: null,
          promo_offer_id: null,
        });
      }
    },
  });

  const responseApple = (response) => {
    if (response && response.authorization) {
      if (response?.user) {
        props.socialAuth({
          access_token: response.authorization.id_token,
          socialType: 'apple',
          user: response.user,
          promo_offer_id: null,
        });
      } else {
        props.socialAuth({
          access_token: response.authorization.id_token,
          socialType: 'apple',
          user: null,
          promo_offer_id: null,
        });
      }
    }
  };

  useEffect(() => {
    if (hash) localStorage.setItem('ref_link_info', hash);
    if (auth.userInfo && !auth.isAdmin) {
      if (auth.userInfo?.onboarding_finished) {
        navigate('/dashboard');
      } else {
        navigate('/onboarding/plan');
      }
    } else if (auth.userInfo && auth.isAdmin) {
      navigate('/admin/portfolio');
    } else {
      document.title = 'Login - Dropship';
    }
    if (error) props.resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleClearError = () => {
    if (error) props.resetError();
  };

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-content">
          <img src={Images.logo} alt="" className={'login-logo'} />
          {(error && (typeof error === 'string' || otpRequired)) && (
            <div className={'force-logout-error'}>
              {typeof error === 'string' ? error : 'OTP is required'}
            </div>
          )}
          <div className="login-card-wrapper">
            <Card className="login-card">
              <h2 className="login-card-title">
                Sign in to Dropship
              </h2>
              <h3 className="login-card-subtitle">
                Welcome back! Sign in to your account below.
              </h3>
              <div className="social-btn-block-login">
                <ButtonComponent className="social-btn-login"
                                 onClick={responseGoogle}
                >
                  <img className="social-btn-login-icon"
                       src={Images.google}
                       alt=""
                  />
                  Google
                </ButtonComponent>
                <AppleSignin authOptions={{
                  clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                  scope: 'email name',
                  redirectURI: process.env.REACT_APP_BASE_URI + '/login',
                  state: 'state',
                  nonce: 'nonce',
                  usePopup: true,
                }}
                             className="apple-auth-btn"
                             noDefaultStyle={false}
                             onSuccess={responseApple}
                             onError={responseApple}
                             skipScript={false}
                             render={(renderProps) => (
                               <ButtonComponent className="social-btn-login social-btn-login--apple"
                                                onClick={renderProps.onClick}
                               >
                                 <img className="social-btn-login-icon"
                                      src={Images.apple}
                                      alt=""
                                 />
                                 Apple
                               </ButtonComponent>
                             )}
                />
              </div>
              <div className="login-card-divider">
                <div />
                <span>
                  or sign in with email
                </span>
                <div />
              </div>
              <LoginForm rememberMe={rememberMe}
                         setRemember={props.setRemember}
                         onSubmit={handleSubmit}
                         error={error?.message}
                         otpRequired={otpRequired}
              />
            </Card>
          </div>
          <div className="forgot-password">
            <Link to="/restore-password">
              Forgot your password?
            </Link>
          </div>
          <div className="create-account">
            New to Dropship? <Link to="/sign-up" onClick={handleClearError}>Create account</Link>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.auth.error,
  fetching: state.auth.fetching,
  rememberMe: state.auth.rememberMe,
  isMobile: state.nav.isMobile,
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  navPush: (path, state) => dispatch(push(path, state)),
  navGoBack: () => dispatch(goBack()),
  signIn: (email, password, otp_pass) =>
    dispatch(AuthActions.signInRequest(email, password, otp_pass)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  setRemember: (data) => dispatch(AuthActions.setRemember(data)),
  socialAuth: (accessToken, type, user) =>
    dispatch(AuthActions.socialAuthRequest(accessToken, type, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
