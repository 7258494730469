import React from 'react';
import {useNavigate} from "react-router-dom";
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from "../../../Icon";
import ButtonComponent from "../../../Components/Button";
import UniversityPageCourseWrapperSkeleton from "./skeletons/UniversityPageCourseWrapperSkeleton";
import { formatDuration } from '../../../Utils/utils';
import { Divider } from 'antd';
import './UniversityPageCourseWrapper.less';

const UniversityPageCourseWrapper = (
  {
    skeleton,
    isMobile,
    disabled,
    info,
    dashboard,
    isComingSoon,
  }
) => {

  const {t} = useTranslation();

  const navigate = useNavigate();

  const {id, title, description, thumbnail, lessons_count, completed_lessons_count} = info;

  const clickHandler = () => {
    if(isComingSoon) return

    if(!disabled && (isMobile && dashboard)){
      navigate(`/dropship-university`)
    }else{
      navigate(`/dropship-university/${id}`, {
        state: {
          name: title,
        }
      })
    }
  }

  if (skeleton) return (<UniversityPageCourseWrapperSkeleton />)

  return (
    <div className={cls('university-page-course-wrapper', {
      'no-clickable': isComingSoon
    })}
         onClick={clickHandler}
    >
      <div className="university-page-course-image"
           style={{backgroundImage: `url("${thumbnail}")`}}
      />
      <div className="university-page-course-info">
        <div className='university-page-course-info-lessons'>
          <span className={'university-page-course-info-lessons-tag'}>
            {t('Trending course')}
          </span>
          <Divider type={'vertical'}
                   style={{margin: 'auto 12px', height: 16}}
          />
          <span className={'university-page-course-info-lessons-progress'}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="16"
                 height="16"
                 viewBox="0 0 16 16"
                 fill="none"
            >
              <path stroke="#661AC6"
                    d="M8.00085 1.8335L8.00065 4.16683M8.00085 11.8335V14.1668M1.83398 8.00063H4.16732M11.834 8.00063H14.1673M3.6403 3.63953L5.29008 5.28959M10.7114 10.7106L12.3613 12.3605M3.64049 12.361L5.2904 10.7111M10.7116 5.28992L12.3615 3.64"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
              />
            </svg>
            <span className={'count'}>
              {completed_lessons_count}/{lessons_count}
            </span>
            {
              t('completed')
            }
          </span>
          <Divider type={'vertical'}
                   style={{margin: 'auto 12px', height: 16}}
          />
          <span className={'university-page-course-info-lessons-progress university-page-course-info-lessons-duration'}>
            <Icon role={'icon'} type={'clock'} />
            <span className={'count'}>
              {formatDuration(0)}
            </span>
            to watch
          </span>
        </div>
        <h3 className="university-page-course-info-title">
          {title}
        </h3>
        <p className="university-page-course-info-text">
          {description}
        </p>
        <ButtonComponent className='university-page-course-info-watch'
                         text={isComingSoon ? t('Coming Soon') : t('Watch Course')}
                         disabled={disabled || isComingSoon}
        />
      </div>
    </div>
  );
};

UniversityPageCourseWrapper.defaultProps = {
  info: {
    id: 1,
    title: 'Title',
    description: 'Description',
    thumbnail: null,
    lessons_count: 1
  }
}

export default UniversityPageCourseWrapper;
