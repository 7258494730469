import React from 'react';

const Moon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={'moon'}
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M9.05363 2.97936C5.58906 3.44192 2.91666 6.40889 2.91666 10C2.91666 13.912 6.08797 17.0834 9.99999 17.0834C13.5911 17.0834 16.5581 14.411 17.0207 10.9464C16.2749 11.2755 15.45 11.4584 14.5833 11.4584C11.2466 11.4584 8.54166 8.75341 8.54166 5.41669C8.54166 4.54998 8.72448 3.72515 9.05363 2.97936ZM1.66666 10C1.66666 5.39765 5.39762 1.66669 9.99999 1.66669C10.0567 1.66669 10.1134 1.66726 10.1699 1.66839C10.4011 1.67302 10.6109 1.80501 10.7152 2.0115C10.8195 2.21798 10.8012 2.46518 10.6677 2.65407C10.1158 3.43467 9.79166 4.38689 9.79166 5.41669C9.79166 8.06305 11.937 10.2084 14.5833 10.2084C15.6131 10.2084 16.5653 9.88421 17.3459 9.33233C17.5348 9.19879 17.782 9.1805 17.9885 9.28479C18.195 9.38908 18.327 9.59888 18.3316 9.83016C18.3328 9.88663 18.3333 9.94325 18.3333 10C18.3333 14.6024 14.6024 18.3334 9.99999 18.3334C5.39762 18.3334 1.66666 14.6024 1.66666 10Z"
            fill="#434C69"
      />
      <path fill="#434C69"
            d="M13.5337 4.18132L14.21 2.82868C14.3636 2.52158 14.8018 2.52158 14.9554 2.82868L15.6317 4.18132C15.672 4.26196 15.7374 4.32734 15.818 4.36766L17.1707 5.04398C17.4778 5.19753 17.4778 5.63578 17.1707 5.78934L15.818 6.46566C15.7374 6.50597 15.672 6.57136 15.6317 6.65199L14.9554 8.00463C14.8018 8.31174 14.3636 8.31174 14.21 8.00463L13.5337 6.65199C13.4934 6.57136 13.428 6.50597 13.3473 6.46566L11.9947 5.78934C11.6876 5.63578 11.6876 5.19753 11.9947 5.04398L13.3473 4.36766C13.428 4.32734 13.4934 4.26196 13.5337 4.18132Z"
      />
    </svg>
  );
};

export default Moon;
