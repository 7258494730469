import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import { DefaultMsg } from '../../../Components/Notification/notification-message';

const key = `open${Date.now()}`;
export const openNotification = ({ text }) => {
  openNotificationWithIcon({
    key,
    style: { width: '400px' },
    className: 'notification notification_delete',
    message: (
      <DefaultMsg text={'This collection was successfully removed from your collections list.'}
                  icon="notification_delete"
                  title={'Collection was successfully removed'}
      />
    ),
  });
};
