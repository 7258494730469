import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Creators from './reducer';
import { Divider, Dropdown, Spin } from 'antd';
import ButtonComponent from '../../Components/Button';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Modal from '../../Components/Modal';
import { connect } from 'react-redux';

// const text = 'You can import the product to your existing AutoDS store.';

const ModalAutoDSImport = ({ isMobile, autoDS, changeVisibleModalAutoDS, importProductToAutoStore, resetState }) => {

  const { loading, isVisibleModal, stores, importProductResult } = autoDS;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [selectStore, setSelectStore] = useState(null);
  const [storeName, setStoreName] = useState(null);


  const closeModalAutoDS = () => changeVisibleModalAutoDS({ isVisibleModal: null, importProductId: null });

  const handleClose = () => {
    if (selectStore) setSelectStore(null);
    if (isVisibleModal === 'import') closeModalAutoDS();
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
    if (!value && storeName) setStoreName(null);
  };

  const handleSelectStore = (store) => {
    if (selectStore?.id !== store?.id) setSelectStore(store);
    if (storeName) setStoreName(null);
    setVisible(false);
  };

  const handleImport = () => {
    let data = {
      ...autoDS?.importProductId,
      'store_id': selectStore?.id,
    };
    importProductToAutoStore(data);
  };

  useEffect(() => {
    if (isVisibleModal === 'import' && stores?.length) setSelectStore(stores?.[0]?.store);
  }, [isVisibleModal]);

  useEffect(() => {
    if (importProductResult) resetState();
  }, [importProductResult]);

  return (
    <Modal handleOk={null}
           handleClose={handleClose}
           title={t('Import product to AutoDS')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal shopify-import"
           open={isVisibleModal === 'import'}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t('To prevent complications when importing products into various regions, we recommend selecting the "WorldWide" as the "Ship to Region" option in your ')}
            <a href="https://platform.autods.com/settings/supplierSettings"
               target="_blank">{t('AutoDS Supplier Settings')}</a>
          </p>
          <div className={'import-dropdown-wrap'}>
            <Dropdown overlayClassName={'dropdown-store-wrapper'}
                      destroyPopupOnHide={true}
                      getPopupContainer={trigger => isMobile ? document.getElementById('global-wrap') : trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      dropdownRender={() => (
                        <>
                          <ul className="user_store-list" style={{ maxHeight: 168 }}>
                            {
                              stores?.map(({ store }) => (
                                <li key={store?.id}
                                    className="user_store-item user_store-item_clickable"
                                    onClick={() => handleSelectStore(store)}
                                >
                                  <span className="user_store-name">{store?.name}</span>
                                  <span className="user_store-link">{store?.store_url}</span>
                                  {
                                    store?.id === selectStore?.id ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                           fill="none">
                                        <path d="M1.83398 10.0625L6.00065 13.5L14.1673 2.5" stroke="#225AEA"
                                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>
                                      :
                                      null
                                  }
                                </li>
                              ))
                            }
                          </ul>
                        </>
                      )}
                      trigger={['click']}
                      open={visible}
            >
              <div className="dropdown-store">
                <span className="dropdown-store_name">
                  {selectStore?.name}
                </span>
                <span className="dropdown-store_domain">
                  {selectStore?.store_url}
                </span>
                <ArrowSelectDown />
              </div>
            </Dropdown>
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={handleClose}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={loading}
                           onClick={handleImport}
                           text={t('Import')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  importProductToAutoStore: (data) => dispatch(Creators.importProductToAutoStoreRequest(data)),
  resetState: () => dispatch(Creators.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDSImport);
