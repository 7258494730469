import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import ButtonComponent from '../Button';
import './styles-empty.less';

const EmptyState = ({ setInputText, btnText, isCollections = false }) => {
  const { t } = useTranslation();

  return (
    <div className={cls('empty-state-product', {
      collection: isCollections
    })}>
      <div className={'empty-state-product-skeleton'}>
        <span className={'link skeleton'}
              style={{ width: 12, height: 12 }}
        />
        <span className={'link skeleton'}
              style={{ width: 20, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 77, height: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 18, height: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 48, height: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 28, height: 8 }}
        />
      </div>
      <h3>
        {
          t('No products found')
        }
      </h3>
      <p>
        {
          t('Try changing your search requirements or try again.')
        }
      </p>
      <ButtonComponent className={'empty-state-product-btn'}
                       text={t(btnText)}
                       onClick={() => setInputText(null)}

      />
    </div>
  );
};

export default EmptyState;
