import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const BookmarkButton = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return !outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon icon-bookmark icon-bookmark-bold bookmark')}
           fill="none"
      >
        <path stroke={theme === 'light' ? "#707BA0" : 'rgba(255,255,255,.36)'}
              d="M16.0423 16.563V4.2915C16.0423 3.18693 15.1469 2.2915 14.0423 2.2915H5.95898C4.85441 2.2915 3.95898 3.18694 3.95898 4.29151V16.563C3.95898 17.3637 4.85313 17.8397 5.51738 17.3925L8.88387 15.1265C9.55904 14.6721 10.4423 14.6721 11.1174 15.1265L14.4839 17.3925C15.1482 17.8397 16.0423 17.3637 16.0423 16.563Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon icon-bookmark icon-bookmark-outline bookmark')}
           fill="none"
      >
        <path fill={theme === 'light' ? 'black' : 'rgba(255,255,255,.36)'}
              className={'bookmark'}
              d="M5.62565 1.66675C4.36 1.66675 3.33398 2.69276 3.33398 3.95841V16.8763C3.33398 18.044 4.63794 18.7381 5.60664 18.0861L9.41899 15.52C9.77064 15.2833 10.2307 15.2833 10.5823 15.52L14.3947 18.0861C15.3634 18.7381 16.6673 18.044 16.6673 16.8763V3.95841C16.6673 2.69276 15.6413 1.66675 14.3757 1.66675H5.62565Z"
        />
      </svg>
    );
}

export default BookmarkButton;
