import React from 'react';

const DatabasePrice = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.29199 9.30115V3.95817C2.29199 3.0377 3.03818 2.2915 3.95866 2.2915H9.30164C9.74366 2.2915 10.1676 2.4671 10.4801 2.77966L17.1551 9.45466C17.806 10.1055 17.806 11.1608 17.1551 11.8117L11.8122 17.1547C11.1613 17.8055 10.106 17.8055 9.45515 17.1547L2.78015 10.4797C2.46759 10.1671 2.29199 9.74317 2.29199 9.30115Z"
      stroke="#707BA0" strokeWidth="1.5" strokeLinejoin="round" />
    <path
      d="M6.87533 6.24984C6.87533 6.59502 6.5955 6.87484 6.25033 6.87484C5.90515 6.87484 5.62533 6.59502 5.62533 6.24984C5.62533 5.90466 5.90515 5.62484 6.25033 5.62484C6.5955 5.62484 6.87533 5.90466 6.87533 6.24984Z"
      stroke="#707BA0" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);
export default DatabasePrice;
