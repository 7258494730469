import React from 'react';
import cls from 'classname';
import { formatDurationAdvanced } from '../../../Utils/utils';
import Icon from '../../../Icon';

const UniversityPlaylistItem = (
  {
    data,
    current,
    onClick,
  }) => {

  return (
    <div className={cls('playlist-item', {
      'active': current?.id === data?.id,
      'completed': data?.completed
    })}
         onClick={onClick}
    >
      <div className={'playlist-item-icon'}>
        {
          current?.id !== data?.id ?
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2083 3.125C6.29632 3.125 3.125 6.29632 3.125 10.2083C3.125 14.1204 6.29632 17.2917 10.2083 17.2917C14.1204 17.2917 17.2917 14.1204 17.2917 10.2083C17.2917 6.29632 14.1204 3.125 10.2083 3.125ZM1.875 10.2083C1.875 5.60596 5.60596 1.875 10.2083 1.875C14.8107 1.875 18.5417 5.60596 18.5417 10.2083C18.5417 14.8107 14.8107 18.5417 10.2083 18.5417C5.60596 18.5417 1.875 14.8107 1.875 10.2083Z"
                    fill="#A1AAC8"
              />
              <path fill="#A1AAC8"
                    d="M8.54167 12.5449V7.87172C8.54167 7.53697 8.91647 7.33888 9.19306 7.52746L12.6201 9.86407C12.8627 10.0295 12.8627 10.3872 12.6201 10.5526L9.19306 12.8892C8.91647 13.0778 8.54167 12.8797 8.54167 12.5449Z"
              />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM8.98505 7.31897C8.70846 7.13038 8.33366 7.32847 8.33366 7.66323V12.3364C8.33366 12.6712 8.70846 12.8693 8.98505 12.6807L12.4121 10.3441C12.6547 10.1787 12.6547 9.82098 12.4121 9.65558L8.98505 7.31897Z"
                    fill="#225AEA"
              />
            </svg>
        }
      </div>
      <div className="info-wrapper">
        <div className="title">
          <p>{data?.title}</p>
          <p className="playlist-item-duration">
            <Icon role={'icon'} type={'clock'} />
            {formatDurationAdvanced(data?.duration)}
          </p>
        </div>
        <p className="playlist-item-status">
          {
            data?.completed ?
              <Icon role={'icon'}
                    type={'university_watched'}
              />
              :
              null
          }
        </p>
      </div>
    </div>
  );
};

export default UniversityPlaylistItem;
