import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Numbers from './numbers';
import { getSuffix, parseTime } from '../../../../Utils/utils';
import { useSelector } from 'react-redux';
import ButtonComponent from '../../../../Components/Button';
import utc from 'dayjs/plugin/utc';
import { Divider } from 'antd';
import './index.less';


dayjs.extend(utc);

const DropComponent = (
  {
    timerEnd,
    drop,
    totalProducts,
    totalDrops,
    isCanceled,
    category,
  }) => {

  const { t } = useTranslation();
  const isMobile = useSelector(store => store.nav.isMobile);

  const date = dayjs.utc(timerEnd) - dayjs();
  const end = date <= 0 ? 1 : date;

  const [counter, setCounter] = useState(end);
  const [clock, setClock] = useState(parseTime(counter));

  useEffect(() => {
    let timer;

    setClock(parseTime(counter));

    if (counter) {
      timer = setTimeout(
        () => setCounter(end - 1000 > 0 ? end - 1000 : 1),
        1000,
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [counter, timerEnd]);

  return (
    <div className="drop">
      {
        isCanceled ?
          (
            <div className="drop_info">
              <div className="drop_week_wrap">
                <p className="drop_text">
                  {t('Status')}
                </p>
                <p className="drop_week">
                  <span className="drop_week_suffix"
                        style={{ margin: 0 }}
                  >
                    {t('Canceled')}
                  </span>
                </p>
              </div>
              <div className="drop_tag">
                <ButtonComponent className="tag-drop"
                                 href={'/setting/plan'}
                >
                  {t('Subscribe')}
                </ButtonComponent>
              </div>
            </div>
          )
          :
          (
            <div className="drop_info">
              <div className="drop_info-week">
                <h3>
                  {`${drop.week}${getSuffix(drop.week)} ${t('Drop')}`}
                </h3>
                <span className="drop_info-circle" />
                <span className="drop_info-progress">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       viewBox="0 0 16 16"
                       fill="none"
                  >
                    <path stroke="#661AC6"
                          d="M8.00085 1.8335L8.00065 4.16683M8.00085 11.8335V14.1668M1.83398 8.00063H4.16732M11.834 8.00063H14.1673M3.6403 3.63953L5.29008 5.28959M10.7114 10.7106L12.3613 12.3605M3.64049 12.361L5.2904 10.7111M10.7116 5.28992L12.3615 3.64"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                    />
                  </svg>
                  {
                    t('In progress')
                  }
                </span>
              </div>
              <div className="drop_info-available">
                {
                  `${t('Available on')} `
                }
                <span>
                  {
                    dayjs(timerEnd).format('MMMM DD, YYYY')
                  }
                </span>
              </div>
            </div>
          )
      }
      {
        isMobile ?
          null
          :
          <Divider type={'vertical'}
                   style={{margin: '8px auto', height: 64}}
          />
      }
      <div className="drop_timer">
        <div className="timer">
          <div className="timer_days">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.days.toString()} />}
            </div>
            <div className="timer_text">
              {t('Days')}
            </div>
          </div>
          <span className="timer_days-separator">
            :
          </span>
          <div className="timer_days">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.hours.toString()} />}
            </div>
            <div className="timer_text">
              {t('Hours')}
            </div>
          </div>
          <span className="timer_days-separator">
            :
          </span>
          <div className="timer_days">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.min.toString()} />}
            </div>
            <div className="timer_text">
              {t('Minutes')}
            </div>
          </div>
          <span className="timer_days-separator">
            :
          </span>
          <div className="timer_days">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.sec.toString()} />}
            </div>
            <div className="timer_text">
              {t('Seconds')}
            </div>
          </div>
        </div>
      </div>
      {
        isMobile ?
          null
          :
          <Divider type={'vertical'}
                   style={{margin: '8px auto', height: 64}}
          />
      }
      <div className="drop_data">
        <div className="drop_row">
          <span className="left strong">
            {t('Summary')}
          </span>
          <Link to={{
            pathname: `/portfolio/product-list/?category=${category?.id}`,
          }}
                state={{
                  name: `All Products`,
                  link: `/portfolio/product-list/?category=${category?.id}`,
                }}
                className="drop_link right"
          >
            {t('View products')}
          </Link>
        </div>
        <div className="drop_row">
          <span className="left">
            {t('Total products')}
          </span>
          <span className="right strong">
            {totalProducts}
          </span>
        </div>
        <div className="drop_row">
          <span className="left">
            {t('Total drops')}
          </span>
          <span className="right strong">
            {totalDrops}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DropComponent;
