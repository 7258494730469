import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Billing = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-billing icon-billing-outline')}
      >
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M16.0416 5.41668C16.0416 4.61126 15.3887 3.95834 14.5833 3.95834H3.29161C2.71631 3.95834 2.24994 4.42471 2.24994 5.00001M2.24994 5.00001L2.24994 14.375C2.24994 15.2955 2.99613 16.0417 3.91661 16.0417H15.9999C16.9204 16.0417 17.6666 15.2955 17.6666 14.375V7.70834C17.6666 6.78787 16.9204 6.04168 15.9999 6.04168H3.29161C2.71631 6.04168 2.24994 5.57531 2.24994 5.00001ZM14.375 11.0417C14.375 11.3869 14.0952 11.6667 13.75 11.6667C13.4048 11.6667 13.125 11.3869 13.125 11.0417C13.125 10.6965 13.4048 10.4167 13.75 10.4167C14.0952 10.4167 14.375 10.6965 14.375 11.0417Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-billing icon-billing-bold')}
    >
      <path stroke={theme === 'light' ? '#225AEA' : 'white'}
            d="M16.0416 5.41668C16.0416 4.61126 15.3887 3.95834 14.5833 3.95834H3.29161C2.71631 3.95834 2.24994 4.42471 2.24994 5.00001M2.24994 5.00001L2.24994 14.375C2.24994 15.2955 2.99613 16.0417 3.91661 16.0417H15.9999C16.9204 16.0417 17.6666 15.2955 17.6666 14.375V7.70834C17.6666 6.78787 16.9204 6.04168 15.9999 6.04168H3.29161C2.71631 6.04168 2.24994 5.57531 2.24994 5.00001ZM14.375 11.0417C14.375 11.3869 14.0952 11.6667 13.75 11.6667C13.4048 11.6667 13.125 11.3869 13.125 11.0417C13.125 10.6965 13.4048 10.4167 13.75 10.4167C14.0952 10.4167 14.375 10.6965 14.375 11.0417Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>;
};

export default Billing;
