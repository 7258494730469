import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../../Redux/OnboardingRedux';
import { Switch } from 'antd';
import './styles.less';

const SwitchSubscriptionPeriod = () => {
  const dispatch = useDispatch();
  const isYearlySubscription = useSelector(store => store.onBoarding.isYearlySubscription);

  function onChangeSwitch(state) {
    if(state === isYearlySubscription) return
    dispatch(Creators.setYearlySubscription(state))
  }

  return (
    <div className={'switch-block'}>
      <Switch name={'period'}
              checked={isYearlySubscription}
              onChange={(state) => onChangeSwitch(state)}
      />
      <span onClick={() => onChangeSwitch(true)}
            className="switch-block-description"
      >
        Yearly billing <span className="switch-block-tag">Save 40%</span>
      </span>
    </div>
  );
};

export default SwitchSubscriptionPeriod;
