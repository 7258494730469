import React from 'react';
import cls from 'classname';

import Icon from '../../Icon';
import './save-to-collections.less';

const SaveToCollectionComponent = (
  {
    isSave=false,
    saveFunc,
    isDisabled
  }) => (
  <span className={cls('favorite', {disabled: isDisabled})}
    onClick={() => {
      if(isDisabled) return
      saveFunc(isSave);
    }}
  >
    <Icon type="bookmark_button" role="icon" outline={isSave} />
  </span>
);

export default SaveToCollectionComponent;
