import React, { useState } from 'react';
import Icon from '../../../../Icon';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import HeaderModal from './HeaderModal';

const TopPageHeader = (
  {
    icon,
    title,
    subtitle,
    takeTourHandler = null,
    watchTutorialHandler = null,
    skeleton,
    isMobile,
    modalTitle = '',
    modalText = ''
  }) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const handleVisibleChange = (value) => setVisible(value);

  if (skeleton) return (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        {
          isMobile ?
            null
            :
            <span className={'link skeleton'}
                  style={{ width: 40, height: 40 }}
            />
        }
        <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'}
                  style={{ width: 70, height: 20 }}
            />
          <span className={'link skeleton'}
                style={{ width: 252, height: 20 }}
          />
        </div>
      </div>
      <span className={'link skeleton'}
            style={{ width: 40, height: 10 }}
      />
    </div>
  );

  return (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <Icon type={icon} role={'icon'} />
        </div>
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            {t(title)}
          </h3>
          <h4 className="sales-tracker-top-page-subtitle">
            {t(subtitle)}
          </h4>
        </div>
      </div>
      {
        takeTourHandler || watchTutorialHandler ?
          isMobile ?
            <span className={'sales-tracker-top-page-header-btn'}
                  onClick={() => {
                    setVisibleModal('header_modal');
                  }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            :
            <Dropdown destroyPopupOnHide={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                      dropdownRender={() => (
                        <div className={'sales-tracker-top-page-header-dropdown'}>
                          {
                            takeTourHandler ?
                              <div className="sales-tracker-top-page-header-dropdown-option tour"
                                   onClick={() => {
                                     takeTourHandler();
                                     setVisible(false);
                                   }}
                              >
                                <Icon type="take_tour" role="icon" />
                                <span>
                                {t('Take tour')}
                              </span>
                              </div>
                              :
                              null
                          }
                          {
                            watchTutorialHandler ?
                              <div className="sales-tracker-top-page-header-dropdown-option tutorial"
                                   onClick={() => {
                                     watchTutorialHandler();
                                     setVisible(false);
                                   }}
                              >
                                <Icon type={'watch_tutorial'}
                                      role={'icon'}
                                />
                                <span>
                                {t('Watch tutorial')}
                              </span>
                              </div>
                              :
                              null
                          }
                        </div>
                      )}
                      trigger={['click']}
                      open={visible}
            >
            <span className={'sales-tracker-top-page-header-btn'}>
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            </Dropdown>
          :
          null
      }
      <HeaderModal  setModal={setVisibleModal}
                    isMobile={isMobile}
                    visible={visibleModal}
                    takeTourHandler={takeTourHandler}
                    watchTutorialHandler={watchTutorialHandler}
                    title={modalTitle}
                    text={modalText}
      />
    </div>
  );
};

export default TopPageHeader;
