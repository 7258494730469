import React from 'react';
import ReactPlayer from 'react-player';
import Modal from '../../../Components/Modal';
import Icon from '../../../Icon';

const VideoTutorialModal = (
  {
    visible,
    isMobile,
    setModal
  }
) => {

  return (
    <div>
      <Modal className="change-modal custom-modal video-modal"
             getContainer={() => document.getElementById('global-wrap')}
             {...(isMobile ? { transitionName: '' } : null)}
             open={visible}
             centered={!isMobile}
             closeIcon={
               <Icon role="icon" type="close_modal" color="#225aea" opacity={1} />
             }
             footer={null}
             closable="true"
             onCancel={() => setModal(null)}
             destroyOnClose
      >
        <ReactPlayer controls={true}
                   width={'100%'}
                   height={'100%'}
                   playing={true}
                   url={'https://www.youtube.com/watch?v=h-rOBAPwptI'}
        />
      </Modal>
    </div>
  );
};

export default VideoTutorialModal;
