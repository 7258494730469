import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import cls from 'classname';

function PasswordValidator({ value, error, isLogin = false, isSignup = false, setValidPass = null }) {
  const { t } = useTranslation();
  const [text, setText] = useState('Your password must contain 6 or more characters.');
  const [errorText, setErrorText] = useState(null);
  const [custom, setCustom] = useState({ status: '', color: '' });

  const great = /^(?=.*[a-zA-Z])(?=.*[±!@#$%^&*()_+=;:'"|?/.,><`~])(?=.*[0-9]).{8,}$/;
  const good1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
  const good2 = /^(?=.*[a-zA-Z])(?=.*[±!@#$%^&*()_+=;:'"|?/.,><`~]).{8,}$/;

  useEffect(() => {
    if (error && !isSignup) {
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(error?.code === 'token_not_valid' ?
          'You have already created a password. Try to log in'
          :
          Object.values(error)[0]);
      }
    } else if (errorText) {
      setErrorText(false);
      checkValue(value);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!error]);

  function defaultValue() {
    setText(isLogin ? '' : 'Your password must contain 6 or more characters.');
    setCustom({ status: '', color: '' });
    if (isLogin && setValidPass) setValidPass(false);
  }

  useEffect(() => {
    value ? checkValue(value) : defaultValue();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [value]);

  function checkValue(val) {
    if (val) {
      if (val?.match(great)) {
        if (val?.length >= 6) {
          if (setValidPass) setValidPass(true);
          setText('Your password is strong and fully compliant with the requirements.');
          setCustom({ status: 'Great', color: 'green' });
        }
      } else if (val?.match(good1) || val.match(good2)) {
        if (val?.length >= 6) {
          if (setValidPass) setValidPass(true);
          setText('Your password is too weak. ');
          setCustom({ status: 'Good', color: 'yellow' });
        }
      } else if (val?.length >= 6) {
        if (setValidPass) setValidPass(true);
        setText('Your password is too weak. ');
        setCustom({ status: 'Good', color: 'yellow' });
      } else {
        if (setValidPass) setValidPass(false);
        setCustom({ status: 'Bad', color: 'red' });
        setText('Your password must contain 6 or more characters.');
      }
    }
  }

  return (

    <div className={`error-text ${custom.color}`}>
      {error?.code === 'token_not_valid' ?
        <span>You have already created a password. Try to <a href="/login">log in</a></span>
        :
        <span>
            {
              errorText || text
            }
          </span>
      }
    </div>
  );
}

export default PasswordValidator;
