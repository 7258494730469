import React from 'react';

const NotificationDelete = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path stroke="#225AEA"
          d="M9.5448 3.7464L11.3531 2.56356C11.9077 2.20082 12.6223 2.20082 13.1768 2.56356L14.2965 3.29592C14.726 3.57688 14.9851 4.05761 14.9851 4.57337C14.9851 5.15602 15.3152 5.68763 15.8353 5.94281L16.7665 6.39962C17.7194 6.86709 18.0064 8.09739 17.3598 8.9432L15.8919 10.8636M9.5448 3.7464L5.27182 4.39141C4.37122 4.52736 3.74376 5.36336 3.85964 6.27294L4.10446 8.19468M9.5448 3.7464L11.3582 5.97054M4.10446 8.19468L3.23183 8.62278C2.65623 8.90516 2.29102 9.49343 2.29102 10.1382V10.8718C2.29102 11.4216 2.56727 11.934 3.0251 12.2335C3.42219 12.4932 3.68583 12.9156 3.74609 13.3885L3.9997 15.3793C4.06576 15.8978 4.36701 16.3561 4.81521 16.62L5.54667 17.0506C6.05773 17.3515 6.68859 17.3581 7.20581 17.0682L9.5448 15.7567M4.10446 8.19468L4.74216 8.82037C5.17554 9.24559 5.8273 9.3512 6.37127 9.08433M9.5448 15.7567L10.6336 17.0921C11.0707 17.6282 11.7893 17.8399 12.4444 17.6256L13.7624 17.1946C13.971 17.1264 14.1643 17.0175 14.3313 16.8741L16.6376 14.8941C17.2552 14.3638 17.4061 13.4637 16.9955 12.7586L15.8919 10.8636M9.5448 15.7567L8.63808 14.8671M15.8919 10.8636L13.6251 11.3085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
);

export default NotificationDelete;
