import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import Icon from '../../../Icon';
import cls from 'classname';

const PresetsModal = (
  {
    setModal,
    setVisibleModal,
    isMobile,
    visible,
    isChanged
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setVisibleModal(null)}
           title={null}
           isMobile={isMobile}
           width={450}
           className="change-modal custom-modal header-modal"
           open={visible}
           destroyOnClose
    >
      <div className={'custom-modal-content'}>
        <div className={'sales-tracker-top-page-header-dropdown'}>
          <div className="sales-tracker-top-page-header-dropdown-option"
               onClick={() => {
                 setVisibleModal(null);
                 setModal('load_preset');
               }}
          >
            <Icon type="load_preset" role="icon" />
            <span>
              {t('Load preset')}
            </span>
          </div>
          <div className={cls('sales-tracker-top-page-header-dropdown-option', {
            disabled: !isChanged,
          })}
               onClick={() => {
                 if (isChanged) {
                   setVisibleModal(null);
                   setModal('save_preset');
                 }
               }}
          >
            <Icon type={'save_preset'}
                  role={'icon'}
            />
            <span>
              {t('Save this search as preset')}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PresetsModal;
