import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../Components/Button';
import cls from 'classnames';
import './BannerBlock.less';

const BannerBlock = (
  {
    skeleton,
    isMobile,
    setVisible,
    showFilters
  }) => {

  const { t } = useTranslation();

  if (skeleton) return (
    <div className={cls('competitor-research-empty-block', {
      active: !showFilters,
    })}
    >
      <h3 className={'competitor-research-empty-block-title'}>
        <span className="link skeleton" style={{ width: 315, height: 24 }} />
      </h3>
      <p className={'competitor-research-empty-block-text'}>
        <span className="link skeleton" style={{ width: isMobile ? '100%' : 400, height: 20 }} />
        <span className="link skeleton" style={{ width: 120, height: 20, marginTop: 4 }} />
      </p>
      <span className="link skeleton" style={{ width: isMobile ? 'calc(100% - 32px)' : 114, height: 36, marginTop: 24 }} />
    </div>
  )

  return (
    <div className={cls('competitor-research-empty-block', {
      active: !showFilters,
    })}
    >
      <h3 className={'competitor-research-empty-block-title'}>
        {
          t('Search through millions of competitors')
        }
      </h3>
      <p className={'competitor-research-empty-block-text'}>
        {
          t('Get inspiration from product listings, research your competitors or study the market of your next potentially winning product.')
        }
      </p>
      <ButtonComponent className={'competitor-research-empty-block-btn'}
                       text={t('Watch tutorial')}
                       onClick={() => setVisible('tutorial')}

      />
    </div>
  );
};

export default BannerBlock;
