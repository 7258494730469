import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../Icon';
import dayjs from 'dayjs';
import createHmac from 'create-hmac';
import ButtonComponent from '../Button';
import { Divider, Tooltip } from 'antd';
import OnboardingActions from '../../Redux/OnboardingRedux';
import './styles.less';

const PlanCardOnboarding = (
  {
    plan,
    description,
    onSelectPlan,
    selectedPlan,
    switchPlan,
    currentSubscription,
    isMobile,
    setVisible,
    setSubModal,
    isOnboarding = false,
  }) => {
  const { t } = useTranslation();

  const useStripe = useSelector((state) => state?.auth?.userInfo?.use_stripe) || false;
  const churnkeyApiKey = useStripe ? 't60avwn34JPxxgQKwLeEbBRnj0QB0J1q' : 'CzX5a9ni0GZjaH3kPy0ALFKYUWiRrUxH';
  const churnkeyApiKeyTest = useStripe ? 'test_YHyQZPoRzHIQPLIU3XOJ2CRbql2s49iJ' : 'test_NhrO00GbElpQnLyzIyZTt21RFipwLBv3';
  const externalCustomerID = useSelector((state) => state?.auth?.userInfo?.[useStripe ? 'stripe_customer_id' : 'chargebee_customer_id']) || '';
  const externalSubscriptionID = useSelector((state) => state?.auth?.userInfo?.external_subscription_id) || '';
  const subWasCancelled = useSelector(state => state?.auth?.userInfo?.subscription_was_canceled);
  const subscriptionID = useSelector(state => state?.auth?.userInfo?.subscriptions?.[0]?.id);
  //for test
  const hash = createHmac('sha256', churnkeyApiKeyTest)?.update(externalCustomerID)?.digest('hex');

  //for prod
  // const hash = createHmac('sha256', churnkeyApiKey).update(externalCustomerID).digest('hex');

  const isYearlySubscription = useSelector(store => store.onBoarding.isYearlySubscription);
  const trialUsed = useSelector(store => store.auth.userInfo.trial_used);

  const priceType = isYearlySubscription ? 'yearly' : 'monthly';
  const yearlyPrice = plan?.prices?.['yearly']?.price * 12;
  const monthlyPrice = plan?.prices?.['monthly']?.price;
  const price = plan?.prices?.[priceType]?.price || 0;

  const isCancelledPlan = currentSubscription?.expire_date && currentSubscription?.next_payment_date === null;

  const isTrial = currentSubscription?.payment_status?.id === 6;
  const isPaused = currentSubscription?.payment_status?.id === 7;

  const isCurrentPlan = plan?.prices?.[priceType]?.id === currentSubscription?.plan?.id;
  const isScheduled = currentSubscription?.scheduled_plan && plan?.prices?.[priceType]?.id === currentSubscription?.scheduled_plan?.id;

  const dispatch = useDispatch();
  // const getUserInfo = () => dispatch(Creators.userRequest());
  const cancelSub = (id) => dispatch(OnboardingActions.cancelSubRequest(id));

  function handleSelectPlan() {
    if ((currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan)) return;
    else if (isPaused && isCurrentPlan) {
      setVisible(true);
      setSubModal('unpause');
    } else onSelectPlan(plan);
  }

  function handleClick() {
    if (isCurrentPlan && !isCancelledPlan && !isPaused && !isScheduled) {
      window.churnkey.init('restart', {
        customerId: externalCustomerID,
        subscriptionId: externalSubscriptionID,
        authHash: hash,
        record: false, // dev only
        onCancel() {
          if (subWasCancelled) cancelSub(subscriptionID);
        },
        // onClose() {
        //   setTimeout(() => getUserInfo(), 5000);
        // },
        appId: useStripe ? 'humrddvg8' : '45c8y6qwz',
        //for test
        mode: 'test',
        //for prod
        // mode: 'live',
        provider: useStripe ? 'stripe' : 'chargebee',
      });
    } else {
      handleSelectPlan();
    }
  }

  function getBtnText() {
    let text = !!currentSubscription ? 'Switch Plan' : (trialUsed ? 'Select Plan' : 'Start free trial');
    if (selectedPlan?.id === plan.id || switchPlan?.id === plan?.id) text = 'Selected';
    if (isCurrentPlan) {
      currentSubscription?.scheduled_plan || (currentSubscription?.expire_date && !currentSubscription?.next_payment_date) ?
        text = 'Current Plan'
        :
        text = 'Cancel Plan';
    }
    if (isScheduled || (isCancelledPlan && isCurrentPlan) || (isPaused && isCurrentPlan)) {
      text = isPaused ? 'Unpause Plan' : isCancelledPlan ? 'Activate Plan' : 'Scheduled';
      return (
        <>
          <Icon
            type="warning_slim"
            role="button"
            width={24}
            height={24}
            tooltipProps={{
              trigger: 'hover',
              placement: 'top',
              overlayClassName: 'subscription-tooltip scheduled',
              getPopupContainer: (trigger) => trigger.parentNode,
            }}
            titleText={
              isPaused ?
                t('Your _currentPlan_ plan is set to be paused until _date_',
                  {
                    currentPlan: currentSubscription?.plan?.plan?.label,
                    date: dayjs(currentSubscription?.next_payment_date).format('MMM DD, YYYY'),
                  },
                )
                :
                isCancelledPlan ?
                  t('Your _currentPlan_ plan is set to be cancelled on _date_',
                    {
                      currentPlan: currentSubscription?.plan?.plan?.label,
                      date: dayjs(currentSubscription?.expire_date).format('MMM DD, YYYY'),
                    },
                  )
                  :
                  t('Your _currentPlan_ plan is set to be changed on _date_ to _scheduledPlan_',
                    {
                      currentPlan: currentSubscription?.plan?.plan?.label,
                      date: dayjs(currentSubscription?.expire_date || currentSubscription?.next_payment_date).format('MMM DD, YYYY'),
                      scheduledPlan: currentSubscription?.scheduled_plan?.name,
                    },
                  )
            }
          />
          {t(text)}
          <Icon role="button" type="close" width={24} height={24} />
        </>
      );
    }
    if ((currentSubscription?.scheduled_plan || (currentSubscription?.expire_date && !currentSubscription?.next_payment_date) || isPaused) && !isScheduled && !isCurrentPlan) {
      return (
        <>
          <Icon
            type={'attention_outline'}
            role="button"
            width={24}
            height={24}
            tooltipProps={{
              trigger: 'hover',
              placement: 'top',
              overlayClassName: 'subscription-tooltip scheduled',
              getPopupContainer: (trigger) => trigger.parentNode,
            }}
            titleText={t('To switch to this plan, you need to cancel your active scheduling.')}
          />
          {t(text)}
        </>
      );
    }

    return t(text);
  }

  return (
    <div className={cls('plan-card-onboarding', {
      'trending': plan?.name === 'standard'
    })}>
      {
        plan?.name === 'standard' ?
          <span className="trending-tag">
            Trending now
          </span>
          :
          null
      }
      <div className="plan-card-onboarding-img-wrap">
        <div className="plan-card-onboarding-img">
          <svg xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="20"
               viewBox="0 0 16 20"
               fill="none"
          >
            <g id="Mark">
              <path id="Vector"
                    d="M14.3041 4.32L10.2678 1.41702C8.91429 0.443349 7.08959 0.443349 5.73525 1.41702L1.69802 4.32C0.685054 5.04876 0.0839844 6.22009 0.0839844 7.4679V12.5331C0.0839844 13.7809 0.684194 14.9522 1.69802 15.681L5.73439 18.584C7.08787 19.5576 8.91257 19.5576 10.2669 18.584L14.3033 15.681C15.3162 14.9522 15.9173 13.7809 15.9173 12.5331V7.4679C15.9173 6.22009 15.3171 5.04876 14.3041 4.32ZM6.64158 2.67601C7.45418 2.09164 8.54884 2.09164 9.36058 2.67601L12.7632 5.12266C13.0091 5.29969 13.0091 5.66493 12.7632 5.84196L9.36058 8.28861C8.54798 8.87299 7.45332 8.87299 6.64158 8.28861L3.23896 5.84196C2.99303 5.66493 2.99303 5.29969 3.23896 5.12266L6.64158 2.67601ZM14.3661 12.5322C14.3661 13.2807 14.0058 13.9837 13.3978 14.4211L9.36144 17.3241C8.54884 17.9085 7.45418 17.9085 6.64244 17.3241L2.60607 14.4211C1.99812 13.9837 1.63782 13.2807 1.63782 12.5322V7.46705C1.63782 7.46447 1.63782 7.46189 1.63782 7.45931C1.63868 7.10095 2.04714 6.8947 2.33778 7.10353L5.7361 9.5476C7.08959 10.5213 8.91429 10.5213 10.2686 9.5476L13.667 7.10353C13.9585 6.89384 14.3661 7.10009 14.3669 7.45931C14.3669 7.46189 14.3669 7.46447 14.3669 7.46705V12.5322H14.3661Z"
                    fill="#F9F9FC"
              />
              <path id="Vector_2"
                    d="M10.2464 11.0745C9.57143 11.2069 9.12342 11.7406 8.79064 12.3542C8.69261 12.5346 8.69949 12.7615 8.85169 12.8827C8.96434 12.972 9.1131 12.9669 9.22231 12.8732C9.64022 12.5174 10.1278 12.3163 10.4434 12.2476C10.7589 12.1788 11.2775 12.1599 11.7805 12.3146C11.9129 12.355 12.0522 12.2966 12.1279 12.1668C12.2311 11.9898 12.1623 11.7784 12.0144 11.6495C11.4761 11.1811 10.9318 10.9396 10.2464 11.0745Z"
                    fill="#F9F9FC"
              />
              <path id="Vector_3"
                    d="M5.7628 11.0745C6.43782 11.2069 6.88583 11.7406 7.21861 12.3542C7.31664 12.5346 7.30976 12.7615 7.15756 12.8827C7.04491 12.972 6.89615 12.9669 6.78694 12.8732C6.36903 12.5174 5.88147 12.3163 5.56588 12.2476C5.2503 12.1788 4.73178 12.1599 4.22874 12.3146C4.09631 12.355 3.95701 12.2966 3.88134 12.1668C3.77815 11.9898 3.84694 11.7784 3.99485 11.6495C4.53314 11.1811 5.07746 10.9396 5.7628 11.0745Z"
                    fill="#F9F9FC"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="plan-card-onboarding-title-wrap">
        <h3 className="plan-card-onboarding-title">
          {
            plan?.name
          }
        </h3>
        <h4 className="plan-card-onboarding-subtitle">
          {
            description
          }
        </h4>
      </div>
      <div className="plan-card-onboarding-price-wrap">
        <h3 className="plan-card-onboarding-price">
          {`$${price}`}
        </h3>
        <span>
          per month
        </span>
        {
          isYearlySubscription ?
            <Divider type={'vertical'} style={{ margin: '0 8px' }} />
            :
            null
        }
        {
          isYearlySubscription ?
            <div>
              <sup>*</sup>{`$${acc.formatNumber(yearlyPrice, 0)} billed annually`}
            </div>
            :
            null
        }
      </div>

      {((isTrial || currentSubscription?.payment_status?.id === 2) && isCurrentPlan) ? (
          <div className="plan-card-onboarding-btn-wrapper">
            <ButtonComponent type="primary"
                             id={(isCurrentPlan && !isCancelledPlan) ? 'cancel-current-sub' : null}
                             className={cls('btn-primary border-only',
                               {
                                 'switch': switchPlan?.id === plan?.id,
                                 'active': (selectedPlan?.id === plan.id || (isCurrentPlan && !isCancelledPlan && !isPaused)) && !isScheduled,
                                 'scheduled': isScheduled || (isCancelledPlan && isCurrentPlan),
                                 'scheduled-current': ((isScheduled || (isCancelledPlan && isCurrentPlan)) && isCancelledPlan) || (isCurrentPlan && isScheduled),
                                 disabled: (currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan),
                               })}
                             disabled={(currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan)}
                             onClick={handleClick}
            >
              {
                getBtnText()
              }
            </ButtonComponent>

            {
              isTrial ?
                currentSubscription?.scheduled_plan || ((currentSubscription?.expire_date && !currentSubscription?.next_payment_date) || isPaused) ?
                  <Tooltip placement="top"
                           title={'To perform the action, cancel scheduling'}
                           overlayClassName={'subscription-tooltip scheduled skip'}
                           arrow={true}
                           trigger={isMobile ? 'click' : 'hover'}
                           getPopupContainer={(trigger) => trigger.parentNode}
                           destroyTooltipOnHide={true}
                  >
                    <ButtonComponent type="primary"
                                     className={cls('btn-primary skip-trial skip-trial-disabled')}
                                     onClick={null}
                    >
                      {t('Skip Trial & Pay')}
                    </ButtonComponent>
                  </Tooltip>
                  :
                  <ButtonComponent type="primary"
                                   className={cls('btn-primary skip-trial')}
                                   onClick={() => {
                                     setVisible(true);
                                     setSubModal('cancelTrial');
                                   }}
                                   disabled={false}
                  >
                    {t('Skip Trial & Pay')}
                  </ButtonComponent>
                :
                <ButtonComponent type="primary"
                                 className={cls('btn-primary skip-trial')}
                                 onClick={handleSelectPlan}
                                 disabled={false}
                >
                  {
                    t('Retry charge')
                  }
                </ButtonComponent>
            }
          </div>
        )
        :
        <ButtonComponent type="primary"
                         id={(isCurrentPlan && !isCancelledPlan) ? 'cancel-current-sub' : null}
                         className={cls('btn-primary border-only',
                           {
                             'switch': switchPlan?.id === plan?.id,
                             'active': (selectedPlan?.id === plan.id || (isCurrentPlan && !isCancelledPlan && !isPaused)) && !isScheduled,
                             'scheduled': isScheduled || (isCancelledPlan && isCurrentPlan) || (isPaused && isCurrentPlan),
                             'scheduled-current': ((isScheduled || (isCancelledPlan && isCurrentPlan)) && isCancelledPlan) || (isPaused && isCurrentPlan) || (isCurrentPlan && isScheduled),
                             'is_onboarding': isOnboarding,
                             disabled: (currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan) || (isPaused && !isCurrentPlan),
                           })}
                         onClick={() => !((currentSubscription?.scheduled_plan && !isScheduled) || (isCancelledPlan && !isCurrentPlan) || (isPaused && !isCurrentPlan)) && handleClick()}
        >
          {
            getBtnText()
          }
        </ButtonComponent>
      }

      <div className={'plan-card-onboarding-description'}>
        <h4>
          What's inside:
        </h4>
        {
          plan?.limits?.map(el => (
            <div key={el.id} className={'info-wrp'}>
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="20"
                   height="20"
                   viewBox="0 0 20 20"
                   fill="none"
              >
                <path d="M2.29199 12.5781L7.50033 16.875L17.7087 3.125"
                      stroke="#16895A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
              </svg>
              <span className="info-text value">
              {
                el.value ?
                  el?.limit_type?.id === 'partial_display' ?
                    `${el?.value}`
                    :
                    (
                      `${el.value} ${
                        el.rate_unit ?
                          `${el?.['limit_unit']?.value} / ${el.rate_unit.value}`
                          :
                          (el?.scope?.id === 'product_drops' ? `/${el?.['limit_unit']?.value}` : el?.['limit_unit']?.value)
                      }`
                    )
                  :
                  el?.limit_type?.id === 'partial_display' ?
                    <span className={'hyphen'} />
                    :
                    t(el.active ? 'Unlimited Use' : 'Coming Soon')
              }
              </span>
              <span className="info-text">
                ({el.scope.value})
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PlanCardOnboarding;
