import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { ButtonComponent } from '../Button';
import Icon from '../../Icon';
import { EventHandler } from '../../Utils/event-handler';
import cls from 'classname';

const SaveToCollectionMsg = (
  {
    text,
    isSave,
    isRestore = false,
    btnText = '',
    btnOnClick = null,
    collectionName=''
  }) => {

  const { t } = useTranslation();

  return (
    <div className="notification-body">
      <div className="notification-wrapper">
        <Icon role="icon"
              type="bookmark"
        />
        <div className={'notification-content'}>
          <div className="notification-text-block">
            <h4 className={'notification-title'}>
              {
                t(isRestore ? '' +
                  'Product has been restored'
                  :
                  isSave ?
                    'Product was successfully saved'
                    :
                    'Product was successfully unsaved',
                )
              }
            </h4>
            <p className="notification-text">
              {`${t(text)} ${t('was successfully saved to ')}"${collectionName ? collectionName : "All Products"}".`}
            </p>
          </div>
          <ButtonComponent className="notification-btn"
                           text={t(btnText ? btnText : 'Select collection')}
                           onClick={() => {
                             if (btnOnClick) {
                               btnOnClick()
                             } else {
                               notification.destroy();
                               EventHandler.emit('open-modal', { text: 'hello world' });
                             }
                           }}
          />
        </div>
      </div>
    </div>
  );
};

export const DefaultMsg = (
  {
    text = null,
    title= null,
    icon,
    textIsWide=false
  }) => {

  const { t } = useTranslation();

  return (
    <div className="notification-body">
      <div className="notification-wrapper">
        <Icon role="icon"
              type={icon || 'folder'}
        />
        <div className={'notification-content'}>
          <div className="notification-text-block">
            <h4 className={'notification-title'}>
              {
                title ?
                  t(title)
                  :
                  null
              }
            </h4>
            {
              text ?
                <p className={cls('notification-text', {
                  wide: textIsWide
                })}>
                  {text}
                </p>
                :
                null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const DefaultMsgSalesTracker = (
  {
    icon,
    iconWidth,
    iconHeight,
    iconOutline,
    connect = false,
    name = null,
    disconnect = false,
    admin = false,
  }) => {

  const { t } = useTranslation();

  return (
    <div className="notification-body">
      <div className="notification-wrapper">
        <Icon role="icon"
              outline={!!iconOutline}
              width={iconWidth}
              height={iconHeight}
              type={icon || 'folder'}
        />
        <div className={'notification-content'}>
          <div className="notification-text-block">
            <h4 className={'notification-title'}>
              some title
            </h4>
            <p className="notification-text">
              {
                admin ?
                  `You have deleted ${name}`
                  :
                  connect ?
                    t('You have successfully started tracking the selected product')
                    :
                    name ?
                      disconnect ?
                        t('You have successfully stopped tracking _name_', { name: name })
                        :
                        t('You have successfully started tracking _name_', { name: name })
                      :
                      t('You have successfully stopped tracking the selected product')
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DefaultMsgSaga = (
  {
    text,
    title,
    icon,
    iconWidth,
    iconHeight,
    iconOutline,
    withTranslate = false,
    preset = null,
    undo = null,
  }) => {

  const { t } = useTranslation();

  return (
    <div className="notification-body">
      <div className="notification-wrapper">
        <Icon role="icon"
              outline={!!iconOutline}
              width={iconWidth}
              height={iconHeight}
              type={icon || 'folder'}
        />
        <div className={'notification-content'}>
          <div className="notification-text-block">
            <h4 className={'notification-title'}>
              {
                title
              }
            </h4>
            {
              text ?
                <p className="notification-text">
                  {
                    withTranslate ?
                      t(text?.props?.children, { name: preset })
                      :
                      text
                  }
                  {undo && undo}
                </p>
                :
                null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveToCollectionMsg;
