import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

export const Favorite = (
  {
    className,
    outline,
  }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon-favorite-outline ')}
           fill="none"
      >
        <path fill={theme === 'light' ? "#151E3A" : "#f2f3f8"}
              d="M11.1332 1.54567C10.6768 0.595779 9.32141 0.595781 8.86504 1.54567L6.89122 5.65397C6.89122 5.65397 6.8889 5.65714 6.88521 5.65762L2.34387 6.25218C1.29737 6.38919 0.870657 7.67965 1.64374 8.40853L4.96385 11.5388C4.96534 11.5402 4.9652 11.5418 4.9652 11.5418L4.13141 16.014C3.93686 17.0575 5.04124 17.8459 5.96561 17.3476L9.99446 15.1759C9.99726 15.1743 10.001 15.1743 10.0038 15.1759L14.0326 17.3476C14.957 17.8459 16.0614 17.0575 15.8668 16.014L15.033 11.5418C15.033 11.5418 15.0329 11.5402 15.0344 11.5388L18.3545 8.40852C19.1276 7.67965 18.7008 6.38919 17.6543 6.25218L13.113 5.65762C13.1093 5.65714 13.107 5.65397 13.107 5.65397L11.1332 1.54567Z"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           className={cls(className, 'icon-favorite')}
           fill="none"
      >
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.26)'}
              d="M9.42929 1.81657C9.65867 1.33914 10.3413 1.33914 10.5707 1.81657L12.5445 5.92488C12.6369 6.1171 12.8206 6.2498 13.0328 6.27758L17.5741 6.87214C18.1021 6.94127 18.3133 7.58942 17.9266 7.95402L14.6065 11.0843C14.4507 11.2311 14.3803 11.4466 14.4195 11.6566L15.2533 16.1288C15.3504 16.6496 14.7979 17.0499 14.3301 16.7977L10.3012 14.6259C10.1133 14.5246 9.88671 14.5246 9.69879 14.6259L5.66994 16.7977C5.20214 17.0499 4.64963 16.6496 4.74671 16.1288L5.58051 11.6566C5.61966 11.4466 5.54926 11.2311 5.39349 11.0843L2.07338 7.95402C1.68667 7.58942 1.89786 6.94127 2.4259 6.87214L6.96724 6.27758C7.17942 6.2498 7.36312 6.1171 7.45547 5.92488L9.42929 1.81657Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    );
};

export default Favorite;
