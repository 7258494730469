import React from 'react';

const EditCardModalComplete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1.83203 10.0625L5.9987 13.5L14.1654 2.5" stroke="#16895A" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  );
};

export default EditCardModalComplete;
