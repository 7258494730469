import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../Icon';

const SidebarHeader = (
  {
    isShowSideBar,
    changeSizeSidebar,
    setFlag,
    isMobile,
    openSidebar
  }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <div className={'main-sidebar-header'}>
      <svg xmlns="http://www.w3.org/2000/svg"
           width={isShowSideBar ? 154 : 44}
           height="44"
           viewBox={`0 0 ${isShowSideBar ? 154 : 44} 44`}
           fill="none"
           className={'main-sidebar-header-logo'}
      >
        <path
          d="M35.8676 9.50484L26.9876 3.11828C24.0099 0.976197 19.9956 0.976197 17.016 3.11828L8.13412 9.50484C5.90561 11.1081 4.58325 13.685 4.58325 16.4302V27.5736C4.58325 30.3188 5.90371 32.8957 8.13412 34.499L17.0141 40.8855C19.9918 43.0276 24.0062 43.0276 26.9857 40.8855L35.8657 34.499C38.0942 32.8957 39.4166 30.3188 39.4166 27.5736V16.4302C39.4166 13.685 38.0961 11.1081 35.8676 9.50484ZM19.01 5.88806C20.7977 4.60243 23.2059 4.60243 24.9918 5.88806L32.4775 11.2707C33.0186 11.6602 33.0186 12.4637 32.4775 12.8531L24.9918 18.2358C23.204 19.5214 20.7958 19.5214 19.01 18.2358L11.5242 12.8531C10.9831 12.4637 10.9831 11.6602 11.5242 11.2707L19.01 5.88806ZM36.0038 27.5717C36.0038 29.2185 35.2112 30.765 33.8737 31.7273L24.9937 38.1139C23.2059 39.3995 20.7977 39.3995 19.0119 38.1139L10.1318 31.7273C8.79435 30.765 8.0017 29.2185 8.0017 27.5717V16.4283C8.0017 16.4227 8.0017 16.417 8.0017 16.4113C8.00359 15.6229 8.90218 15.1692 9.54161 15.6286L17.0179 21.0055C19.9956 23.1476 24.0099 23.1476 26.9895 21.0055L34.4658 15.6286C35.1071 15.1673 36.0038 15.621 36.0057 16.4113C36.0057 16.417 36.0057 16.4227 36.0057 16.4283V27.5717H36.0038Z"
          fill="#225AEA" />
        <path
          d="M26.9404 24.365C25.4553 24.6562 24.4697 25.8302 23.7376 27.1802C23.5219 27.5772 23.5371 28.0763 23.8719 28.3429C24.1197 28.5395 24.447 28.5282 24.6873 28.3221C25.6067 27.5394 26.6793 27.097 27.3736 26.9457C28.0679 26.7945 29.2086 26.7529 30.3153 27.0932C30.6066 27.1821 30.9131 27.0535 31.0796 26.768C31.3066 26.3785 31.1553 25.9134 30.8299 25.6298C29.6456 24.5994 28.4481 24.0682 26.9404 24.365Z"
          fill="#225AEA" />
        <path
          d="M17.0768 24.365C18.5618 24.6562 19.5475 25.8302 20.2796 27.1802C20.4952 27.5772 20.4801 28.0763 20.1453 28.3429C19.8974 28.5395 19.5702 28.5282 19.3299 28.3221C18.4105 27.5394 17.3379 27.097 16.6436 26.9457C15.9493 26.7945 14.8085 26.7529 13.7019 27.0932C13.4105 27.1821 13.1041 27.0535 12.9376 26.768C12.7106 26.3785 12.8619 25.9134 13.1873 25.6298C14.3715 24.5994 15.569 24.0682 17.0768 24.365Z"
          fill="#225AEA" />
        {
          isShowSideBar ?
            <path
              d="M49.0769 29.5646H55.1674C57.1892 29.5646 58.9436 29.1543 60.4307 28.3338C61.9178 27.5133 63.0708 26.3851 63.8895 24.9492C64.7083 23.4962 65.1176 21.8466 65.1176 20.0004C65.1176 18.1543 64.7083 16.5133 63.8895 15.0774C63.0708 13.6415 61.9178 12.5133 60.4307 11.6928C58.9436 10.8722 57.1892 10.462 55.1674 10.462H49.0769V29.5646ZM52.9618 26.103V13.9235H55.2676C56.4874 13.9235 57.5401 14.1714 58.4256 14.6671C59.3112 15.1628 59.9963 15.8637 60.4809 16.7697C60.9821 17.6757 61.2328 18.7526 61.2328 20.0004C61.2328 21.2312 60.9821 22.3081 60.4809 23.2312C59.9963 24.1372 59.3112 24.8466 58.4256 25.3594C57.5401 25.8552 56.4874 26.103 55.2676 26.103H52.9618Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M66.5778 29.5646H70.3373V22.0261C70.3373 20.9834 70.6214 20.1799 71.1895 19.6158C71.7576 19.0346 72.5012 18.744 73.4202 18.744H74.5982V15.4107H73.7961C72.9607 15.4107 72.2171 15.5731 71.5655 15.8979C70.9305 16.2056 70.4376 16.7953 70.0867 17.6671V15.5646H66.5778V29.5646Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M82.2862 29.8722C83.6397 29.8722 84.8678 29.556 85.9706 28.9235C87.0734 28.291 87.9506 27.4278 88.6023 26.3338C89.2706 25.2227 89.6048 23.9663 89.6048 22.5646C89.6048 21.1458 89.2706 19.8893 88.6023 18.7953C87.9506 17.7013 87.0734 16.8381 85.9706 16.2056C84.8678 15.5731 83.6397 15.2569 82.2862 15.2569C80.9328 15.2569 79.6963 15.5731 78.5768 16.2056C77.474 16.8381 76.5884 17.7013 75.9201 18.7953C75.2684 19.8893 74.9426 21.1458 74.9426 22.5646C74.9426 23.9663 75.2684 25.2227 75.9201 26.3338C76.5884 27.4278 77.474 28.291 78.5768 28.9235C79.6963 29.556 80.9328 29.8722 82.2862 29.8722ZM82.2862 26.4107C81.267 26.4107 80.4315 26.0517 79.7799 25.3338C79.1449 24.6158 78.8274 23.6928 78.8274 22.5646C78.8274 21.4534 79.1449 20.5389 79.7799 19.821C80.4315 19.0859 81.267 18.7184 82.2862 18.7184C83.3055 18.7184 84.1326 19.0859 84.7675 19.821C85.4025 20.5389 85.72 21.4534 85.72 22.5646C85.72 23.6928 85.4025 24.6158 84.7675 25.3338C84.1326 26.0517 83.3055 26.4107 82.2862 26.4107Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M91.0677 34.6928H94.8273V28.4876C95.3118 28.9492 95.8883 29.2996 96.5567 29.5389C97.2417 29.7611 97.9686 29.8722 98.7372 29.8722C100.074 29.8722 101.269 29.5475 102.321 28.8979C103.374 28.2483 104.201 27.368 104.803 26.2569C105.421 25.1457 105.73 23.915 105.73 22.5646C105.73 21.197 105.421 19.9663 104.803 18.8722C104.184 17.7611 103.349 16.8808 102.296 16.2312C101.244 15.5816 100.066 15.2569 98.7623 15.2569C96.941 15.2569 95.5458 15.8466 94.5766 17.0261V15.5646H91.0677V34.6928ZM98.286 26.4107C97.2668 26.4107 96.4313 26.0603 95.7797 25.3594C95.1447 24.6415 94.8273 23.7099 94.8273 22.5646C94.8273 21.4363 95.1447 20.5133 95.7797 19.7953C96.4313 19.0774 97.2668 18.7184 98.286 18.7184C99.322 18.7184 100.174 19.0859 100.843 19.821C101.511 20.5389 101.845 21.4534 101.845 22.5646C101.845 23.6928 101.511 24.6158 100.843 25.3338C100.174 26.0517 99.322 26.4107 98.286 26.4107Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M112.544 29.8722C114.131 29.8722 115.409 29.4705 116.378 28.6671C117.347 27.8637 117.832 26.8124 117.832 25.5133C117.832 24.5047 117.531 23.6586 116.93 22.9748C116.328 22.274 115.459 21.7526 114.323 21.4107L112.143 20.744C111.157 20.4534 110.664 20.0346 110.664 19.4876C110.664 19.1287 110.797 18.8466 111.065 18.6415C111.349 18.4363 111.733 18.3338 112.218 18.3338C112.886 18.3338 113.479 18.5133 113.997 18.8722C114.532 19.2141 114.9 19.6757 115.1 20.2569L117.882 18.9235C117.464 17.7611 116.746 16.8637 115.727 16.2312C114.724 15.5816 113.554 15.2569 112.218 15.2569C111.198 15.2569 110.296 15.4449 109.511 15.821C108.726 16.1799 108.116 16.6842 107.681 17.3338C107.247 17.9834 107.03 18.744 107.03 19.6158C107.03 20.6073 107.33 21.4705 107.932 22.2056C108.533 22.9235 109.394 23.4449 110.513 23.7697L112.844 24.4363C113.747 24.6928 114.198 25.103 114.198 25.6671C114.198 26.5047 113.646 26.9235 112.544 26.9235C111.808 26.9235 111.14 26.7099 110.538 26.2825C109.937 25.8552 109.461 25.2654 109.11 24.5133L106.353 25.8466C106.754 27.0774 107.506 28.0603 108.609 28.7953C109.728 29.5133 111.04 29.8722 112.544 29.8722Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M119.327 29.5646H123.087V21.3851C123.087 20.5816 123.32 19.9406 123.788 19.462C124.256 18.9663 124.858 18.7184 125.593 18.7184C126.328 18.7184 126.93 18.9663 127.397 19.462C127.865 19.9406 128.099 20.5816 128.099 21.3851V29.5646H131.859V20.5646C131.859 19.5047 131.642 18.5816 131.207 17.7953C130.773 16.9919 130.163 16.368 129.377 15.9235C128.609 15.4791 127.707 15.2569 126.671 15.2569C125.902 15.2569 125.209 15.3936 124.59 15.6671C123.972 15.9235 123.471 16.3252 123.087 16.8722V10.1543H119.327V29.5646Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path d="M133.579 14.3081H137.339V10.462H133.579V14.3081ZM133.579 29.5646H137.339V15.5646H133.579V29.5646Z"
                  fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
        {
          isShowSideBar ?
            <path
              d="M139.338 34.6928H143.097V28.4876C143.582 28.9492 144.158 29.2996 144.827 29.5389C145.512 29.7611 146.239 29.8722 147.007 29.8722C148.344 29.8722 149.539 29.5475 150.591 28.8979C151.644 28.2483 152.471 27.368 153.073 26.2569C153.691 25.1457 154 23.915 154 22.5646C154 21.197 153.691 19.9663 153.073 18.8722C152.454 17.7611 151.619 16.8808 150.566 16.2312C149.514 15.5816 148.336 15.2569 147.032 15.2569C145.211 15.2569 143.816 15.8466 142.847 17.0261V15.5646H139.338V34.6928ZM146.556 26.4107C145.537 26.4107 144.701 26.0603 144.05 25.3594C143.415 24.6415 143.097 23.7099 143.097 22.5646C143.097 21.4363 143.415 20.5133 144.05 19.7953C144.701 19.0774 145.537 18.7184 146.556 18.7184C147.592 18.7184 148.444 19.0859 149.113 19.821C149.781 20.5389 150.115 21.4534 150.115 22.5646C150.115 23.6928 149.781 24.6158 149.113 25.3338C148.444 26.0517 147.592 26.4107 146.556 26.4107Z"
              fill={theme === 'light' ? "#151E3A" : 'white'} />
            :
            null
        }
      </svg>
      {
        isMobile ?
          <span onClick={() => openSidebar(false)}
                style={{marginTop: -4}}
          >
            <Icon role={'icon'} type={'close_modal'} />
          </span>
          :
          <svg xmlns="http://www.w3.org/2000/svg"
               width="40"
               height="40"
               viewBox="0 0 40 40"
               fill="none"
               className={'main-sidebar-header-btn'}
               onClick={() => {
                 setFlag(true);
                 changeSizeSidebar(!isShowSideBar);
               }}
          >
            <path stroke={theme === 'light' ? "#434C69" : 'rgba(255, 255, 255, .36)'}
                  d="M19.3751 13.959H13.9584C13.0379 13.959 12.2917 14.7052 12.2917 15.6257V24.3757C12.2917 25.2961 13.0379 26.0423 13.9584 26.0423H19.3751M19.3751 13.959H26.0417C26.9622 13.959 27.7084 14.7052 27.7084 15.6257V24.3757C27.7084 25.2961 26.9622 26.0423 26.0417 26.0423H19.3751M19.3751 13.959V26.0423"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeLinejoin="round"
            />
            <path fill={theme === 'light' ? "#434C69" : 'rgba(255, 255, 255, .36)'}
                  d="M15.1667 17.0827C15.1667 17.4509 15.4651 17.7493 15.8333 17.7493C16.2015 17.7493 16.5 17.4509 16.5 17.0827C16.5 16.7145 16.2015 16.416 15.8333 16.416C15.4651 16.416 15.1667 16.7145 15.1667 17.0827ZM15.1667 19.9993C15.1667 20.3675 15.4651 20.666 15.8333 20.666C16.2015 20.666 16.5 20.3675 16.5 19.9993C16.5 19.6312 16.2015 19.3327 15.8333 19.3327C15.4651 19.3327 15.1667 19.6312 15.1667 19.9993ZM15.1667 22.916C15.1667 23.2842 15.4651 23.5827 15.8333 23.5827C16.2015 23.5827 16.5 23.2842 16.5 22.916C16.5 22.5478 16.2015 22.2493 15.8333 22.2493C15.4651 22.2493 15.1667 22.5478 15.1667 22.916Z"
                  stroke="#434C69"
                  strokeWidth="0.5"
            />
          </svg>
      }
    </div>
  );
};

export default SidebarHeader;
