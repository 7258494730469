import React from 'react';
import cls from 'classname';
import { Modal } from 'antd';
import Icon from '../../Icon';
import './styles.less';


const ModalComponent = (
  {
    title,
    visible,
    isMobile,
    children,
    buttons,
    handleOk,
    handleClose,
    className,
    wrapClassName,
    ...props
  }) => (
  <Modal className={cls(className, 'custom-modal')}
         wrapClassName={cls(wrapClassName, 'custom-wrap-modal')}
         {...(isMobile ? { transitionName: '' } : null)}
         title={title}
         open={visible}
         centered={!isMobile}
         closable
         closeIcon={<Icon role="icon" type="close_modal" />}
         onOk={handleOk}
         onCancel={handleClose}
         getContainer={() => document.getElementById('global-wrap')}
         footer={
           buttons ? <div className="modal-footer-wrapper">{buttons}</div> : <div />
         }
         {...props}
  >
    {children}
  </Modal>
);

export default ModalComponent;
