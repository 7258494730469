import React from 'react';
import cls from 'classname';
import { Button } from 'antd';
import './styles.less';

export const ButtonComponent = ({ className, text, onClick, ...props }) => {

  return (
    <Button onClick={onClick} className={cls(className, 'btn')} {...props}>
      {text}
      {props.children}
    </Button>
  )
};

export default ButtonComponent;
