import React from 'react';

const DatabaseStoreProducts = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.7087 6.45974L15.7397 3.90431C15.361 3.41289 14.7758 3.125 14.1554 3.125H5.84524C5.22487 3.125 4.63962 3.41289 4.26097 3.90431L2.29199 6.45974M17.7087 6.45974V7.29342C17.7087 8.03415 17.3778 8.69967 16.8522 9.15763M17.7087 6.45974H2.29199M2.29199 6.45974V7.29342C2.29199 8.03415 2.62281 8.69967 3.14847 9.15763M12.5698 7.29342C12.5698 8.67471 13.7201 9.79447 15.1392 9.79447C15.7973 9.79447 16.3976 9.55366 16.8522 9.15763M12.5698 7.29342V6.45974M12.5698 7.29342C12.5698 8.67471 11.4194 9.79447 10.0003 9.79447C8.58126 9.79447 7.43088 8.67471 7.43088 7.29342M7.43088 7.29342C7.43088 8.67471 6.2805 9.79447 4.86144 9.79447C4.20336 9.79447 3.60306 9.55366 3.14847 9.15763M7.43088 7.29342V6.45974M11.7133 16.875L11.7133 14.3739C11.7133 13.4531 10.9464 12.7066 10.0003 12.7066C9.05429 12.7066 8.28737 13.4531 8.28737 14.3739L8.28736 16.875M3.14847 9.15763V14.875C3.14847 15.9796 4.0439 16.875 5.14847 16.875H14.8522C15.9567 16.875 16.8522 15.9796 16.8522 14.875V9.15763"
      stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default DatabaseStoreProducts;
