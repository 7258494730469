import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Creators from "../../Containers/SalesTrackerPage/reducer";
import { Divider, Dropdown, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import Image from '../Image';
import Images from '../../Images';
const { Search } = Input;
import './styles.less';
import useDebounce from '../../hooks/use-debounce';
import acc from 'accounting';

const Suggestions = (
  {
    options = [],
    setValue,
    setSearchSuggestionValue,
    suggestionsLoading = false,
    setVisible
  }) => {

  const { t } = useTranslation();
  const theme = useSelector(store => store.nav.theme);

  return (
    options?.length ?
      <div className={cls('dropdown-input-menu-items', {
        loading: suggestionsLoading,
      })}>
        {options.map(elem => (
          <div key={elem?.id}
               className={'dropdown-input-menu-item'}
               onClick={() => {
                 setValue(elem?.custom_domain);
                 setSearchSuggestionValue(elem);
                 setVisible(false);
               }}
          >
            <div className="dropdown-input-menu-item-info">
              <div className="logo">
                {
                  elem.favicon ?
                    <img src={utils.changeImageSize(elem?.favicon, 496)} alt="" onError={utils.addDefaultSrc} />
                    :
                    <Image src={Images.defaultImg} small={true} />
                }
              </div>
              <div className="title">
                <h4>
                  {elem?.title}
                </h4>
                <span>
                  {elem?.custom_domain}
                </span>
              </div>
            </div>
            <span className={'dropdown-input-menu-item-count'}>
              {acc.formatNumber(elem?.products_count || 0, 0,',',',')}{' '}Products
            </span>
          </div>
        ))}
      </div>
      :
      <div className={cls('dropdown-input-menu-items empty', {
        loading: suggestionsLoading,
      })}>
        <div className="empty-state empty-state-product-database">
          <div className="empty-state_icon">
            <Icon width={48} height={48}
                  role="icon"
                  type="empty_warning"
                  fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
            />
          </div>
          <div className="empty-state-product-database-title">
            {t('No Results Found')}
            <div className="empty-state-product-database-subtitle">
              {t('kindly_change_search')}
            </div>
          </div>
        </div>
      </div>
  );
};


export const SearchComponent = (
  {
    handleSearch,
    visibleDropdown = false,
    setVisibleDropdown = () => null,
    button,
    disabled,
    onFocusCallback,
    value,
    setValue,
    setSearchSuggestionValue,
    onKeyUp,
    wrapperStyle,
    gps = false,
    withSuggestions = false,
    ...props
  }) => {

  const debouncedValue = useDebounce(value, 500);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [focusedSearch, setFocusedSearch] = useState(false);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);
  const suggestions = useSelector(state => state.salesTracker.suggestions);
  const suggestionsLoading = useSelector(state => state.salesTracker.suggestionsLoading);

  useEffect(() => {
    if (withSuggestions && debouncedValue.length > 2 && focusedSearch) setVisibleDropdown(true);
    else setVisibleDropdown(false);
  }, [debouncedValue, withSuggestions]);

  useEffect(() => {
    if (visibleDropdown && debouncedValue.length > 2 && focusedSearch) {
      dispatch(Creators.getStoreSuggestionsRequest({value: debouncedValue}))
    } else {
      dispatch(Creators.resetStoreSuggestions());
    }
  }, [visibleDropdown, debouncedValue]);

  return withSuggestions ?
    (
      <div className="search" style={wrapperStyle && wrapperStyle}>
        <div className={cls('search_wrapper', {
          'disabled-transition': isDisabledTransition,
        })}>
          <Dropdown overlayClassName={'dropdown-input-wrapper'}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placement={'bottom'}
                    open={visibleDropdown}
                    dropdownRender={() => (
                      <div className="dropdown-input-menu" style={{ maxHeight: 248 }}>
                    <span className="dropdown-input-menu-title">
                      <Icon type={'energy_bulb'} role={'icon'} />
                      {t('Suggestions')}
                    </span>
                        <Divider type={'horizontal'} style={{ margin: '10px auto 4px' }} />
                        <Suggestions options={suggestions}
                                     setValue={setValue}
                                     setSearchSuggestionValue={setSearchSuggestionValue}
                                     suggestionsLoading={suggestionsLoading}
                                     setVisible={setVisibleDropdown}
                        />
                      </div>
                    )}
          >
            <Search placeholder={
              props?.placeholder ?
                props?.placeholder
                :
                t('common.search_products')
            }
                    className="search-input"
                    onFocus={() => {
                      setFocusedSearch(true);
                      onFocusCallback && onFocusCallback();
                    }}
                    onBlur={() => {
                      setFocusedSearch(false);
                    }}
                    onChange={(e) => {
                      e.persist();
                      handleSearch(e);
                    }}
                    onKeyUp={(e) => {
                      if (onKeyUp) {
                        onKeyUp(e);
                        if (e.keyCode === 13) setFocusedSearch(false);
                      }
                    }}
                    defaultValue={value && value}
                    {...(value ? { value: value } : null)}
                    prefix={
                      <>
                        <Icon type={gps ? 'gps' : 'search_icon'} role="icon"
                        />
                        <Divider type="vertical" className="search-divider" />
                      </>
                    }
                    disabled={disabled}
            />
          </Dropdown>
          <div className="search_button">{button}</div>
        </div>
      </div>
    )
    :
    (
      <div className="search" style={wrapperStyle && wrapperStyle}>
        <div className={cls('search_wrapper', {
          'disabled-transition': isDisabledTransition,
        })}>
          <Search
            placeholder={
              props?.placeholder
                ? props?.placeholder
                : t('common.search_products')
            }
            className="search-input"
            onFocus={() => {
              setFocusedSearch(true);
              onFocusCallback && onFocusCallback();
            }}
            onBlur={() => setFocusedSearch(false)}
            onChange={(e) => {
              e.persist();
              handleSearch(e);
            }}
            onKeyUp={(e) => {
              if (onKeyUp) {
                onKeyUp(e);
                if (e.keyCode === 13) setFocusedSearch(false);
              }
            }}
            defaultValue={value && value}
            {...(value ? { value: value } : null)}
            prefix={<Icon type={gps ? 'gps' : 'search_icon'} role="icon" color={focusedSearch ? '#225AEA' : '#707BA0'} />}
            disabled={disabled}
          />
          {
            button ?
              <div className="search_button">{button}</div>
              :
              null
          }
        </div>
      </div>
    );
};

SearchComponent.defaultProps = {};

SearchComponent.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  button: PropTypes.object,
};

export default SearchComponent;
