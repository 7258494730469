import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Icon from '../../../Icon';
import Images from '../../../Images';
import ProductDetailsCard from './ProductDetailsCard';
import acc from 'accounting';
import './SaturationBlock.less';

const SaturationBlock = (
  {
    skeleton,
    isMobile,
    saturation,
  }) => {

  const { t } = useTranslation();

  function checkSaturation(value) {
    let result;
    switch (true) {
      case value >= 66:
        result = 'high';
        break;

      case value < 36:
        result = 'low';
        break;

      default:
        result = 'medium';
        break;
    }
    return result;
  }

  const renderHeader = (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 4
    }}>
      <span className="title">
        {t('Saturation')}
      </span>
      <Icon type={'attention_outline'}
            role={'button'}
            titleText={t('The saturation metric gives you a visual look at the saturation of the product and is calculated based on the amount of competitors found for the product.')}
            btnType={'header-tooltip'}
            tooltipProps={{
              placement: 'top',
              trigger: isMobile ? 'click' : 'hover',
              overlayClassName: cls('details-tooltip', 'saturation'),
              getPopupContainer: (trigger) => trigger.parentNode}}
      />
    </div>
  );

  const renderHeaderSkeleton = (
    <span className={'link skeleton'}
          style={{ width: 90, height: 28 }}
    />
  );

  return (
    <ProductDetailsCard header={skeleton ? renderHeaderSkeleton : renderHeader}>
      <div className="saturation-card">
        <div className="saturation-card-indicator-line">
          {['low', 'medium', 'high'].map((el) => (
            <span key={el}
              className={cls('saturation-card-indicator-line-item', {
                active: checkSaturation(saturation) === el,
                [`${el}`]: el,
                'link skeleton': skeleton
              })}
                  style={skeleton ? {width: 60, height: 20} : {}}
            >
              {skeleton ? null : t(el)}
            </span>
          ))}
        </div>
        {
          skeleton ?
            <div className={'saturation-card-speedometer-wrap'}>
              <span className={'link skeleton'}
                    style={{ width: isMobile ? '100%' : 339, height: 169 }}
              />
            </div>
            :
            <div className={'saturation-card-speedometer-wrap'}>
            <img src={Images.speedometer}
                   className="speedometer-img"
                   alt=""
              />
              <div className="speedometer-arrow">
                <div className={'speedometer-arrow-icon-wrap'}
                     style={{
                       transform: `translate(-50%, -50%) rotate(${
                         (
                           (saturation / 100) * 180 > 180 ?
                             180
                             :
                             (saturation / 100) * 180) - 90
                       }deg)`,
                     }}>
                  <Icon role="icon"
                        type="speedometer_arrow"
                        className="speedometer-arrow-icon"
                  />
                </div>
                <span className="speedometer-arrow-count" />
              </div>
            </div>
        }
        {
          skeleton ?
            <div className={'saturation-card-value'}>
              <span className={'link skeleton'}
                    style={{ width: 49, height: 25 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 65, height: 28 }}
              />
            </div>
            :
            <div className={'saturation-card-value'}>
              {
                acc.formatNumber(saturation, 2, ',', '.')
              }
              <span className={`saturation-card-value-tag ${checkSaturation(saturation)}`}>
            {checkSaturation(saturation)}
          </span>
            </div>
        }
      </div>
    </ProductDetailsCard>
  );
};

export default SaturationBlock;
