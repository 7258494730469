import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {connect} from 'react-redux'
import {PrivateRoute} from '../../Components/PrivateRoute'
import CustomLayout from '../Layout'
import DashboardPage from '../DashboardPage'
import SettingPage from '../SettingPage'
import PortfolioPage from '../PortfolioPage';
import ProductPage from '../ProductsPage';
import ProductDetailsPage from '../ProductDetailsPage';
import CollectionsPage from '../CollectionsPage';
import ShopifySearch from '../ShopifySearch';
import ProductDatabasePage from '../ProductDatabasePage';
import AdSpotPage from '../AdSpotPage';
import AdItemPage from "../AdSpotPage/pages/AdItemPage";
import SalesTrackerPage from '../SalesTrackerPage';
import StoreTrackerPage from '../SalesTrackerPage/StoreTrackerPage';
import ProductTrackerPage from '../SalesTrackerPage/ProductTrackerPage';
import SalesTrackerTopPage from "../SalesTrackerPage/pages/SalesTrackerTopPage";
import TrackerReportDetailsPage from '../SalesTrackerPage/TrackerReportDetailsPage';
import ConnectShopifyStoreVerifyPage from '../ConnectShopifyStoreVerifyPage';
import UniversityPage from '../UniversityPage';
import UniversityVideoPage from "../UniversityPage/UniversityVideoPage";
import UniversityCoursePage from "../UniversityPage/UniversityCoursePage";
import NotFoundPage from "../NotFoundPage";
import SuppliersPage from "../SuppliersPage";
import ProductDetailsPageAdmin from '../ProductDetailsPageAdmin';
import PortfolioPageAdmin from '../PortfolioPageAdmin';
import AliDropsPageAdmin from '../AliDropsPageAdmin';
import DropTimesPageAdmin from '../DropTimesPageAdmin';
import BDPageAdmin from '../BDPageAdmin';
import AdminStoreManager from '../AdminStoreManager';
import AdminStoreTable from '../AdminStoreTable';
import AdminTrackerReportDetailsPage from '../AdminStoreTable/AdminTrackerReportDetailsPage';
import AdminStoreTrackerPage from '../AdminStoreTable/AdminStoreTrackerPage';

function InternalPages(props) {
  const {
    auth,
    userInfo,
    isAdmin,
  } = props

  return (
    <CustomLayout>
        <Routes>
          <Route
            path="portfolio"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <PortfolioPage />
              </PrivateRoute>
              }
          />
          <Route
            path='/dashboard/product-list'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />
          <Route
            path='/portfolio/product-list'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/collections/product-list'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />
          <Route
            path='/portfolio/product/:product_id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={(!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin) || isAdmin}>
                <ProductDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/dashboard/product/:product_id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={(!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin) || isAdmin}>
                <ProductDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/collections/product/:product_id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={(!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin) || isAdmin}>
                <ProductDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/portfolio/product/:product_id/*'
            element={
              <PrivateRoute redirectPath={'/portfolio'}
                            auth={false}
              />
            }
          />

          <Route
            path='/portfolio/product-list/:id/*'
            element={
              <PrivateRoute redirectPath={'/portfolio'}
                            auth={false}
              />
            }
          />

          <Route
            path='/dashboard/product/:product_id/*'
            element={
              <PrivateRoute redirectPath={'/dashboard'}
                            auth={false}
              />
            }
          />

          <Route
            path='/dashboard/product-list/:id/*'
            element={
              <PrivateRoute redirectPath={'/dashboard'}
                            auth={false}
              />
            }
          />

          <Route
            path='/collections/product/:product_id/*'
            element={
              <PrivateRoute redirectPath={'/collections'}
                            auth={false}
              />
            }
          />

          <Route
            path='/collections/product-list/:id/*'
            element={
              <PrivateRoute redirectPath={'/collections'}
                            auth={false}
              />
            }
          />

          <Route
            path="collections"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <CollectionsPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/portfolio/product-list/:id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/dashboard/product-list/:id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/collections/product-list/:id'
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />

          <Route
            path="dashboard"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sales-tracker/stores/store/:id/:report"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <TrackerReportDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sales-tracker/stores/store/:id/:report/*"
            element={
              <PrivateRoute redirectPath={'/sales-tracker/stores'}
                            auth={false}
              />
            }
          />
          <Route
            path="sales-tracker/stores/store/:id"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <StoreTrackerPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sales-tracker/products/product/:id"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <ProductTrackerPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sales-tracker/products/product/:id/*"
            element={
              <PrivateRoute redirectPath={'/sales-tracker/products'}
                            auth={false}
              />
            }
          />
          <Route
            path="sales-tracker/:view"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <SalesTrackerPage />
              </PrivateRoute>
            }
          />

          <Route
            path="sales-tracker/top/stores"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <SalesTrackerTopPage />
              </PrivateRoute>
            }
          />

          <Route
            path="sales-tracker/top/products"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <SalesTrackerTopPage />
              </PrivateRoute>
            }
          />

          <Route
            path="dropship-university"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <UniversityPage />
              </PrivateRoute>
            }
          />

          <Route
            path="dropship-university/:courseID"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <UniversityCoursePage />
              </PrivateRoute>
            }
          />

          <Route
            path="dropship-university/:course/:chapter/:video"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <UniversityVideoPage />
              </PrivateRoute>
            }
          />

          <Route
            path="dropship-university/:courseID/:chapter/:video/*"
            element={
              <PrivateRoute redirectPath={'/dropship-university'}
                            auth={false}
              />
            }
          />

          <Route
            path="suppliers"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <SuppliersPage />
              </PrivateRoute>
            }
          />

          <Route
            path="suppliers/*"
            element={
              <PrivateRoute redirectPath={'/suppliers'}
                            auth={false}
              />
            }
          />

          <Route
            path="competitor-research"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <ShopifySearch />
              </PrivateRoute>
            }
          />

          <Route
            path={'/competitor-research/*'}
            element={
              <PrivateRoute redirectPath={'/competitor-research'}
                            auth={false}
              />
            }
          />

          <Route
            path="product-database"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <ProductDatabasePage />
              </PrivateRoute>
            }
          />

          <Route
            path={'/product-database/*'}
            element={
              <PrivateRoute redirectPath={'/product-database'}
                            auth={false}
              />
            }
          />

          <Route
            path="ad-spot"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <AdSpotPage />
              </PrivateRoute>
            }
          />

          <Route
            path={'/ad-spot/:id/:mode'}
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <AdItemPage />
              </PrivateRoute>
            }
          />

          <Route
            path={'/ad-spot/:id/:mode/:productId'}
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin && !userInfo?.need_block_pages}>
                <AdItemPage />
              </PrivateRoute>
            }
          />

          <Route
            path={'/ad-spot/:id/:mode/:productId/*'}
            element={
              <PrivateRoute redirectPath={'/ad-spot'}
                            auth={false}
              />
            }
          />

          <Route
            path="setting"
            element={
              <PrivateRoute redirectPath={'/setting/account'}
                            auth={false}>
                <SettingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="setting/:hash"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <SettingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="setting/:hash/*"
            element={
              <PrivateRoute redirectPath={'/setting/account'}
                            auth={false}
              />
            }
          />
          <Route
            path="admin/portfolio/product/:product_id"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <ProductDetailsPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/portfolio"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <PortfolioPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/portfolio/product-list"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <ProductPage />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/alidrops"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AliDropsPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/database"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AliDropsPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/droptimes"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <DropTimesPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/bd"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <BDPageAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/shopify"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <ShopifySearch />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/store-manager"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AdminStoreManager />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/store-table"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AdminStoreTable />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/store-table/store/:id/:report"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AdminTrackerReportDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/store-table/store/:id/:report/*"
            element={
              <PrivateRoute redirectPath={'/admin/store-table'}
                            auth={false} />
            }
          />
          <Route
            path="admin/store-table/store/:id"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && isAdmin}>
                <AdminStoreTrackerPage />
              </PrivateRoute>
            }
          />
          <Route
            path="connect-shopify/verify"
            element={
              <PrivateRoute redirectPath={'/login'}
                            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}>
                <ConnectShopifyStoreVerifyPage />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>

    </CustomLayout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userInfo: state.auth.userInfo,
  isAdmin: state.auth.isAdmin,
})

export default connect(mapStateToProps, null)(InternalPages)
