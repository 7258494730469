import React from 'react';
import { useSelector } from 'react-redux';

const EditCardModalInput = () => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.29297 8.1252V14.3719C2.29297 15.2924 3.03916 16.0386 3.95964 16.0386L16.0397 16.0386C16.9602 16.0386 17.7064 15.2924 17.7064 14.3719V8.1252M2.29297 8.1252V5.6276C2.29297 4.70713 3.03916 3.96094 3.95964 3.96094H16.0413C16.9599 3.96094 17.7053 4.70418 17.706 5.62276C17.7067 6.45691 17.7064 7.29106 17.7064 8.1252M2.29297 8.1252H17.7064M5.6263 11.0418H8.1263"
        stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.26)'}
        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditCardModalInput;
