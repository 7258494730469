import React from 'react'
import cls from 'classname'

const NotificationSchedule = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.0013 6.45866V10.0003L12.293 12.292M17.7096 10.0003C17.7096 14.2575 14.2585 17.7087 10.0013 17.7087C5.74411 17.7087 2.29297 14.2575 2.29297 10.0003C2.29297 5.74313 5.74411 2.29199 10.0013 2.29199C14.2585 2.29199 17.7096 5.74313 17.7096 10.0003Z"
      stroke="#225AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default NotificationSchedule
