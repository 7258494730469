import React, { useState } from 'react';
import { Divider } from 'antd';
import InputComponent from '../Input';
import { useTranslation } from 'react-i18next';
import ChangePasswordModal from './ChangePasswordModal';
import UserCreators from '../../Containers/User/reducer';
import { connect } from 'react-redux';

const Security = (
  {
    isMobile,
    saveFetching,
    userError,
    saveResult,
    userErrorsReset,
    userSaveResultReset,
    changePassword
  }
) => {

  const {t} = useTranslation();
  const [modal, setModal] = useState(null);

  return (
    <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          {t('Security')}
        </span>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className="account-subpage-block-email">
        <div className="input-wrapper">
          <span className="input-label">
            {t('Password')}
          </span>
          <InputComponent placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                          disabled
          />
        </div>
        <div className="btn-wrapper">
          <span onClick={() => setModal('change_password')}>
            {t('Change password')}
          </span>
        </div>
      </div>
      <ChangePasswordModal visible={modal === 'change_password'}
                           setModal={setModal}
                           loading={saveFetching}
                           isMobile={isMobile}
                           userError={userError?.detail}
                           userErrorsReset={userErrorsReset}
                           changePassword={changePassword}
                           saveResult={saveResult}
                           userSaveResultReset={userSaveResultReset}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userError: state.users.errors,
  saveResult: state.users.saveResult,
  saveFetching: state.users.saveFetching,
});

const mapDispatchToProps = (dispatch) => ({
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
  userErrorsReset: () => dispatch(UserCreators.userErrorsReset()),
  changePassword: (password) => dispatch(UserCreators.changePasswordRequest(password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Security);
