import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cls from 'classname';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Dropdown, Checkbox, DatePicker } from 'antd';
import Icon from '../../../Icon';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import ButtonComponent from '../../../Components/Button';
import { langMapISO,calendarLocale } from '../../../Utils/utils';
import '../../ProductDatabasePage/components/DropdownDateBlock.less';

const { RangePicker } = DatePicker;

export default function DropdownDateBlockST(
  {
    limit,
    label,
    iconType,
    data,
    id,
    customOverlayClassName,
    customInnerOverlayClassName,
    dropdownFilters,
    setDropdownFilters,
    memoCheckedList,
    setMemoCheckedList,
    timeZone,
    hideTimeZoneText=false
  }) {
  //inner dropdown period ref
  const dropdownInner = useRef();
  const { t } = useTranslation();
  //show/hide outer dropdown
  const [visible, setVisible] = useState(false);
  //show/hide inner dropdown
  const [innerVisible, setInnerVisible] = useState(false);
  //main checkbox state
  const [checkAll, setCheckAll] = useState(false);
  //dayjs date value
  const [dateValue, setDateValue] = useState(null);
  //string from dayjs date value to display if selected
  const [stringDate, setStringDate] = useState(null);

  const [localMemoList, setLocalMemoList] = useState(null);
  const [localDropdownFilters, setLocalDropdownFilters] = useState(null);

  const lang = useSelector(store => store?.auth?.userInfo?.language) || 'en';
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  //calendar display settings
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  useEffect(() => {
    setLocalDropdownFilters({ [id]: {...dropdownFilters[id]} });
    const newData = [...memoCheckedList].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days);
    setLocalMemoList(limit ?
      newData.length ?
        newData
        :
        [{days: 0, id: 999, name: 'Custom', value: null,}]
      :
      memoCheckedList);
    setCheckAll(memoCheckedList && memoCheckedList.length > 1);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [memoCheckedList, dropdownFilters[id]]);

  //set all if nothing selected on close
  useEffect(() => {
    if (!visible && !localMemoList && !dropdownFilters[id]?.min && !dropdownFilters[id]?.max) {
      setMemoCheckedList(data);
      setDropdownFilters(state => ({ ...state, [id]: { min: null, max: null } }));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  //set initial value
  useEffect(() => {
    if (!dropdownFilters[id]?.min && !dropdownFilters[id]?.max) {
      setMemoCheckedList(data);
      setCheckAll(true);
      setDateValue(null);
      setStringDate(null);
    } else {
      setDateValue(dropdownFilters[id]);
      setStringDate({
        min: {
          date: dayjs(dropdownFilters[id]?.min).date(),
          month: dayjs(dropdownFilters[id]?.min).locale(langMapISO[lang]).format('MMM'),
          year: dayjs(dropdownFilters[id]?.min).year(),
        },
        max: {
          date: dayjs(dropdownFilters[id]?.max).date(),
          month: dayjs(dropdownFilters[id]?.max).locale(langMapISO[lang]).format('MMM'),
          year: dayjs(dropdownFilters[id]?.max).year(),
        },
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dropdownFilters]);

  //toggle outer dropdown show/hide
  const handleVisibleChange = (value) => {
    if (!value && innerVisible) setInnerVisible(false);
    setVisible(value);
  };

  //toggle inner dropdown show/hide
  const handleInnerVisibleChange = (value) => {
    setInnerVisible(value);
  };

  const minDate = (quantity, value, period) => dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
  const maxDate = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');

  //period checkbox handler + calculations min max
  const onChange = e => {
    const { value } = e.target;
    let val = value;
    let list = [value];
    if ((localMemoList && localMemoList?.[0]?.name === val?.name && !checkAll) || !val) {
      setCheckAll(true);
      const newData = [...data].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days);
      setLocalMemoList(limit ? newData : data);
      setLocalDropdownFilters(state => ({ ...state, [id]: { min: null, max: null } }));
    } else {
      if (localMemoList?.length === data?.length) {
        let r = data.map((elem, index) => !list.some(el => el.id === elem.id) && index).filter(el => typeof el === 'number');
        setLocalMemoList([data[r[0]]]);
        let quantity = data[r[0]].value.split(' ')[1];
        let value = data[r[0]].value.split(' ')[2];
        let period = data[r[0]].id;
        let max = maxDate();
        let min = minDate(quantity, value, period);
        setLocalDropdownFilters(state => ({ ...state, [id]: { min: min, max: max } }));
        setDateValue({ min: min, max: max });
      } else {
        setLocalMemoList([val]);
        let quantity = val.value.split(' ')[1];
        let value = val.value.split(' ')[2];
        let period = val.id;
        let min = minDate(quantity, value, period);
        let max = [1,2].includes(period) ? min : maxDate();
        setLocalDropdownFilters(state => ({ ...state, [id]: { min: min, max: max } }));
        setDateValue({ min: min, max: max });
      }
      setCheckAll(false);
    }
    setStringDate(null);
    setInnerVisible(false);
  };

  const onCheckAllChange = e => {
    if(checkAll) return
    const checked = e.target.checked;
    const newData = [...data].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days);
    setLocalMemoList(checked ? (limit ? newData : data) : null);
    setCheckAll(checked);
    let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1];
    let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2];
    let period = newData?.[newData?.length - 1]?.id;
    let min = limit ? dayjs(limit).format('YYYY-MM-DD') : minDate(quantity, value, period);
    let max = dayjs().diff(dayjs(limit), 'days') >= 1
    && dayjs().diff(dayjs(limit), 'days') < 7 ?
      maxDate()
      :
      [1, 2].includes(period) ? minDate(quantity, value, period) : maxDate();

    setLocalDropdownFilters(state => ({ ...state, [id]: { min: limit ? min : null, max: limit ? max : null } }));
    setDateValue({ min: limit ? min : null, max: limit ? max : null });
    if(innerVisible) setInnerVisible(false);
  };

  //string to display in outer dropdown after change is made
  function getSelectValue() {

    const allResult = () => {
      let yearMin = dayjs(dateValue?.min).year()
      let yearMax = dayjs(dateValue?.max).year()
      return yearMin === yearMax ?
        `${dayjs(dateValue?.min).format('DD MMM')} - ${dayjs(dateValue?.max).format('DD MMM')}`
        :
        `${dayjs(dateValue?.min).format('DD MMM YYYY')} - ${dayjs(dateValue?.max).format('DD MMM YYYY')}`
    }

    return dateValue && stringDate
      ?
      //display or not year
      stringDate?.min?.year === stringDate?.max?.year
        ? `${stringDate?.min?.date} ${stringDate?.min?.month} - ${stringDate?.max?.date} ${stringDate?.max?.month}`
        : `${stringDate?.min?.date} ${stringDate?.min?.month} ${stringDate?.min?.year} - ${stringDate?.max?.date} ${stringDate?.max?.month} ${stringDate?.max?.year}`
      :
      //if selected all
      localMemoList
        ? localMemoList?.length === [...data].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days).length
          ? allResult()
          : t(localMemoList?.[0]?.['name'])
        : allResult();
  }

  //string to display in inner dropdown after change is made
  function getSelectValueInner() {
    return localMemoList
      ? localMemoList?.length === [...data].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days).length
        ? t('All')
        : t(localMemoList?.[0]?.['name'])
      : t('All');
  }

  return (
    <div className={cls("select-block", {
      'disabled-transition': isDisabledTransition
    })}
         style={{position: 'relative'}}
    >
      {label && <span className="select-block_label">{t(label)}</span>}

      <Dropdown
        overlayClassName={customOverlayClassName || 'dropdown-database-wrapper'}
        destroyPopupOnHide={true}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        dropdownRender={() => (
          <div ref={dropdownInner} className={'dropdown-date-wrapper'}>

            <div className="dropdown-date-title">
              {t('Date Range')}
            </div>

            <Dropdown
              overlayClassName={customInnerOverlayClassName || '.dropdown-database-wrapper-inner'}
              destroyPopupOnHide={true}
              getPopupContainer={() => dropdownInner.current}
              onOpenChange={handleInnerVisibleChange}
              dropdownRender={() => (
                <div className="dropdown-database-menu">

                  <ul className="dropdown-database-list">
                    {localMemoList?.[0]?.name === 'Custom' &&
                    <div className="dropdown-database-list-item">
                      <Checkbox checked={true}>
                        {t('Custom')}
                      </Checkbox>
                    </div>
                    }

                    <div className="dropdown-database-list-item">
                      <Checkbox onChange={onCheckAllChange}
                                checked={checkAll}
                      >
                        {t('All')}
                      </Checkbox>
                    </div>
                    {
                      data.map(el => (
                        <div key={el.id} className="dropdown-database-list-item">
                          <Checkbox
                            value={el}
                            checked={localMemoList?.some(checked => checked.id === el.id)}
                            onChange={onChange}
                            disabled={limit && dayjs().diff(dayjs(limit), 'days') < el.days}>
                            {t(el.name)}
                          </Checkbox>
                        </div>
                      ))
                    }
                  </ul>
                </div>
              )}
              trigger={['click']}
              open={innerVisible}
            >
              <div className={cls('dropdown-database', { 'ant-dropdown-open': innerVisible })}>
                <Icon role="icon" type={iconType}/>
                <span className="dropdown-database_value">
            {
              getSelectValueInner()
            }
          </span>
                <ArrowSelectDown/>
              </div>
            </Dropdown>

            <div className="rangepicker-title">
              <span>{t('Start Date')}</span>
              <span>{t('End Date')}</span>
            </div>

            <RangePicker className={'dropdown-date-rangepicker'}
                         value={[dateValue?.min ? dayjs(dateValue?.min) : null, dateValue?.max ? dayjs(dateValue?.max) : null]}
                         open={true}
                         format={'YYYY-MM-DD'}
                         locale={calendarLocale(lang)}
                         nextIcon={<Icon type={'arrow_datepicker_next'} role={'icon'} width={24} height={24} color={'#707ba0'}/>}
                         prevIcon={<Icon type={'arrow_datepicker_prev'} role={'icon'} width={24} height={24} color={'#707ba0'}/>}
                         clearIcon={<Icon role="icon" type="close_modal" color="#707BA0" width={10} height={10} opacity={1}/>}
                         onCalendarChange={(date, strings, info) => {
                           const newData = [...data].filter(el => dayjs().diff(dayjs(limit), 'days') >= el.days);
                           setLocalMemoList(limit ? newData : data);
                           setCheckAll(true);
                           if (date.filter(el => el !== null).length) {
                             const inputs = document.querySelectorAll('.ant-picker-input');
                             //if date selected, set local and parent states
                             let min = (info?.range === 'end' && !date[0]) ? date[1]?.format('YYYY-MM-DD') : date[0]?.format('YYYY-MM-DD');
                             let max = info?.range === 'start' ? date[0]?.format('YYYY-MM-DD') : date[1]?.format('YYYY-MM-DD');

                             setLocalDropdownFilters(state => ({ ...state, [id]: { min: min, max: max} }));
                             setDateValue({ min: min, max: max});
                             if (checkAll) setCheckAll(false)
                             setLocalMemoList([{
                               days: 0,
                               id: 999,
                               name: 'Custom',
                               value: null,
                             }])

                             let minIndex = (info?.range === 'end' && !date[0]) ? 1 : 0
                             let maxIndex = info?.range === 'start' ? 0 : 1

                             setStringDate({
                               min: {
                                 date: date[minIndex].get('date'),
                                 month: date[minIndex].locale(langMapISO[lang]).format('MMM'),
                                 year: date[minIndex].get('year'),
                               },
                               max: {
                                 date: date[maxIndex].get('date'),
                                 month: date[maxIndex].locale(langMapISO[lang]).format('MMM'),
                                 year: date[maxIndex].get('year'),
                               }
                             })
                             if (info.range === 'start') {
                               return inputs?.[1]?.firstElementChild?.focus()
                             } else if( info.range === 'end' && strings[0] === strings[1])  {
                               return inputs?.[0]?.firstElementChild?.focus();
                             }
                           } else {
                             setDateValue(null);
                             setStringDate(null);
                           }
                         }}
                         getPopupContainer={() => dropdownInner.current}
                         placeholder={[t('Select Start Date'), t('Select End Date')]}
                         disabledDate={current => {
                           let currentWithTimeZone = current.clone().format('YYYY-MM-DD');
                           return dayjs(currentWithTimeZone).diff(dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD'), 'days') > 0 || (limit && current < dayjs(limit));
                         }
                         }
                         dateRender={current => {
                           let currentWithTimeZone = current.clone().format('YYYY-MM-DD');
                           return (
                             <div className={cls('ant-picker-cell-inner', {
                               //style days after today
                               'ant-picker-future': dayjs(currentWithTimeZone).diff(dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD'), 'days') > 0 || (limit && current < dayjs(limit)),
                             })}
                             >
                               {current.date()}
                             </div>
                           );
                         }}
            />

            <div className="dropdown-date-button-wrapper">
              <div className="dropdown-date-info-wrapper">
                <p className="dropdown-date-info-date">
                  {dayjs(dateValue?.min).locale(langMapISO[lang]).format('MMM DD, YYYY')} - {dayjs(dateValue?.max).locale(langMapISO[lang]).format('MMM DD, YYYY')}
                </p>
                {
                  hideTimeZoneText ?
                    null
                    :
                    <p className="dropdown-date-info-timezone">
                      {t('Store Timezone')}: {timeZone ? timeZone : 'UTC +0:00'}
                    </p>
                }
              </div>
              <ButtonComponent className={'dropdown-date-button'}
                               text={t('Cancel')}
                               onClick={() => {
                                 setVisible(false);
                               }}
              />
              <ButtonComponent className={'dropdown-date-button apply'}
                               text={t('Apply')}
                               disabled={!dateValue}
                               onClick={() => {
                                 setVisible(false);
                                 setMemoCheckedList(localMemoList);
                                 setDropdownFilters(state => ({ ...state, ...localDropdownFilters }));
                               }}
              />
            </div>
          </div>
        )}
        trigger={['click']}
        open={visible}
        placement={'bottomRight'}
      >
        <div className={cls('dropdown-database', { 'ant-dropdown-open': visible }, id)}>
          <Icon role="icon" type={iconType}/>
          <span className="dropdown-database_value">
            {
              getSelectValue()
            }
          </span>
          <ArrowSelectDown/>
        </div>
      </Dropdown>
    </div>
  );
}
