import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import Icon from '../../Icon';
import cls from 'classname';
import ButtonComponent from '../Button';
import InputComponent from '../Input';

const LoginForm = (
  {
    rememberMe,
    setRemember,
    onSubmit,
    error,
    otpRequired
  }) => {

  const [errorText, setErrorText] = useState(null);
  const [isBackError, setIsBackError] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (error) {
      setIsBackError(true);
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
      }
    } else {
      if (setErrorText && isBackError)
        setIsBackError(false);
      setErrorText(null);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  const onFinish = values => {
    if (rememberMe) setRemember(values);
    onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0]['errors']);
    }
  };

  function onChange(e) {
    if (e.target.checked) {
      let data = form.getFieldsValue();
      setRemember(data);
    } else {
      setRemember(null);
    }
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setIsBackError(false);
  }

  function onBlurHandler(e) {
    const target = e.target;

    setTimeout(() => {
      let error = form.getFieldError(target.name);
      if (error.length) {
        setErrorText(error[0]);
      }
    }, 10);
  }

  return (
    <Form onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onSubmit"
          className="login-form"
          layout="vertical"
          form={form}
          requiredMark={false}
    >
      <Form.Item noStyle shouldUpdate={true}>
        {() =>
          <div className={cls('error-text', { 'error-text_empty': !errorText })}>
            <span>{errorText}</span>
          </div>}
      </Form.Item>

      <Form.Item
        name="email"
        initialValue={rememberMe?.email}
        validateStatus={errorText || isBackError ? 'error' : null}
        rules={[
          { type: 'email', message: 'The email or password you entered is incorrect.' },
          { required: true, message: 'The email or password you entered is incorrect.' },
        ]}
      >
        <InputComponent
          placeholder={'Email Address'}
          name="email"
          type="email"
          prefix={null}
          onChange={e => {
            handleClearError(e, 'email');
          }}
          onBlur={onBlurHandler}
        />
      </Form.Item>
      <Form.Item
        name="password"
        initialValue={rememberMe?.password}
        validateStatus={errorText || isBackError ? 'error' : null}
        rules={[{ required: true, message: 'The email or password you entered is incorrect.' }]}
      >
        <Input.Password
          size="large"
          placeholder={'Password'}
          autoComplete="current-password"
          visibilityToggle
          prefix={null}
          onChange={e => {
            handleClearError(e, 'password');
          }}
          iconRender={visible => (
            visible
              ? <Icon type="eye" color={'#C5CCE3'} />
              : <Icon type="eye_invisible" color={'#C5CCE3'} />
          )
          }
        />
      </Form.Item>

      {
        otpRequired ?
          <Form.Item
            name="otp_pass"
            initialValue={''}
            validateStatus={errorText || isBackError ? 'error' : null}
            rules={[{ required: true, message: 'OTP is required' }]}
          >
            <InputComponent
              placeholder={'6-digit Code'}
              name="otp_pass"
              type="number"
              prefix={null}
              onChange={e => {
                handleClearError(e, 'otp');
              }}
            />
          </Form.Item>
          :
          null
      }

      <ButtonComponent type="primary"
                       htmlType="submit"
                       className="login-form-submit"
                       style={{ width: '100%' }}
      >
        Sign In
      </ButtonComponent>
    </Form>
  );
}

export default LoginForm;
