import React from 'react';
import Plan from './Plan';
import TrackingLimits from './TrackingLimits';
import PayMethod from './PayMethod';
import BilAddress from './BilAddress';
import ComDetails from './ComDetails';
import PayHistory from './PayHistory';
import './styles.less';

const BillingSubPage = () => {

  return (
    <div className="billing-subpage">
      <Plan />
      <TrackingLimits />
      <PayMethod />
      <BilAddress />
      <ComDetails />
      <PayHistory />
    </div>
  );
}

export default BillingSubPage;
