import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import acc from 'accounting';
import { useCountdown } from '../../../hooks/useCountdown';
import Creator from '../reducer';
import ButtonComponent from '../../../Components/Button';
import actions from '../../Layout/actions';
import { useNavigate } from 'react-router';

const SubscriptionInfo = ({closeDropdown}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscription = useSelector(state => state?.auth?.userInfo?.subscriptions?.[0]);
  const nav = useSelector(state => state?.nav);

  const isTrial = subscription?.payment_status?.id === 6;
  const planName = subscription ? subscription?.['payment_status']?.id : false;
  const deadline = (subscription?.['trial_ends_at'] + 15) * 1000 || null;
  const [days, hours, minutes, seconds] = useCountdown(deadline);

  function handleTrial(adjusted = false) {
    if (planName === 6 && deadline) {
      if (seconds === -1) return;
      else if (days < 1 && hours >= 1) {
        return adjusted ?
          `${minutes >= 30 ? hours + 1 : hours}-hours`
          :
          `${minutes >= 30 ? hours + 1 : hours} hours trial`
      } else if (hours < 1 && days < 1) {
        if ((minutes === 0 && seconds === 0) || (minutes < 0)) {
          dispatch(Creator.userRequest());
        }
        return adjusted ?
          `${minutes < 1 ? '< 1' : minutes }-minutes`
          :
          `${minutes < 1 ? '< 1' : minutes } minutes trial`;
      } else {
        return adjusted ?
          `${(hours >= 12 ? days + 1 : days) || 0}-days`
          :
          `${(hours >= 12 ? days + 1 : days) || 0} days trial`;
      }
    }
  }

  return isTrial ?
    (
      <div className={'user-dropdown-inner-subscription-trial'}>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path fillRule="evenodd"
                clipRule="evenodd"
                d="M8.03007 2.71487C8.91836 1.22094 11.0813 1.22094 11.9696 2.71487L18.2063 13.2038C19.1146 14.7314 18.0137 16.6667 16.2365 16.6667H3.7632C1.98597 16.6667 0.88513 14.7314 1.79343 13.2038L8.03007 2.71487ZM9.99992 6.66665C10.3451 6.66665 10.6249 6.94647 10.6249 7.29165V10.625C10.6249 10.9702 10.3451 11.25 9.99992 11.25C9.65474 11.25 9.37492 10.9702 9.37492 10.625V7.29165C9.37492 6.94647 9.65474 6.66665 9.99992 6.66665ZM10.8333 12.9166C10.8333 13.3769 10.4602 13.75 9.99992 13.75C9.53968 13.75 9.16658 13.3769 9.16658 12.9166C9.16658 12.4564 9.53968 12.0833 9.99992 12.0833C10.4602 12.0833 10.8333 12.4564 10.8333 12.9166Z"
                fill="#F6B60E"
          />
        </svg>
        <h3>
          {handleTrial()}
        </h3>
        <h4>
          {`Keep using Dropship, your trial ends in ${handleTrial(true)}, or upgrade plan now.`}
        </h4>
        <ButtonComponent className={'user-dropdown-inner-subscription-trial-button'}
                         onClick={() => {
                           if (nav?.isMobile && nav?.isShowMenu) dispatch(actions.changeNavSetProp('isShowMenu', false));
                           navigate('/setting/plan');
                           closeDropdown(false);
                         }}
        >
                <span>
                  {t('Upgrade')}
                </span>
        </ButtonComponent>
      </div>
    )
    :
    (
      <div className={'user-dropdown-inner-subscription-wrapper'}>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="20"
             viewBox="0 0 16 20"
             fill="none"
        >
          <path
            d="M14.3034 4.31988L10.267 1.4169C8.91356 0.443227 7.08885 0.443227 5.73451 1.4169L1.69728 4.31988C0.684322 5.04863 0.083252 6.21997 0.083252 7.46778V12.533C0.083252 13.7808 0.683462 14.9521 1.69728 15.6809L5.73365 18.5838C7.08713 19.5575 8.91184 19.5575 10.2662 18.5838L14.3026 15.6809C15.3155 14.9521 15.9166 13.7808 15.9166 12.533V7.46778C15.9166 6.21997 15.3164 5.04863 14.3034 4.31988ZM6.64085 2.67589C7.45345 2.09151 8.5481 2.09151 9.35985 2.67589L12.7625 5.12254C13.0084 5.29957 13.0084 5.66481 12.7625 5.84184L9.35985 8.28849C8.54725 8.87287 7.45259 8.87287 6.64085 8.28849L3.23822 5.84184C2.99229 5.66481 2.99229 5.29957 3.23822 5.12254L6.64085 2.67589ZM14.3653 12.5321C14.3653 13.2806 14.005 13.9836 13.3971 14.421L9.36071 17.324C8.5481 17.9084 7.45345 17.9084 6.64171 17.324L2.60534 14.421C1.99739 13.9836 1.63709 13.2806 1.63709 12.5321V7.46692C1.63709 7.46435 1.63709 7.46177 1.63709 7.45919C1.63795 7.10083 2.0464 6.89458 2.33705 7.10341L5.73537 9.54748C7.08885 10.5212 8.91356 10.5212 10.2679 9.54748L13.6662 7.10341C13.9577 6.89372 14.3653 7.09997 14.3662 7.45919C14.3662 7.46177 14.3662 7.46435 14.3662 7.46692V12.5321H14.3653Z"
            fill="#225AEA"
          />
          <path
            d="M10.2457 11.0745C9.5707 11.2069 9.12269 11.7406 8.78991 12.3542C8.69188 12.5346 8.69876 12.7615 8.85096 12.8827C8.96361 12.972 9.11237 12.9669 9.22157 12.8732C9.63949 12.5174 10.127 12.3163 10.4426 12.2476C10.7582 12.1788 11.2767 12.1599 11.7798 12.3146C11.9122 12.355 12.0515 12.2966 12.1272 12.1668C12.2304 11.9898 12.1616 11.7784 12.0137 11.6495C11.4754 11.1811 10.9311 10.9396 10.2457 11.0745Z"
            fill="#225AEA"
          />
          <path
            d="M5.76231 11.0745C6.43733 11.2069 6.88534 11.7406 7.21812 12.3542C7.31615 12.5346 7.30927 12.7615 7.15707 12.8827C7.04442 12.972 6.89566 12.9669 6.78645 12.8732C6.36854 12.5174 5.88098 12.3163 5.56539 12.2476C5.24981 12.1788 4.73129 12.1599 4.22825 12.3146C4.09583 12.355 3.95652 12.2966 3.88085 12.1668C3.77766 11.9898 3.84645 11.7784 3.99436 11.6495C4.53265 11.1811 5.07697 10.9396 5.76231 11.0745Z"
            fill="#225AEA"
          />
        </svg>
        <div className="user-dropdown-inner-subscription-info">
          <span className="user-dropdown-inner-subscription-title">
            {subscription?.plan?.plan?.label} plan
          </span>
          <div className={'user-dropdown-inner-subscription-line'}>
          <span className={'user-dropdown-inner-subscription-key'}>
            {t('Next billing on')}
            </span>
            <span className="user-dropdown-inner-subscription-value">
            {dayjs(subscription?.next_payment_date).format('DD/MM/YYYY')}
          </span>
          </div>
          <div className={'user-dropdown-inner-subscription-line'}
               style={{ marginTop: -4 }}
          >
          <span className={'user-dropdown-inner-subscription-key'}>
            {t('Billing amount')}{' '}-
          </span>
            <span className="user-dropdown-inner-subscription-value">
              ${acc.formatNumber(subscription?.next_payment_amount, 2, ',', '.')}
          </span>
          </div>
        </div>
      </div>
    );
};



export default SubscriptionInfo;
