import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Database = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-database icon-database-outline')}
      >
        <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
              d="M10.0001 2.29102C5.74289 2.29102 2.29175 5.74215 2.29175 9.99935C2.29175 14.2565 5.74289 17.7077 10.0001 17.7077C13.3563 17.7077 16.2116 15.5627 17.2698 12.5688M10.0001 2.29102C14.2573 2.29102 17.7084 5.74215 17.7084 9.99935C17.7084 10.9003 17.5539 11.7651 17.2698 12.5688M10.0001 2.29102V9.99935L17.2698 12.5688"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-database icon-database-bold')}
      >
        <path stroke={theme === 'light' ? '#225AEA' : 'white'}
              d="M10.0001 2.29102C5.74289 2.29102 2.29175 5.74215 2.29175 9.99935C2.29175 14.2565 5.74289 17.7077 10.0001 17.7077C13.3563 17.7077 16.2116 15.5627 17.2698 12.5688M10.0001 2.29102C14.2573 2.29102 17.7084 5.74215 17.7084 9.99935C17.7084 10.9003 17.5539 11.7651 17.2698 12.5688M10.0001 2.29102V9.99935L17.2698 12.5688"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    );
}

export default Database;
