import React from 'react';

const LoadPreset = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.29199 9.99996H4.37533M2.29199 5.62496H5.20866M2.29199 14.375H5.20866M16.2503 13.75L18.542 16.0416M17.7087 9.99996C17.7087 12.8764 15.3768 15.2083 12.5003 15.2083C9.62384 15.2083 7.29199 12.8764 7.29199 9.99996C7.29199 7.12348 9.62384 4.79163 12.5003 4.79163C15.3768 4.79163 17.7087 7.12348 17.7087 9.99996Z"
        stroke="#434C69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LoadPreset;
