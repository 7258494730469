import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import './ProductDetailsCrad.less';

const ProductDetailsCard = (
  {
    skeleton,
    isMobile,
    header,
    headerClassName,
    headerToggle = null,
    bodyClassName,
    wrapperClassName,
    isLocked,
    onOpenUpgradePlan,
    ...props
  }) => {

  const { t } = useTranslation();

  const [view, setView] = headerToggle?.handler || [];

  return (
    <div className={cls('product-details-card', wrapperClassName)}>
      <div className={cls('product-details-card-header', headerClassName)}>
        {header}
        {
          headerToggle ?
            <div className={'product-details-card-header-toggle'}
                 onClick={() => setView(headerToggle?.options?.[headerToggle?.options?.[0]?.key === view ? 1 : 0]?.key)}
            >
                <span className={cls('product-details-card-header-toggle-option', {
                  active: headerToggle?.options?.[0]?.key === view,
                })}>
                  {headerToggle?.options?.[0]?.title}
                </span>
              <span className={cls('product-details-card-header-toggle-option', {
                active: headerToggle?.options?.[1]?.key === view,
              })}>
                  {headerToggle?.options?.[1]?.title}
                </span>
            </div>
            :
            null
        }
      </div>
      <div className={cls('product-details-card-body', bodyClassName)}
      >
        {
          props.children
        }
      </div>
      {
        isLocked
          ? <div className="block-locked">
            <div className="block-locked-info-wrap">
              <Icon role="icon" type="locked_block" />
              <div className="block-locked-info-wrap block-locked-info-wrap_text">
                <span className="block-locked-title">{t('Locked Content')}</span>
                <span className="block-locked-text">
                    <span className={'block-locked-text block-locked-text_clickable'}
                          onClick={() => onOpenUpgradePlan(isLocked)}
                    >
                      {t('Upgrade')}
                    </span>
                  {t('your plan to unlock')}
                  </span>
              </div>
            </div>
          </div>
          : null
      }
    </div>
  );
};

export default ProductDetailsCard;
