import React from 'react';

const WatchTutorial = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path stroke="#A1AAC8"
          d="M3.95866 3.125H16.042M9.58366 11.0417H10.2087M9.58366 11.0417V10.8333M9.58366 11.0417V11.25M8.95866 8.95833V13.125L11.667 11.0417L8.95866 8.95833ZM3.95866 16.875H16.042C16.9625 16.875 17.7087 16.1288 17.7087 15.2083V6.875C17.7087 5.95453 16.9625 5.20833 16.042 5.20833H3.95866C3.03818 5.20833 2.29199 5.95453 2.29199 6.875V15.2083C2.29199 16.1288 3.03818 16.875 3.95866 16.875Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
);

export default WatchTutorial;
