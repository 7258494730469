import React from 'react';
import { useSelector } from 'react-redux';

const EditCardModalDivider = () => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.6654 13.3337L14.4094 10.5896C14.7349 10.2641 14.7349 9.73651 14.4094 9.41107L11.6654 6.66699M5.83203 13.3337L8.57611 10.5896C8.90155 10.2641 8.90155 9.73651 8.57611 9.41107L5.83203 6.66699"
        stroke={theme === 'light' ? "#C5CCE3" : 'rgba(255, 255, 255, 0.18)'}
        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditCardModalDivider;
