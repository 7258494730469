import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import AuthActions from './LoginPage/reducer';
import { Layout, Spin, Statistic } from 'antd';
import Images from '../Images';
import utils from '../Utils/utils';
import cls from 'classname';
import { Link } from 'react-router-dom';
import LoginHeader from '../Components/LoginHeader';
import ButtonComponent from "../Components/Button";
import InputComponent from "../Components/Input";
import {useLocation, useNavigate} from "react-router";

const { Countdown } = Statistic;

const CheckMailboxPage = (props) => {

  const { isMobile, error, fetching, userInfo } = props;

  const inputRef = useRef(null);
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();

  const [localState, setLocalState] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [isBackError, setIsBackError] = useState(false);

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    document.title = 'Confirm Email - Dropship'
    if (userInfo && userInfo?.onboarding_finished) navigate('/dashboard');
    if (state) {
      setLocalState(state);
      setNewEmail(state?.email);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        if (error.length > 100) {
          setErrorText('Server response error');
        } else {
          setErrorText(error);
        }
      } else {
        setErrorText(Object.values(error)[0]);
      }
      setIsBackError(true);
      props.resetError();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  useEffect(() => {
    if (isChange) {
      document.addEventListener('click', handleClickOutside, false);
    }
    return function() {
      document.removeEventListener('click', handleClickOutside, false);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isChange]);

  const clearError = () => {
    if (errorText) setErrorText(null);
    if (isBackError) setIsBackError(false);
  };

  const handleClickOutside = (e) =>{
    let path = e?.composedPath() || [];
    const block = document.getElementsByClassName('input-with-btn')[0];
    if (!path.includes(block)) {
      const btn = document.getElementById('input-btn');
      if (!path.includes(btn)) {
        setErrorText(null);
        setIsBackError(false);
        setIsChange(false);
        setNewEmail(localState?.email);
      }
    }
  };

  function handleChange(e) {
    const { value } = e.target;
    if(errorText) clearError();
    setNewEmail(value);
  }

  const handleClickChangeEmail = () => {
    if (isChange) {
      if (newEmail !== localState?.email) {
        if (utils.validateEmail(newEmail)) {
          props.changeEmail({ email: localState?.email, new_email: newEmail });
          setLocalState({ ...localState, email: newEmail });
          setIsChange(false);
        } else {
          setErrorText('The email you entered is incorrect');
        }
      }
    } else {
      setIsChange(true);
      setTimeout(() => {
        inputRef.current.focus({
          cursor: 'end',
        });
      }, 0);

    }
  };

  function handleResendEmail() {
    if (state) {
      setDeadline(Date.now() + 1000 * 30);
      setResendFetching(true);
      props.checkEmail(localState);
    }
  }

  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">
          <LoginHeader />

          <div className="login-content verify">

            <div className="content-title">
              <div>Verify your email</div>
              <h2>An email has been sent{localState?.first_name ? `, ${localState?.first_name}` : ''}!</h2>
            </div>

            <div className={cls('error-text', { 'error-text_empty': !errorText })}>
              {
                isBackError
                  ? (<>{errorText + ` Kindly try another one or `} <Link to='/login'>Sign In</Link></>)
                  : errorText
              }

            </div>

            <div className={cls('input-with-btn', { 'input-with-btn_error': !!errorText })}>

              <InputComponent ref={inputRef}
                     value={newEmail}
                     onChange={handleChange}
                     disabled={!isChange}
                     onPressEnter={handleClickChangeEmail}
              />
              <ButtonComponent id='input-btn' type="primary" onClick={handleClickChangeEmail}>
                <img src={isChange ? Images.check : Images.edit} alt=""/>
              </ButtonComponent>
            </div>

            <div className="page-content">
              <img className={'img'} src={isMobile ? Images.verifyEmailMobile : Images.verifyEmail} alt=""/>

              <div className="verify-btn-block">

                <ButtonComponent className="btn-primary" type="primary white"
                        href={`http://mail.${utils.checkMailDomain(localState?.email).link}.com`} target="_blank" rel="noopener noreferrer">
                  <img className="btn-icon icon-space" src={Images[utils.checkMailDomain(localState?.email).type]} alt=""/>
                  Go To {` ${utils.checkMailDomain(localState?.email).name}`}
                </ButtonComponent>

                <ButtonComponent type="primary" className="btn-primary" style={{ width: '100%', marginLeft: 16 }}
                        onClick={resendFetching ? () => {
                        } : handleResendEmail}
                        disabled={resendFetching}
                >
                  {
                    resendFetching
                      ? <div className='resend-timer in-btn'>
                        <Countdown title=""
                                   value={deadline}
                                   onFinish={onFinish}
                                   format='ss'
                                   prefix={<span>Resend In</span>}
                                   suffix={<span>{` `}Sec...</span>}
                        />
                        <Spin />
                      </div>
                      :
                      'Resend Email'
                  }
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.nav.isMobile,
    error: state.auth.error?.message,
    fetching: state.auth.fetching,
    userInfo: state.auth.userInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (data) => dispatch(AuthActions.checkEmailRequest(data)),
  changeEmail: (data) => dispatch(AuthActions.changeEmailRequest(data)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckMailboxPage);
