import React from 'react';
import Modal from '../../Components/Modal';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const SelectLanguageModal = (
  {
    isMobile,
    setModal,
    visible,
    optionsLanguages,
    changeLang,
    userInfo
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={t('Language')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal language-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Change language depending from your needs.')
            }
          </p>
          <div className={'language-dropdown'}>
            {
              optionsLanguages.map((option) => (
                <div key={option?.value}
                     className={'language-dropdown-option'}
                     onClick={() => {
                       setModal(null);
                       changeLang(option.value);
                     }}
                >
                  {option?.label}
                  {
                    option?.value === userInfo?.language ?
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           viewBox="0 0 16 16"
                           fill="none"
                           className={'checkmark'}
                      >
                        <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                              stroke="#225AEA"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                      </svg>
                      :
                      null
                  }
                </div>
              ))
            }
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default SelectLanguageModal;
