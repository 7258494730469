import React from 'react';
import utils from '../../../Utils/utils';

const SupplierCardSkeleton = () => {

  return (
    <div className="suppliers-page-card-wrapper">
      <div className="suppliers-page-card-bio">
        <div className="suppliers-page-card-image">
          <span className={'link skeleton'} style={{ width: '100%', height: '100%', borderRadius: 50 }} />
        </div>
        <div className="suppliers-page-card-name-wrapper"
        >
          <h3 className="suppliers-page-card-name">
            <span className={'link skeleton'} style={{ width: utils.randomNum(50, 80), height: 20 }} />
          </h3>
          <p className="suppliers-page-card-job">
            <span className={'link skeleton'} style={{ width: utils.randomNum(200, 240), height: 20 }} />
          </p>
        </div>
      </div>
      <p className="suppliers-page-card-description">
        <span className={'link skeleton'} style={{ width: '100%', height: utils.randomNum(60, 90) }} />
      </p>
      <div className="suppliers-page-card-button" style={{padding: 0}}>
        <span className={'link skeleton'} style={{ width: '100%', height: '100%' }} />
      </div>
      <div className="suppliers-page-card-info-wrapper">
        {
          [1,2,3,4,5].map(el => (
            <div className="suppliers-page-card-info-line"
                 key={el}
            >
              <div className="suppliers-page-card-info">
                <p className="suppliers-page-card-info-title">
                  <span className={'link skeleton'} style={{ width: utils.randomNum(110, 130), height: 20 }} />
                </p>
                <p className="suppliers-page-card-info-value">
                  <span className={'link skeleton'} style={{ width: utils.randomNum(40, 60), height: 20 }} />
                </p>
              </div>
              <div className="suppliers-page-card-info">
                <p className="suppliers-page-card-info-title">
                  <span className={'link skeleton'} style={{ width: utils.randomNum(90, 130), height: 20 }} />
                </p>
                <p className="suppliers-page-card-info-value">
                  <span className={'link skeleton'} style={{ width: utils.randomNum(40, 70), height: 20 }} />
                </p>
              </div>
            </div>
          ))
        }
      </div>
      <div className="suppliers-page-card-country-wrapper">
        <div className="suppliers-page-card-info-title">
          <span>
            <span className={'link skeleton'} style={{ width: 70, height: 20 }} />
          </span>
          <div />
        </div>
        <div className="suppliers-page-card-country-info-wrapper">
          {
            [1, 2].map(el => (
              <div className="suppliers-page-card-country-info"
                   key={el}
              >
                <div className="suppliers-page-card-country-image">
                  <span className={'link skeleton'} style={{ width: '100%', height: '100%' }} />
                </div>
                <div className="suppliers-page-card-country">
                <span>
                  <span className={'link skeleton'} style={{ width: utils.randomNum(50, 120), height: 20 }} />
                </span>
                </div>
                <p className="suppliers-page-card-city">
                  <span className={'link skeleton'} style={{ width: utils.randomNum(40, 70), height: 20 }} />
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default SupplierCardSkeleton;
