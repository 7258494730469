import React from 'react';

const DatabaseProductImages = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M3.33333 11.8421L5.70407 10.0684C6.38447 9.61485 7.29346 9.72188 7.84989 10.3211C9.09359 11.6605 10.5355 12.8782 12.5 12.8782C14.3106 12.8782 15.5104 12.2071 16.6667 11.0508M4.79167 16.875H15.2083C16.1288 16.875 16.875 16.1288 16.875 15.2083V4.79167C16.875 3.87119 16.1288 3.125 15.2083 3.125H4.79167C3.87119 3.125 3.125 3.87119 3.125 4.79167V15.2083C3.125 16.1288 3.87119 16.875 4.79167 16.875ZM14.375 7.29167C14.375 8.21214 13.6288 8.95833 12.7083 8.95833C11.7879 8.95833 11.0417 8.21214 11.0417 7.29167C11.0417 6.37119 11.7879 5.625 12.7083 5.625C13.6288 5.625 14.375 6.37119 14.375 7.29167Z"
      stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
export default DatabaseProductImages;
