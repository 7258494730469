import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';

const ProductViewComponent = (
  {
    view,
    setView,
    wide= false
  }) => {

  const {t} = useTranslation();

  return (
    <div className={cls("product-view", {
      wide: wide
    })}
         onClick={() => setView(view === 'details' ? 'overview' : 'details')}
    >
      <div className={cls('product-view-btn', {
        'product-view-btn--active': view === 'details',
      })}
      >
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path d="M2.29102 9.99984H17.7077M2.29102 4.7915H17.7077M2.29102 15.2082H17.7077"
                stroke="#151E3A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </svg>
        {
          wide ?
            t('List')
            :
            null
        }
      </div>
      <div className={cls('product-view-btn', {
        'product-view-btn--active': view === 'overview',
      })}
      >
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path d="M3.125 5.79167C3.125 4.85825 3.125 4.39154 3.30666 4.03502C3.46644 3.72141 3.72141 3.46644 4.03502 3.30666C4.39154 3.125 4.85825 3.125 5.79167 3.125H8.54167V8.54167H3.125V5.79167Z"
                stroke="#434C69"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
          <path d="M11.4583 3.125H14.2083C15.1418 3.125 15.6085 3.125 15.965 3.30666C16.2786 3.46644 16.5336 3.72141 16.6933 4.03502C16.875 4.39154 16.875 4.85825 16.875 5.79167V8.54167H11.4583V3.125Z"
                stroke="#434C69"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
          <path d="M3.125 11.4583H8.54167V16.875H5.79167C4.85825 16.875 4.39154 16.875 4.03502 16.6933C3.72141 16.5336 3.46644 16.2786 3.30666 15.965C3.125 15.6085 3.125 15.1418 3.125 14.2083V11.4583Z"
                stroke="#434C69"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
          <path d="M11.4583 11.4583H16.875V14.2083C16.875 15.1418 16.875 15.6085 16.6933 15.965C16.5336 16.2786 16.2786 16.5336 15.965 16.6933C15.6085 16.875 15.1418 16.875 14.2083 16.875H11.4583V11.4583Z"
                stroke="#434C69"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </svg>
        {
          wide ?
            t('Grid')
            :
            null
        }
      </div>
    </div>
  );
};

export default ProductViewComponent;
