import React from 'react';

const SuppliersDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
      <g filter="url(#filter0_dd_1785_14332)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.9646 17.4997C21.9764 17.4999 21.9882 17.5 22 17.5C23.1046 17.5 24 16.6046 24 15.5V8.5C24 6.29086 22.2091 4.5 20 4.5H14C12.5194 4.5 11.2267 5.3044 10.5351 6.5H8.60555C7.26814 6.5 6.01921 7.1684 5.27735 8.2812L4.6718 9.18953C4.23375 9.8466 4 10.6186 4 11.4083V14.7857C4 16.0462 4.85922 17.106 6.02393 17.4114C6.22757 19.1505 7.70619 20.5 9.5 20.5C11.2632 20.5 12.7219 19.1961 12.9646 17.5H15.0354C15.2781 19.1961 16.7368 20.5 18.5 20.5C20.2633 20.5 21.7221 19.196 21.9646 17.4997ZM10 8.5L8.60555 8.5C7.93684 8.5 7.31238 8.8342 6.94145 9.3906L6.3359 10.2989C6.11688 10.6275 6 11.0135 6 11.4083V14.7857C6 15.0586 6.15308 15.2958 6.37807 15.4161C6.95611 14.279 8.13708 13.5 9.5 13.5C9.66976 13.5 9.8367 13.5121 10 13.5354V8.5ZM18.5 15.5C17.6716 15.5 17 16.1716 17 17C17 17.8284 17.6716 18.5 18.5 18.5C19.3284 18.5 20 17.8284 20 17C20 16.1716 19.3284 15.5 18.5 15.5ZM8 17C8 16.1716 8.67157 15.5 9.5 15.5C10.3284 15.5 11 16.1716 11 17C11 17.8284 10.3284 18.5 9.5 18.5C8.67157 18.5 8 17.8284 8 17Z"
              fill="#F9F9FC" />
      </g>
      <defs>
        <filter id="filter0_dd_1785_14332" x="-2" y="-2.5" width="34" height="35" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1785_14332" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1785_14332" result="effect2_dropShadow_1785_14332" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1785_14332" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default SuppliersDashboard;
