import React from 'react';
import { useSelector } from 'react-redux';

const EditCardModalEmpty = () => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="7.5"
              stroke={theme === 'light' ? "#E2E6F3" : "rgba(255, 255, 255, 0.12)"}
      />
    </svg>
  );
};

export default EditCardModalEmpty;
