import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import cls from "classname";
import {Modal, Spin, Table} from "antd";
import SalesStoreSkeleton from "./components/SalesStoreSkeleton";
import {getColumns} from "./components/SalesStoreTableColumns";
import BannerBlock from "./components/BannerBlock";
import Creators from "./reducer";
import {useTranslation} from "react-i18next";
import Icon from "../../Icon";
import ReactPlayer from "react-player";
import SearchComponent from "../../Components/Search";
import _ from 'lodash'
import ButtonComponent from "../../Components/Button";

const LocalTable = (props) => <Table {...props}/>

const MemoTable = React.memo(LocalTable, (prev, next) =>
  _.isEqual(prev?.dataSource, next?.dataSource) && prev?.loading === next?.loading && prev?.sortOrder === next?.sortOrder);

const SalesStorePage = (props) => {
  const {
    skeleton,
    isMobile,
    theme,
    fetching,
    setRecordToDelete,
    setVisible,
    setModal,
    getStores,
    getStoresNext,
    setView,
    visibleVideo,
    setVisibleVideo,
    spinner,
    isTrial,
    storesNewTab,
    searchFetching,
    resetSearchResults
  } = props;

  const tableRef = useRef(null);
  const { t } = useTranslation();
  //tracked stores
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const isAvailable = useSelector(state => state.salesTracker.isAvailable);
  const stores = useSelector(state => state.salesTracker.stores) || {results: [], next: null};
  const { results, next, searchResults} = stores;

  const [searchValue, setSearchValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [newTab, setNewTab] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  const handleSort = useCallback(({order, columnKey}) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, [])

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll*0.95) && !fetching && !!next) {
      getStoresNext(next);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleSearch = e => {
    setSearchValue(e.target.value);
    if (!e.target.value.length) {
      setShowSearch(false)
      resetSearchResults()
    }
  }

  const searchSubmit = () => {
    getStores({page: 1, page_size: 50, search: searchValue.trim()})
    setSearchValue(prev => prev.trim());
    setShowSearch(true)
  }

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[next, fetching, spinner]);

  useEffect(() => {
    setView('stores');
    if (firstLoading) {
      setNewTab(false);
      storesNewTab.current = false;
      dispatch(Creators.resetIsAvailable());
      setFirstLoading(false)
    }
    getStores({page: 1,page_size: 50});
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (!isAvailable && typeof isAvailable !== 'object') {
      setSelected(null);
    }
    dispatch(Creators.resetIsAvailable());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isAvailable, selected?.id])

  //check if there are no products, to display default banner
  if (!results?.length && !fetching)
    return (
      <Spin spinning={spinner}>
        <BannerBlock skeleton={skeleton}
                     isMobile={isMobile}
                     theme={theme}
                     setVisible={setVisibleVideo}
                     searchPlaceholder={'Search Tracked Stores...'}
        />
        <Modal
          className="change-modal custom-modal video-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visibleVideo}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
          }
          footer={null}
          closable="true"
          onCancel={() => setVisibleVideo(false)}
          destroyOnClose
        >
          <ReactPlayer controls={true}
                       width={'100%'}
                       height={'100%'}
                       playing={true}
                       url={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
          />
        </Modal>
      </Spin>
    );

  return (
    <div className={'sales-tracker-data-wrapper'}>
      {
        !isMobile ?
        skeleton || (fetching && !newTab) ?
        <div style={{ width: '100%' }}>
          <span className="link skeleton" style={{ width: '100%', height: 40, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}/>
        </div>
        :
        <SearchComponent handleSearch={handleSearch}
                         onKeyUp={null}
                         value={searchValue}
                         onFocusCallback={null}
                         placeholder={t('Search Tracked Stores...')}
                         button={<ButtonComponent onClick={searchSubmit}
                                                  className={'sales-tracker-search'}
                                                  text={t('Search')}
                                                  disabled={fetching || spinner || searchFetching || !searchValue.length}
                         />}
        />
          :
          null
      }
      {skeleton || (fetching && !newTab) ? <SalesStoreSkeleton isMobile={isMobile} /> : null}
      { !searchResults?.length && !searchFetching && searchValue && showSearch ?
        <div className={cls('product-database-table-wrapper', {
          'product-database-table-wrapper-empty': !searchResults?.length,
        })}
             style={{marginTop: 4, paddingTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}
        >
          <div className="empty-state empty-state-product-database">
            <div className="empty-state-product-database-title">
              {t('No Stores Found')}
            </div>
            <div className="empty-state-product-database-subtitle">
              {t('kindly_change_search')}
            </div>
            <div className="empty-state_icon">
              <Icon width={96} height={96}
                    role="icon"
                    type="empty_warning"
                    fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
              />
            </div>
          </div>
        </div>
        :
        <div className={cls('products-database-table-wrapper sales-tracker-stores-table-wrapper', {
        'product-database-table-wrapper-hidden': (fetching && !newTab),
      })}>

        <div id={'product-database-table-body'} className="product-database-table-body sales-tracker-stores-table-body">
          <MemoTable
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({className, children, ...other}) => (
                <table
                  {...other}
                  ref={tableRef}
                  className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            className="list-table"
            loading={spinner || searchFetching}
            rowClassName={'product-database-table-row'}
            rowKey={(record) => record.id}
            dataSource={showSearch && searchValue && searchResults.length ?
              searchResults?.length ?
                [...searchResults]
                :
                []
              :
              results?.length ?
                [...results]
                :
                []
            }
            sortOrder={sortOrder}
            columns={getColumns({
              sortOrder,
              setRecordToDelete,
              setVisible,
              setModal,
              t,
              isTrial,
              isMobile
            })}
            pagination={false}
            locale={{emptyText: '-'}}
            scroll={{
              x: 1600, //'max-content',
              y: 'calc(100dvh - 96px - 120px)',
            }}
            sticky={{
              offsetHeader: 0,
              offsetScroll: 0,
              getContainer: () => document.querySelector('.product-database-table-body')
            }}
            sortDirections={['descend', 'ascend', null]}
            onChange={(pagination, filters, sorter) => {
              handleSort(sorter);
            }}
          />
        </div>
      </div>}
    </div>
  );
};

export default SalesStorePage;
