import React from 'react';
import NotificationClose from '../../Icon/img/NotificationClose';
import { Warning } from '../../Icon/img';
import { useSelector } from 'react-redux';

const ConfirmEmail = ({ setVisible }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <div className={'confirm-email-banner'}>
      <span>
        {
          theme === 'light' ?
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="21"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
            >
              <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.53007 2.7142C9.41836 1.22027 11.5813 1.22026 12.4696 2.7142L18.7063 13.2031C19.6146 14.7307 18.5137 16.666 16.7365 16.666H4.2632C2.48597 16.666 1.38513 14.7307 2.29343 13.2031L8.53007 2.7142ZM10.4999 6.66597C10.8451 6.66597 11.1249 6.9458 11.1249 7.29097V10.6243C11.1249 10.9695 10.8451 11.2493 10.4999 11.2493C10.1547 11.2493 9.87492 10.9695 9.87492 10.6243V7.29097C9.87492 6.9458 10.1547 6.66597 10.4999 6.66597ZM11.3333 12.916C11.3333 13.3762 10.9602 13.7493 10.4999 13.7493C10.0397 13.7493 9.66659 13.3762 9.66659 12.916C9.66659 12.4557 10.0397 12.0826 10.4999 12.0826C10.9602 12.0826 11.3333 12.4557 11.3333 12.916Z"
                    fill="#F6B60E"
              />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="21"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
            >
              <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.53032 2.7142C9.4186 1.22027 11.5816 1.22026 12.4699 2.7142L18.7065 13.2031C19.6148 14.7307 18.514 16.666 16.7367 16.666H4.26344C2.48622 16.666 1.38537 14.7307 2.29367 13.2031L8.53032 2.7142ZM10.5002 6.66597C10.8453 6.66597 11.1252 6.9458 11.1252 7.29097V10.6243C11.1252 10.9695 10.8453 11.2493 10.5002 11.2493C10.155 11.2493 9.87516 10.9695 9.87516 10.6243V7.29097C9.87516 6.9458 10.155 6.66597 10.5002 6.66597ZM11.3335 12.916C11.3335 13.3762 10.9604 13.7493 10.5002 13.7493C10.0399 13.7493 9.66683 13.3762 9.66683 12.916C9.66683 12.4557 10.0399 12.0826 10.5002 12.0826C10.9604 12.0826 11.3335 12.4557 11.3335 12.916Z"
                    fill="#F6B60E"
              />
            </svg>
        }
        <a href="/setting/account">Verify your e-mail </a> to secure your account.
      </span>
      <span onClick={() => setVisible(false)}>
        <NotificationClose />
      </span>
    </div>
  );
};

export default ConfirmEmail;
