import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import utils from '../../../Utils/utils';
import acc from 'accounting';
import SaveToCollection from '../../../Components/SaveToCollections/save-to-collections';
import CollectionsActions from '../../CollectionsPage/reducer';
import { useDispatch } from 'react-redux';
import './MoreProductsBlock.less';

const MoreProductsBlock = (
  {
    skeleton,
    isMobile,
    product,
    breadCrumbs,
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productsList = product?.more_products ? [...product?.more_products] : [];

  const path = breadCrumbs?.filter((el) => !!el.link)[0]?.link || '/dashboard';

  function addToCollectionFunc({ id, isSave, name }) {
    if (!isSave) {
      dispatch(
        CollectionsActions.addToCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    } else {
      dispatch(
        CollectionsActions.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  const renderCardSkeleton = (el) => (
    <div className="product"
         key={el}
    >
      <span className={'link skeleton product-image'}
            style={{ width: isMobile ? '100%' : 264, height: 264, borderRadius: '6px 6px 0 0' }}
      />
      <div className="product_wrap"
           id="product_wrap"
      >
        <span className={'link skeleton'}
              style={{ width: '100%', height: 20 }}
        />
      </div>
      <div className="product-detail_price">
        <span className={'link skeleton'}
              style={{ width: 51, height: 24 }}
        />
      </div>
      <div className="product_details">
        {
          [1,2,3,4].map(el => (
            <div className="product-detail"
                 key={el*22}
            >
              <div className="product-detail_title">
            <span className={'link skeleton'}
                  style={{ width: utils.randomNum(50, 80), height: 20 }}
            />
              </div>
              <div className="product-detail_value">
            <span className={'link skeleton'}
                  style={{ width: utils.randomNum(50, 80), height: 20 }}
            />
              </div>
            </div>
          ))
        }
      </div>
      <div className={'product-btn-wrapper'}>
        <span className={'link skeleton'}
              style={{ width: 32, height: 32 }}
        />
        <span className={'link skeleton'}
              style={{ width: 'calc(100% - 40px)', height: 32 }}
        />
      </div>
    </div>
  )

  const renderCard = (data) => (
    <div className="product"
         key={`product-${data?.product_id}`}
    >
      <Link to={`${path}/product/${data?.product_id}`}
            className="product_image"
            state={data}
      >
        <img src={data?.image}
             alt="images"
             onError={utils.addDefaultSrc}
        />
      </Link>
      <div className="product_wrap"
           id="product_wrap"
      >
        <Tooltip overlayClassName="product_name-tooltip details-tooltip"
                 title={data?.title}
                 trigger={isMobile ? 'click' : 'hover'}
                 destroyTooltipOnHide={true}
                 getPopupContainer={() =>
                   document.getElementById('product_wrap')
                 }
        >
          <Link to={`${path}/product/${data?.product_id}`}
                className="product_name"
          >
            {data?.title}
          </Link>
        </Tooltip>
      </div>
      <div className="product-detail_price">
        ${acc.formatNumber(data?.price || 0, 2, ' ', ',')}
      </div>
      <div className="product_details">
        <div className="product-detail">
          <div className="product-detail_title">
            {t('Cost')}
          </div>
          <div className="product-detail_value">
            {data?.cost ? data?.cost?.replaceAll('.', ',') : 0}
          </div>
        </div>
        <div className="product-detail">
          <div className="product-detail_title">
            {t('P/C Ratio')}
          </div>
          <div className="product-detail_value">
            {acc.formatNumber(data?.p_c_ratio || 0, 2, ' ', '.')}X
          </div>
        </div>
        <div className="product-detail">
          <div className="product-detail_title">
            {t('BECPA')}
          </div>
          <div className="product-detail_value">
            ${acc.formatNumber(data?.BECPA || 0, 2, ' ', ',')}
          </div>
        </div>
        <div className="product-detail">
          <div className="product-detail_title">
            {t('POT Profit')}
          </div>
          <div className="product-detail_value">
            ≈${acc.formatNumber(data?.pot_profit || 0, 0, ',', ',')}
          </div>
        </div>
      </div>
      <div className={'product-btn-wrapper'}>
        <div className={'product_view-details'}>
          <SaveToCollection saveFunc={(isSaved) =>
            addToCollectionFunc({
              id: data?.product_id,
              isSave: isSaved,
              name: data?.title,
            })
          }
                            isSave={data?.saved}
          />
        </div>
        <Link to={`${path}/product/${data.product_id}`}
              state={{ ...data }}
              className="product_view-details wide"
        >
          {t('Product insights')}
        </Link>
      </div>
    </div>
  );

  if (skeleton) return (
    <div className="more-products-block">
      <div className="more-products-block-header">
        <span className="more-products-block-header-title">
          <span className={'link skeleton'}
                style={{ width: 260, height: 28 }}
          />
        </span>
      </div>
      <div className="more-products-block-body">
        {
          (isMobile ? [1] : [1, 2, 3, 4]).map((el) => renderCardSkeleton(el))
        }
      </div>
    </div>
  );

  return (
    <div className="more-products-block">
      <div className="more-products-block-header">
          <span className="more-products-block-header-title">
            {t('More Products From This Drop')}
          </span>
      </div>
      <div className="more-products-block-body">
        {
          productsList.map((el, index) => renderCard(el, index))
        }
      </div>
    </div>
  );
};

export default MoreProductsBlock;
