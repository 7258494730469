import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import SaveToCollection from '../SaveToCollections/save-to-collections';
import Icon from '../../Icon';
import { titleSortFilters } from '../titleSortFilters';
import utils, { checkSaturationIndex } from '../../Utils/utils';

const ScoreStats = ({ score }) => {
  return (
    <div className={'score-counter-stats'}>
      {
        Array.from({length: 10}, (value, index) => index).map(el => (
          <span className={cls('score-counter-stat', {
            'score-counter-stat--active': score !== 0 && el <= score - 1
          })}
                key={el}
          />
        ))
      }
    </div>
  )
}

export const columnsDesktopFull = (
  {
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    t,
    onOpenUpgradePlan,
    isMobile = false,
  }) => {

  return [
    {
      title: () => <span/>,
      dataIndex: 'saved-overview',
      key: 'saved-overview',
      width: isAdminEdit ? 100: 70,
      fixed: 'left',
      sorter: false,
      render: (_, record) => (
        <span className={cls('saved-overview product-database-table-cell', {disabled: record['is_locked']})}>
        {
          isAdmin
            ? null
            : (
              <SaveToCollection
                saveFunc={(isSave) =>
                  addToCollectionFunc(record.product_id, isSave, record.title)
                }
                isDisabled={record['is_locked']}
                isSave={record.saved && !record['is_locked']}
              />)
        }
      </span>
      ),
    },
    {
      title: t('Product'),
      dataIndex: 'title',
      key: 'title',
      width: 320,
      fixed: isMobile ? false : 'left',
      sorter: false,
      render: (_, record) => (
        <span className="title-column product-database-table-cell">
        <div className={cls('title-column-img-wrap', {'title-column-img-wrap_locked': record['is_locked']})}
        >
          {
            record['is_locked']
              ? <Icon role="icon" type="locked_block" width={40} height={40}/>
              : <img
                src={record?.image?.url}
                alt="images"
                width={48}
                onError={utils.addDefaultSrc}
              />
          }
          {isAdmin ? <div className={cls('status', record?.status?.name)}/> : null}
        </div>
        <span className="title-column-text">
          {
            record['is_locked']
              ? (
                <>
                <span className={'block-locked-text block-locked-text_clickable'}
                      onClick={()=> onOpenUpgradePlan(record['is_locked'])}
                >
                  {t('Upgrade')}
                </span>
                  {t('your plan to unlock')}
                </>)
              : record.title
          }
        </span>
      </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Cost'),
          stateData: state,
          key: 'cost',
        }),
      dataIndex: 'cost',
      key: 'cost',
      width: 120,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => <span className={'product-database-table-cell'}>{record.cost ? record?.cost?.replaceAll('.',',') : 0}</span>,
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Price'),
          stateData: state,
          key: 'price',
        }),
      dataIndex: 'price',
      key: 'price',
      width: 120,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className={'product-database-table-cell'}>${acc.formatNumber(record.price, 2, ' ', ',')}</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('P/C Ratio'),
          stateData: state,
          key: 'p_c_ratio',
        }),
      dataIndex: 'p_c_ratio',
      key: 'p_c_ratio',
      width: 140,
      sorter: true,
      showSorterTooltip: {
        title: t('The price to cost ratio is calculated by dividing the product cost with the selling price.'),
        overlayClassName: 'details-tooltip p_c_ratio',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className={'product-database-table-cell'}>{acc.formatNumber(record.p_c_ratio, 2, ' ', '.')}Х</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Profit Margin'),
          stateData: state,
          key: 'profit_margin',
        }),
      dataIndex: 'profit_margin',
      key: 'profit_margin',
      width: 140,
      sorter: true,
      showSorterTooltip: {
        title: t('This is the profit margin you can expect after taking into account all the numbers in the “Numbers Breakdown”.'),
        overlayClassName: 'details-tooltip p_c_ratio',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className={'product-database-table-cell'}>{acc.formatNumber(record.profit_margin, 2, ' ', '.')}%</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Potential Profit'),
          stateData: state,
          key: 'pot_profit',
        }),
      dataIndex: 'pot_profit',
      key: 'pot_profit',
      width: 140,
      sorter: true,
      showSorterTooltip: {
        title: t('The potential profit is calculated by subtracting BECPA with the CPA of the product, and the difference is multiplied by 25,000 to give you an idea of the potential profit if you sold that many units.'),
        overlayClassName: 'details-tooltip pot_profit',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => <span className={'product-database-table-cell'}>≈${acc.formatNumber(record.pot_profit)}</span>,
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('BECPA'),
          stateData: state,
          key: 'BECPA',
        }),
      dataIndex: 'BECPA',
      key: 'BECPA',
      width: 120,
      sorter: true,
      showSorterTooltip: {
        title: t('The breakeven cost per acquisition (BECPA) is the maximum CPA you should aim to get to breakeven on the product.'),
        overlayClassName: 'details-tooltip BECPA',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className={'product-database-table-cell'}>${acc.formatNumber(record.BECPA, 2, ' ', ',')}</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('BEROAS'),
          stateData: state,
          key: 'BEROAS',
        }),
      dataIndex: 'BEROAS',
      key: 'BEROAS',
      width: 120,
      sorter: true,
      showSorterTooltip: {
        title: t('The breakeven return on ad spend (BEROAS) is the minimum ROAS you should aim to get to breakeven on the product.'),
        overlayClassName: 'details-tooltip BEROAS',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className={'product-database-table-cell'}>{acc.formatNumber(record.BEROAS, 2, ' ', '.')}</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Competitors'),
          stateData: state,
          key: 'competitors',
        }),
      dataIndex: 'competitors',
      key: 'competitors',
      width: 140,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => <span className={'product-database-table-cell'}>{record.competitors}</span>,
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Saturation'),
          stateData: state,
          key: 'saturation',
        }),
      dataIndex: 'saturation',
      key: 'saturation',
      width: 120,
      sorter: true,
      showSorterTooltip: {
        title: t('The saturation metric gives you a visual look at the saturation of the product and is calculated based on the amount of competitors found for the product.'),
        overlayClassName: 'details-tooltip saturation',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className={'product-database-table-cell'}>
        <Icon type="ellipse"
              role="icon"
              width={40}
              height={20}
              outline={checkSaturationIndex(record.saturation)}
        />
      </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Score'),
          stateData: state,
          key: 'score',
        }),
      dataIndex: 'score',
      key: 'score',
      width: 140,
      sorter: true,
      showSorterTooltip: {
        title: t('The product score is calculated through mathematical functions setup to rate each product based on multiple factors such as the numbers breakdown, saturation, benefits, drawbacks, etc. Rated from 0 being the worst to 10 being the best.'),
        overlayClassName: 'details-tooltip score',
        getPopupContainer: () => document.getElementById('global-wrap'),
      },
      render: (_, record) => (
        <span className="score-counter product-database-table-cell">
        <span className="score-counter-num">{record.score || 0}</span>
        <ScoreStats score={record?.score || 0} />
      </span>
      ),
    },
    {
      title: () => <span/>,
      dataIndex: '',
      key: 'go_in',
      width: 70,
      fixed: isMobile ? false : 'right',
      sorter: false,
      render: (_, record) => (
        <span className={cls('product-database-table-cell', {disabled: record['is_locked']})}>
        <Icon type={'arrow_table_in'} role={'icon'} />
      </span>
      ),
    },
  ];
}

export const changeColumns = () => {
  return columnsDesktopFull;
};
