import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OnboardingActions from '../../Redux/OnboardingRedux';
import ChargebeeActions from '../PlanCardUpcomingNew/reducer';
import SettingCreators from '../../Containers/SettingPage/reducer';
import API from '../../Services/Api';
import acc from 'accounting';
import cls from 'classname';
import { openNotificationWithIcon } from '../Notification';
import Icon from '../../Icon';
import DiscountCard from '../DiscountCard';
import { Divider, Dropdown, notification, Slider, Spin } from 'antd';
import ButtonComponent from '../Button';
import Modal from '../Modal';
import { DefaultMsg } from '../Notification/notification-message';
import InputComponent from '../Input';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';

const style = {
  light: {
    base: {
      color: '#151E3A',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: '-0.3px',

      ':focus': {
        color: '#151E3A',
      },

      '::placeholder': {
        color: '#A1AAC8',
      },

      ':focus::placeholder': {
        color: '#A1AAC8',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
  dark: {
    base: {
      color: '#f2f3f8',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: '-0.3px',

      ':focus': {
        color: '#f2f3f8',
      },

      '::placeholder': {
        color: 'rgba(255, 255, 255, 0.26)',
      },

      ':focus::placeholder': {
        color: 'rgba(255, 255, 255, 0.26)',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
};

const options = {
  // Custom classes - applied on container elements based on field's state
  classes: {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  },
  // locale
  locale: 'en',
  // Custom placeholders
  placeholder: {
    number: 'Enter card holder name...',
    expiry: 'MM/YY',
    cvv: 'CVC',
  },
  // Custom fonts
  fonts: [
    'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap',
  ],
};

const PlanInfo = (
  {
    planName,
    t,
    price,
    priceType,
    recalculate=false,
    now=false,
    discount=null
  }) => {

  const diffToPay = useSelector(state => state?.chargebee?.result?.amount_due) || 0;

  return (
    <div className="switch-plan-wrapper">
      <div className="switch-plan-title">
        {planName}
      </div>
      <Divider style={{margin: '0'}}
               type={'horizontal'}
      />
      <div className="switch-plan-info-wrapper">
        <div className="switch-plan-info">
          <div className="title">
            {t('Billing')}
          </div>
          <div className="value">
            {priceType}
          </div>
        </div>
        <div className="switch-plan-info">
          <div className="title">
            {t('Amount')}
          </div>
          <div className="value">
            ${acc.formatNumber(recalculate ? price : price * (priceType === 'monthly' ? 1 : 12), 2, ',', '.')}
          </div>
        </div>
        <div className="switch-plan-info">
          <div className="title">
            {t('Billed now')}
          </div>
          <div className="value">
            {diffToPay >= 0 && (now || discount) ?
              `$${acc.formatNumber(diffToPay / 100, 2, ',', ',')}`
              :
              '-'
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const LimitChangeModal = (
  {
    setModal,
    visible,
    data,
  },
) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const cardRef = useRef();

  const { storeLimit, productLimit, subscription } = data;

  const {classes, locale, placeholder, fonts} = options;

  const currentPlan = subscription?.plan;
  const userInfo = useSelector(state => state?.auth?.userInfo);
  const isMobile = useSelector(state => state?.nav?.isMobile);
  const theme = useSelector(state => state?.nav?.theme);
  const onBoarding = useSelector(state => state?.onBoarding);
  const fetching = onBoarding?.updateSubscriptionFetching || onBoarding?.chargebeeCardFetching;
  const updateSubscriptionResult = onBoarding?.updateSubscriptionResult
  const premiumPlan = useSelector(state => state?.plan?.plans?.[2]);
  const discountId =  onBoarding?.discountResult?.id || null;
  const spinner = useSelector(state => state.chargebee.loading);
  const defaultUserCountry = useSelector(state => state.setting.userCountry);
  const saveFetching = useSelector(state => state.setting.saveFetching);
  const saveResult = useSelector(state => state.setting.saveResult);
  const errors = useSelector(state => state.setting.errors);

  const hasCard = Boolean(userInfo?.card) || (
    onBoarding?.discountResult?.duration_type === "forever" &&
    onBoarding?.discountResult?.type === "percent" &&
    +onBoarding?.discountResult?.value === 100
  );
  let discountCash = 0;

  if (onBoarding.discountResult) {
    discountCash = onBoarding.discountResult.type === 'value' ? onBoarding.discountResult.value : (premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * 100 / 100) * onBoarding.discountResult.value;
  }

  const optionsCountry = onBoarding?.countries?.length ?
    [...onBoarding?.countries].sort((a, b) => (a['name'] > b['name'] ? 1 : -1)).map((el) => ({ label: el.name, value: el.name, id: el.id, }))
    :
    [];
  const optionsState = onBoarding?.countries?.length ?
    [...[...onBoarding?.countries].filter((country) => country.name === 'United States')?.[0]?.['state']]?.sort((a, b)=> a.localeCompare(b)).map((el) => ({ label: el, value: el, }))
    :
    [];
  const billingInfo = { country: defaultUserCountry};
  const isPreset = !!billingInfo;
  const isCurrentPrem = currentPlan?.plan?.id === 3;

  const checkDiscountCode = code => dispatch(OnboardingActions.checkDiscountCodeRequest(code));
  const checkDiscountCodeReset = () => dispatch(OnboardingActions.checkDiscountCodeReset());
  const getCountries = () => dispatch(OnboardingActions.countriesRequest());
  const changeSubscriptionReset = () => dispatch(OnboardingActions.changeSubscriptionReset());
  const updateSubscription = (plan) => dispatch(OnboardingActions.updateSubscriptionRequest(plan));
  const createIntent = data => dispatch(OnboardingActions.createIntentRequest(data));
  const clearIntent = () => dispatch(OnboardingActions.createIntentReset());
  const setChargebeeCardFetching = (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value));
  const getCharge = (data) => dispatch(ChargebeeActions.getChargeRequest(data));
  const resetErrors = () => dispatch(SettingCreators.settingsErrorsReset());

  const intent={
    result: onBoarding?.intentResult,
    error: onBoarding?.intentError,
    createIntent: createIntent,
    clearIntent: clearIntent,
    amount: onBoarding.discountResult ?
      (premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * 100 - discountCash)
      :
      premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price,
  };

  const [store, setStore] = useState(storeLimit < 50 ? 50 : storeLimit);
  const [product, setProduct] = useState(productLimit < 100 ? 100 : productLimit);
  const [step, setStep] = useState(1);
  const [finalStep, setFinalStep] = useState(1);
  const [paymentType, setPaymentType] = useState('now');
  const [spin, setSpin] = useState(false);

  const[dropdown, setDropdown] = useState({
    country: {
      visible: false,
      search: ''
    },
    state: {
      visible: false,
      search: ''
    },
  });
  const [cardData, setCardData] = useState({
    1: {
      name: '',
      number: null,
      expiry: null,
      cvv: null
    },
    2: {
      street: '',
      city: '',
      code: '',
      country: null,
      state: null
    },
  });

  const [innerVisible, setInnerVisible] = useState(null);
  const [focused, setFocused] = useState(false);
  const [cardError, setCardError] = useState('');
  const [cardIsValid, setCardIsValid] = useState({
    1: false,
    2: false
  });
  const [reload, setReload] = useState(false);
  const [instance, setInstance] = useState(null);

  const submit = () => {
    let data = {
      plan: {2: premiumPlan?.prices?.[currentPlan?.price_type?.id]},
      'discount_code': discountId || null,
      force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
      new_limits: {
        stores_limit: store,
        products_limit: product
      }
    }
    updateSubscription(data)
  }

  const submitWithCard = (value) => {
    let fullData = {
      plan: {2: premiumPlan?.prices?.[currentPlan?.price_type?.id]},
      force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
      'discount_code': discountId || null,
      payment: {
        intent_id: value?.intent_id,
        type: 'card',
      },
      new_limits: {
        stores_limit: store,
        products_limit: product
      }
    }
    updateSubscription(fullData);
  }

  const okHandler  = () => {
    if (step === 2) {
      if (hasCard) {
        submit();
      } else {
        setStep(prev => prev + 1);
      }
    } else {
      setStep(prev => prev + 1);
    }
  }

  const cancelHandler = () => {
    if (step === 1) {
      setModal(null);
    } else if(step === 3) {
      if (onBoarding?.intentResult) intent?.clearIntent();
      setModal(null);
    } else {
      setStep(prev => prev - 1);
    }
  }

  function handleClearDiscount() {
    if (onBoarding.discountResult) checkDiscountCodeReset();
  }

  function handleCheckDiscount(code) {
    checkDiscountCode(code);
  }

  function handleClearError() {
    if (onBoarding.discountError) checkDiscountCodeReset();
  }

  function calculateCost() {
    const storeLimitDiff = (store - 50) * (currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25;
    const productLimitDiff = (product - 100) * (currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50;
    return premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * (currentPlan?.price_type?.id === 'monthly' ? 1 : 12) + storeLimitDiff + productLimitDiff;
  }

  function calculateCurrentCost() {
    const storeLimitDiff = (storeLimit - 50) * (currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25;
    const productLimitDiff = (productLimit - 100) * (currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50;
    return premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * (currentPlan?.price_type?.id === 'monthly' ? 1 : 12) + storeLimitDiff + productLimitDiff;
  }

  const onReady = form => {
    for (let field of form?.fields) {
      if (field?.mountStatus !== 3) return setReload(true);
    }
  };

  const onContinue = (data) => {
    submitWithCard(data);
  };

  const handleChange = (status) => {
    if (status?.complete && status?.error?.message) {
      setCardError(status?.error?.message);
    } else {
      setCardError('');
      setCardData((prev) => ({
        ...prev,
        1: {
          ...prev?.[1],
          [status?.field]: {...status}
        }
      }));
    }
  }

  const content = {
    1: (
      <>
        <div className="sales-tracker-limit-modal-content">
          <Divider type={'horizontal'}
                   style={{ margin: '0 0 24px' }}
          />
          <div className="sales-tracker-limit-modal-sliders-wrapper">
            <div className="sales-tracker-limit-modal-slider-wrapper">
              <p className="sales-tracker-limit-modal-slider-title">
                {t('Store Tracking Limit')}
              </p>
              <div className="sales-tracker-limit-modal-slider">
                <Slider defaultValue={store}
                        min={50}
                        max={500}
                        step={25}
                        tooltip={{ open: false }}
                        onChange={(value) => setStore(value)}
                        marks={{
                          50: '50',
                          100: '100',
                          200: '200',
                          300: '300',
                          400: '400',
                          500: '500',
                        }}
                />
              </div>
            </div>
            <Divider type={'horizontal'}
                     style={{ margin: '24px 0' }}
            />
            <div className="sales-tracker-limit-modal-slider-wrapper">
              <p className="sales-tracker-limit-modal-slider-title">
                {t('Product Tracking Limit')}
              </p>
              <div className="sales-tracker-limit-modal-slider">
                <Slider defaultValue={product}
                        min={100}
                        max={1000}
                        step={50}
                        tooltip={{ open: false }}
                        onChange={(value) => setProduct(value)}
                        marks={{
                          100: '100',
                          200: '200',
                          300: '300',
                          400: '400',
                          500: '500',
                          600: '600',
                          700: '700',
                          800: '800',
                          900: '900',
                          1000: '1000',
                        }}
                />
              </div>
            </div>
          </div>
          <Divider type={'horizontal'}
                   style={{ margin: '24px 0' }}
          />
          <div className="sales-tracker-limit-modal-cost-wrapper">
            <div className="sales-tracker-limit-modal-cost">
              <span className="title">
                <span className="key">
                  {
                    t('Store tracking limit')
                  }
                </span>
                <span className="value">
                  - x{store}
                </span>
              </span>
              <span className="value">
                <span className="key">
                   {
                     `(${store - 50}x$${acc.formatNumber((currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25, 2, ',', '.')})`
                   }
                </span>
                <span className="value">
                  ${acc.formatNumber((store - 50) * (currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25, 2, ',', '.')}
                </span>
              </span>
            </div>
            <div className="sales-tracker-limit-modal-cost">
              <span className="title">
                <span className="key">
                  {
                    t('Product tracking limit')
                  }
                </span>
                <span className="value">
                  - x{product}
                </span>
              </span>
              <span className="value">
                <span className="key">
                   {
                     `(${product - 100}x$${acc.formatNumber((currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50, 2, ',', '.')})`
                   }
                </span>
                <span className="value">
                  ${acc.formatNumber((product - 100) * (currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50, 2, ',', '.')}
                </span>
              </span>
            </div>
            <Divider type={'horizontal'}
                     style={{ margin: '0' }}
            />
            <div className="sales-tracker-limit-modal-cost">
              <span className="title">
                <span className="key">
                  {
                    t('Total updated subscription cost')
                  }
                </span>
              </span>
              <span className="value">
                <span className="value">
                  ${acc.formatNumber(calculateCost(), 2, ',', '.')}
                </span>
                <span className="key">
                  /{currentPlan?.price_type?.id}
                </span>
              </span>
            </div>
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={cancelHandler}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           onClick={okHandler}
                           disabled={isCurrentPrem && (store === storeLimit && product === productLimit)}
                           text={t('Continue')}
          />
        </div>
      </>
    ),
    2: (
      <>
        <div className="sales-tracker-limit-modal-content">
          <div className="final-confirmation-plan">
            <Spin spinning={false}>
              <PlanInfo t={t}
                        planName={currentPlan?.plan?.name}
                        price={currentPlan?.price}
                        priceType={currentPlan?.price_type?.id}
              />
              <span className="final-confirmation-icon">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <path
                    d="M14.5833 17.7085L16.6414 15.6504C16.8855 15.4064 16.8855 15.0106 16.6414 14.7666L14.5833 12.7085M5.41667 2.29183L3.35861 4.34989C3.11453 4.59396 3.11453 4.98969 3.35861 5.23377L5.41667 7.29183M4.375 4.79183H15.2083C16.1288 4.79183 16.875 5.53802 16.875 6.4585V8.9585M3.125 11.4585V13.5418C3.125 14.4623 3.87119 15.2085 4.79167 15.2085H15.625"
                        stroke="#F2F3F8"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                  />
                </svg>
              </span>
              <PlanInfo t={t}
                        planName={premiumPlan?.name}
                        recalculate={true}
                        price={calculateCost()}
                        priceType={currentPlan?.price_type?.id}
                        now={paymentType === 'now'}
                        discount={onBoarding.discountResult}
              />
            </Spin>
          </div>
          <div className="discount-wrapper">
            <DiscountCard key={'discount'}
                          onSubmit={handleCheckDiscount}
                          error={onBoarding.discountError}
                          clearError={handleClearError}
                          discount={onBoarding.discountResult}
                          clearDiscount={handleClearDiscount}
                          fetching={onBoarding.discountFetching}
                          style={{padding: 0, marginTop: 16}}
                          isMobile={isMobile}
                          priceType={currentPlan?.price_type?.id}
                          isSt={true}
            />
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={cancelHandler}
                           text={t('Back')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           onClick={okHandler}
                           disabled={isCurrentPrem && (store === storeLimit && product === productLimit)}
                           text={t(calculateCurrentCost() > calculateCost() ? 'Downgrade Plan' : 'Upgrade Plan')}
          />
        </div>
      </>
    ),
    3: (
      <>
        <div className="sales-tracker-limit-modal-content">
          <div className="final-confirmation-form-wrapper">
            <Spin spinning={false}>
              {
                Boolean(innerVisible) ?
                  null
                  :
                  <div className="edit-card-modal-header">
                <span className={cls('edit-card-modal-header-item', {
                  active: finalStep === 1,
                })}>
                  {
                    finalStep === 1 && !cardIsValid?.[1] ?
                      <Icon role={'icon'} type={'edit_card_modal_progress'} />
                      :
                      <Icon role={'icon'} type={'edit_card_modal_complete'} />
                  }
                  {t('Card information')}
                </span>
                    <Icon role={'icon'} type={'edit_card_modal_divider'} />
                    <span className={cls('edit-card-modal-header-item', {
                      active: finalStep === 2,
                    })}>
              {
                finalStep === 1 ?
                  <Icon role={'icon'} type={'edit_card_modal_empty'} />
                  :
                  !cardIsValid?.[2] ?
                    <Icon role={'icon'} type={'edit_card_modal_progress'} />
                    :
                    <Icon role={'icon'} type={'edit_card_modal_complete'} />
              }
                      {t('Billing details')}
            </span>
                  </div>
              }
              <div className="change-form">
                <div className={cls('change-form-content', {
                  hidden: finalStep === 1 || Boolean(innerVisible),
                })}
                >
                  <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Street and house number')}
                  </span>
                    <InputComponent type="text"
                                    name={'street'}
                                    placeholder={t('e.g. Sand Hill Road 3')}
                                    value={cardData?.[2]?.street}
                                    onChange={(e) => {
                                      setCardData((prev) => ({
                                        ...prev,
                                        2: {
                                          ...prev?.[2],
                                          street: e?.target?.value,
                                        },
                                      }));
                                    }}
                    />
                  </div>
                  <div className={'change-form-inner'}>
                  <div className={'input-wrapper'}>
                  <span className="title">
                    {t('City or Town')}
                  </span>
                      <InputComponent type="text"
                                      name={'city'}
                                      placeholder={t('e.g. Silicon Valley')}
                                      value={cardData?.[2]?.city}
                                      onChange={(e) => {
                                        setCardData((prev) => ({
                                          ...prev,
                                          2: {
                                            ...prev?.[2],
                                            city: e?.target?.value,
                                          },
                                        }));
                                      }}
                      />
                    </div>
                    <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Postal code')}
                  </span>
                      <InputComponent type="text"
                                      name={'code'}
                                      placeholder={t('e.g. 94024')}
                                      value={cardData?.[2]?.code}
                                      onChange={(e) => {
                                        setCardData((prev) => ({
                                          ...prev,
                                          2: {
                                            ...prev?.[2],
                                            code: e?.target?.value,
                                          },
                                        }));
                                      }}
                      />
                    </div>
                  </div>
                  {
                    isMobile ?
                      <div className="ant-dropdown-trigger input-wrapper"
                           onClick={() => {
                             setInnerVisible('country');
                           }}
                      >
                        {cardData?.[2]?.country?.label || cardData?.[2]?.country?.name}
                        <ArrowSelectDown />
                      </div>
                      :
                      <Dropdown destroyPopupOnHide={true}
                                getPopupContainer={(trigger) => trigger?.parentNode}
                                onOpenChange={value => setDropdown(prev => ({
                                  ...prev,
                                  country: {
                                    visible: value,
                                    search: '',
                                  },
                                }))}
                                dropdownRender={() => (
                                  <div className={'input-wrapper-dropdown'}>
                                    <InputComponent type="text"
                                                    name={'country'}
                                                    placeholder={t('Search...')}
                                                    prefix={<Icon type={'search_icon'} role="icon" />}
                                                    value={dropdown?.country?.search}
                                                    onChange={(e) => {
                                                      setDropdown((prev) => ({
                                                        ...prev,
                                                        country: {
                                                          ...prev?.country,
                                                          search: e?.target?.value,
                                                        },
                                                      }));
                                                    }}
                                    />
                                    {
                                      !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                                        <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                          <div className="dropdown-database-list-item"
                                               style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                                          </div>
                                          <div className="empty-state_wrapper-select">
                                            {t('No options found')}
                                          </div>
                                          <p className={'empty-state_wrapper-text'}>
                                            {t('Try changing your search query or try again.')}
                                          </p>
                                        </div>
                                        :
                                        <div className="input-wrapper-dropdown-options">
                                          {
                                            optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                                              <div className="input-wrapper-dropdown-option"
                                                   key={el?.value}
                                                   onClick={() => {
                                                     setDropdown((prev) => ({
                                                       ...prev,
                                                       country: {
                                                         visible: false,
                                                         search: '',
                                                       },
                                                     }));
                                                     setCardData((prev) => ({
                                                       ...prev,
                                                       2: {
                                                         ...prev?.[2],
                                                         country: { ...el },
                                                       },
                                                     }));
                                                     if (el?.label?.toLowerCase()?.includes('united states')) {
                                                       setCardData((prev) => ({
                                                         ...prev,
                                                         2: {
                                                           ...prev?.[2],
                                                           country: { ...el },
                                                           state: {
                                                             label: 'Alabama',
                                                             value: 'Alabama',
                                                           },
                                                         },
                                                       }));
                                                     } else {
                                                       setCardData((prev) => ({
                                                         ...prev,
                                                         2: {
                                                           ...prev?.[2],
                                                           country: { ...el },
                                                           state: null,
                                                         },
                                                       }));
                                                     }
                                                   }}
                                              >
                                                {
                                                  el?.label
                                                }
                                                {
                                                  el?.id === cardData?.[2]?.country?.id ?
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         width="16"
                                                         height="16"
                                                         viewBox="0 0 16 16"
                                                         fill="none"
                                                         className={'checkmark'}
                                                    >
                                                      <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                            stroke="#225AEA"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                    :
                                                    null
                                                }
                                              </div>
                                            ))
                                          }
                                        </div>
                                    }
                                  </div>
                                )}
                                trigger={['click']}
                                open={dropdown?.country?.visible}
                      >
                        <div className="input-wrapper">
                          {cardData?.[2]?.country?.label || cardData?.[2]?.country?.name}
                          <ArrowSelectDown />
                        </div>
                      </Dropdown>
                  }
                  {
                    cardData?.[2]?.country?.label?.toLowerCase()?.includes('united states') || cardData?.[2]?.country?.name?.toLowerCase()?.includes('united states') ?
                      isMobile ?
                        <div className="ant-dropdown-trigger input-wrapper"
                             onClick={() => {
                               setInnerVisible('state');
                             }}
                        >
                          {cardData?.[2]?.state?.label || cardData?.[2]?.state}
                          <ArrowSelectDown />
                        </div>
                        :
                        <Dropdown destroyPopupOnHide={true}
                                  getPopupContainer={(trigger) => trigger?.parentNode}
                                  onOpenChange={value => setDropdown(prev => ({
                                    ...prev,
                                    state: {
                                      visible: value,
                                      search: '',
                                    },
                                  }))}
                                  dropdownRender={() => (
                                    <div className={'input-wrapper-dropdown state'}>
                                      <InputComponent type="text"
                                                      name={'state'}
                                                      placeholder={t('Search...')}
                                                      prefix={<Icon type={'search_icon'} role="icon" />}
                                                      value={dropdown?.state?.search}
                                                      onChange={(e) => {
                                                        setDropdown((prev) => ({
                                                          ...prev,
                                                          state: {
                                                            ...prev?.state,
                                                            search: e?.target?.value,
                                                          },
                                                        }));
                                                      }}
                                      />
                                      {
                                        !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                                          <div
                                            className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                            <div className="dropdown-database-list-item"
                                                 style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className={'link skeleton'}
                                                  style={{ width: 120, height: 20, marginLeft: 8 }}
                                            />
                                            </div>
                                            <div className="empty-state_wrapper-select">
                                              {t('No options found')}
                                            </div>
                                            <p className={'empty-state_wrapper-text'}>
                                              {t('Try changing your search query or try again.')}
                                            </p>
                                          </div>
                                          :
                                          <div className="input-wrapper-dropdown-options">
                                            {
                                              optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                                                <div className="input-wrapper-dropdown-option"
                                                     key={el?.value}
                                                     onClick={() => {
                                                       setDropdown((prev) => ({
                                                         ...prev,
                                                         state: {
                                                           visible: false,
                                                           search: '',
                                                         },
                                                       }));
                                                       setCardData((prev) => ({
                                                         ...prev,
                                                         2: {
                                                           ...prev?.[2],
                                                           state: { ...el },
                                                         },
                                                       }));
                                                     }}
                                                >
                                                  {
                                                    el?.label
                                                  }
                                                  {
                                                    el?.label === cardData?.[2]?.state?.label || el?.label === cardData?.[2]?.state ?
                                                      <svg xmlns="http://www.w3.org/2000/svg"
                                                           width="16"
                                                           height="16"
                                                           viewBox="0 0 16 16"
                                                           fill="none"
                                                           className={'checkmark'}
                                                      >
                                                        <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                              stroke="#225AEA"
                                                              strokeWidth="1.5"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                      :
                                                      null
                                                  }
                                                </div>
                                              ))
                                            }
                                          </div>
                                      }
                                    </div>
                                  )}
                                  trigger={['click']}
                                  open={dropdown?.state?.visible}
                        >
                          <div className="input-wrapper">
                            {cardData?.[2]?.state?.label || cardData?.[2]?.state}
                            <ArrowSelectDown />
                          </div>
                        </Dropdown>
                      :
                      null
                  }
                </div>
                <div className={cls('change-form-content', {
                  hidden: finalStep === 2 || Boolean(innerVisible),
                })}
                >
                  <div className={'input-wrapper'}>
                    <span className="title">
                      {t('Full name')}
                    </span>
                    <InputComponent type="text"
                                    name={'name'}
                                    placeholder={t('Enter card holder name...')}
                                    value={cardData?.[1]?.name}
                                    onChange={(e) => {
                                      setCardData((prev) => ({
                                        ...prev,
                                        1: {
                                          ...prev?.[1],
                                          name: e?.target?.value,
                                        },
                                      }));
                                    }}
                    />
                  </div>
                  <div className={'input-wrapper'}>
                    <span className="title">
                      {t('Card number')}
                    </span>
                    {
                      !!instance ?
                        <CardComponent ref={cardRef}
                                       className="card-wrapper"
                                       styles={style[theme]}
                                       classes={classes}
                                       locale={locale}
                                       placeholder={placeholder}
                                       fonts={fonts}
                                       icon={false}
                                       disabled={true}
                                       onReady={onReady}
                                       reload={reload}
                        >
                          <div className={cls('complex-input', {
                            focused: focused,
                          })}>
                            <Icon role={'icon'} type={'edit_card_modal_input'} />
                            <CardNumber className={'number card-field'}
                                        onChange={handleChange}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                            />
                            <CardExpiry className={'expiration card-field'}
                                        onChange={handleChange}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                            />
                            <CardCVV className={'cvv card-field'}
                                     onChange={handleChange}
                                     onFocus={() => setFocused(true)}
                                     onBlur={() => setFocused(false)}
                            />
                          </div>
                          {
                            cardError ?
                              <span className="form-error">
                                {cardError}
                              </span>
                              :
                              null
                          }
                        </CardComponent>
                        :
                        null
                    }
                  </div>
                </div>
                <div className={cls('inner-modal', {
                  hidden: innerVisible !== 'country',
                })}
                >
                  <InputComponent type="text"
                                  name={'country'}
                                  placeholder={t('Search...')}
                                  prefix={<Icon type={'search_icon'} role="icon" />}
                                  value={dropdown?.country?.search}
                                  onChange={(e) => {
                                    setDropdown((prev) => ({
                                      ...prev,
                                      country: {
                                        ...prev?.country,
                                        search: e?.target?.value,
                                      },
                                    }));
                                  }}
                  />
                  {
                    !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                      <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                        <div className="dropdown-database-list-item"
                             style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                        </div>
                        <div className="empty-state_wrapper-select">
                          {t('No options found')}
                        </div>
                        <p className={'empty-state_wrapper-text'}>
                          {t('Try changing your search query or try again.')}
                        </p>
                      </div>
                      :
                      <div className="input-wrapper-dropdown-options">
                        {
                          optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                            <div className="input-wrapper-dropdown-option"
                                 key={el?.value}
                                 onClick={() => {
                                   setDropdown((prev) => ({
                                     ...prev,
                                     country: {
                                       visible: false,
                                       search: '',
                                     },
                                   }));
                                   setCardData((prev) => ({
                                     ...prev,
                                     2: {
                                       ...prev?.[2],
                                       country: { ...el },
                                     },
                                   }));
                                   setInnerVisible(null);
                                   if (el?.label?.toLowerCase()?.includes('united states')) {
                                     setCardData((prev) => ({
                                       ...prev,
                                       2: {
                                         ...prev?.[2],
                                         country: { ...el },
                                         state: {
                                           label: 'Alabama',
                                           value: 'Alabama',
                                         },
                                       },
                                     }));
                                   } else {
                                     setCardData((prev) => ({
                                       ...prev,
                                       2: {
                                         ...prev?.[2],
                                         country: { ...el },
                                         state: null,
                                       },
                                     }));
                                   }
                                 }}
                            >
                              {
                                el?.label
                              }
                              {
                                el?.id === cardData?.[2]?.country?.id ?
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       className={'checkmark'}
                                  >
                                    <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                          stroke="#225AEA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                                  </svg>
                                  :
                                  null
                              }
                            </div>
                          ))
                        }
                      </div>
                  }
                </div>
                <div className={cls('inner-modal', {
                  hidden: innerVisible !== 'state',
                })}
                >
                  <InputComponent type="text"
                                  name={'state'}
                                  placeholder={t('Search...')}
                                  prefix={<Icon type={'search_icon'} role="icon" />}
                                  value={dropdown?.state?.search}
                                  onChange={(e) => {
                                    setDropdown((prev) => ({
                                      ...prev,
                                      state: {
                                        ...prev?.state,
                                        search: e?.target?.value,
                                      },
                                    }));
                                  }}
                  />
                  {
                    !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                      <div
                        className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                        <div className="dropdown-database-list-item"
                             style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className={'link skeleton'}
                                                  style={{ width: 120, height: 20, marginLeft: 8 }}
                                            />
                        </div>
                        <div className="empty-state_wrapper-select">
                          {t('No options found')}
                        </div>
                        <p className={'empty-state_wrapper-text'}>
                          {t('Try changing your search query or try again.')}
                        </p>
                      </div>
                      :
                      <div className="input-wrapper-dropdown-options">
                        {
                          optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                            <div className="input-wrapper-dropdown-option"
                                 key={el?.value}
                                 onClick={() => {
                                   setDropdown((prev) => ({
                                     ...prev,
                                     state: {
                                       visible: false,
                                       search: '',
                                     },
                                   }));
                                   setCardData((prev) => ({
                                     ...prev,
                                     2: {
                                       ...prev?.[2],
                                       state: { ...el },
                                     },
                                   }));
                                   setInnerVisible(null);
                                 }}
                            >
                              {
                                el?.label
                              }
                              {
                                el?.label === cardData?.[2]?.state?.label || el?.label === cardData?.[2]?.state ?
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       className={'checkmark'}
                                  >
                                    <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                          stroke="#225AEA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                                  </svg>
                                  :
                                  null
                              }
                            </div>
                          ))
                        }
                      </div>
                  }
                </div>
              </div>
            </Spin>
          </div>
          {
            Boolean(innerVisible) ?
              null
              :
              <Divider type={'horizontal'}
                       style={{ margin: '16px 0' }}
              />
          }
          {
            Boolean(innerVisible) ?
              null
              :
              <div className={'collection-modal-btn-wrapper'}>
                {
                  finalStep === 1 ?
                    null
                    :
                    <ButtonComponent className="collection-modal-btn collection-modal-btn-create"
                                     onClick={() => {
                                       setFinalStep(1);
                                     }}
                                     text={t('Previous step')}
                    />
                }
                <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                                 onClick={cancelHandler}
                                 text={t('Cancel')}
                />
                <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                                 disabled={!cardIsValid?.[finalStep]}
                                 onClick={() => {
                                   if (finalStep === 1) {
                                     setFinalStep(2);
                                   } else tokenize();
                                 }}
                                 text={t(finalStep === 1 ? 'Continue' : 'Add payment method')}
                />
              </div>
          }
        </div>
      </>
    )
  };

  const contentTitle = {
    1: {
      title: 'Increase tracking limits',
      text: 'Adjust your store and product tracking limit for Sales Tracker.',
    },
    2: {
      title: 'Confirmation',
      text: calculateCurrentCost() > calculateCost() ?
        'Are you sure you want to downgrade your plan? Your extra tracked stores will be removed. This action can not be reversed.'
        :
        'Are you sure you want to make changes to your plan?',
    },
    3: {
      title: 'Add payment method',
      text: 'Add a credit or debit card as your payment method.',
    },
  };

  useEffect(() => {
    if (!visible) {
      setInnerVisible(null);
      setStore(storeLimit < 50 ? 50 : storeLimit);
      setProduct(productLimit < 100 ? 100 : productLimit);
      setStep(1);
      setPaymentType('now');
      setCardIsValid({
        1: false,
        2: false,
      });
      setFinalStep(1);
      setCardData({
        1: {
          name: '',
          number: null,
          expiry: null,
          cvv: null,
        },
        2: {
          street: '',
          city: '',
          code: '',
          country: null,
          state: null,
        },
      });
      setDropdown({
        country: {
          visible: false,
          search: ''
        },
        state: {
          visible: false,
          search: ''
        },
      });
    } else {
      setCardData(prev => ({
        ...prev,
        2: {
          ...prev?.[2],
          ...billingInfo
        }
      }));
    }
  }, [visible, isPreset]);

  useEffect(() => {
    if (updateSubscriptionResult) {
      notification.destroy();
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg
            text={(paymentType === 'now' && calculateCurrentCost() <= calculateCost()) ? 'You can now use updated benefits of your plan.' : 'Your plan change has been successfully scheduled'}
            icon={(paymentType === 'now' && calculateCurrentCost() <= calculateCost()) ? 'payment_added' : 'notification_schedule'}
            title={'Plan was successfully updated'}
          />
        ),
      });
      setModal(null);
      setChargebeeCardFetching(false);
      changeSubscriptionReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [updateSubscriptionResult]);

  useEffect(() => {
    if (step === 2 && (paymentType === 'now' || onBoarding.discountResult)) {
      let data = {
        plan: { 2: premiumPlan?.prices?.[currentPlan?.price_type?.id] },
        'discount_code': discountId || null,
        force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
        new_limits: {
          stores_limit: store,
          products_limit: product
        }
      };
      getCharge(data);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [discountId, step]);

  useEffect(() => {
    if (step === 3) {
      let inst = window.Chargebee?.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
        publishableKey: process.env.REACT_APP_APPLE_CHARGEBEE_PUBLISHABLE_KEY,
        enableRefersionTracking: true,
      });
      if(!onBoarding?.countries?.length) getCountries();
      setInstance(inst);
    }
  }, [step]);

  useEffect(() => {
    if (finalStep === 1) {
      if (cardError || !cardData?.[1]?.name || !cardData?.[1]?.number?.complete || !cardData?.[1]?.expiry?.complete || !cardData?.[1]?.cvv?.complete) {
        setCardIsValid(prev => ({
          ...prev,
          1: false
        }));
      } else setCardIsValid(prev => ({
        ...prev,
        1: true
      }));
    } else {
      if (cardData?.[2]?.street && cardData?.[2]?.city && cardData?.[2]?.code && Boolean(cardData?.[2]?.country)) {
        setCardIsValid(prev => ({
          ...prev,
          2: true
        }));
      } else setCardIsValid(prev => ({
        ...prev,
        2: false
      }));
    }
  }, [cardError, JSON.stringify(cardData)]);

  useEffect(() => {
    if (errors?.change_payments) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'Something went wrong, kindly try adding your new payment method again.'}
                      icon="notification_warning"
                      title={'Something went wrong'}
          />
        ),
      });
      setModal(null);
      resetErrors();
    }
    setSpin(false);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  useEffect(() => {
    if (cardRef?.current && intent?.result) {

      const additionalData = {
        billingAddress: {
          firstName: cardData?.[1]?.name?.split(' ')[0] || '',
          lastName: cardData?.[1]?.name?.split(' ')[1] || '',
          addressLine1: cardData?.[2]?.street || '',
          city: cardData?.[2]?.city || '',
          state: cardData?.[2]?.country?.id === 1450 ? cardData?.[2]?.state : null,
          country: cardData?.[2]?.country?.label || cardData?.[2]?.country?.name || '',
          zip: cardData?.[2]?.code || '',
        }
      }

      cardRef.current.authorizeWith3ds(intent?.result, additionalData)
        .then(authorizedPaymentIntent => {
          onContinue({
            intent_id: authorizedPaymentIntent.id
          });
        }).catch(error => {
        API.saveFrontLogs({ pathname, action: 'authorizeWith3ds',  error });
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [intent?.result]);

  function tokenize() {
    setSpin(true);
    cardRef.current.tokenize({
      firstName: cardData?.[1]?.name?.split(' ')[0] || '',
      lastName: cardData?.[1]?.name?.split(' ')[1] || '',
      addressLine1: cardData?.[2]?.street || '',
      city: cardData?.[2]?.city || '',
      state: cardData?.[2]?.country?.id === 1450 ? cardData?.[2]?.state : null,
      country: cardData?.[2]?.country?.label || cardData?.[2]?.country?.name || '',
      zip: cardData?.[2]?.code || '',
    }).then((data) => {
      intent.createIntent({
        'amount': intent?.amount,
        'gateway_account_id': 'gw_BTcXhMTspTHPA8UH',
        'payment_method_type': 'card',
        'card_token': data['vaultToken'],
      });
    }).catch((error) => {
      API.saveFrontLogs({ pathname, action: 'tokenize', error })
    });
  }

  return (
    <Modal handleOk={null}
           handleClose={() => {
             if (Boolean(innerVisible)) {
               setInnerVisible(null);
             } else setModal(null);
           }}
           title={t(
             Boolean(innerVisible) ?
               innerVisible === 'state' ?
                 'State'
                 :
                 'Country'
               :
               contentTitle?.[step]?.title)}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal edit-card-modal edit-limits-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={fetching || spinner || onBoarding?.intentFetching || saveFetching || spin || onBoarding.discountFetching}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(
                Boolean(innerVisible) ?
                  `Change ${innerVisible === 'state' ? 'state' : 'country'} depending from your needs.`
                  : contentTitle?.[step]?.text
              )
            }
          </p>
        </div>
        {
          content[step]
        }
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default LimitChangeModal;
