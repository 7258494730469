import React from 'react';
import {Divider} from "antd";

const SalesStoreSkeleton = ({isMobile}) => {
  return (
    <div className="product-database-table-wrapper" style={{marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
      <div className="product-database-table-header" style={{paddingRight: 0, paddingTop: 9, paddingBottom: 9}}>
        <div style={{width: '35%'}}>
          <span className="link skeleton" style={{width: 41, height: 24}}/>
        </div>
        <div style={{width: '65%', display: isMobile ? 'none' : 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', width: '25%'}}>
            <span className="link skeleton" style={{width: 24, height: 24}}/>
            <span className="link skeleton" style={{width: 93, height: 24, marginLeft: 2}}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', justifyContent: 'flex-end', width: '25%'}}>
            <span className="link skeleton" style={{width: 24, height: 24}}/>
            <span className="link skeleton" style={{width: 93, height: 24, marginLeft: 2}}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', justifyContent: 'flex-end', width: '25%'}}>
            <span className="link skeleton" style={{width: 24, height: 24}}/>
            <span className="link skeleton" style={{width: 93, height: 24, marginLeft: 2}}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', justifyContent: 'flex-end', width: '25%'}}>
            <span className="link skeleton" style={{width: 24, height: 24}}/>
            <span className="link skeleton" style={{width: 93, height: 24, marginLeft: 2, borderRadius: '6px 0 0 6px'}}/>
          </div>
        </div>
      </div>

      <div style={{marginTop: 20, maxHeight: 'calc((100dvh - 96px) - 80px)', overflow: 'hidden'}} className="product-database-table-body">
        {[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
        ].map(el => (
          <div key={el} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>
              <div key={el} className={'product-database-table-skeleton-cell-1'}>
                <div style={{position: 'relative'}}>
                  <span className="link skeleton" style={{width: 64, height: 64}}/>
                </div>

                <div style={{marginLeft: 16, display: 'flex', flexDirection: "column", justifyContent: "center"}}>
                  <span className="link skeleton" style={{width: 85, height: 16}}/>
                  <span className="link skeleton" style={{width: 174, height: 16, marginTop: 10}}/>
                </div>
              </div>

              <div key={el + 1} style={{display: isMobile ? 'none' : 'flex'}} className={'product-database-table-skeleton-cell-2'}>
                <span className="link skeleton" style={{ width: 24, height: 24 }}/>
              </div>

              <div key={el + 30} style={{width: '16%', display: isMobile ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span className="link skeleton" style={{width: 64, height: 16}}/>
                <span className="link skeleton" style={{width: 55, height: 16, marginTop: 10}}/>
              </div>

              <div key={el + 40} style={{width: '16%', display: isMobile ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span className="link skeleton" style={{width: 64, height: 16}}/>
                <span className="link skeleton" style={{width: 55, height: 16, marginTop: 10}}/>
              </div>

              <div key={el + 31} style={{width: '16%', display: isMobile ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span className="link skeleton" style={{width: 64, height: 16}}/>
                <span className="link skeleton" style={{width: 55, height: 16, marginTop: 10}}/>
              </div>

              <div key={el + 41} style={{width: '16%', display: isMobile ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span className="link skeleton" style={{width: 64, height: 16}}/>
                <span className="link skeleton" style={{width: 55, height: 16, marginTop: 10}}/>
              </div>

            </div>
            <Divider type={'horizontal'} style={{margin: '12px auto'}}/>
          </div>
        ))}
        <div className={'product-database-table-skeleton-row'}>
          <span className="link skeleton" style={{width: 64, height: 9, borderRadius: '6px 6px 0 0'}}/>
        </div>
      </div>
    </div>
  );
};

export default SalesStoreSkeleton;
