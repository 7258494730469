import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Gps = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-gps icon-gps-outline')}
      >
        <g clipPath="url(#clip0_1558_1684)">
          <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
                d="M9.99992 1.45898V6.87565M18.5416 10.0007H13.1249M9.99992 13.1256V18.5423M6.87492 10.0007H1.45825M9.99992 16.0423C6.6632 16.0423 3.95825 13.3374 3.95825 10.0007C3.95825 6.66393 6.6632 3.95898 9.99992 3.95898C13.3366 3.95898 16.0416 6.66393 16.0416 10.0007C16.0416 13.3374 13.3366 16.0423 9.99992 16.0423Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1558_1684">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-gps icon-gps-bold')}
      >
        <g clipPath="url(#clip0_1558_1684)">
          <path stroke={theme === 'light' ? '#225AEA' : 'white'}
                d="M9.99992 1.45898V6.87565M18.5416 10.0007H13.1249M9.99992 13.1256V18.5423M6.87492 10.0007H1.45825M9.99992 16.0423C6.6632 16.0423 3.95825 13.3374 3.95825 10.0007C3.95825 6.66393 6.6632 3.95898 9.99992 3.95898C13.3366 3.95898 16.0416 6.66393 16.0416 10.0007C16.0416 13.3374 13.3366 16.0423 9.99992 16.0423Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1558_1684">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
}

export default Gps;
