import React from 'react';
import cls from 'classname';
import { Col } from 'antd';
import ButtonComponent from "../Button";
import { useTranslation } from 'react-i18next';
import './styles.less';

const SubUnSubComponent = ({
  categoryName,
  categoryText,
  className,
  active,
  subscribeFunc,
}) => {

  const {t} = useTranslation();

  return (
    <Col span={24}>
      <div className={cls(className, 'panel', `panel--${categoryName}`)}>
        <div className="category-portfolio">
          {active && (
            <div className="category-portfolio_sub-text">
              {categoryText ||
                t('To access product drops from this category,\n' +
                  'you must subscribe to a plan')}
            </div>
          )}
          <div
            className={cls('category-portfolio_button', {
              'category-portfolio_button--disabled': !active,
            })}
          >
            <ButtonComponent disabled={!active} type="button" onClick={subscribeFunc}>
              {
                t('Subscribe')
              }
            </ButtonComponent>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default SubUnSubComponent;
