import React from 'react';
import { useSelector } from 'react-redux';

const FilterIcon = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path stroke={theme === 'light' ? '#707ba0' : 'rgba(255, 255, 255, 0.36)'}
            d="M4.75135 16.8788V11.6684M4.75135 8.54214V3.12305M10.0002 16.6703V10.6259M10.0002 7.49978V3.33138M15.249 16.8785V13.3354M15.249 10.2095V3.12305M3.12207 11.46H6.45851M8.33351 7.70801H11.6668M13.5418 13.1271H16.8752"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
