import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Spin } from 'antd';
import Icon from '../../Icon';
import InputComponent from '../Input';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import ButtonComponent from '../Button';
import Modal from '../Modal';
import cls from 'classname';

const EditAddressModal = (
  {
    setModal,
    visible,
    isMobile,
    isAdd,
    loading,
    onBoarding,
    getCountries,
    billingInfo,
    changeBillingAddress,
  },
) => {

  const { t } = useTranslation();

  const optionsCountry = onBoarding?.countries?.length ?
    [...onBoarding?.countries].sort((a, b) => (a['name'] > b['name'] ? 1 : -1)).map((el) => ({
      label: el.name,
      value: el.name,
      id: el.id,
    }))
    :
    [];
  const optionsState = onBoarding?.countries?.length ?
    [...[...onBoarding?.countries].filter((country) => country.name === 'United States')?.[0]?.['state']]?.sort((a, b) => a.localeCompare(b)).map((el) => ({
      label: el,
      value: el,
    }))
    :
    [];

  const [innerVisible, setInnerVisible] = useState(null);

  const [dropdown, setDropdown] = useState({
    country: {
      visible: false,
      search: '',
    },
    state: {
      visible: false,
      search: '',
    },
  });

  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    postal_code: '',
    country: null,
    state: '',
  });

  useEffect(() => {
    if (!onBoarding?.countries?.length) getCountries();
  }, []);

  useEffect(() => {
    if (!visible) {
      setInnerVisible(null);
      setData({
        first_name: '',
        last_name: '',
        address: '',
        city: '',
        postal_code: '',
        country: null,
        state: null,
      });
      setDropdown({
        country: {
          visible: false,
          search: '',
        },
        state: {
          visible: false,
          search: '',
        },
      });
    } else {
      setData(prev => ({
        ...prev,
        ...billingInfo,
      }));
    }
  }, [visible]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             if (Boolean(innerVisible)) {
               setInnerVisible(null);
             } else setModal(null);
           }}
           title={t(Boolean(innerVisible) ?
             innerVisible === 'state' ?
               'State'
               :
               'Country'
             :
             isAdd ?
               'Add billing address'
               :
               'Replace billing address')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal edit-card-modal edit-address-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(
                Boolean(innerVisible) ?
                  `Change ${innerVisible === 'state' ? 'state' : 'country'} depending from your needs.`
                  :
                  'Please provide the information to display on your invoices.')
            }
          </p>
          <div className="change-form">
            <div className={cls('change-form-content', {
              hidden: Boolean(innerVisible),
            })}
            >
              <div className={'change-form-inner'}>
                <div className={'input-wrapper'}>
                  <span className="title">
                    {t('First name')}
                  </span>
                  <InputComponent type="text"
                                  name={'first_name'}
                                  placeholder={t('e.g. John')}
                                  value={data?.first_name}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      first_name: e?.target?.value,
                                    }));
                                  }}
                  />
                </div>
                <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Last name')}
                  </span>
                  <InputComponent type="text"
                                  name={'last_name'}
                                  placeholder={t('e.g. Smith')}
                                  value={data?.last_name}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      last_name: e?.target?.value,
                                    }));
                                  }}
                  />
                </div>
              </div>
              <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Street and house number')}
                  </span>
                <InputComponent type="text"
                                name={'address'}
                                placeholder={t('e.g. Sand Hill Road 3')}
                                value={data?.address}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    address: e?.target?.value,
                                  }));
                                }}
                />
              </div>
              <div className={'change-form-inner'}>
                <div className={'input-wrapper'}>
                  <span className="title">
                    {t('City or Town')}
                  </span>
                  <InputComponent type="text"
                                  name={'city'}
                                  placeholder={t('e.g. Silicon Valley')}
                                  value={data?.city}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      city: e?.target?.value,
                                    }));
                                  }}
                  />
                </div>
                <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Postal code')}
                  </span>
                  <InputComponent type="text"
                                  name={'postal_code'}
                                  placeholder={t('e.g. 94024')}
                                  value={data?.postal_code}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      postal_code: e?.target?.value,
                                    }));
                                  }}
                  />
                </div>
              </div>
              {
                isMobile ?
                  <div className="ant-dropdown-trigger input-wrapper"
                       onClick={() => {
                         setInnerVisible('country');
                       }}
                  >
                    {data?.country?.label || data?.country?.name}
                    <ArrowSelectDown />
                  </div>
                  :
                  <Dropdown destroyPopupOnHide={true}
                            getPopupContainer={(trigger) => trigger?.parentNode}
                            onOpenChange={value => setDropdown(prev => ({
                              ...prev,
                              country: {
                                visible: value,
                                search: '',
                              },
                            }))}
                            dropdownRender={() => (
                              <div className={'input-wrapper-dropdown'}>
                                <InputComponent type="text"
                                                name={'country'}
                                                placeholder={t('Search...')}
                                                prefix={<Icon type={'search_icon'} role="icon" />}
                                                value={dropdown?.country?.search}
                                                onChange={(e) => {
                                                  setDropdown((prev) => ({
                                                    ...prev,
                                                    country: {
                                                      ...prev?.country,
                                                      search: e?.target?.value,
                                                    },
                                                  }));
                                                }}
                                />
                                {
                                  !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                                    <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                      <div className="dropdown-database-list-item"
                                           style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                                      </div>
                                      <div className="empty-state_wrapper-select">
                                        {t('No options found')}
                                      </div>
                                      <p className={'empty-state_wrapper-text'}>
                                        {t('Try changing your search query or try again.')}
                                      </p>
                                    </div>
                                    :
                                    <div className="input-wrapper-dropdown-options">
                                      {
                                        optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                                          <div className="input-wrapper-dropdown-option"
                                               key={el?.value}
                                               onClick={() => {
                                                 setDropdown((prev) => ({
                                                   ...prev,
                                                   country: {
                                                     visible: false,
                                                     search: '',
                                                   },
                                                 }));
                                                 setData((prev) => ({
                                                   ...prev,
                                                   country: { ...el },
                                                 }));
                                                 if (el?.label?.toLowerCase()?.includes('united states')) {
                                                   setData((prev) => ({
                                                     ...prev,
                                                     country: { ...el },
                                                     state: {
                                                       label: 'Alabama',
                                                       value: 'Alabama',
                                                     },
                                                   }));
                                                 } else {
                                                   setData((prev) => ({
                                                     ...prev,
                                                     country: { ...el },
                                                     state: null,
                                                   }));
                                                 }
                                               }}
                                          >
                                            {
                                              el?.label
                                            }
                                            {
                                              el?.id === data?.country?.id ?
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     width="16"
                                                     height="16"
                                                     viewBox="0 0 16 16"
                                                     fill="none"
                                                     className={'checkmark'}
                                                >
                                                  <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                        stroke="#225AEA"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                  />
                                                </svg>
                                                :
                                                null
                                            }
                                          </div>
                                        ))
                                      }
                                    </div>
                                }
                              </div>
                            )}
                            trigger={['click']}
                            open={dropdown?.country?.visible}
                  >
                    <div className="input-wrapper">
                      {data?.country?.label || data?.country?.name}
                      <ArrowSelectDown />
                    </div>
                  </Dropdown>
              }
              {
                data?.country?.label?.toLowerCase()?.includes('united states') || data?.country?.name?.toLowerCase()?.includes('united states') ?
                  isMobile ?
                    <div className="ant-dropdown-trigger input-wrapper"
                         onClick={() => {
                           setInnerVisible('state');
                         }}
                    >
                      {data?.state?.label || data?.state}
                      <ArrowSelectDown />
                    </div>
                    :
                    <Dropdown destroyPopupOnHide={true}
                              getPopupContainer={(trigger) => trigger?.parentNode}
                              onOpenChange={value => setDropdown(prev => ({
                                ...prev,
                                state: {
                                  visible: value,
                                  search: '',
                                },
                              }))}
                              dropdownRender={() => (
                                <div className={'input-wrapper-dropdown state'}>
                                  <InputComponent type="text"
                                                  name={'state'}
                                                  placeholder={t('Search...')}
                                                  prefix={<Icon type={'search_icon'} role="icon" />}
                                                  value={dropdown?.state?.search}
                                                  onChange={(e) => {
                                                    setDropdown((prev) => ({
                                                      ...prev,
                                                      state: {
                                                        ...prev?.state,
                                                        search: e?.target?.value,
                                                      },
                                                    }));
                                                  }}
                                  />
                                  {
                                    !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                                      <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                        <div className="dropdown-database-list-item"
                                             style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                                        </div>
                                        <div className="empty-state_wrapper-select">
                                          {t('No options found')}
                                        </div>
                                        <p className={'empty-state_wrapper-text'}>
                                          {t('Try changing your search query or try again.')}
                                        </p>
                                      </div>
                                      :
                                      <div className="input-wrapper-dropdown-options">
                                        {
                                          optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                                            <div className="input-wrapper-dropdown-option"
                                                 key={el?.value}
                                                 onClick={() => {
                                                   setDropdown((prev) => ({
                                                     ...prev,
                                                     state: {
                                                       visible: false,
                                                       search: '',
                                                     },
                                                   }));
                                                   setData((prev) => ({
                                                     ...prev,
                                                     state: { ...el },
                                                   }));
                                                 }}
                                            >
                                              {
                                                el?.label
                                              }
                                              {
                                                el?.label === data?.state?.label || el?.label === data?.state ?
                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                       width="16"
                                                       height="16"
                                                       viewBox="0 0 16 16"
                                                       fill="none"
                                                       className={'checkmark'}
                                                  >
                                                    <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                          stroke="#225AEA"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  :
                                                  null
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>
                                  }
                                </div>
                              )}
                              trigger={['click']}
                              open={dropdown?.state?.visible}
                    >
                      <div className="input-wrapper">
                        {data?.state?.label || data?.state}
                        <ArrowSelectDown />
                      </div>
                    </Dropdown>
                  :
                  null
              }
            </div>
            <div className={cls('inner-modal', {
              hidden: innerVisible !== 'country',
            })}
            >
              <InputComponent type="text"
                              name={'country'}
                              placeholder={t('Search...')}
                              prefix={<Icon type={'search_icon'} role="icon" />}
                              value={dropdown?.country?.search}
                              onChange={(e) => {
                                setDropdown((prev) => ({
                                  ...prev,
                                  country: {
                                    ...prev?.country,
                                    search: e?.target?.value,
                                  },
                                }));
                              }}
              />
              {
                !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                  <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                    <div className="dropdown-database-list-item"
                         style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                    </div>
                    <div className="empty-state_wrapper-select">
                      {t('No options found')}
                    </div>
                    <p className={'empty-state_wrapper-text'}>
                      {t('Try changing your search query or try again.')}
                    </p>
                  </div>
                  :
                  <div className="input-wrapper-dropdown-options">
                    {
                      optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                        <div className="input-wrapper-dropdown-option"
                             key={el?.value}
                             onClick={() => {
                               setDropdown((prev) => ({
                                 ...prev,
                                 country: {
                                   visible: false,
                                   search: '',
                                 },
                               }));
                               setData((prev) => ({
                                 ...prev,
                                 country: { ...el },
                               }));
                               setInnerVisible(null);
                               if (el?.label?.toLowerCase()?.includes('united states')) {
                                 setData((prev) => ({
                                   ...prev,
                                   country: { ...el },
                                   state: {
                                     label: 'Alabama',
                                     value: 'Alabama',
                                   },
                                 }));
                               } else {
                                 setData((prev) => ({
                                   ...prev,
                                   country: { ...el },
                                   state: null,
                                 }));
                               }
                             }}
                        >
                          {
                            el?.label
                          }
                          {
                            el?.id === data?.country?.id ?
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                   height="16"
                                   viewBox="0 0 16 16"
                                   fill="none"
                                   className={'checkmark'}
                              >
                                <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                      stroke="#225AEA"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                />
                              </svg>
                              :
                              null
                          }
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
            <div className={cls('inner-modal state', {
              hidden: innerVisible !== 'state',
            })}
            >
              <InputComponent type="text"
                              name={'state'}
                              placeholder={t('Search...')}
                              prefix={<Icon type={'search_icon'} role="icon" />}
                              value={dropdown?.state?.search}
                              onChange={(e) => {
                                setDropdown((prev) => ({
                                  ...prev,
                                  state: {
                                    ...prev?.state,
                                    search: e?.target?.value,
                                  },
                                }));
                              }}
              />
              {
                !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                  <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                    <div className="dropdown-database-list-item"
                         style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                    </div>
                    <div className="empty-state_wrapper-select">
                      {t('No options found')}
                    </div>
                    <p className={'empty-state_wrapper-text'}>
                      {t('Try changing your search query or try again.')}
                    </p>
                  </div>
                  :
                  <div className="input-wrapper-dropdown-options">
                    {
                      optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                        <div className="input-wrapper-dropdown-option"
                             key={el?.value}
                             onClick={() => {
                               setDropdown((prev) => ({
                                 ...prev,
                                 state: {
                                   visible: false,
                                   search: '',
                                 },
                               }));
                               setData((prev) => ({
                                 ...prev,
                                 state: { ...el },
                               }));
                               setInnerVisible(null);
                             }}
                        >
                          {
                            el?.label
                          }
                          {
                            el?.label === data?.state?.label || el?.label === data?.state ?
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                   height="16"
                                   viewBox="0 0 16 16"
                                   fill="none"
                                   className={'checkmark'}
                              >
                                <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                      stroke="#225AEA"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                />
                              </svg>
                              :
                              null
                          }
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>
        </div>
        {
          Boolean(innerVisible) ?
            null
            :
            <Divider type={'horizontal'}
                     style={{ margin: '16px 0' }}
            />
        }
        {
          Boolean(innerVisible) ?
            null
            :
            <div className={'collection-modal-btn-wrapper'}>
              <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                               onClick={() => {
                                 setModal(null);
                               }}
                               text={t('Cancel')}
              />
              <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                               disabled={loading || (!Boolean(data?.first_name) || !Boolean(data?.last_name) || !Boolean(data?.address) || !Boolean(data?.city) || !Boolean(data?.postal_code) || !Boolean(data?.country))}
                               onClick={() => {
                                 const {
                                   first_name,
                                   last_name,
                                   address,
                                   city,
                                   postal_code,
                                 } = data;
                                 changeBillingAddress({
                                   first_name, last_name, address, city, postal_code,
                                   country: { id: data?.country?.id },
                                   state: data?.state?.value,
                                 });
                               }}
                               text={t('Add billing address')}
              />
            </div>
        }
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default EditAddressModal;
