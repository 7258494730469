import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import PasswordValidator from '../PasswordValidator';
import Icon from '../../Icon';
import ButtonComponent from '../Button';

function NewPasswordForm({ error, ...props }) {

  const [value, setValue] = useState(null);
  const [valueConfirm, setValueConfirm] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        if (error.length > 100) {
          setErrorText('Server response error');
        } else {
          setErrorText(error);
        }
      } else {
        setErrorText(Object.values(error)[0]);
      }
    }
  }, [error]);

  const onFinish = values => {
    const data = { ...values };
    props.onSubmit(data.password);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0]['errors']);
    }
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
  }

  function onFocusHandler(e) {
    const target = e.target;

    handleClearError(e, target.name);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'password') setValue(value);
    if (name === 'confirm') setValueConfirm(value);
  }

  return (
    <Form onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onSubmit"
          className="login-form login-create-new-pass-form"
          layout="vertical"
          form={form}
          requiredMark={false}
    >
      <Form.Item name="password"
                 validateStatus={errorText ? 'error' : null}
                 rules={[{ required: true, message: 'Required field' }]}
      >
        <Input.Password
          size="large"
          name="password"
          placeholder={'New password with 6+ characters'}
          autoComplete="new-password"
          visibilityToggle
          iconRender={visible => (
            visible
              ? <Icon type="eye" color={'#C5CCE3'} />
              : <Icon type="eye_invisible" color={'#C5CCE3'} />
          )
          }
          onChange={(e) => {
            handleChange(e);
          }}
          onFocus={(e) => {
            onFocusHandler(e);
          }}
        />
      </Form.Item>

      <Form.Item name="confirm"
                 style={{ marginBottom: 8 }}
                 rules={[
                   {
                     required: true,
                     message: 'Please confirm your password!',
                   },
                   ({ getFieldValue }) => ({
                     validator(rule, value) {
                       if (!value || !getFieldValue('password') || getFieldValue('password') === value) {
                         if (value?.length < 6) {
                           return Promise.reject('Must be at least 6 characters');
                         } else {
                           return Promise.resolve();
                         }
                       } else {
                         return Promise.reject('Passwords do not match');
                       }
                     },
                   }),
                 ]}
      >
        <Input.Password
          size="large"
          name="confirm"
          placeholder={'Confirm new password'}
          autoComplete="dontshow"
          visibilityToggle
          iconRender={visible => (
            visible
              ? <Icon type="eye" color={'#C1C8DD'} />
              : <Icon type="eye_invisible" color={'#C1C8DD'} />
          )
          }
          onChange={(e) => {
            handleChange(e);
          }}
          onFocus={(e) => {
            onFocusHandler(e);
          }}
        />
      </Form.Item>

      <PasswordValidator value={value} error={errorText} isLogin={true} />

      <ButtonComponent htmlType="submit"
                       className="login-form-submit"
                       style={{ width: '100%' }}
                       disabled={!valueConfirm || (value?.length < 6 || valueConfirm?.length < 6)}
      >
        Change password
      </ButtonComponent>


    </Form>
  );
}

export default NewPasswordForm;
