import React from 'react';
import utils from '../../../Utils/utils';

const Skeleton = (
  {
    view,
    isCollections,
    isMobile
  },
) => {

  return (
    <div className="product-database-page portfolio-page portfolio-page-product-list">
      <div className={'page-top-line-wrapper'}>
        <div className="custom-breadcrumbs">
          <span className={'link skeleton'}
                style={{ width: 55, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 20, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 72, height: 20 }}
          />
        </div>
      </div>
      <div className="search">
        <div className="search-wrapper">
          <span className={'link skeleton'}
                style={{ width: isMobile ? 'calc(100% - 82px)' : 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: 70, height: 40 }}
          />
          {
            view === 'overview' && !isMobile ?
              <span className={'link skeleton'}
                    style={{ width: 80, height: 40 }}
              />
              :
              null
          }
        </div>
        {
          isMobile && view === 'overview' ?
            <div style={{ display: 'flex', alignItems: 'center', gap: 12, width: '100%' }}>
              <span className={'link skeleton'}
                    style={{ width: 'calc(100% - 84px)', height: 40 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 72, height: 40 }}
              />
            </div>
            :
            <span className={'link skeleton'}
                  style={{ width: isMobile ? '100%' : 72, height: 40 }}
            />
        }
      </div>
      {/*{*/}
      {/*  isCollections ?*/}
      {/*    <div className={'collections-stats-wrapper'}>*/}
      {/*      <div className={'collections-stats-title-wrapper'}>*/}
      {/*        <span className={'link skeleton'}*/}
      {/*          style={{ width: 129, height: 20 }}*/}
      {/*        />*/}
      {/*        <span className={'link skeleton'}*/}
      {/*              style={{ width: 20, height: 10 }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className={'collections-stats'}>*/}
      {/*        <div className="collections-stat">*/}
      {/*    <span className="title">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(50, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*          <span className="value">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(30, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*        </div>*/}
      {/*        <div className="collections-stat">*/}
      {/*    <span className="title">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(50, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*          <span className="value">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(30, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*        </div>*/}
      {/*        <div className="collections-stat">*/}
      {/*    <span className="title">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(50, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*          <span className="value">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(30, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*        </div>*/}
      {/*        <div className="collections-stat">*/}
      {/*    <span className="title">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(50, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*          <span className="value">*/}
      {/*      <span className={'link skeleton'}*/}
      {/*            style={{ width: utils.randomNum(30, 70), height: 20 }}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    null*/}
      {/*}*/}
      <div className={'product-list'}
           style={{
             overflow: isMobile ? 'scroll' : 'hidden',
             height: isMobile ? 'calc(100dvh - 68px - 69px - 125px)' : 'calc(100dvh - 89px - 69px)'
      }}
      >
        {
          view === 'overview' ?
            <div className={'products-overview'}>
              <div className="products-overview_wrapper"
                   style={{ overflow: isMobile ? 'scroll' : 'hidden' }}
              >
                {
                  Array.from({ length: 12 }, (v, i) => i).map(el => (
                    <div className="product"
                         key={el}
                    >
                      <div className="product_image">
                        <span className={'link skeleton'}
                              style={{ width: '100%', height: '100%', borderRadius: '10px 10px 0 0' }}
                        />
                      </div>
                      <div className="product_wrap"
                           id="product_wrap"
                      >
                        <span className={'link skeleton'}
                              style={{ width: '100%', height: 20 }}
                        />
                      </div>
                      <div className="product-detail_price">
                        <span className={'link skeleton'}
                              style={{ width: 51, height: 24 }}
                        />
                      </div>
                      <div className="product_details">
                        <div className="product-detail">
                          <div className="product-detail_title">
                            <span className={'link skeleton'}
                                  style={{ width: 40, height: 20 }}
                            />
                          </div>
                          <div className="product-detail_value">
                            <span className={'link skeleton'}
                                  style={{ width: 40, height: 20 }}
                            />
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">
                            <span className={'link skeleton'}
                                  style={{ width: 55, height: 20 }}
                            />
                          </div>
                          <div className="product-detail_value">
                            <span className={'link skeleton'}
                                  style={{ width: 65, height: 20 }}
                            />
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">
                            <span className={'link skeleton'}
                                  style={{ width: 45, height: 20 }}
                            />
                          </div>
                          <div className="product-detail_value">
                            <span className={'link skeleton'}
                                  style={{ width: 60, height: 20 }}
                            />
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">
                            <span className={'link skeleton'}
                                  style={{ width: 65, height: 20 }}
                            />
                          </div>
                          <div className="product-detail_value">
                            <span className={'link skeleton'}
                                  style={{ width: 50, height: 20 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={'product-btn-wrapper'}>
                        <span className={'link skeleton'}
                              style={{ width: 32, height: 32 }}
                        />
                        <span className={'link skeleton'}
                              style={{ width: 100, height: 32, flex: 1 }}
                        />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            :
            <div className="products-details-table-body product-database-table-body">
              <div className="skeleton-header">
                <div className={'cell cell-1'} />
                <div className={'cell cell-2'}>
                  <span className={'link skeleton'}
                    style={{ width: 51, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16 }}
                  />
                </div>
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-3'}>
                      <span className={'link skeleton'}
                            style={{ width: 31, height: 20 }}
                      />
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                    </div>
                }
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-4'}>
              <span className={'link skeleton'}
                    style={{ width: 33, height: 20 }}
              />
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                    </div>
                }
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-5'}>
              <span className={'link skeleton'}
                    style={{ width: 57, height: 20 }}
              />
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                    </div>
                }
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-6'}>
              <span className={'link skeleton'}
                    style={{ width: 83, height: 20 }}
              />
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                    </div>
                }
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-7'}>
              <span className={'link skeleton'}
                    style={{ width: 95, height: 20 }}
              />
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                    </div>
                }
                {
                  isMobile ?
                    null
                    :
                    <div className={'cell cell-8'} />
                }
              </div>
              {
                Array.from({ length: 13 }, (v, i) => i).map(el => (
                  <div className="skeleton-row"
                       key={el}
                  >
                    <div className={'cell cell-1'}>
                      <span className={'link skeleton'}
                            style={{ width: 20, height: 20 }}
                      />
                    </div>
                    <div className={'cell cell-2'}>
                      <span className={'link skeleton'}
                            style={{ width: 40, height: 40 }}
                      />
                      <span className={'link skeleton'}
                            style={{ width: utils.randomNum(100, 230), height: 20 }}
                      />
                    </div>
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-3'}>
                      <span className={'link skeleton'}
                            style={{ width: 40, height: 20 }}
                      />
                        </div>
                    }
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-4'}>
                      <span className={'link skeleton'}
                            style={{ width: 49, height: 20 }}
                      />
                        </div>
                    }
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-5'}>
                          <span className={'link skeleton'}
                                style={{ width: 40, height: 20 }}
                          />
                        </div>
                    }
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-6'}>
                      <span className={'link skeleton'}
                            style={{ width: 51, height: 20 }}
                      />
                        </div>
                    }
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-7'}>
                      <span className={'link skeleton'}
                            style={{ width: 88, height: 20 }}
                      />
                        </div>
                    }
                    {
                      isMobile ?
                        null
                        :
                        <div className={'cell cell-8'}>
                      <span className={'link skeleton'}
                            style={{ width: 16, height: 16 }}
                      />
                        </div>
                    }
                  </div>
                ))
              }
            </div>
        }
      </div>
    </div>
  );
};

export default Skeleton;
