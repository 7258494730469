import React from 'react';

const PaymentAdded = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.8333 17.5C11.2936 17.5 11.6667 17.1269 11.6667 16.6667C11.6667 16.2064 11.2936 15.8333 10.8333 15.8333C10.3731 15.8333 10 16.2064 10 16.6667C10 17.1269 10.3731 17.5 10.8333 17.5Z"
        fill="#16895A" />
      <path
        d="M17.5 9.16665C17.5 8.70642 17.1269 8.33332 16.6667 8.33332C16.2064 8.33332 15.8333 8.70642 15.8333 9.16665C15.8333 9.62689 16.2064 9.99999 16.6667 9.99999C17.1269 9.99999 17.5 9.62689 17.5 9.16665Z"
        fill="#16895A" />
      <path
        d="M16.6079 11.89C17.0065 12.1201 17.143 12.6297 16.9129 13.0283C16.6828 13.4269 16.1731 13.5634 15.7746 13.3333C15.376 13.1032 15.2394 12.5935 15.4695 12.195C15.6997 11.7964 16.2093 11.6598 16.6079 11.89Z"
        fill="#16895A" />
      <path
        d="M14.473 16.0785C14.8716 15.8484 15.0081 15.3387 14.778 14.9401C14.5479 14.5416 14.0382 14.405 13.6397 14.6351C13.2411 14.8652 13.1045 15.3749 13.3347 15.7735C13.5648 16.172 14.0744 16.3086 14.473 16.0785Z"
        fill="#16895A" />
      <path
        d="M15.7725 6.66665C15.3739 6.89677 14.8642 6.76021 14.6341 6.36163C14.404 5.96305 14.5405 5.45339 14.9391 5.22328C15.3377 4.99316 15.8474 5.12972 16.0775 5.5283C16.3076 5.92687 16.171 6.43653 15.7725 6.66665Z"
        fill="#16895A" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 12.3975 5.10018 14.481 7.08333 15.5293V12.2917C7.08333 11.9465 7.36316 11.6667 7.70833 11.6667C8.05351 11.6667 8.33333 11.9465 8.33333 12.2917V16.875C8.33333 17.2202 8.05351 17.5 7.70833 17.5H3.125C2.77982 17.5 2.5 17.2202 2.5 16.875C2.5 16.5298 2.77982 16.25 3.125 16.25H5.85301C3.83253 14.9067 2.5 12.6095 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C11.0477 2.5 12.0467 2.71519 12.9541 3.10437C13.2713 3.24044 13.4182 3.6079 13.2821 3.92513C13.1461 4.24236 12.7786 4.38922 12.4614 4.25316C11.7068 3.92952 10.8752 3.75 10 3.75Z"
            fill="#16895A" />
    </svg>
  );
};

export default PaymentAdded;
