import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OnboardingActions from '../../Redux/OnboardingRedux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { openNotificationWithIcon } from '../Notification';
import { ArrowSelectDown } from '../../Icon/img';
import { Divider } from 'antd';
import EditCompanyModal from './EditCompanyModal';

import { DefaultMsg } from '../Notification/notification-message';
import cls from 'classname';

const ComDetails = (
  {
    userInfo,
    isMobile,
    onBoarding,
    saveFetching,
    saveResult,
    errors,
    getCountries,
    addCompanyDetails,
    changeCompanyDetails,
    resetErrors,
    resetResult,
  }
) => {

  const {t} = useTranslation();

  const stats = userInfo?.company?.country?.name === 'United States' ?
    isMobile ?
      [
        {
          label: 'Company name',
          key: ['name'],
        },
        {
          label: 'Country',
          key: ['country', 'name'],
        },
        {
          label: 'City',
          key: ['city'],
        },
        {
          label: 'State',
          key: ['state'],
        },
        {
          label: 'Postal code',
          key: ['postal_code'],
        },
        {
          label: 'VAT',
          key: ['VAT'],
        },
        {
          label: 'Address line 1',
          key: ['address_line_1'],
        },
        {
          label: 'Address line 2',
          key: ['address_line_2'],
        },
      ]
      :
      [
        {
          label: 'Company name',
          key: ['name'],
        },
        {
          label: 'Country',
          key: ['country', 'name'],
        },
        {
          label: 'City',
          key: ['city'],
        },
        {
          label: 'State',
          key: ['state'],
        },
        {
          label: 'Postal code',
          key: ['postal_code'],
        },
        {
          label: 'Address line 1',
          key: ['address_line_1'],
        },
        {
          label: 'Address line 2',
          key: ['address_line_2'],
        },
        {
          label: 'VAT',
          key: ['VAT'],
        },
      ]
    :
    isMobile ?
      [
        {
          label: 'Company name',
          key: ['name'],
        },
        {
          label: 'Country',
          key: ['country', 'name'],
        },
        {
          label: 'City',
          key: ['city'],
        },
        {
          label: 'Postal code',
          key: ['postal_code'],
        },
        {
          label: 'VAT',
          key: ['VAT'],
        },
        {
          label: 'Address line 1',
          key: ['address_line_1'],
        },
        {
          label: 'Address line 2',
          key: ['address_line_2'],
        },
      ]
      :
      [
        {
          label: 'Company name',
          key: ['name'],
        },
        {
          label: 'Country',
          key: ['country', 'name'],
        },
        {
          label: 'City',
          key: ['city'],
        },
        {
          label: 'Postal code',
          key: ['postal_code'],
        },
        {
          label: 'Address line 1',
          key: ['address_line_1'],
        },
        {
          label: 'Address line 2',
          key: ['address_line_2'],
        },
        {
          label: 'VAT',
          key: ['VAT'],
        },
      ]

  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (errors?.add_company_details || errors?.change_company_details) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'Something went wrong, kindly try again.'}
                      icon="notification_warning"
                      title={'Something went wrong'}
          />
        ),
      });
      resetErrors();
    }
    if (saveResult?.add_company_details || saveResult?.change_company_details) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={null}
                      icon="notification_success"
                      title={'Company details were successfully added'}
          />
        ),
      });
      resetResult();
    }
    if(modal) setModal(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className='billing-subpage-block-title'>
          {t('Company details')}
        </span>
        <span className='billing-subpage-block-link'
              onClick={() => setModal('edit_details')}
        >
          {
            t(userInfo?.company ?
              'Edit company details'
              :
              'Add company details'
            )}
          <ArrowSelectDown />
        </span>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div className="billing-subpage-block-address-wrapper">
        {
          stats.map((el) => (
            <div className={cls("item", {
              wide: el?.label?.includes?.('Address') && isMobile
            })}>
              <span className="title">
                {t(el?.label)}
              </span>
              <span className="value">
              {
                el?.key?.length > 1 ?
                  userInfo?.company?.[el?.key[0]]?.[el?.key?.[1]] || '-'
                  :
                  userInfo?.company?.[el?.key[0]] || '-'
              }
              </span>
            </div>
          ))
        }
      </div>
      <EditCompanyModal onBoarding={onBoarding}
                        details={userInfo.company || {}}
                        visible={modal === 'edit_details'}
                        setModal={setModal}
                        isMobile={isMobile}
                        loading={saveFetching}
                        isAdd={!Boolean(userInfo?.company)}
                        getCountries={getCountries}
                        changeCompanyDetails={!Boolean(userInfo?.company) ? addCompanyDetails : changeCompanyDetails}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  onBoarding: state?.onBoarding,
  saveFetching: state.setting.saveFetching,
  saveResult: state.setting.saveResult,
  errors: state.setting.errors,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  addCompanyDetails: (data) => dispatch(SettingCreators.addCompanyDetailsRequest(data)),
  changeCompanyDetails: (data) => dispatch(SettingCreators.changeCompanyDetailsRequest(data)),
  resetErrors: () => dispatch(SettingCreators.settingsErrorsReset()),
  resetResult: () => dispatch(SettingCreators.settingsSaveResultReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComDetails);
