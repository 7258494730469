import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../Components/Modal';
import { Divider, Spin } from 'antd';
import ButtonComponent from '../Button';

const DisconnectSocialModal = (
  {
    isMobile,
    setModal,
    visible,
    socialType,
    disconnectAccount
  }
) => {

  const {t} = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t(`Disconnect ${socialType?.charAt(0)?.toUpperCase() + socialType?.slice(1)} account`)}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t(`Your ${socialType?.charAt(0)?.toUpperCase() + socialType?.slice(1)} account has been integrated for quicker sign-in to Dropship. You can disconnect it from your account at any time and reconnect it later.`)}
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => setModal(null)}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={false}
                           onClick={() => disconnectAccount(socialType)}
                           text={t('Disconnect')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default DisconnectSocialModal;
