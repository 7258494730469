import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyState = ({ searchValue }) => {

  const {t} = useTranslation();

  return (
    <div className="collection-modal-list-empty">
      <div className={'empty-state-product-skeleton'}>
        <span className={'link skeleton'}
              style={{ width: 12, height: 12 }}
        />
        <div>
          {
            [1,2,3,4].map(el => (
              <div key={el}
                   style={{ zIndex: el, marginLeft: el === 1 ? 0 : '-8px', border: 'none', display: 'flex', alignItems: 'center' }}
                   className={'empty-state-product-skeleton-wrapper'}
              >
                <span className={'link skeleton'}
                      style={{ width: 20, height: 20 }}
                />
              </div>
            ))
          }
        </div>
        <span className={'link skeleton'}
              style={{ width: 77, height: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 48, height: 8 }}
        />
      </div>
      <h3>
        {
          t('No presets found')
        }
      </h3>
      <p>
        {
          !searchValue ?
            t("You don't currently have any presets, create your own by doing your first search with filters.")
            :
            t("Try changing your search requirements, try again or create new preset.")
        }
      </p>
    </div>
  );
};

export default EmptyState;
