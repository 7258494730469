import React from 'react';

const DatabaseVariants = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.75135 16.8783V11.6679M4.75135 8.54166V3.12256M10.0002 16.6699V10.6254M10.0002 7.49929V3.33089M15.249 16.878V13.335M15.249 10.209V3.12256M3.12207 11.4596H6.45851M8.33351 7.70752H11.6668M13.5418 13.1266H16.8752"
      stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default DatabaseVariants;
