import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import './StepTitle.less';

const StepTitle = ({ stepInfo }) => {

  const { title, text } = stepInfo;

  const isMobile = useSelector(state => state?.nav?.isMobile);


  return (
    <div className="step-title">
      <h3 className="title">
        {title}
      </h3>
      {
        isMobile ?
          null
          :
          <Divider type={'vertical'}
                   style={{ margin: '0 16px' }}
          />
      }
      <span className="text">
        {text}
      </span>
    </div>
  );
};

export default StepTitle;
