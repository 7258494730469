import React from 'react';

const Sun = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={'sun'}
    >
      <g clipPath="url(#clip0_1708_12922)">
        <path stroke="#434C69"
              d="M10.0001 2.74234V1.47296M4.86818 15.1322L3.97059 16.0298M10.0001 18.5272V17.2578M16.0294 3.97055L15.1318 4.86813M17.2575 10.0001H18.5269M15.1318 15.1321L16.0294 16.0297M1.47299 10.0001H2.74237M3.97053 3.9705L4.86811 4.86808M13.0936 6.90649C14.8021 8.61503 14.8021 11.3851 13.0936 13.0937C11.3851 14.8022 8.61496 14.8022 6.90642 13.0937C5.19787 11.3851 5.19787 8.61503 6.90642 6.90649C8.61496 5.19795 11.3851 5.19795 13.0936 6.90649Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1708_12922">
          <rect width="20"
                height="20"
                fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sun;
