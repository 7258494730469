import React from 'react'

const PlayVideoBtn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="60" viewBox="0 0 50 60" fill="none">
    <g filter="url(#filter0_ddddd_3063_63758)">
      <path
        d="M36.0965 28.7206C37.4037 27.9459 37.4037 26.0541 36.0965 25.2794L15.0196 12.7894C13.6864 11.9993 12 12.9603 12 14.51V39.49C12 41.0397 13.6864 42.0007 15.0196 41.2106L36.0965 28.7206Z"
        fill="white" />
    </g>
    <defs>
      <filter id="filter0_ddddd_3063_63758" x="0" y="0.506836" width="49.0762" height="58.9863"
              filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feMorphology radius="0.5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3063_63758" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3063_63758" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect2_dropShadow_3063_63758" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_3063_63758" result="effect2_dropShadow_3063_63758" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feMorphology radius="3" operator="erode" in="SourceAlpha" result="effect3_dropShadow_3063_63758" />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_3063_63758" result="effect3_dropShadow_3063_63758" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feMorphology radius="6" operator="erode" in="SourceAlpha" result="effect4_dropShadow_3063_63758" />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_3063_63758" result="effect4_dropShadow_3063_63758" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha" />
        <feMorphology radius="12" operator="erode" in="SourceAlpha" result="effect5_dropShadow_3063_63758" />
        <feOffset />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.24 0" />
        <feBlend mode="normal" in2="effect4_dropShadow_3063_63758" result="effect5_dropShadow_3063_63758" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect5_dropShadow_3063_63758" result="shape" />
      </filter>
    </defs>
  </svg>

)

export default PlayVideoBtn
