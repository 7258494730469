import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Warning } from '../../Icon/img';
import ButtonComponent from "../Button";
import {useTranslation} from "react-i18next";
import './styles.less';

export const BannerLimited = (
  {
    className,
    text,
    btnText,
    onClick,
    skeleton,
    isTrial,
    isAdSpot=false,
    isCredits = false
  }) => {

  const {t} = useTranslation();

  if (skeleton) return <div className='limited-banner skeleton' style={{borderRadius: 0}} />
  return (
    <div className={cls(className, 'limited-banner')}>
      <Warning color={'#F6B60E'}/>
      <span className='text'>{text}</span>
      {isTrial ?
        isAdSpot ?
          <span className={'text trial-text'}>{t('Free trial users can only perform 5 searches per day')}</span>
          :
          <span className={'text trial-text'}>{t('Free trial users can only perform 20 searches per day')}</span>
        :
        null
      }
      {
        isCredits ?
          null
          :
          <ButtonComponent type={'primary'}
                           className={cls('btn-primary white', {
                             'trial-banner-button': isTrial
                           })}
                           onClick={onClick}
          >
            {btnText}
          </ButtonComponent>
      }
    </div>
  )
}

BannerLimited.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  skeleton: PropTypes.bool,
};

export default BannerLimited;
