import React from 'react';

const UniversityWatched = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path stroke="#16895A"
            d="M12.4993 7.91675L8.74935 12.5001L7.08268 10.8334M17.7077 10.0001C17.7077 14.2573 14.2565 17.7084 9.99935 17.7084C5.74215 17.7084 2.29102 14.2573 2.29102 10.0001C2.29102 5.74289 5.74215 2.29175 9.99935 2.29175C14.2565 2.29175 17.7077 5.74289 17.7077 10.0001Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default UniversityWatched;
