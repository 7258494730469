import React from 'react';
import { useSelector } from 'react-redux';

const ShopifyConnectNewAi = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <g clipPath="url(#clip0_769_49097)">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M1.45833 7.49992C5.65393 7.49992 7.49999 5.65385 7.49999 1.45825C7.49999 5.65385 9.34606 7.49992 13.5417 7.49992C9.34606 7.49992 7.49999 9.34598 7.49999 13.5416C7.49999 9.34598 5.65393 7.49992 1.45833 7.49992Z"
              stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M10.625 14.5833C13.3738 14.5833 14.5833 13.3738 14.5833 10.6249C14.5833 13.3738 15.7928 14.5833 18.5417 14.5833C15.7928 14.5833 14.5833 15.7927 14.5833 18.5416C14.5833 15.7927 13.3738 14.5833 10.625 14.5833Z"
              stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_769_49097">
          <rect width="20"
                height="20"
                fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShopifyConnectNewAi;
