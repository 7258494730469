import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import _ from 'lodash';
import cls from 'classname';
import EmptyState from './empty-state';
import { changeColumns } from './columns';
import './styles-details.less';

const ProductDetails = (
  {
    isMobile,
    products,
    loading,
    next,
    getNextList,
    onSort,
    isShowSideBar,
    searchText,
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    onOpenUpgradePlan,
    setInputText,
    breadcrumbs,
    isCollections=false
  }) => {

  const tableRef = useRef();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  const path =
    isAdmin && !isAdminEdit ?
      '/admin/portfolio'
      :
      breadCrumbs?.filter((el) => !!el.link)[0].link || '/portfolio';

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [searchText]);

  const dataSource = products?.length ?
    products?.map((u) => ({ ...u, key: u.product_id }))
    :
    [];

  return (
    <div className={cls('products-details-table-body product-database-table-body', 'products', {
      'details-close': isShowSideBar,
      'products-details--empty': dataSource.length === 0,
    })}
    >
      {
        dataSource.length ? (
            <Table components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table {...other}
                       ref={tableRef}
                       className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
                   className="list-table"
                   loading={loading}
                   rowClassName={(record) => record['is_locked'] ? 'details-row details-row_locked' : 'details-row'}
                   dataSource={dataSource}
                   columns={changeColumns()({
                     addToCollectionFunc,
                     isAdmin,
                     isAdminEdit,
                     t,
                     onOpenUpgradePlan,
                     isMobile
                   })}
                   pagination={false}
                   scroll={{ y: `calc(${isMobile ? '100dvh - 68px - 186px' : '100dvh - 164px - 57px'} - ${isCollections ? '80px' : '0px'})`, x: 1700 }}
                   sticky={{
                     offsetHeader: 0,
                     offsetScroll: 0,
                     getContainer: () => document.querySelector('.product-database-table-body')
                   }}
                   sortDirections={['descend', 'ascend', null]}
                   onChange={(pagination, filters, sorter) => {
                     onSort(sorter);
                   }}
                   onRow={(record, rowIndex) => {
                     return {
                       onClick: event => {
                         if (![...event.target.classList].includes('bookmark') && !record['is_locked']) {
                           navigate(`${isAdminEdit ? '/admin' : ''}/portfolio/product/${record.product_id}`);
                         }
                       }, // click row
                     };
                   }}
            />
          )
          :
          (
            <EmptyState setInputText={(value) => searchText?.length ? setInputText(value) : navigate(path)}
                        btnText={searchText?.length ? 'Clear search' : 'Go back'}
                        isCollections={isCollections}
            />
          )
      }
    </div>
  );
};

export default React.memo(ProductDetails, (prev, next) => {
  return _.isEqual(prev.products, next?.products) && prev?.loading === next?.loading
});
