import React from 'react';

const Visa  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_3385_18141)">
        <mask id="mask0_3385_18141" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="28"
              height="28">
          <path d="M0.671875 0.667969H27.3385V27.3346H0.671875V0.667969Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3385_18141)">
          <mask id="mask1_3385_18141" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="28"
                height="28">
            <path d="M0.667969 0.667969H27.3346V27.3346H0.667969V0.667969Z" fill="white" />
          </mask>
          <g mask="url(#mask1_3385_18141)">
            <path d="M0.667969 0.667969H27.3346V27.3346H0.667969V0.667969Z" fill="#1C33C3" />
            <path
              d="M16.851 8.07422L13.771 16.0313L12.488 9.2594C12.3162 8.49792 11.7177 8.07422 11.1191 8.07422H6.68358L6.59766 8.41348C7.62432 8.66681 8.39618 8.92014 9.08062 9.2594C9.29395 9.36607 9.47173 9.5557 9.57988 10.3601L11.8036 19.9261H14.968L19.931 8.07422H16.851Z"
              fill="url(#paint0_linear_3385_18141)" />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_3385_18141" x1="114.291" y1="161.413" x2="116.599" y2="68.7883"
                        gradientUnits="userSpaceOnUse">
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_3385_18141">
          <rect x="0.664062" y="0.666016" width="26.6667" height="26.6667" rx="5.33333" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Visa;
