import React, { useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ImagesComponent from './images';
import ActionsComponent from './actions';
import acc from 'accounting';
import CollectionsActions from '../../reducer';
import { useDispatch } from 'react-redux';
import './styles.less';

const CollectionItemComponent = (
  {
    id,
    general,
    products,
    name,
    products_count,
    collection_statistic,
    setAsDefaultFunc,
    setModal,
    ...rest
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  // ON CLICK TAB HANDLER
  const onTabClickHandler = (index) => {
    setActiveTab(index);
  };

  function handleResend({ collectionID, collectionName }) {
    navigate(`/collections/product-list/${collectionID}`, {
      state: {
        name: collectionName,
        link: `/collections/product-list/${collectionID}`,
      },
    });
    dispatch(CollectionsActions.saveCollection({ collectionID, collectionName }));
  }

  return (
    <div className={'collections-page-list-item'}
         key={`collection-item-${id}`}
    >
      <ImagesComponent id={id}
                       products={products}
                       handleResend={handleResend}
                       name={name}
      />
      <div className="collections-page-list-item-content-wrapper">
        <ActionsComponent isDefault={rest?.default}
                          isGeneral={general}
                          handleResend={handleResend}
                          saveCollection={({collectionID, collectionName, collectionCount}) => dispatch(CollectionsActions.saveCollection({collectionID, collectionName, collectionCount}))}
                          collection={{id, name, count: products_count}}
                          setModal={setModal}
                          setAsDefaultFunc={setAsDefaultFunc}
        />
        <div className={'collections-page-list-item-tabs'}
        >
          {
            collection_statistic.map((tab, keyIndex) =>
              (
                <div className={cls('collections-page-list-item-tab', {
                  'active': activeTab === keyIndex && products.length,
                })}
                     onClick={() => onTabClickHandler(keyIndex)}
                     key={`collection-tab-${keyIndex}`}
                >
                  {tab.name}
                </div>
              ))}
        </div>
        <div className="collections-page-list-item-tab-value-wrapper">
          <div className={'collections-page-list-item-tab-value-item'}>
            <span className="title">
              {
                t('Average')
              }
            </span>
            <span className="value">
              ${acc.formatNumber(collection_statistic[activeTab]?.average || 0, 2, ',', '.')}
            </span>
          </div>
          <div className={'collections-page-list-item-tab-value-item'}>
            <span className="title">
              {
                t('Lowest')
              }
            </span>
            <span className="value">
              ${acc.formatNumber(collection_statistic[activeTab]?.minimum || 0, 2, ',', '.')}
            </span>
          </div>
          <div className={'collections-page-list-item-tab-value-item'}>
            <span className="title">
              {
                t('Highest')
              }
            </span>
            <span className="value">
              ${acc.formatNumber(collection_statistic[activeTab]?.maximum || 0, 2, ',', '.')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionItemComponent;
