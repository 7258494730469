import React from 'react';
import cls from 'classname';

const Logout = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
       className={cls(className, 'icon icon-logout')}
  >
    <path stroke="#D71313"
          d="M16.875 9.99999L7.5 9.99999M16.875 9.99999L13.125 13.75M16.875 9.99999L13.125 6.25M9.375 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083L3.125 4.79167C3.125 3.87119 3.87119 3.125 4.79167 3.125L9.375 3.125"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
);

export default Logout;
