import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import { DefaultMsg } from '../../../Components/Notification/notification-message';

const key = `open${Date.now()}`;
export const openNotification = ({ text }) => {
  openNotificationWithIcon({
    key,
    style: { width: '400px' },
    className: 'notification notification_rename',
    message: (
      <DefaultMsg text={null}
                  icon="notification_success"
                  title={'Collection was successfully renamed'}
      />
    ),
  });
};

export default openNotification;
