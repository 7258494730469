import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Pln = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-pln icon-pln-outline')}
      >
        <path fill={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M16.0416 17.7083L15.5491 18.2739C15.7707 18.467 16.0848 18.5127 16.3523 18.391C16.6199 18.2692 16.7916 18.0023 16.7916 17.7083H16.0416ZM13.8889 15.8333L14.3815 15.2678C14.0882 15.0123 13.6482 15.0235 13.3683 15.2935L13.8889 15.8333ZM11.9444 17.7083L11.4238 18.2482C11.7144 18.5284 12.1745 18.5284 12.465 18.2482L11.9444 17.7083ZM9.99998 15.8333L10.5206 15.2935C10.23 15.0133 9.76991 15.0133 9.47938 15.2935L9.99998 15.8333ZM8.05553 17.7083L7.53493 18.2482C7.82547 18.5284 8.2856 18.5284 8.57614 18.2482L8.05553 17.7083ZM6.11109 15.8333L6.63169 15.2935C6.35171 15.0235 5.9118 15.0123 5.61851 15.2678L6.11109 15.8333ZM3.95831 17.7083H3.20831C3.20831 18.0023 3.38006 18.2692 3.64762 18.391C3.91518 18.5127 4.22922 18.467 4.4509 18.2739L3.95831 17.7083ZM13.2484 6.98867C13.5413 6.69578 13.5413 6.2209 13.2484 5.92801C12.9555 5.63512 12.4806 5.63512 12.1877 5.92801L13.2484 6.98867ZM6.77108 11.3447C6.47819 11.6376 6.47819 12.1124 6.77108 12.4053C7.06398 12.6982 7.53885 12.6982 7.83174 12.4053L6.77108 11.3447ZM5.62498 3.04167H14.375V1.54167H5.62498V3.04167ZM15.2916 3.95834V17.7083H16.7916V3.95834H15.2916ZM16.5342 17.1428L14.3815 15.2678L13.3963 16.3989L15.5491 18.2739L16.5342 17.1428ZM13.3683 15.2935L11.4238 17.1685L12.465 18.2482L14.4095 16.3732L13.3683 15.2935ZM12.465 17.1685L10.5206 15.2935L9.47938 16.3732L11.4238 18.2482L12.465 17.1685ZM9.47938 15.2935L7.53493 17.1685L8.57614 18.2482L10.5206 16.3732L9.47938 15.2935ZM8.57614 17.1685L6.63169 15.2935L5.59049 16.3732L7.53493 18.2482L8.57614 17.1685ZM5.61851 15.2678L3.46573 17.1428L4.4509 18.2739L6.60368 16.3989L5.61851 15.2678ZM4.70831 17.7083V3.95834H3.20831V17.7083H4.70831ZM14.375 3.04167C14.8812 3.04167 15.2916 3.45208 15.2916 3.95834H16.7916C16.7916 2.62365 15.7097 1.54167 14.375 1.54167V3.04167ZM5.62498 1.54167C4.29029 1.54167 3.20831 2.62365 3.20831 3.95834H4.70831C4.70831 3.45208 5.11872 3.04167 5.62498 3.04167V1.54167ZM12.1877 5.92801L6.77108 11.3447L7.83174 12.4053L13.2484 6.98867L12.1877 5.92801Z"
        />
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M7.50002 6.66666V6.65833M7.70835 6.66666C7.70835 6.78172 7.61508 6.87499 7.50002 6.87499C7.38496 6.87499 7.29169 6.78172 7.29169 6.66666C7.29169 6.5516 7.38496 6.45833 7.50002 6.45833C7.61508 6.45833 7.70835 6.5516 7.70835 6.66666Z"
              strokeWidth="1.5"
              strokeLinecap="round"
        />
        <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
              d="M12.5 11.6667V11.6583M12.7084 11.6667C12.7084 11.7817 12.6151 11.875 12.5 11.875C12.385 11.875 12.2917 11.7817 12.2917 11.6667C12.2917 11.5516 12.385 11.4583 12.5 11.4583C12.6151 11.4583 12.7084 11.5516 12.7084 11.6667Z"
              strokeWidth="1.5" strokeLinecap="round"
        />
      </svg>
    )
    :
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-pln icon-pln-bold')}
    >
      <path fill={theme === 'light' ? '#225AEA' : 'white'}
            d="M16.0416 17.7083L15.5491 18.2739C15.7707 18.467 16.0848 18.5127 16.3523 18.391C16.6199 18.2692 16.7916 18.0023 16.7916 17.7083H16.0416ZM13.8889 15.8333L14.3815 15.2678C14.0882 15.0123 13.6482 15.0235 13.3683 15.2935L13.8889 15.8333ZM11.9444 17.7083L11.4238 18.2482C11.7144 18.5284 12.1745 18.5284 12.465 18.2482L11.9444 17.7083ZM9.99998 15.8333L10.5206 15.2935C10.23 15.0133 9.76991 15.0133 9.47938 15.2935L9.99998 15.8333ZM8.05553 17.7083L7.53493 18.2482C7.82547 18.5284 8.2856 18.5284 8.57614 18.2482L8.05553 17.7083ZM6.11109 15.8333L6.63169 15.2935C6.35171 15.0235 5.9118 15.0123 5.61851 15.2678L6.11109 15.8333ZM3.95831 17.7083H3.20831C3.20831 18.0023 3.38006 18.2692 3.64762 18.391C3.91518 18.5127 4.22922 18.467 4.4509 18.2739L3.95831 17.7083ZM13.2484 6.98867C13.5413 6.69578 13.5413 6.2209 13.2484 5.92801C12.9555 5.63512 12.4806 5.63512 12.1877 5.92801L13.2484 6.98867ZM6.77108 11.3447C6.47819 11.6376 6.47819 12.1124 6.77108 12.4053C7.06398 12.6982 7.53885 12.6982 7.83174 12.4053L6.77108 11.3447ZM5.62498 3.04167H14.375V1.54167H5.62498V3.04167ZM15.2916 3.95834V17.7083H16.7916V3.95834H15.2916ZM16.5342 17.1428L14.3815 15.2678L13.3963 16.3989L15.5491 18.2739L16.5342 17.1428ZM13.3683 15.2935L11.4238 17.1685L12.465 18.2482L14.4095 16.3732L13.3683 15.2935ZM12.465 17.1685L10.5206 15.2935L9.47938 16.3732L11.4238 18.2482L12.465 17.1685ZM9.47938 15.2935L7.53493 17.1685L8.57614 18.2482L10.5206 16.3732L9.47938 15.2935ZM8.57614 17.1685L6.63169 15.2935L5.59049 16.3732L7.53493 18.2482L8.57614 17.1685ZM5.61851 15.2678L3.46573 17.1428L4.4509 18.2739L6.60368 16.3989L5.61851 15.2678ZM4.70831 17.7083V3.95834H3.20831V17.7083H4.70831ZM14.375 3.04167C14.8812 3.04167 15.2916 3.45208 15.2916 3.95834H16.7916C16.7916 2.62365 15.7097 1.54167 14.375 1.54167V3.04167ZM5.62498 1.54167C4.29029 1.54167 3.20831 2.62365 3.20831 3.95834H4.70831C4.70831 3.45208 5.11872 3.04167 5.62498 3.04167V1.54167ZM12.1877 5.92801L6.77108 11.3447L7.83174 12.4053L13.2484 6.98867L12.1877 5.92801Z"
      />
      <path stroke={theme === 'light' ? '#225AEA' : 'white'}
            d="M7.50002 6.66666V6.65833M7.70835 6.66666C7.70835 6.78172 7.61508 6.87499 7.50002 6.87499C7.38496 6.87499 7.29169 6.78172 7.29169 6.66666C7.29169 6.5516 7.38496 6.45833 7.50002 6.45833C7.61508 6.45833 7.70835 6.5516 7.70835 6.66666Z"
            strokeWidth="1.5"
            strokeLinecap="round"
      />
      <path stroke={theme === 'light' ? '#225AEA' : 'white'}
            d="M12.5 11.6667V11.6583M12.7084 11.6667C12.7084 11.7817 12.6151 11.875 12.5 11.875C12.385 11.875 12.2917 11.7817 12.2917 11.6667C12.2917 11.5516 12.385 11.4583 12.5 11.4583C12.6151 11.4583 12.7084 11.5516 12.7084 11.6667Z"
            strokeWidth="1.5" strokeLinecap="round"
      />
    </svg>;
};

export default Pln;
