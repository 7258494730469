import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../Components/Modal';
import { Divider, Spin, Statistic } from 'antd';
import ButtonComponent from '../Button';

const { Countdown } = Statistic;

const ResendBlock = (
  {
    changeEmails,
    email,
    password,
    t
  }
) => {

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  function handleResend() {
    changeEmails({ password: password, new_email: email });
    handleResetTime();
  }

  useEffect(() => {
    handleResetTime();
  }, []);

  function handleResetTime() {
    setDeadline(Date.now() + 1000 * 30);
    setResendFetching(true);
  }
  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return resendFetching ? (
    <div className="resend-timer">
      <Countdown title=""
                 value={deadline}
                 onFinish={onFinish}
                 format="ss"
                 prefix={<span>{t('You will be able to resend code 0:')} </span>}
                 suffix={null}
      />
    </div>
    )
    :
    (
      <span className='resend-text' onClick={handleResend}>
        {t('Didn’t receive code? Resend')}
      </span>
    )
}

const CheckEmailModal = (
  {
    setModal,
    isMobile,
    visible,
    loading,
    saveConfirm,
    changeEmails
  }
) => {

  const {t} = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={(
             <>
               <svg xmlns="http://www.w3.org/2000/svg" width="44" height="24" viewBox="0 0 44 24" fill="none">
                 <g filter="url(#filter0_dddddd_947_178997)">
                   <path
                     d="M12.1202 6.20855C12.0732 6.40572 12.0469 6.60508 12.0306 6.80497C12 7.17953 12 7.63426 12 8.16138V15.8385C12 16.3657 12 16.8205 12.0306 17.195C12.0629 17.5904 12.1342 17.9836 12.327 18.362C12.6146 18.9265 13.0735 19.3854 13.638 19.673C14.0164 19.8658 14.4096 19.9371 14.805 19.9694C15.1795 20 15.6342 20 16.1613 20H27.8385C28.3656 20 28.8205 20 29.195 19.9694C29.5904 19.9371 29.9836 19.8658 30.362 19.673C30.9265 19.3854 31.3854 18.9265 31.673 18.362C31.8658 17.9836 31.9371 17.5904 31.9694 17.195C32 16.8205 32 16.3657 32 15.8386V8.16144C32 7.6343 32 7.17954 31.9694 6.80497C31.9531 6.60507 31.9268 6.40572 31.8799 6.20855L23.8997 12.7378C22.7946 13.6419 21.2054 13.6419 20.1003 12.7378L12.1202 6.20855Z"
                     fill="#225AEA" />
                   <path
                     d="M30.7406 4.55656C30.6207 4.47119 30.4943 4.39438 30.362 4.32698C29.9836 4.13419 29.5904 4.06287 29.195 4.03057C28.8205 3.99997 28.3657 3.99998 27.8386 4H16.1615C15.6343 3.99998 15.1795 3.99997 14.805 4.03057C14.4096 4.06287 14.0164 4.13419 13.638 4.32698C13.5058 4.39438 13.3793 4.47119 13.2594 4.55656L21.3668 11.1898C21.7351 11.4912 22.2649 11.4912 22.6332 11.1898L30.7406 4.55656Z"
                     fill="#225AEA" />
                 </g>
                 <defs>
                   <filter id="filter0_dddddd_947_178997" x="-2" y="-1" width="48" height="61"
                           filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                     <feFlood floodOpacity="0" result="BackgroundImageFix" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="1" operator="dilate" in="SourceAlpha"
                                   result="effect1_dropShadow_947_178997" />
                     <feOffset />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.133333 0 0 0 0 0.352941 0 0 0 0 0.917647 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_947_178997" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="0.5" operator="erode" in="SourceAlpha"
                                   result="effect2_dropShadow_947_178997" />
                     <feOffset dy="1" />
                     <feGaussianBlur stdDeviation="0.5" />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="effect1_dropShadow_947_178997"
                              result="effect2_dropShadow_947_178997" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="1.5" operator="erode" in="SourceAlpha"
                                   result="effect3_dropShadow_947_178997" />
                     <feOffset dy="3" />
                     <feGaussianBlur stdDeviation="1.5" />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="effect2_dropShadow_947_178997"
                              result="effect3_dropShadow_947_178997" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="3" operator="erode" in="SourceAlpha"
                                   result="effect4_dropShadow_947_178997" />
                     <feOffset dy="6" />
                     <feGaussianBlur stdDeviation="3" />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="effect3_dropShadow_947_178997"
                              result="effect4_dropShadow_947_178997" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="6" operator="erode" in="SourceAlpha"
                                   result="effect5_dropShadow_947_178997" />
                     <feOffset dy="12" />
                     <feGaussianBlur stdDeviation="6" />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="effect4_dropShadow_947_178997"
                              result="effect5_dropShadow_947_178997" />
                     <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                     <feMorphology radius="12" operator="erode" in="SourceAlpha"
                                   result="effect6_dropShadow_947_178997" />
                     <feOffset dy="24" />
                     <feGaussianBlur stdDeviation="12" />
                     <feComposite in2="hardAlpha" operator="out" />
                     <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
                     <feBlend mode="normal" in2="effect5_dropShadow_947_178997"
                              result="effect6_dropShadow_947_178997" />
                     <feBlend mode="normal" in="SourceGraphic" in2="effect6_dropShadow_947_178997" result="shape" />
                   </filter>
                 </defs>
               </svg>
               <span>
                 {t("You’ve got an email")}
               </span>
             </>
           )}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t('Kindly check the email sent to ')} <span className={'bold'}>{saveConfirm?.email}</span>.
            {t(' The new email address will not be active until it is confirmed.')}
          </p>
          <Divider type={'horizontal'}
                   style={{ margin: '16px 0' }}
          />
          <div className={'collection-modal-btn-wrapper'}>
            <ResendBlock changeEmails={changeEmails}
                         email={saveConfirm?.email}
                         password={saveConfirm?.password}
                         t={t}
            />
            <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                             disabled={loading}
                             onClick={() => window.open('https://mail.google.com/mail/u/0/#search/dropship', '_blank')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                <path
                  d="M10.7804 1.32715L7.03843 4.24741L3.21094 1.32715V1.32794L3.21556 1.33188V5.42104L6.99527 8.40444L10.7804 5.53627V1.32715Z"
                  fill="#EA4335" />
                <path
                  d="M11.7639 0.616491L10.7812 1.32683V5.53595L13.8735 3.16185V1.73172C13.8735 1.73172 13.4981 -0.31089 11.7639 0.616491Z"
                  fill="#FBBC05" />
                <path
                  d="M10.7812 5.53556V10.9949H13.1513C13.1513 10.9949 13.8257 10.9254 13.8742 10.1567V3.16147L10.7812 5.53556Z"
                  fill="#34A853" />
                <path d="M3.21574 11.0006V5.42127L3.21094 5.41733L3.21574 11.0006Z" fill="#C5221F" />
                <path
                  d="M3.21258 1.32768L2.23529 0.621292C0.501121 -0.306089 0.125 1.73573 0.125 1.73573V3.16587L3.21258 5.41684V1.32768Z"
                  fill="#C5221F" />
                <path d="M3.21094 1.32877V5.41792L3.21574 5.42187V1.33271L3.21094 1.32877Z" fill="#C5221F" />
                <path
                  d="M0.125 3.16635V10.1616C0.172786 10.9311 0.847954 10.9998 0.847954 10.9998H3.21798L3.21258 5.41653L0.125 3.16635Z"
                  fill="#4285F4" />
              </svg>
              {
                t('Go to Gmail')
              }
            </ButtonComponent>
          </div>
          {
            isMobile ?
              null
              :
              <div style={{ height: 16 }} />
          }
        </div>
      </Spin>
    </Modal>
  );
};

export default CheckEmailModal;
