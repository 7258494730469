import React from 'react';
import {Divider} from "antd";
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import cls from 'classname';

const UniversityCourseProgress = ({info}) => {

  const {t} = useTranslation();

  const {lessons_count, completed_lessons_count, instructor, completed} = info;

  const progressWidth = completed_lessons_count === 0 ? '8px'
    :
    `${(completed_lessons_count * 100)/lessons_count}%`

  return (
    <div className="course-chapter-page-progress-wrapper">
      <div className="course-chapter-page-progress">
        <div className="course-chapter-page-progress-info">
          <div className={cls('course-chapter-page-progress-info-subtitle', {
            completed: completed
          })}>
            <span>
              {
                completed ?
                  <Icon type={'university_watched'}
                        role={'icon'}
                  />
                  :
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       viewBox="0 0 16 16"
                       fill="none"
                  >
                    <path stroke="#661AC6"
                          d="M8.00085 1.8335L8.00065 4.16683M8.00085 11.8335V14.1668M1.83398 8.00063H4.16732M11.834 8.00063H14.1673M3.6403 3.63953L5.29008 5.28959M10.7114 10.7106L12.3613 12.3605M3.64049 12.361L5.2904 10.7111M10.7116 5.28992L12.3615 3.64"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                    />
                  </svg>
              }
              {t('Course progress')}
            </span>
            <span>
              {`${completed_lessons_count}/${lessons_count} ${t('lessons')} `}
            </span>
          </div>
          <div className="course-chapter-page-progress-info-bar">
            <span style={{ width: progressWidth }} />
          </div>
        </div>
      </div>
      <div className="course-chapter-page-instructor">
        <h4 className="course-chapter-page-instructor-title">
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
            <path d="M13.125 5.41675C13.125 7.14264 11.7258 8.54175 9.99996 8.54175C8.27407 8.54175 6.87496 7.14264 6.87496 5.41675C6.87496 3.69086 8.27407 2.29175 9.99996 2.29175C11.7258 2.29175 13.125 3.69086 13.125 5.41675Z"
              stroke="#A1AAC8"
              strokeWidth="1.5"
                  strokeLinejoin="round"
            />
            <path d="M9.99996 11.0417C7.174 11.0417 5.02995 12.7204 4.15202 15.0942C3.81135 16.0153 4.59856 16.8751 5.58065 16.8751H14.4193C15.4014 16.8751 16.1886 16.0153 15.8479 15.0942C14.97 12.7204 12.8259 11.0417 9.99996 11.0417Z"
              stroke="#A1AAC8"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
            />
          </svg>
          {t('Mentor')}
        </h4>
        <div className="course-chapter-page-instructor-info">
          <div className="course-chapter-page-instructor-image"
               style={{ backgroundImage: `url("${instructor?.avatar}")` }}
          />
          <div className="course-chapter-page-instructor-bio">
            <div className="course-chapter-page-instructor-name">
              {instructor?.name}
            </div>
            <div className="course-chapter-page-instructor-subtitle">
              {instructor?.job_position}
            </div>
          </div>
        </div>
        <div className="course-chapter-page-instructor-text">
          {instructor?.description}
        </div>
      </div>
    </div>
  );
};

UniversityCourseProgress.defaultProps = {
  info: {
    title: 'title',
    thumbnail: null,
    lessons_count: 1,
    completed_lessons_count: 1,
    instructor: {
      avatar: null,
      name: 'name',
      job_position: 'job',
      description: 'description',
    },
  }
}

export default UniversityCourseProgress;
