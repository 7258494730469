import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../../Components/Modal';
import { ButtonComponent } from '../../../../Components/Button';
import { Divider, Spin, Tooltip } from 'antd';
import { OverlayScrollbar } from '../../../../Components/ScrollBar';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import utils, { debounce } from '../../../../Utils/utils';
import SearchComponent from '../../../../Components/Search';
import Icon from '../../../../Icon';
import EmptyState from './EmptyState';

const SaveToModalComponent = (
  {
    setModalVisible,
    isMobile,
    isModalVisible,
    openCreateCollectionModalHandler,
    getNextCollectionFunc,
    addToCollectionFunc,
    collections,
    loading,
  }) => {

  const { t } = useTranslation();
  const scrollRef = useRef();

  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState(collections?.results?.filter(el => el?.default)?.[0]?.id || null);

  const onScrollHandler = debounce((link) => {
    if (scrollRef.current) {
      const { max, position } = scrollRef.current.osInstance().scroll();
      if (position.y >= max.y) {
        getNextCollectionFunc({ link });
        scrollRef.current.osInstance();
      }
    }
  }, 300);

  useEffect(() => {
    setSearchValue('');
  }, [isModalVisible]);

  const handleSearch = debounce(e => {
    setSearchValue(e.target.value);
  }, 300);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModalVisible(false);
           }}
           title={t('Select collection')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={isModalVisible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Choose what collection to save your product to.')
            }
          </p>
          <SearchComponent handleSearch={handleSearch}
                           onKeyUp={null}
                           value={null}
                           onFocusCallback={null}
                           placeholder={t('Search for collection...')}
          />
          {
            collections.results?.filter(el => el?.name.includes(searchValue)).length ?
              <OverlayScrollbar ref={scrollRef}
                                onScroll={() => onScrollHandler(collections.next)}
                                visibility={isMobile && 'hidden'}
              >
                <div className={'collection-modal-list'}>
                  {
                    collections.results?.filter(el => el?.name.includes(searchValue)).map((el) => (
                      <div key={el?.id}
                           className={cls('collection-modal-list-item', {
                             active: selected?.id === el?.id,
                           })}
                      >
                        {
                          el?.default ?
                            <div className={'collection-modal-list-item-fav-icon'}
                                 style={{ cursor: 'default' }}
                            >
                              <Icon type="favorite"
                                    outline={el?.default}
                                    role="icon"
                              />
                            </div>
                            :
                            <Tooltip placement="top"
                                     title={t('Make collection default')}
                                     destroyTooltipOnHide={true}
                            >
                              <div className={'collection-modal-list-item-fav-icon'}
                                   style={{ cursor: 'pointer' }}
                                   id="set-as-default"
                                   onClick={(e) => {
                                     addToCollectionFunc(
                                       {
                                         collectionID: el?.id,
                                         collectionName: el?.name,
                                         event: e,
                                         isCollectionList: true,
                                       });
                                   }}
                              >
                                <Icon type="favorite"
                                      outline={el?.default}
                                      role="icon"
                                />
                              </div>
                            </Tooltip>
                        }
                        <div className={'collection-modal-list-item-content'}
                             onClick={() => setSelected(el)}
                        >
                          <div className={'collection-modal-list-item-content-images'}>
                            {
                              [0, 1, 2, 3].map(image => (
                                <div key={image}
                                     style={{ zIndex: image }}
                                     className={'collection-modal-list-item-content-image'}
                                >
                                  {
                                    el?.products?.[image]?.image ?
                                      <img src={el?.products?.[image]?.image}
                                           onError={utils.addDefaultSrcCollections}
                                      />
                                      :
                                      <Icon type={'empty_collection'}
                                            role={'icon'}
                                      />
                                  }
                                </div>
                              ))
                            }
                          </div>
                          <span className={'collection-modal-list-item-content-title'}>
                        {el?.name}
                      </span>
                          {
                            el?.default ?
                              <span className={'collection-modal-list-item-content-default'}>
                            {t('Default')}
                          </span>
                              :
                              null
                          }
                        </div>
                        <div className={'collection-modal-list-item-content-select-icon'}
                             onClick={() => setSelected(el)}
                        />
                      </div>
                    ))
                  }
                </div>
              </OverlayScrollbar>
              :
              <EmptyState onClick={openCreateCollectionModalHandler} />
          }
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-create"
                           onClick={openCreateCollectionModalHandler}
                           text={t('Create collection')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModalVisible(false);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={!collections.results?.filter(el => el?.name.includes(searchValue))?.length}
                           onClick={(e) => {
                             addToCollectionFunc(
                               {
                                 collectionID: selected?.id,
                                 collectionName: selected?.name,
                                 event: e,
                                 isCollectionList: true,
                               });
                           }}
                           text={t('Select')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default SaveToModalComponent;
