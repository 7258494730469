import React from 'react';
import { Divider, Spin } from 'antd';
import ButtonComponent from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { useTranslation } from 'react-i18next';

const DeletePresetModal = (
  {
    setModal,
    visible,
    deleteFilter,
    record,
    loading,
    isMobile,
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={`Remove "${record?.name}" preset?`}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t("Are you sure you want to remove this preset? This action is irreversible and can't be undone.")
            }
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => setModal('load_preset')}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={false}
                           onClick={() => {
                             deleteFilter(record);
                             setModal('load_preset');
                           }}
                           text={t('Remove')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default DeletePresetModal;
