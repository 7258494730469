import React from 'react';

const GoogleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_1861_173158)">
      <path
        d="M18.8 10.2085C18.8 9.5585 18.7417 8.9335 18.6333 8.3335H10V11.8835H14.9333C14.7167 13.0252 14.0667 13.9918 13.0917 14.6418V16.9502H16.0667C17.8 15.3502 18.8 13.0002 18.8 10.2085Z"
        fill="#4285F4" />
      <path
        d="M9.99974 19.1669C12.4747 19.1669 14.5497 18.3502 16.0664 16.9502L13.0914 14.6419C12.2747 15.1919 11.2331 15.5252 9.99974 15.5252C7.61641 15.5252 5.59141 13.9169 4.86641 11.7502H1.81641V14.1169C3.32474 17.1086 6.41641 19.1669 9.99974 19.1669Z"
        fill="#34A853" />
      <path
        d="M4.86536 11.7418C4.68203 11.1918 4.5737 10.6085 4.5737 10.0002C4.5737 9.39185 4.68203 8.80851 4.86536 8.25851V5.89185H1.81536C1.19036 7.12518 0.832031 8.51685 0.832031 10.0002C0.832031 11.4835 1.19036 12.8752 1.81536 14.1085L4.19036 12.2585L4.86536 11.7418Z"
        fill="#FBBC05" />
      <path
        d="M9.99974 4.48374C11.3497 4.48374 12.5497 4.95041 13.5081 5.85041L16.1331 3.22541C14.5414 1.74207 12.4747 0.83374 9.99974 0.83374C6.41641 0.83374 3.32474 2.89207 1.81641 5.89207L4.86641 8.25874C5.59141 6.09207 7.61641 4.48374 9.99974 4.48374Z"
        fill="#EA4335" />
    </g>
    <defs>
      <clipPath id="clip0_1861_173158">
        <rect width="20" height="20" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
