import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import { Dropdown } from 'antd';
import cls from 'classname';
import './styles.less';

const ThemeSwitcher = (
  {
    theme = 'light',
    changeTheme,
    setDisabledTransition,
    isMobile,
    closeParentDropdown,
    setThemeModal,
    isSettings= false
  }) => {

  const { t } = useTranslation();

  const [activeTheme, setTheme] = useState(theme);
  const [visible, setVisible] = useState(false);

  const onClickHandler = (value) => {
    if (activeTheme !== value) {
      changeTheme(value);
      setTheme(value);
      setDisabledTransition(true);
      setTimeout(() => setDisabledTransition(false), 300);
      setVisible(false);
    }
  };

  const handleVisibleChange = (value) => setVisible(value);

  return (
    isMobile ?
      <div className={cls("theme-switcher", {
        settings: isSettings
      })}
           id="theme-switcher"
           onClick={() => {
             closeParentDropdown(false);
             setThemeModal(true);
           }}
      >
        <div className={'theme-switcher-inner'}>
          <Icon type={activeTheme === 'dark' ? 'moon' : 'sun'}
                role="icon"
          />
          {
            isSettings ?
              null
              :
              <div>
                {t('Theme')}
              </div>
          }
          <span>
            {t(activeTheme === 'dark' ? 'Dark' : 'Light')}
            <ArrowSelectDown />
          </span>
        </div>
      </div>
      :
      <div className={cls("theme-switcher", {
        settings: isSettings
      })}
           id="theme-switcher"
      >
        <Dropdown destroyPopupOnHide={true}
                  getPopupContainer={() => document.getElementById('theme-switcher')}
                  onOpenChange={handleVisibleChange}
                  dropdownRender={() => (
                    <div className={'theme-switcher-dropdown'}>
                      <div className="theme-switcher-dropdown-option"
                           onClick={() => onClickHandler('light')}
                      >
                        <Icon type="sun" role="icon" />
                        <span>
                          {t('Light')}
                        </span>
                        {
                          activeTheme === 'light' ?
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 className={'checkmark'}
                            >
                              <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                    stroke="#225AEA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                              />
                            </svg>
                            :
                            null
                        }
                      </div>
                      <div className="theme-switcher-dropdown-option"
                           onClick={() => onClickHandler('dark')}
                      >
                        <Icon type="moon" role="icon" />
                        <span>
                          {t('Dark')}
                        </span>
                        {
                          activeTheme === 'dark' ?
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 className={'checkmark'}
                            >
                              <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                    stroke="#225AEA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                              />
                            </svg>
                            :
                            null
                        }
                      </div>
                    </div>
                  )}
                  trigger={['click']}
                  open={visible}
        >
          <div className={'theme-switcher-inner'}>
            <Icon type={activeTheme === 'dark' ? 'moon' : 'sun'}
                  role="icon"
            />
            {
              isSettings ?
                null
                :
                <div>
                  {t('Theme')}
                </div>
            }
            <span>
            {t(activeTheme === 'dark' ? 'Dark' : 'Light')}
              <ArrowSelectDown />
          </span>
          </div>
        </Dropdown>
      </div>
  );
};

export default ThemeSwitcher;
