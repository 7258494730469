import React from 'react';
import Icon from '../../../Icon';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../actions';
import { useTranslation } from 'react-i18next';

const ThemeModal = ({ setVisiblePopup }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const nav = useSelector(state => state?.nav);

  return (
    <Modal className={'change-modal custom-modal change-theme-modal'}
           getContainer={() => document.getElementById('global-wrap')}
           {...(nav?.isMobile ? { transitionName: '' } : null)}
           open={nav?.showTheme}
           centered={!nav?.isMobile}
           closeIcon={
             <Icon role="icon" type="close_modal" />
           }
           footer={null}
           closable="true"
           onCancel={() => {
             setVisiblePopup(true);
             dispatch(actions.changeTheme('showTheme', false));
           }}
           destroyOnClose
           width={'100vw'}
    >
      <div className={'custom-modal-content'}>
        <h3 className={'custom-modal-title'}>
          {t('Theme')}
        </h3>
        <p className={'custom-modal-text'}>
          {t('Select a theme for your software interface.')}
        </p>
        <div className={'theme-switcher-dropdown'}>
          <div className="theme-switcher-dropdown-option"
               onClick={() => {
                 setVisiblePopup(true);
                 dispatch(actions.changeTheme('theme', 'light'));
                 dispatch(actions.changeTheme('showTheme', false));
               }}
          >
            <Icon type="sun" role="icon" />
            <span>
              {t('Light')}
            </span>
            {
              nav?.theme === 'light' ?
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                     className={'checkmark'}
                >
                  <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                        stroke="#225AEA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                  />
                </svg>
                :
                null
            }
          </div>
          <div className="theme-switcher-dropdown-option"
               onClick={() => {
                 setVisiblePopup(true);
                 dispatch(actions.changeTheme('theme', 'dark'));
                 dispatch(actions.changeTheme('showTheme', false));
               }}
          >
            <Icon type="moon" role="icon" />
            <span>
              {t('Dark')}
            </span>
            {
              nav?.theme === 'dark' ?
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                     className={'checkmark'}
                >
                  <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                        stroke="#225AEA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                  />
                </svg>
                :
                null
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThemeModal;
