import React from 'react';

const Plus = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
  >
    <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
    />
  </svg>
);

export default Plus;
