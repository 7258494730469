import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../Components/Modal';
import { Divider } from 'antd';
import ButtonComponent from '../../../../Components/Button';
import InputComponent from '../../../../Components/Input';

const RenameModal = (
  {
    setModal,
    isMobile,
    modal,
    renameCollection,
    collection,
  },
) => {

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    modal === 'collection_rename' && setInputValue(collection?.collectionName || collection?.name);
  }, [modal])

  return (
    <Modal handleOk={null}
           handleClose={() => setModal(null)}
           title={t(`Rename collection`)}
           isMobile={isMobile}
           width={450}
           className="collection-rename-modal collection-modal"
           open={modal === 'collection_rename'}
           destroyOnClose
    >
      <div className={'collection-modal-content'}>
        <p className={'collection-modal-text'}>
          {
            t('Rename your collection to make it easier to distinguish from others in the list')
          }
        </p>
        <InputComponent value={inputValue}
                        placeholder={t('Enter name for collection...')}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="input--collections-modal"
        />
      </div>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className={'collection-modal-btn-wrapper'}>
        <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                         onClick={() => setModal(null)}
                         text={t('Cancel')}
        />
        <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                         disabled={!Boolean(inputValue) || inputValue.trim() === collection?.collectionName || inputValue.trim() === collection?.name}
                         onClick={() => {
                           renameCollection(collection?.collectionID || collection?.id, inputValue.trim());
                           setModal(null);
                         }}
                         text={t('Rename')}
        />
      </div>
      {
        isMobile ?
          null
          :
          <div style={{ height: 16 }} />
      }
    </Modal>
  );
};

export default RenameModal;
