import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Modal from '../../Components/Modal';
import { Divider, Spin } from 'antd';
import ButtonComponent from '../Button';
import Icon from '../../Icon';
import InputComponent from '../Input';

const ChangeEmailModal = (
  {
    visible,
    isMobile,
    setModal,
    loading,
    changeEmails,
    userError,
    userErrorsReset,
    saveResult,
    userSaveResultReset,
    setSaveConfirm
  }
) => {

  const {t} = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [inputMail, setInputMail] = useState('');
  const [inputPass, setInputPass] = useState('');

  useEffect(() => {
    setVisiblePassword(false);
    setInputMail('');
    setInputPass('');
  }, [visible]);

  useEffect(() => {
    if (saveResult?.confirm && visible) {
      setModal('check_email');
      setSaveConfirm({
        email: inputMail,
        password: inputPass,
      });
      userSaveResultReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('Change email')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t('Enter your current password to add a new email.')}
          </p>

          <div className="change-form">
            <div className={cls('input-wrapper', {
              error: userError && userError?.includes('password'),
            })}>
              <span className="title">
                {t('Current password')}
              </span>
              <InputComponent type={visiblePassword ? 'text' : 'password'}
                              placeholder={t('Enter current password...')}
                              autoComplete="off"
                              value={inputPass}
                              onChange={(e) => {
                                userErrorsReset();
                                setInputPass(e.target.value);
                              }}
                              suffix={(
                                <span onClick={() => setVisiblePassword(prev => !prev)}>
                                  {
                                    visiblePassword ?
                                      <Icon type="eye" />
                                      :
                                      <Icon type="eye_invisible" />
                                  }
                                </span>
                              )}
              />
              <span className={'error'}>
                {
                  userError && userError?.includes('password') ?
                    userError
                    :
                    null
                }
              </span>
            </div>
            <div className={cls('input-wrapper', {
              error: userError && userError?.includes('email'),
            })}>
              <span className="title">
                {t('New email')}
              </span>
              <InputComponent type="email"
                              placeholder={t('Enter new email you would like to use...')}
                              value={inputMail}
                              onChange={(e) => {
                                userErrorsReset();
                                setInputMail(e.target.value);
                              }}
              />
              <span className={'error'}>
                {
                  userError && userError?.includes('email') ?
                    userError
                    :
                    null
                }
              </span>
            </div>
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModal(null);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={loading || !inputMail || !inputPass}
                           onClick={() => {
                             changeEmails({ password: inputPass, new_email: inputMail });
                           }}
                           text={t('Change email')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default ChangeEmailModal;
