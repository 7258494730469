import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider } from 'antd';
import Images from '../../../Images';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import Modal from '../../../Components/Modal';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import utils from '../../../Utils/utils';
import './CompetitorsBlock.less';

const acc = require('accounting');

const CompetitorsBlock = (
  {
    skeleton,
    isMobile,
    product,
    onOpenUpgradePlan,
  }) => {

  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const isEmptyState = !product?.competitors?.length;

  const competitorsList = !isEmptyState ? [...product?.competitors] : [];
  const competitorsShortList =
    competitorsList?.length > 6
      ? competitorsList?.slice(0, 6)
      : competitorsList;
  const skeletonList = [1, 2, 3, 4, 5];
  const isLocked = product?.is_locked && competitorsList.length > 3;

  const handleClose = () => {
    setVisible(false);
  };

  const renderHeader = () => {
    let result = null;

    if (isEmptyState) {
      result = <span className="title">{t('No Competitors Found')}</span>;
    } else {
      result = (
        <>
          <span className="title">
            {t('Competitors')}
            <span className="header-tag">
              {product?.competitors_count}
            </span>
          </span>
          {
            competitorsList?.length > 6 ?
              (
                <span className={cls('link', { disabled: isLocked })}
                      onClick={() => setVisible(!isLocked)}
                >
                  {isLocked ? <Icon role="icon" type="lock" /> : ''}
                  {t('View All')}
                </span>
              )
              :
              null
          }
        </>
      );
    }

    return result;
  };

  const renderHeaderSkeleton = (
    <>
      <span className={'link skeleton'}
            style={{ width: 112, height: 28 }}
      />

      <span className={'link skeleton'}
            style={{ width: 50, height: 20 }}
      />
    </>
  );

  const renderCompetitorsList = (arr, className) => (
    <Row gutter={[0, 12]} className={cls('competitors-card-wrap', className)}>
      {arr?.map((el, index) => (
        <Col key={(el?.domain || el) + index} span={24}>
          <div className={cls('competitors-card', { locked: !skeleton && el.is_locked })}>
            {
              skeleton ?
                (
                  <div className="competitors-card-info-wrap">
                    <span className="competitors-card-img skeleton" />
                    <span className="competitors-card-link skeleton"
                          style={{
                            width: isMobile
                              ? utils.randomNum(55, 90)
                              : index
                                ? 140
                                : 108,
                          }}
                    />
                  </div>
                )
                :
                (
                  <div className="competitors-card-info-wrap">
                    <img alt=""
                         src={el.favicon || Images.defaultImg}
                         onError={utils.addDefaultSrc}
                         className="competitors-card-img"
                    />
                    <a href={el.link || '#'}
                       target="_blank"
                       rel="noopener noreferrer"
                       className="competitors-card-link"
                    >
                      {el.domain}
                      {
                        isMobile ?
                          null
                          :
                          <svg xmlns="http://www.w3.org/2000/svg"
                               width="16"
                               height="16"
                               viewBox="0 0 16 16"
                               fill="none"
                          >
                            <path stroke="#225AEA"
                                  d="M12.1667 9.33333V11.3667C12.1667 12.1134 12.1667 12.4868 12.0213 12.772C11.8935 13.0229 11.6895 13.2268 11.4387 13.3547C11.1534 13.5 10.7801 13.5 10.0333 13.5H4.63333C3.8866 13.5 3.51323 13.5 3.22801 13.3547C2.97713 13.2268 2.77316 13.0229 2.64532 12.772C2.5 12.4868 2.5 12.1134 2.5 11.3667V5.91667C2.5 5.21706 2.5 4.86726 2.62801 4.59659C2.7599 4.31774 2.9844 4.09324 3.26326 3.96135C3.53392 3.83333 3.88373 3.83333 4.58333 3.83333H6.16667M9.16667 2.5H13.5M13.5 2.5V6.83333M13.5 2.5L7.33333 8.66667"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                            />
                          </svg>
                      }
                    </a>
                  </div>
                )}
            <span className={cls('competitors-card-tag', { skeleton })}
                  style={
                    skeleton ?
                      { width: isMobile ? 48 : utils.randomNum(53, 113) }
                      :
                      {}
                  }
            >
              {skeleton ? null : el.platform}
            </span>
            <span className={cls('competitors-card-price', { skeleton })}
                  style={
                    skeleton ?
                      {
                        width: isMobile ?
                          utils.randomNum(66, 90)
                          :
                          utils.randomNum(88, 94),
                      }
                      :
                      {}
                  }
            >
              {
                skeleton ?
                  null
                  :
                  `$${acc.formatNumber(el?.price, 2, '', ',')} ${el?.currency}`}
            </span>
          </div>
          {isMobile ? <Divider /> : null}
        </Col>
      ))}
    </Row>
  );

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
        <div className="competitors-card-list-header">
            <span className={'link skeleton'}
                  style={{ width: 55, height: 20 }}
            />
          <span className={'link skeleton'}
                style={{ width: 65, height: 20, marginLeft: '41.5%' }}
          />
          <span className={'link skeleton'}
                style={{ width: 80, height: 20 }}
          />
        </div>
        {renderCompetitorsList(skeletonList)}
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard header={renderHeader()}
                        withLine={!isMobile}
                        isLocked={isLocked}
                        onOpenUpgradePlan={onOpenUpgradePlan}
    >
      <div className="competitors-card-list-header">
        <span>
          {t('Store')}
        </span>
        <span>
          {t('Provider')}
        </span>
        <span>
          {t('Selling price')}
        </span>
      </div>
      {
        isEmptyState ?
          (
            <div className="competitors-card empty">
              <Icon role="icon" type="competitors_empty" />
            </div>
          )
          :
          (
            renderCompetitorsList(competitorsShortList)
          )
      }
      <Modal className="modal-competitors small"
             title={
               <span className={'modal-competitors-title'}>
                 <span>
                   {t('Competitors')}{' '}
                   <span className="header-tag">{product?.competitors_count}</span>
                 </span>
                 <p>
                   {
                     t('A list of all the competitors that we were able to find.')
                   }
                 </p>
               </span>
             }
             open={visible}
             width={450}
             handleClose={handleClose}
             isMobile={isMobile}
             destroyOnClose
      >
        <div className="competitors-card-list-header">
        <span>
          {t('Store')}
        </span>
          <span>
          {t('Provider')}
        </span>
          <span>
          {t('Selling price')}
        </span>
        </div>
        <OverlayScrollbar>
          {renderCompetitorsList(competitorsList, 'withScroll')}
        </OverlayScrollbar>
      </Modal>
    </ProductDetailsCard>
  );
};

export default CompetitorsBlock;
