import React from 'react';
import { useSelector } from 'react-redux';

const CloseModal = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
    >
      <path stroke={theme === 'light' ? "#434C69" : "rgba(255, 255, 255, .36)"}
            d="M6.45833 6.45801L13.5417 13.5413M13.5417 6.45801L6.45833 13.5413"
            strokeWidth="1.5"
            strokeLinecap="round"
      />
    </svg>
  );
}

export default CloseModal;
