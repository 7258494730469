import React, { useEffect } from 'react';
import ButtonComponent from '../../Components/Button';
import Modal from '../../Components/Modal';
import Creators from './reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Spin } from 'antd';
import Icon from '../../Icon';

const text = 'Click “Connect AutoDS”  below, and you will be redirected to AutoDS to connect your account to Dropship.';

const ModalAutoDsConnect = ({ isVisibleModal, changeVisibleModalAutoDS, isMobile, autoDS, recheckAutoStores }) => {

  const { t } = useTranslation();

  const closeModalAutoDS = () => changeVisibleModalAutoDS({ isVisibleModal: null, importProductId: null });

  const handleClose = () => closeModalAutoDS();

  useEffect(() => {
    if (isVisibleModal === 'connect' && !autoDS?.email) {
      recheckAutoStores();
    }
  }, [isVisibleModal]);

  return (
    <Modal className={'custom-modal autods-connect'}
           title={'Connect Store'}
           open={isVisibleModal === 'connect'}
           width={450}
           handleOk={null}
           handleClose={handleClose}
           isMobile={isMobile}
           destroyOnClose={true}
           footer={false}
    >
      <Spin spinning={autoDS?.loading}>
        <div className="image-wrapper">
          <div className="left" />
          <div className="circle blue">
            <div className="inner">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="20"
                   viewBox="0 0 16 20"
                   fill="none"
              >
                <path
                  d="M14.3035 4.31976L10.2671 1.41678C8.91364 0.443105 7.08894 0.443105 5.73459 1.41678L1.69736 4.31976C0.684403 5.04851 0.083333 6.21984 0.083333 7.46766V12.5328C0.083333 13.7807 0.683543 14.952 1.69736 15.6807L5.73373 18.5837C7.08722 19.5574 8.91192 19.5574 10.2663 18.5837L14.3026 15.6807C15.3156 14.952 15.9167 13.7807 15.9167 12.5328V7.46766C15.9167 6.21984 15.3165 5.04851 14.3035 4.31976ZM6.64093 2.67577C7.45353 2.09139 8.54819 2.09139 9.35993 2.67577L12.7626 5.12242C13.0085 5.29945 13.0085 5.66469 12.7626 5.84172L9.35993 8.28837C8.54733 8.87274 7.45267 8.87274 6.64093 8.28837L3.23831 5.84172C2.99237 5.66469 2.99237 5.29945 3.23831 5.12242L6.64093 2.67577ZM14.3654 12.532C14.3654 13.2805 14.0051 13.9835 13.3972 14.4209L9.36079 17.3239C8.54818 17.9082 7.45353 17.9082 6.64179 17.3239L2.60542 14.4209C1.99747 13.9835 1.63717 13.2805 1.63717 12.532V7.4668C1.63717 7.46422 1.63717 7.46165 1.63717 7.45907C1.63803 7.10071 2.04648 6.89446 2.33713 7.10329L5.73545 9.54736C7.08894 10.521 8.91364 10.521 10.268 9.54736L13.6663 7.10329C13.9578 6.8936 14.3654 7.09985 14.3663 7.45907C14.3663 7.46165 14.3663 7.46422 14.3663 7.4668V12.532H14.3654Z"
                  fill="#225AEA" />
                <path
                  d="M10.2456 11.0743C9.57059 11.2066 9.12258 11.7403 8.7898 12.3539C8.69177 12.5344 8.69865 12.7613 8.85085 12.8824C8.9635 12.9718 9.11226 12.9666 9.22147 12.873C9.63938 12.5172 10.1269 12.3161 10.4425 12.2473C10.7581 12.1786 11.2766 12.1597 11.7797 12.3144C11.9121 12.3548 12.0514 12.2963 12.1271 12.1666C12.2303 11.9895 12.1615 11.7781 12.0136 11.6492C11.4753 11.1809 10.9309 10.9394 10.2456 11.0743Z"
                  fill="#225AEA" />
                <path
                  d="M5.76231 11.0743C6.43733 11.2066 6.88534 11.7403 7.21812 12.3539C7.31615 12.5344 7.30927 12.7613 7.15706 12.8824C7.04442 12.9718 6.89565 12.9666 6.78645 12.873C6.36854 12.5172 5.88097 12.3161 5.56539 12.2473C5.24981 12.1786 4.73129 12.1597 4.22825 12.3144C4.09582 12.3548 3.95652 12.2963 3.88085 12.1666C3.77766 11.9895 3.84645 11.7781 3.99435 11.6492C4.53265 11.1809 5.07697 10.9394 5.76231 11.0743Z"
                  fill="#225AEA" />
              </svg>
            </div>
          </div>
          <div className="middle" />
          <div className="circle">
            <div className="inner">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width={25}
                   height={24}
                   viewBox="0 0 25 24"
                   fill="none">
                <g clipPath="url(#clip0_36231_525624)">
                  <path
                    d="M2.20468 5.51379C2.07238 5.51379 1.95748 5.56167 1.86174 5.65828C1.76687 5.75577 1.71899 5.87066 1.71899 6.00383V17.722C1.71899 17.8552 1.76687 17.9701 1.86174 18.0658C1.95748 18.1642 2.07238 18.2121 2.20468 18.2121H6.34778C8.02852 18.2121 9.30801 17.7743 10.1836 16.8978C11.0593 16.0204 11.5467 14.7896 11.6424 13.2055C11.6659 12.9035 11.6781 12.4552 11.6781 11.8625C11.6781 11.2706 11.6659 10.8224 11.6424 10.5212C11.5467 8.93621 11.054 7.70633 10.1654 6.82897C9.27668 5.95248 7.97456 5.51379 6.25726 5.51379H2.20468ZM4.14915 7.78119H6.16848C7.15203 7.78119 7.89361 8.03012 8.39235 8.52538C8.88935 9.02151 9.16265 9.68649 9.21053 10.5212C9.2349 10.8224 9.24708 11.2706 9.24708 11.8625C9.24708 12.4552 9.2349 12.9035 9.21053 13.2055C9.16265 14.0394 8.89544 14.7052 8.41063 15.2013C7.92407 15.6975 7.20512 15.9438 6.25726 15.9438H4.14915V7.78119Z"
                    fill={'#191A35'} />
                  <path
                    d="M15.7976 5.81234C15.0769 6.13352 14.5102 6.57829 14.0959 7.14666C13.6816 7.71416 13.4745 8.35652 13.4745 9.06938C13.4745 10.1217 13.753 10.9198 14.3118 11.4647C14.8697 12.0078 15.7802 12.437 17.0414 12.752C17.5201 12.8722 17.8038 12.9635 18.1033 13.0245C18.8605 13.2046 19.3192 13.3544 19.6909 13.4675C20.0634 13.5833 20.3506 13.7286 20.5552 13.9044C20.7597 14.0794 20.8616 14.3127 20.8616 14.6025C20.8616 15.11 20.5978 15.4903 20.0695 15.7462C19.542 15.9986 18.9876 16.1257 18.1938 16.1257C17.3782 16.1257 16.9874 16.0073 16.5966 15.7715C16.2075 15.5356 15.9099 15.2196 15.7062 14.8201C15.6339 14.6748 15.5399 14.5651 15.4276 14.4928C15.3119 14.4206 15.1596 14.384 14.9672 14.384H13.7069C13.5633 14.384 13.4431 14.4293 13.3474 14.5207C13.2508 14.6103 13.209 14.7287 13.2203 14.8741C13.2569 15.5634 13.5154 16.1771 13.995 16.715C14.4746 17.2537 15.1056 17.6681 15.8872 17.9579C16.6662 18.2477 17.2938 18.394 18.1938 18.394C19.2513 18.394 20.066 18.2303 20.8529 17.9031C21.6388 17.5775 22.242 17.1267 22.6633 16.5513C23.0819 15.9786 23.2935 15.3276 23.2935 14.6025C23.2935 13.6103 22.9688 12.8304 22.3212 12.262C21.6728 11.6945 20.6213 11.2523 19.1686 10.9373C18.1607 10.7197 17.6454 10.5438 17.2024 10.4115C16.7585 10.2784 16.4312 10.113 16.2206 9.91192C16.0108 9.71347 15.9064 9.43233 15.9064 9.06938C15.9064 8.62199 16.1283 8.26513 16.5722 7.99878C17.0161 7.73244 17.4566 7.59927 18.1033 7.59927C18.7648 7.59927 19.0746 7.70285 19.4585 7.90913C19.8432 8.11368 20.1365 8.38611 20.3411 8.7247C20.4255 8.87005 20.5212 8.97798 20.6292 9.05023C20.738 9.12421 20.8868 9.15903 21.0792 9.15903H22.3395C22.484 9.15903 22.6041 9.11377 22.6998 9.02412C22.7956 8.93272 22.8382 8.81522 22.8261 8.67073C22.7895 8.1746 22.5797 7.66629 22.195 7.14666C21.812 6.62616 21.2498 6.19444 20.5125 5.84977C19.7744 5.50422 19.1129 5.33275 18.1033 5.33275C17.2155 5.33275 16.5183 5.49203 15.7976 5.81234Z"
                    fill={'#191A35'} />
                  <path
                    d="M-5.94411 10.8015C-6.07554 10.8015 -6.1913 10.8502 -6.28705 10.946C-6.38279 11.0435 -6.43066 11.1583 -6.43066 11.2915V12.7599C-6.43066 12.8931 -6.38279 13.0088 -6.28705 13.1054C-6.1913 13.2012 -6.07554 13.2499 -5.94411 13.2499H-0.451886C-0.319585 13.2499 -0.205562 13.2012 -0.109818 13.1054C-0.0132036 13.0088 0.0346684 12.8931 0.0346684 12.7599V11.2915C0.0346684 11.1583 -0.0132036 11.0435 -0.109818 10.946C-0.205562 10.8502 -0.319585 10.8015 -0.451886 10.8015H-5.94411Z"
                    fill={'#D59955'} />
                  <path
                    d="M25.4635 10.9686C25.3312 10.9686 25.2163 11.0173 25.1205 11.1148C25.0248 11.2106 24.9778 11.3263 24.9778 11.4595V12.9279C24.9778 13.061 25.0248 13.1768 25.1205 13.2725C25.2163 13.37 25.3312 13.4188 25.4635 13.4188H30.9557C31.0871 13.4188 31.202 13.37 31.2978 13.2725C31.3944 13.1768 31.4414 13.061 31.4414 12.9279V11.4595C31.4414 11.3263 31.3944 11.2106 31.2978 11.1148C31.202 11.0173 31.0871 10.9686 30.9557 10.9686H25.4635Z"
                    fill={'#D9D4AB'} />
                  <path
                    d="M15.51 20.0887C15.3786 20.0887 15.2645 20.1383 15.1679 20.2349C15.0713 20.3315 15.0234 20.4464 15.0234 20.5796V22.0488C15.0234 22.182 15.0713 22.296 15.1679 22.3935C15.2645 22.491 15.3786 22.5388 15.51 22.5388H21.0022C21.1345 22.5388 21.2494 22.491 21.3452 22.3935C21.4409 22.296 21.4888 22.182 21.4888 22.0488V20.5796C21.4888 20.4464 21.4409 20.3315 21.3452 20.2349C21.2494 20.1383 21.1345 20.0887 21.0022 20.0887H15.51Z"
                    fill={'#E5BBB8'} />
                  <path
                    d="M3.6626 5.51379H2.86705H2.20468C2.07238 5.51379 1.95835 5.56167 1.86261 5.65828C1.76687 5.75577 1.71899 5.86979 1.71899 6.00296V11.537H4.14915V6.90905V6.00296V5.51379H3.6626Z"
                    fill={'#B2D8D0'} />
                </g>
                <defs>
                  <clipPath id="clip0_36231_525624">
                    <rect width="25" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="right" />
        </div>
        <p className={'custom-modal-text'}>
          {t(text)}
        </p>
        <Divider type={'horizontal'}
                 style={{ margin: '24px auto 16px' }}
        />
        <div className="custom-modal-btn-wrapper">
          <ButtonComponent className={'custom-modal-button cancel'}
                           text={t('Cancel')}
                           onClick={handleClose}
          />
          <ButtonComponent className={'custom-modal-button apply blue'}
                           disabled={false}
                           onClick={() => {
                             closeModalAutoDS();
                             setTimeout(() => window.open(
                               `https://www.autods.com/integrate/?response_type=code&ref=Mjc0NzQ5&client_id=72buojhlk54m0aj1i9hn1071qc&redirect_uri=${process.env.REACT_APP_BASE_URI}/dashboard`,
                               '_blank',
                             ), 300);
                           }}
          >
            {t('Connect AutoDs')}
          </ButtonComponent>
        </div>
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  autoDS: state.autoDS,
  isVisibleModal: state.autoDS.isVisibleModal,
  theme: state?.nav?.theme,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  recheckAutoStores: () => dispatch(Creators.recheckAutoStoresRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDsConnect);
