import React from 'react';
import PersonalInformation from './PersonalInformation';
import Security from './Security';
import Preferences from './Preferences';
import ConnectedAccounts from './ConnectedAccounts';
import './styles.less';

const AccountSubPage = () => {

  return (
    <div className="account-subpage">
      <PersonalInformation />
      <Security />
      <Preferences />
      <ConnectedAccounts />
    </div>
  );
}

export default AccountSubPage;
