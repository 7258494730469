import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

const options = [
  {
    key: 'price',
    name: 'Price'
  },
  {
    key: 'cost',
    name: 'Cost'
  },
  {
    key: 'p_c_ratio',
    name: 'P/C ratio'
  },
  {
    key: 'BECPA',
    name: 'BECPA'
  },
  {
    key: 'pot_profit',
    name: 'Potential profit'
  },
]

const SortDropdown = (
  {
    order,
    setSort,
    isMobile = false,
    setModal
  }) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => setVisible(value);

  return (
    !isMobile ?
      <Dropdown open={visible}
                onOpenChange={handleVisibleChange}
                dropdownRender={() => (
                  <div className={'search-sort-menu'}>
                    {
                      options.map(el => (
                        <div className={'search-sort-menu-item-wrapper'}
                             key={el?.key}
                        >
                        <span className={'search-sort-menu-item'}
                              onClick={() => setSort({ order: order === el?.key ? null : 'ascend', field: el?.key })}
                        >
                          {t(`_name_: Low to High`, {name: el?.name})}
                          {
                            order === el?.key ?
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                   height="16"
                                   viewBox="0 0 16 16"
                                   fill="none"
                              >
                                <path d="M1.8335 10.0625L6.00016 13.5L14.1668 2.5" stroke="#225AEA" strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                />
                              </svg>
                              :
                              null
                          }
                        </span>
                          <span className={'search-sort-menu-item'}
                                onClick={() => setSort({
                                  order: order === `-${el?.key}` ? null : 'descend',
                                  field: el?.key
                                })}
                          >
                          {t(`_name_: High to Low`, {name: el?.name})}
                            {
                              order === `-${el?.key}` ?
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="16"
                                     height="16"
                                     viewBox="0 0 16 16"
                                     fill="none"
                                >
                                  <path d="M1.8335 10.0625L6.00016 13.5L14.1668 2.5" stroke="#225AEA" strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                  />
                                </svg>
                                :
                                null
                            }
                        </span>
                        </div>
                      ))
                    }
                  </div>
                )}
                destroyPopupOnHide={true}
                trigger={['click']}
                placement="bottomRight"
                getPopupContainer={(trigger) => trigger.parentNode}
                overlayClassName={'search-sort-menu-wrapper'}
      >
        <div className={'search-sort-menu-inner'}>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
            <path stroke="#707BA0"
                  d="M5.00133 3.9585V16.0418M5.00133 16.0418L2.5 13.5418M5.00133 16.0418L7.5 13.5418M9.79167 5.62516H16.875M13.125 14.3752H16.875M11.4583 10.0002H16.875"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
          </svg>
          <span>
          Sort
        </span>
        </div>
      </Dropdown>
      :
      <div className={'search-sort-menu-inner'}
           onClick={() => setModal('sort_modal')}
      >
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path stroke="#707BA0"
                d="M5.00133 3.9585V16.0418M5.00133 16.0418L2.5 13.5418M5.00133 16.0418L7.5 13.5418M9.79167 5.62516H16.875M13.125 14.3752H16.875M11.4583 10.0002H16.875"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </svg>
        <span>
          Sort
        </span>
      </div>
  );
};

export default SortDropdown;
