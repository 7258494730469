import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Divider } from 'antd';
import ProductDetailsCard from './ProductDetailsCard';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import Icon from '../../../Icon';
import ButtonComponent from '../../../Components/Button';
import './DescriptionBlock.less';

const platformsList = [
  { id: 1, name: 'aliexpress' },
  { id: 2, name: 'amazon' },
  { id: 3, name: 'alibaba' },
  { id: 4, name: 'facebook' },
  { id: 5, name: 'google' },
];

const DescriptionBlock = (
  {
    skeleton,
    isMobile,
    theme,
    product,
    isShopifyConnected,
    autoDS = {},
    openModalShopify,
    openModalAutoDS = null,
    disabledImport,
  }) => {

  const { t } = useTranslation();
  const description = product?.description;
  const shopifyRedirectUrl = product?.shopify_redirect_url?.latest || null;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const renderHeader = (
    <div className="description-card-header">
      <div className="description-card-header-item">
        <span className="label">{t('Cost')}</span>
        <span className="value">
          {product?.cost?.replaceAll('.', ',') || '-'}
        </span>
      </div>
      <div className="description-card-header-item">
        <span className="label">
          {t('Shipping')}
          <Icon type="attention_outline"
                role="button"
                titleText={t('The shipping cost is based on the cheapest shipping option to the United States. If the seller does not ship to Unites States, we will display Canada, Australia or United Kingdom.')}
                tooltipProps={{
                  placement: 'right',
                  overlayClassName: cls('details-tooltip', 'shipping'),
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
          />
        </span>
        <span className="value">
          {product.shipping
            ? `$${product.shipping.replaceAll('.', ',')}`
            : t('free')}
        </span>
      </div>
    </div>
  );

  const renderHeaderSkeleton = (
    <div className="description-card-header">
      <div className="description-card-header-item">
        <span className={'link skeleton'}
              style={{ width: 31, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 117, height: 24 }}
        />
      </div>
      <div className="description-card-header-item">
        <span className={'link skeleton'}
              style={{ width: 57, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 50, height: 24 }}
        />
      </div>
    </div>
  );

  const handleOpenModal = () => {
    if (isShopifyConnected) {
      openModalShopify('import', initialUrl, product?.product_id);
    } else {
      openModalShopify('connect', initialUrl, null);
    }
  };

  const handleOpenAutoDS = () => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) openModalAutoDS('import', {
        'source': 'portfolio',
        'product_id': product?.product_id,
      });
      else openModalAutoDS('import_unavailable', null);
    } else openModalAutoDS('connect', null);
  };

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeaderSkeleton}>
        <Divider style={{margin: '16px auto'}} />
        <div className="description-card">
          <div className="description-card-description">
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 20, marginBottom: 4 }}
            />
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 20, marginBottom: 4 }}
            />
            <span className={'link skeleton'}
                  style={{ width: '20%', height: 20, marginBottom: 20 }}
            />
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 20, marginBottom: 4 }}
            />
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 20, marginBottom: 4 }}
            />
            <span className={'link skeleton'}
                  style={{ width: '80%', height: 20 }}
            />
          </div>
          <Divider style={{margin: '16px auto'}} />
          <div className="description-card-footer">
            <div className="description-card-footer-item">
              <span className={'link skeleton'}
                    style={{ width: 100, height: 20 }}
              />
              <div className="list">
                {[1, 2, 3, 4].map((el) => (
                  <span className={'link skeleton'}
                        key={el}
                        style={{ width: 24, height: 24, marginRight: 14 }}
                  />
                ))}
              </div>
            </div>
            <div className="btn-wrap">
              <span className={'link skeleton'}
                    style={{ width: 164, height: 40 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 164, height: 40 }}
              />
            </div>
          </div>
        </div>
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard header={renderHeader} withLine={!isMobile}>
      {
        isMobile ?
          <Divider type={'horizontal'}
                   style={{margin: '0 0 16px'}}
          />
          :
          null
      }
      <div className="description-card">
        <OverlayScrollbar visibility="hidden">
          {
            description ?
              <div className="description-card-description">
                <p>{description}</p>
              </div>
              :
              null
          }
        </OverlayScrollbar>
        <Divider style={{ margin: '16px auto' }} />
        <div className="description-card-footer">
          <div className="description-card-footer-item">
            <span className="label">{t('Quick search')}</span>
            <div className="list">
              {platformsList.map((el, index) => {
                return (
                  <a key={el.id}
                     href={product?.quick_search?.[el.id] || '#'}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="link with-img"
                  >
                    <Icon role="button"
                          type={`search_${el.name}`}
                          theme={theme}
                          className={`${el.name}-icon`}
                          titleText={() => <>Search on <span
                            style={{ textTransform: 'capitalize' }}>{el.name}</span></>}
                          tooltipProps={{
                            placement: 'bottom',
                            destroyTooltipOnHide: true,
                            trigger: 'hover',
                            overlayClassName: cls('details-tooltip', `${el.name}-tooltip`),
                            getPopupContainer: () =>
                              document.querySelector('.description-card-footer-item'),
                          }}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="btn-wrap">
            {
              shopifyRedirectUrl ?
                <ButtonComponent className="btn view-shopify-btn"
                                 href={shopifyRedirectUrl}
                                 target="_blank"
                >
                  shopify
                </ButtonComponent>
                :
                null
            }
            <ButtonComponent className="btn auto-ds-import"
                             onClick={handleOpenAutoDS}
            >
              <Icon type={'auto_ds'} role={'icon'} />
              {t('Import to AutoDS')}
            </ButtonComponent>
            <ButtonComponent className="btn view-shopify-btn"
                             disabled={disabledImport}
                             onClick={handleOpenModal}
            >
              <Icon role="icon" type="shopify_btn_icon" />
              {t('Import to Shopify')}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </ProductDetailsCard>
  );
};

export default DescriptionBlock;
