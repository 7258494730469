import React from 'react';
import { useSelector } from 'react-redux';

const PresetIcon = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20" fill="none"
    >
      <path stroke={theme === 'light' ? '#707ba0' : 'rgba(255, 255, 255, 0.36)'}
            d="M8.54134 17.7087H5.62467C4.7042 17.7087 3.95801 16.9625 3.95801 16.042V3.95866C3.95801 3.03818 4.7042 2.29199 5.62467 2.29199H14.3747C15.2951 2.29199 16.0413 3.03818 16.0413 3.95866V8.95866M17.083 17.917L15.9772 16.7754M15.9772 16.7754C16.5322 16.2114 16.8747 15.4375 16.8747 14.5837C16.8747 12.8578 15.4756 11.4587 13.7497 11.4587C12.0238 11.4587 10.6247 12.8578 10.6247 14.5837C10.6247 16.3095 12.0238 17.7087 13.7497 17.7087C14.6217 17.7087 15.4103 17.3515 15.9772 16.7754ZM7.29134 5.62533H12.708M7.29134 8.95866H10.208"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default PresetIcon;
