import React from 'react'

const ArrowSliderNext = ({className}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       className={className}
       viewBox="0 0 20 20"
       fill="none"
  >
    <path d="M8.33398 13.3334L11.0781 10.5893C11.4035 10.2639 11.4035 9.73626 11.0781 9.41083L8.33398 6.66675"
          stroke="#225AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>
)

export default ArrowSliderNext
