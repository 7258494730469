import React from 'react';

const PortfolioDashboard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width ? width : 30}
         height={height ? height : 29}
         viewBox="0 0 30 29"
         fill="none">
      <g filter="url(#filter0_dd_2165_9629)">
        <path fill="#F9F9FC"
          d="M11.002 6C11.002 5.44772 11.4497 5 12.002 5H16.002C16.5542 5 17.002 5.44772 17.002 6V7H19V21H20.0022C22.2113 21 24.0022 19.2091 24.0022 17V12C24.0022 11.9775 24.0015 11.9553 24 11.9332V11C24 8.79086 22.2091 7 20 7H19.002V6C19.002 4.34315 17.6588 3 16.002 3H12.002C10.3451 3 9.00195 4.34315 9.00195 6V7H11V21H17V7H11.002V6Z"
        />
        <path fill="#F9F9FC"
              d="M8 7H9V21H8.0022C5.79306 21 4.0022 19.2091 4.0022 17L4 11C4 8.79086 5.79086 7 8 7Z"
        />
      </g>
      <defs>
        <filter id="filter0_dd_2165_9629"
                x="-2"
                y="-3"
                width="34"
                height="35"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0"
                   result="BackgroundImageFix"
          />
          <feColorMatrix in="SourceAlpha"
                         type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2165_9629" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2165_9629" result="effect2_dropShadow_2165_9629" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2165_9629" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default PortfolioDashboard;
