// /*  Setting services */
import apisauce from 'apisauce';
import {API, encryptedData} from '../../Services/Api';

const request = new API();

export const getRecharge = (data) =>
  request.api.get(`/payments/pay_now/${data}/`);

export const getLanguagesList = () => request.api.get(`/user/get-language/`);

export const getSubscriptionsList = (filter) =>
  request.api.get(`subscription/${filter}/`);

export const addCompanyDetails = (data) =>
  request.api.put(`user/update/`, encryptedData({ company: data }));

export const changeBillingAddress = (data) =>
  request.api.put(`user/update/`, encryptedData({ billing_info: data }));

export const getPayments = () => request.api.get(`user/payments/`);
export const changePayments = (data) => request.api.post(`payments/replace_card/`, encryptedData(data));

export const getPaymentsNext = ({ link }) => {
  const uri = new URL(link);
  uri.protocol = 'https://';
  return request.api.get(uri.toString(), null);
};

//api for landing page invoice - without authorization
const baseURL = process.env.REACT_APP_BASE_API_ROUTE;
const api = apisauce.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export const getPaymentDetails = ({ id }) => {
  const { retrieve_invoice_id } = id;
  if (retrieve_invoice_id) {
    return api.get(`/payments/retrieve_invoice/${retrieve_invoice_id}/`);
  }
  return request.api.get(`user/payments/${id}/`);
}

export const getUserCountry = () => request.api.get(`user/get_country/`);
