import React from 'react';

const UniversityDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="34"
         height="31"
         viewBox="0 0 34 31"
         fill="none"
    >
      <g filter="url(#filter0_dd_3063_63687)">
        <path fill="#F9F9FC"
              d="M9 14.8541V16.3959C9 17.911 9.85601 19.296 11.2111 19.9736L14.2111 21.4736C15.3373 22.0367 16.6627 22.0367 17.7889 21.4736L20.7889 19.9736C22.144 19.296 23 17.911 23 16.3959V14.8541L17.3416 17.6833C16.4971 18.1056 15.5029 18.1056 14.6584 17.6833L9 14.8541Z"
        />
        <path fill="#F9F9FC"
              d="M25 11.618V16C25 16.5523 25.4477 17 26 17C26.5523 17 27 16.5523 27 16V10C27 9.62123 26.786 9.27497 26.4472 9.10557L16.4472 4.10557C16.1657 3.96481 15.8343 3.96481 15.5528 4.10557L5.55279 9.10557C5.214 9.27497 5 9.62123 5 10C5 10.3788 5.214 10.725 5.55279 10.8944L15.5528 15.8944C15.8343 16.0352 16.1657 16.0352 16.4472 15.8944L25 11.618Z"
        />
        <path fill="#F9F9FC"
              d="M16.4472 4.10557C16.1657 3.96481 15.8343 3.96481 15.5528 4.10557L5.55279 9.10557C5.214 9.27497 5 9.62123 5 10C5 10.3788 5.214 10.725 5.55279 10.8944L15.5528 15.8944C15.8343 16.0352 16.1657 16.0352 16.4472 15.8944L25 11.618V16C25 16.5523 25.4477 17 26 17C26.5523 17 27 16.5523 27 16V10C27 9.62123 26.786 9.27497 26.4472 9.10557L16.4472 4.10557Z"
        />
      </g>
      <defs>
        <filter id="filter0_dd_3063_63687"
                x="-0.266667"
                y="-2.2"
                width="34.6667"
                height="35.7333"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0"
                   result="BackgroundImageFix"
          />
          <feColorMatrix in="SourceAlpha"
                         type="matrix"
                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"
          />
          <feOffset dy="4.26667" />
          <feGaussianBlur stdDeviation="2.13333" />
          <feComposite in2="hardAlpha"
                       operator="out"
          />
          <feColorMatrix type="matrix"
                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend mode="normal"
                   in2="BackgroundImageFix"
                   result="effect1_dropShadow_3063_63687"
          />
          <feColorMatrix in="SourceAlpha"
                         type="matrix"
                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"
          />
          <feOffset dx="1.06667"
                    dy="2.13333"
          />
          <feGaussianBlur stdDeviation="2.66667" />
          <feComposite in2="hardAlpha"
                       operator="out"
          />
          <feColorMatrix type="matrix"
                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend mode="normal"
                   in2="effect1_dropShadow_3063_63687"
                   result="effect2_dropShadow_3063_63687"
          />
          <feBlend mode="normal"
                   in="SourceGraphic"
                   in2="effect2_dropShadow_3063_63687"
                   result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default UniversityDashboard;
