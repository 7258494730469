import React, { useEffect } from 'react';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import Creators from './reducer';
import Icon from '../../Icon';
import { formatDuration } from '../../Utils/utils';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import UniversityCourseChapterCard from './components/UniversityCourseChapterCard';
import UniversityCourseChapterSkeleton from './components/skeletons/UniversityCourseChapterSkeleton';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import UniversityCourseProgress from './components/UniversityCourseProgress';
import './UniversityCoursePage.less';

const UniversityCoursePage = (
  {
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    clearBreadCrumbs,
    getCourseByID,
    getChapterByID,
    courseInfo,
    loading,
    isMobile,
  },
) => {

  const { courseID } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;

  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  useEffect(() => {
    if (breadCrumbs.length > 3) {
      clearBreadCrumbs();
      addBreadCrumb({
        name: 'Dropship University',
        link: `/dropship-university`,
      });
      addBreadCrumb({
        name: state ? state?.name : courseInfo ? courseInfo?.title : courseID,
        link: `/dropship-university/${courseID}`,
      });
    } else if (breadCrumbs.length === 3) {
      removeBreadCrumb();
    } else if (breadCrumbs.length < 2) {
      addBreadCrumb({
        name: state ? state?.name : courseInfo ? courseInfo?.title : courseID,
        link: `/dropship-university/${courseID}`,
      });
    }
    document.title = 'University - Dropship';
    getCourseByID(courseID);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const clickHandler = (chapterId, title) => {
    getChapterByID({ id: chapterId, name: title, courseID });
  };


  if (loading) return (<UniversityCourseChapterSkeleton isMobile={isMobile} />);

  return (
    <div className={'product-database-page university-page course-chapter-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
      />
      <div className="course-chapter-page-content">
        <div className={'course-chapter-info-wrapper'}>
          <h3 className={'title'}>
            {courseInfo?.title}
          </h3>
          <p className={'description'}>
            {courseInfo?.description}
          </p>
          <div className={'stats-wrapper'}>
            <span className={'stat'}>
              <Icon type={'watch_tutorial'}
                    role={'icon'}
              />
              {courseInfo?.lessons_count} {` ${t('lessons')}`}
            </span>
            <Divider type={'vertical'}
                     style={{ margin: '2px 12px 0', height: 12 }}
            />
            <span className={'stat'}>
              <Icon role={'icon'}
                    type={'clock'}
              />
              {formatDuration(0)} {` ${t('to watch')}`}
            </span>
          </div>
        </div>

        <div className="course-chapter-page-chapter">
          <div className="course-chapter-page-chapter-wrapper">
            <h3 className={'course-chapter-page-chapter-title'}>
              {t('Course overview')}
            </h3>
            {
              (courseInfo?.chapters ? courseInfo?.chapters : []).map(el => (
                <UniversityCourseChapterCard key={el?.id}
                                             info={el}
                                             onClick={() => clickHandler(el?.id, el?.title)}
                                             isMobile={isMobile}
                />
              ))
            }
          </div>
          <UniversityCourseProgress info={courseInfo} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  courseInfo: state.universityPage.selectedCourse,
  loading: state.universityPage.loading,
  isMobile: state.nav.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getCourseByID: (id) => dispatch(Creators.getCourseByIDRequest(id)),
  getChapterByID: (data) => dispatch(Creators.getChapterByIDRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversityCoursePage);
