import React from 'react';
import Icon from '../../../Icon';
import acc from 'accounting';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../Components/Input';
import './MinMaxInput.less';

const areEqual = (prev, next) => JSON.stringify(next?.data[next?.field]) === JSON.stringify(prev?.data[prev?.field]);

const MinMaxInput = (
  {
    iconType,
    data,
    field,
    text,
    tooltip,
    onChange,
    isMobile
  }) => {

  //state to change icon colors
  const { t } = useTranslation();

  return (
    <div className="min-max-input-wrapper">
      <Icon role="button"
            type={iconType}
            titleText={t(tooltip)}
            tooltipProps={{
              placement: 'right',
              destroyTooltipOnHide: true,
              trigger: isMobile ? 'click' : 'hover',
            }}
      />

      <InputComponent placeholder={t(text)}
                      name={field + '_min'}
                      suffix={'MIN'}
                      value={
                        data?.[field].min ?
                          acc.formatNumber(data?.[field].min, 0, ',', '')
                          :
                          ''
                      }
                      onChange={e => onChange(e, field, 'min')}
      />

      <InputComponent placeholder={t(text)}
                      name={field + '_max'}
                      suffix={'MAX'}
                      value={
                        data?.[field].max ?
                          acc.formatNumber(data?.[field].max, 0, ',', '')
                          :
                          ''
                      }
                      onChange={e => onChange(e, field, 'max')}
      />
    </div>
  );
};

export default React.memo(MinMaxInput, areEqual)
