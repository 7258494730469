import React, { useState } from 'react';
import cls from 'classname';
import ImageGallery from 'react-image-gallery';
import './ImagesBlock.less';

const ImagesBlock = (
  {
    skeleton,
    images,
    isMobile
  }) => {

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const imagesList = images?.map(el => ({
    original: el.url,
    thumbnail: el.url,
    thumbnailHeight: 48,
    originalClass: 'custom-original-img',
    thumbnailClass: 'custom-thumbnail-img',
  }));

  const renderSkeleton = (
    <>
      <span className={'link skeleton'}
            style={{ width: isMobile ? 'calc(100vw - 32px)' : 385, height: isMobile ? 'calc(100vw - 32px)' : 385 }}
      />
      <div className="image-gallery-thumbnails-skeleton">
        {
          [1, 2, 3, 4, 6].map(el => (
            <span className={'link skeleton'}
                  key={el}
                  style={{ width: 48, height: 48 }}
            />
          ))
        }
      </div>
    </>
  );

  return (
    <div className={cls('product-details-images-block', {
      withBefore: images?.length > 6 && activeImageIndex !== 0,
      withAfter: skeleton || (images?.length > 6 && (activeImageIndex + 1) !== images?.length),
    })}>
      {
        skeleton ?
          renderSkeleton
          :
          <ImageGallery items={imagesList}
                        showNav={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        onSlide={(currentIndex) => setActiveImageIndex(currentIndex)}
          />
      }
    </div>
  );
};

export default ImagesBlock;
