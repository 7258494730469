import React from 'react';
import { useSelector } from 'react-redux';

const EmptyCollection = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none"
    >
      <path stroke={theme === 'light' ? "#C5CCE3" : "rgba(255, 255, 255, 0.18)"}
            d="M2.66667 9.47368L4.56326 8.05476C5.10758 7.69188 5.83477 7.7775 6.27991 8.25689C7.27487 9.32839 8.42838 10.3025 10 10.3025C11.4485 10.3025 12.4083 9.76567 13.3333 8.84064M3.83333 13.5H12.1667C12.903 13.5 13.5 12.903 13.5 12.1667V3.83333C13.5 3.09695 12.903 2.5 12.1667 2.5H3.83333C3.09695 2.5 2.5 3.09695 2.5 3.83333V12.1667C2.5 12.903 3.09695 13.5 3.83333 13.5ZM11.5 5.83333C11.5 6.56971 10.903 7.16667 10.1667 7.16667C9.43029 7.16667 8.83333 6.56971 8.83333 5.83333C8.83333 5.09695 9.43029 4.5 10.1667 4.5C10.903 4.5 11.5 5.09695 11.5 5.83333Z"
            strokeWidth="1.5"
            strokeLinecap="round"
      />
    </svg>
  );
};

export default EmptyCollection;
