import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Suppliers = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="18"
           height="14"
           viewBox="0 0 18 14"
           fill="none"
           className={cls(className, 'icon icon-suppliers icon-suppliers-outline')}
      >
        <path stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
              d="M7.33342 10.5423H11.0834M6.29175 2.62565V9.50065M6.29175 2.62565H4.04C3.49309 2.62565 2.98099 2.89397 2.66968 3.34363L1.58809 4.90593C1.39513 5.18466 1.29175 5.5156 1.29175 5.85461V8.90541C1.29175 9.80945 2.02462 10.5423 2.92865 10.5423M6.29175 2.62565V2.70141M6.29175 2.62565C6.29175 1.70518 7.03794 0.958984 7.95842 0.958984H15.0417C15.9622 0.958984 16.7084 1.70518 16.7084 2.62565V8.96403C16.7084 9.8357 16.0018 10.5423 15.1301 10.5423M7.12508 10.959C7.12508 12.1096 6.19234 13.0423 5.04175 13.0423C3.89115 13.0423 2.95841 12.1096 2.95841 10.959C2.95841 9.80839 3.89115 8.87565 5.04175 8.87565C6.19234 8.87565 7.12508 9.80839 7.12508 10.959ZM15.0417 10.959C15.0417 12.1096 14.109 13.0423 12.9584 13.0423C11.8078 13.0423 10.8751 12.1096 10.8751 10.959C10.8751 9.80839 11.8078 8.87565 12.9584 8.87565C14.109 8.87565 15.0417 9.80839 15.0417 10.959Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="18"
           height="14"
           viewBox="0 0 18 14"
           fill="none"
           className={cls(className, 'icon icon-suppliers icon-suppliers-bold')}
      >
        <path stroke={theme === 'light' ? "#225AEA" : "white"}
              d="M7.33342 10.5423H11.0834M6.29175 2.62565V9.50065M6.29175 2.62565H4.04C3.49309 2.62565 2.98099 2.89397 2.66968 3.34363L1.58809 4.90593C1.39513 5.18466 1.29175 5.5156 1.29175 5.85461V8.90541C1.29175 9.80945 2.02462 10.5423 2.92865 10.5423M6.29175 2.62565V2.70141M6.29175 2.62565C6.29175 1.70518 7.03794 0.958984 7.95842 0.958984H15.0417C15.9622 0.958984 16.7084 1.70518 16.7084 2.62565V8.96403C16.7084 9.8357 16.0018 10.5423 15.1301 10.5423M7.12508 10.959C7.12508 12.1096 6.19234 13.0423 5.04175 13.0423C3.89115 13.0423 2.95841 12.1096 2.95841 10.959C2.95841 9.80839 3.89115 8.87565 5.04175 8.87565C6.19234 8.87565 7.12508 9.80839 7.12508 10.959ZM15.0417 10.959C15.0417 12.1096 14.109 13.0423 12.9584 13.0423C11.8078 13.0423 10.8751 12.1096 10.8751 10.959C10.8751 9.80839 11.8078 8.87565 12.9584 8.87565C14.109 8.87565 15.0417 9.80839 15.0417 10.959Z"
              strokeWidth="1.5"
              strokeLinejoin="round"
        />
      </svg>
    );
}

export default Suppliers;
