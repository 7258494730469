import React from 'react';

const RemovePreset = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.69139 16.149L5.43983 16.1007L4.69139 16.149ZM15.3093 16.149L14.5608 16.1007L15.3093 16.149ZM2.29199 4.04169C1.87778 4.04169 1.54199 4.37747 1.54199 4.79169C1.54199 5.2059 1.87778 5.54169 2.29199 5.54169V4.04169ZM17.7087 5.54169C18.1229 5.54169 18.4587 5.2059 18.4587 4.79169C18.4587 4.37747 18.1229 4.04169 17.7087 4.04169V5.54169ZM8.87533 8.95835C8.87533 8.54414 8.53954 8.20835 8.12533 8.20835C7.71111 8.20835 7.37533 8.54414 7.37533 8.95835H8.87533ZM7.37533 13.5417C7.37533 13.9559 7.71111 14.2917 8.12533 14.2917C8.53954 14.2917 8.87533 13.9559 8.87533 13.5417H7.37533ZM12.6253 8.95835C12.6253 8.54414 12.2895 8.20835 11.8753 8.20835C11.4611 8.20835 11.1253 8.54414 11.1253 8.95835H12.6253ZM11.1253 13.5417C11.1253 13.9559 11.4611 14.2917 11.8753 14.2917C12.2895 14.2917 12.6253 13.9559 12.6253 13.5417H11.1253ZM12.5023 4.97863C12.6056 5.37977 13.0145 5.62126 13.4156 5.51801C13.8167 5.41477 14.0582 5.00588 13.955 4.60474L12.5023 4.97863ZM3.21021 4.83997L3.94294 16.1973L5.43983 16.1007L4.7071 4.7434L3.21021 4.83997ZM6.3546 18.4584H13.6461V16.9584H6.3546V18.4584ZM16.0577 16.1973L16.7904 4.83997L15.2935 4.7434L14.5608 16.1007L16.0577 16.1973ZM16.042 4.04169H3.95866V5.54169H16.042V4.04169ZM2.29199 5.54169H3.95866V4.04169H2.29199V5.54169ZM16.042 5.54169H17.7087V4.04169H16.042V5.54169ZM13.6461 18.4584C14.9203 18.4584 15.9757 17.4689 16.0577 16.1973L14.5608 16.1007C14.5297 16.5831 14.1294 16.9584 13.6461 16.9584V18.4584ZM3.94294 16.1973C4.02499 17.4689 5.08032 18.4584 6.3546 18.4584V16.9584C5.87125 16.9584 5.47095 16.5831 5.43983 16.1007L3.94294 16.1973ZM7.37533 8.95835V13.5417H8.87533V8.95835H7.37533ZM11.1253 8.95835V13.5417H12.6253V8.95835H11.1253ZM10.0003 3.04169C11.203 3.04169 12.2155 3.86426 12.5023 4.97863L13.955 4.60474C13.5017 2.84373 11.904 1.54169 10.0003 1.54169V3.04169ZM7.49835 4.97863C7.78517 3.86426 8.79764 3.04169 10.0003 3.04169V1.54169C8.09664 1.54169 6.49895 2.84373 6.0457 4.60474L7.49835 4.97863Z"
      fill="#D71313" />
  </svg>
);

export default RemovePreset;
