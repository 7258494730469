import React from 'react';
import { useSelector } from 'react-redux';

const AppleIcon = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6689 5.96536C16.0969 6.31558 15.6231 6.8052 15.2918 7.3884C14.9605 7.9716 14.7826 8.62932 14.7748 9.29999C14.7771 10.0548 15.0007 10.7924 15.418 11.4214C15.8353 12.0504 16.428 12.5432 17.1226 12.8387C16.8487 13.7223 16.4434 14.5596 15.9203 15.3225C15.1718 16.4001 14.3891 17.4775 13.1982 17.4775C12.0074 17.4775 11.7011 16.7857 10.3287 16.7857C8.99032 16.7857 8.51393 17.5002 7.42508 17.5002C6.33623 17.5002 5.5764 16.5021 4.70301 15.2772C3.54934 13.5612 2.91529 11.5488 2.87695 9.48141C2.87695 6.0788 5.0887 4.27547 7.26628 4.27547C8.42322 4.27547 9.38732 5.03531 10.1132 5.03531C10.8051 5.03531 11.8826 4.23001 13.1982 4.23001C13.8747 4.21256 14.545 4.36171 15.1503 4.66432C15.7555 4.96694 16.277 5.41373 16.6689 5.96536ZM12.5745 2.78963C13.1544 2.1074 13.4827 1.24682 13.5045 0.351663C13.5055 0.233654 13.4941 0.11587 13.4705 0.000244141C12.4743 0.0975582 11.5531 0.57237 10.8958 1.32729C10.3102 1.98234 9.96958 2.81993 9.93169 3.69772C9.93213 3.80447 9.94353 3.9109 9.96573 4.01531C10.0443 4.03016 10.124 4.03777 10.2039 4.03805C10.663 4.00151 11.1099 3.872 11.5175 3.65739C11.925 3.44278 12.2846 3.14755 12.5745 2.78963Z"
        fill={theme === 'light' ? 'black' : 'white'} />
    </svg>
  );
};

export default AppleIcon;
