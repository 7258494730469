import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import actions from '../../Containers/User/actions';
import UserCreators from '../../Containers/User/reducer';
import { connect } from 'react-redux';
import { Divider, Dropdown } from 'antd';
import ThemeSwitcher from '../ThemeSwitcher';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import dayjs from 'dayjs';
import ThemeModal from '../../Containers/User/components/ThemeModal';
import SelectLanguageModal from './SelectLanguageModal';

const Preferences = (
  {
    userInfo,
    theme,
    isMobile,
    changeTheme,
    showThemeModal,
    setDisabledTransition,
    changeLang,
    languages,
    userSaveResultReset
  }
) => {

  const {t, i18n} = useTranslation();

  const[visibleLanguage, setVisibleLanguage] = useState(false);
  const[modal, setModal] = useState(null);

  const handleVisibleChange = (value) => setVisibleLanguage(value);

  const optionsLanguages = languages ?
    languages.map((el) => ({
      label: el.name,
      value: el.value,
    }))
    :
    [];

  useEffect(() => {
    i18n.changeLanguage(userInfo?.language);
    dayjs.locale(userInfo?.language === 'zh-hans' ? 'zh-cn' : userInfo?.language);
    userSaveResultReset();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [userInfo?.language]);

  return (
    <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          {t('Preferences')}
        </span>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className="account-subpage-block-preferences">
        <div className="preference-wrapper">
          <div className="preference-text">
            <span className="preference-title">
              {t('Theme')}
            </span>
            <p className="preference-subtitle">
              {t('Select a theme for your software interface.')}
            </p>

          </div>
          <ThemeSwitcher theme={theme}
                         changeTheme={changeTheme}
                         setDisabledTransition={setDisabledTransition}
                         isMobile={isMobile}
                         closeParentDropdown={() => null}
                         setThemeModal={showThemeModal}
                         isSettings={true}
          />
        </div>
        <div className="preference-wrapper">
          <div className="preference-text">
            <span className="preference-title">
              {t('Language')}
            </span>
            <p className="preference-subtitle">
              {t('Change language depending from your needs.')}
            </p>
          </div>
          {
            isMobile ?
              <div className="language-dropdown-inner"
                   onClick={() => setModal('change_language')}
              >
                {optionsLanguages?.filter(el => el.value === userInfo?.language)?.[0]?.label}
                <ArrowSelectDown />
              </div>
              :
              <Dropdown destroyPopupOnHide={true}
                        getPopupContainer={(trigger) => trigger?.parentNode}
                        onOpenChange={handleVisibleChange}
                        dropdownRender={() => (
                          <div className={'language-dropdown'}>
                            {
                              optionsLanguages.map((option) => (
                                <div key={option?.value}
                                     className={'language-dropdown-option'}
                                     onClick={() => {
                                       setVisibleLanguage(false);
                                       changeLang(option.value);
                                     }}
                                >
                                  {option?.label}
                                  {
                                    option?.value === userInfo?.language ?
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           width="16"
                                           height="16"
                                           viewBox="0 0 16 16"
                                           fill="none"
                                           className={'checkmark'}
                                      >
                                        <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                              stroke="#225AEA"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                        />
                                      </svg>
                                      :
                                      null
                                  }
                                </div>
                              ))
                            }
                          </div>
                        )}
                        trigger={['click']}
                        open={visibleLanguage}
              >
                <div className="language-dropdown-inner">
                  {optionsLanguages?.filter(el => el.value === userInfo?.language)?.[0]?.label}
                  <ArrowSelectDown />
                </div>
              </Dropdown>
          }
        </div>
      </div>
      <ThemeModal setVisiblePopup={() => null} />
      <SelectLanguageModal isMobile={isMobile}
                           setModal={setModal}
                           visible={modal === 'change_language'}
                           optionsLanguages={optionsLanguages}
                           changeLang={changeLang}
                           userInfo={userInfo}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
  languages: state.setting.languages,
});

const mapDispatchToProps = (dispatch) => ({
  changeTheme: theme => dispatch(actions.changeTheme('theme', theme)),
  showThemeModal: value => dispatch(actions.changeTheme('showTheme', value)),
  setDisabledTransition: disabledTransition => dispatch(actions.setDisabledTransition('disabledTransition', disabledTransition)),
  changeLang: (lang) => dispatch(UserCreators.changeLanguageRequest(lang)),
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
