import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const Portfolio = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-portfolio icon-portfolio-outline')}
    >
      <path fill={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
            d="M5.91858 6.45833C5.91858 6.87255 6.25437 7.20833 6.66858 7.20833C7.08279 7.20833 7.41858 6.87255 7.41858 6.45833H5.91858ZM12.5852 6.45833C12.5852 6.87255 12.921 7.20833 13.3352 7.20833C13.7495 7.20833 14.0852 6.87255 14.0852 6.45833H12.5852ZM14.0834 6.04167V5.29167H12.5834V6.04167H14.0834ZM12.5834 16.875V17.625H14.0834V16.875H12.5834ZM7.41675 6.04167V5.29167H5.91675V6.04167H7.41675ZM5.91675 16.875V17.625H7.41675V16.875H5.91675ZM16.0417 16.125H3.95841V17.625H16.0417V16.125ZM3.95841 7.20833H16.0417V5.70833H3.95841V7.20833ZM16.9584 8.125V15.2083H18.4584V8.125H16.9584ZM3.04175 15.2083V8.125H1.54175V15.2083H3.04175ZM16.0417 7.20833C16.548 7.20833 16.9584 7.61874 16.9584 8.125H18.4584C18.4584 6.79031 17.3764 5.70833 16.0417 5.70833V7.20833ZM3.95841 5.70833C2.62373 5.70833 1.54175 6.79031 1.54175 8.125H3.04175C3.04175 7.61874 3.45215 7.20833 3.95841 7.20833V5.70833ZM3.95841 16.125C3.45215 16.125 3.04175 15.7146 3.04175 15.2083H1.54175C1.54175 16.543 2.62373 17.625 3.95841 17.625V16.125ZM16.0417 17.625C17.3764 17.625 18.4584 16.543 18.4584 15.2083H16.9584C16.9584 15.7146 16.548 16.125 16.0417 16.125V17.625ZM7.41858 6.45833V4.375H5.91858V6.45833H7.41858ZM7.91858 3.875H12.0852V2.375H7.91858V3.875ZM12.5852 4.375V6.45833H14.0852V4.375H12.5852ZM12.0852 3.875C12.3614 3.875 12.5852 4.09886 12.5852 4.375H14.0852C14.0852 3.27043 13.1898 2.375 12.0852 2.375V3.875ZM7.41858 4.375C7.41858 4.09886 7.64244 3.875 7.91858 3.875V2.375C6.81401 2.375 5.91858 3.27043 5.91858 4.375H7.41858ZM12.5834 6.04167V16.875H14.0834V6.04167H12.5834ZM5.91675 6.04167V16.875H7.41675V6.04167H5.91675Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-portfolio icon-portfolio-bold')}
    >
      <path fill={theme === 'light' ? "#225AEA" : "white"}
            d="M5.91858 6.45833C5.91858 6.87255 6.25437 7.20833 6.66858 7.20833C7.08279 7.20833 7.41858 6.87255 7.41858 6.45833H5.91858ZM12.5852 6.45833C12.5852 6.87255 12.921 7.20833 13.3352 7.20833C13.7495 7.20833 14.0852 6.87255 14.0852 6.45833H12.5852ZM14.0834 6.04167V5.29167H12.5834V6.04167H14.0834ZM12.5834 16.875V17.625H14.0834V16.875H12.5834ZM7.41675 6.04167V5.29167H5.91675V6.04167H7.41675ZM5.91675 16.875V17.625H7.41675V16.875H5.91675ZM16.0417 16.125H3.95841V17.625H16.0417V16.125ZM3.95841 7.20833H16.0417V5.70833H3.95841V7.20833ZM16.9584 8.125V15.2083H18.4584V8.125H16.9584ZM3.04175 15.2083V8.125H1.54175V15.2083H3.04175ZM16.0417 7.20833C16.548 7.20833 16.9584 7.61874 16.9584 8.125H18.4584C18.4584 6.79031 17.3764 5.70833 16.0417 5.70833V7.20833ZM3.95841 5.70833C2.62373 5.70833 1.54175 6.79031 1.54175 8.125H3.04175C3.04175 7.61874 3.45215 7.20833 3.95841 7.20833V5.70833ZM3.95841 16.125C3.45215 16.125 3.04175 15.7146 3.04175 15.2083H1.54175C1.54175 16.543 2.62373 17.625 3.95841 17.625V16.125ZM16.0417 17.625C17.3764 17.625 18.4584 16.543 18.4584 15.2083H16.9584C16.9584 15.7146 16.548 16.125 16.0417 16.125V17.625ZM7.41858 6.45833V4.375H5.91858V6.45833H7.41858ZM7.91858 3.875H12.0852V2.375H7.91858V3.875ZM12.5852 4.375V6.45833H14.0852V4.375H12.5852ZM12.0852 3.875C12.3614 3.875 12.5852 4.09886 12.5852 4.375H14.0852C14.0852 3.27043 13.1898 2.375 12.0852 2.375V3.875ZM7.41858 4.375C7.41858 4.09886 7.64244 3.875 7.91858 3.875V2.375C6.81401 2.375 5.91858 3.27043 5.91858 4.375H7.41858ZM12.5834 6.04167V16.875H14.0834V6.04167H12.5834ZM5.91675 6.04167V16.875H7.41675V6.04167H5.91675Z"
      />
    </svg>
  );
};

export default Portfolio;
