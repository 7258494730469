import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Creators from './reducer';
import SuppliersPageSkeleton from './components/SuppliersPageSkeleton';
import SupplierCard from './components/SupplierCard';
import AutoDSBanner from './components/AutoDSBanner';
import AutoDSCreators from '../AutoDS/reducer';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import './styles.less';

const SuppliersPage = (
  {
    getSuppliers,
    getAutoStore,
    loading,
    suppliers,
    autoDS,
    isMobile
  },
) => {

  useEffect(() => {
    document.title = 'Suppliers - Dropship';
    getSuppliers();
    getAutoStore();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  return (
    <div className={'product-database-page suppliers-page'}>
      {
        loading ?
          <SuppliersPageSkeleton isMobile={isMobile} />
          :
          <>
            <TopPageHeader icon={'suppliers_dashboard'}
                           title={'Suppliers'}
                           subtitle={'Contact industry-leading suppliers'}
            />

            <AutoDSBanner isConnected={autoDS?.email} />

            <div className="suppliers-page-cards-wrapper">
              {
                suppliers.map(el => <SupplierCard info={el} key={el?.id} />)
              }
            </div>
          </>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  loading: state.suppliersPage.loading,
  suppliers: state.suppliersPage.suppliers,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  getSuppliers: () => dispatch(Creators.getSuppliersRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersPage);
