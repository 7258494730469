import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Divider, Spin } from 'antd';
import ButtonComponent from '../../../Components/Button';

const StopTrackingModal = (
  {
    setModal,
    isMobile,
    visible,
    loading,
    type,
    recordToDelete,
    disconnect,
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={`Stop tracking ${type}`}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(`Are you sure you want to stop tracking `)
            }
            <span className={'bold'}>
              {
                type === 'store' ?
                  recordToDelete?.store?.custom_domain
                  :
                  recordToDelete?.title
              }
            </span>
            ?
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => setModal(null)}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={false}
                           onClick={() => {
                             disconnect(recordToDelete);
                             setModal(null);
                           }}
                           text={t('Stop tracking')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default StopTrackingModal;
