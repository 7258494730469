import React from 'react';
import Icon from '../../../Icon';
import { Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../actions';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../Components/Button';

const LogoutModal = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const nav = useSelector(state => state?.nav);

  return (
    <Modal className={'change-modal custom-modal logout-modal'}
           getContainer={() => document.getElementById('global-wrap')}
           {...(nav?.isMobile ? { transitionName: '' } : null)}
           open={nav?.showLogout}
           centered={!nav?.isMobile}
           closeIcon={
             <Icon role="icon" type="close_modal" />
           }
           footer={null}
           closable="true"
           onCancel={() => dispatch(actions.changeTheme('showLogout', false))}
           destroyOnClose
           width={450}
    >
      <div className={'custom-modal-content'}>
        <h3 className={'custom-modal-title'}>
          { t('Logout') }
        </h3>
        <p className={'custom-modal-text'}>
          { t('Are you sure you want to logout?') }
        </p>
        <Divider type={'horizontal'}
                 style={{margin: '16px auto'}}
        />
        <div className="custom-modal-btn-wrapper">
          <ButtonComponent className={'custom-modal-button cancel'}
                           text={t('Cancel')}
                           onClick={() => dispatch(actions.changeTheme('showLogout', false))}
          />
          <ButtonComponent className={'custom-modal-button apply red'}
                           text={t('Logout')}
                           onClick={() => dispatch(actions.logout())}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
