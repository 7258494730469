import React from 'react';

const SetAsDefaultCollection = () => {
  return (
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
      >
        <path stroke="#225AEA"
              d="M14.5833 17.7083L16.6414 15.6502C16.8855 15.4061 16.8855 15.0104 16.6414 14.7663L14.5833 12.7083M5.41667 2.29158L3.35861 4.34964C3.11453 4.59372 3.11453 4.98945 3.35861 5.23353L5.41667 7.29158M4.375 4.79158H15.2083C16.1288 4.79158 16.875 5.53778 16.875 6.45825V8.95825M3.125 11.4583V13.5416C3.125 14.4621 3.87119 15.2083 4.79167 15.2083H15.625"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
    )
  );
};

export default SetAsDefaultCollection;
