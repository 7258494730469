import React from 'react';
import { useSelector } from 'react-redux';

const EditCardModalProgress = () => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.9989 1.83301L7.9987 4.16634M7.9989 11.833V14.1663M1.83203 8.00014H4.16536M11.832 8.00014H14.1654M3.63835 3.63904L5.28812 5.2891M10.7094 10.7101L12.3593 12.36M3.63854 12.3605L5.28845 10.7106M10.7096 5.28943L12.3595 3.63951"
        stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.26)'}
        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditCardModalProgress;
