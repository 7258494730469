import React, { useEffect, useInsertionEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthActions from './LoginPage/reducer';
import { Layout, Card, Spin } from 'antd';
import RestorePasswordForm from '../Components/Forms/RestorePasswordForm';
import Images from '../Images';

const RestorePasswordPage = (props) => {

  const { error, fetching } = props;

  const [firstLoading, setFirstLoading] = useState(true);

  function handleSubmit(data) {
    if (fetching) return;
    props.checkPassword(data.email);
  }

  useInsertionEffect(() => {
    document.title = 'Forgot Password - Dropship';
    if (firstLoading) {
      props.resetError();
      setFirstLoading(false);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (error) props.resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-content">
          <img src={Images.logo} alt="" className={'login-logo'} />
          <div className="login-card-wrapper">
            <Card className="login-card">
              <h2 className="login-card-title left">
                Reset Password
              </h2>
              <h3 className="login-card-subtitle left"
                  style={{marginBottom: 24}}
              >
                Enter the email associated with your Dropship account, and we’ll send an email with instructions to
                reset your password
              </h3>
              <RestorePasswordForm onSubmit={handleSubmit} error={!firstLoading && error?.message} />
            </Card>
          </div>
          <div className="remember-password">
            Remember Password? <Link to="/login">Back to Sign in</Link>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    isMobile: state.nav.isMobile,
  };
};

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  checkPassword: (email) => dispatch(AuthActions.checkPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestorePasswordPage);
