import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import InputComponent from '../Input';
import { connect } from 'react-redux';
import { openNotificationWithIcon } from '../Notification';
import AuthActions from '../../Containers/LoginPage/reducer';
import UserCreators from '../../Containers/User/reducer';
import cls from 'classname';
import { DefaultMsg } from '../Notification/notification-message';
import ChangeEmailModal from './ChangeEmailModal';
import CheckEmailModal from './CheckEmailModal';
import VerifyEmailModal from './VerifyEmailModal';

const PersonalInformation = (
  {
    isMobile,
    userInfo,
    error,
    userError,
    saveResult,
    resetError,
    updatePersonalInfo,
    userSaveResultReset,
    userErrorsReset,
    saveFetching,
    changeEmails,
    verifyEmail
  }
) => {

  const { email, first_name, last_name, email_confirmed } = userInfo;

  const data = useRef({ first_name, last_name});
  const {t} = useTranslation();

  const [field, setField] = useState('');
  const [modal, setModal] = useState(null);
  const [saveConfirm, setSaveConfirm] = useState(null);
  const [disabledVerify, setDisabledVerify] = useState(false);

  function handleSelect(value, fieldName) {
      data.current = { ...data.current, [fieldName]: value.trim() };
    }

  function changePersonalData(params) {
    const changedField = {
      first_name: t('First name'),
      last_name: t('Last name'),
      email: t('Email address'),
    };
    setField(changedField[params]);
  }

  function handleUpdateUserInfo(params, value) {
    if (params === 'first_name' && first_name !== data.current.first_name && data.current.first_name) {
      updatePersonalInfo({ [params]: data.current[params] });
    } else if (params === 'last_name' && last_name !== data.current.last_name && data.current.last_name) {
      updatePersonalInfo({ [params]: data.current[params] });
    } else {
      return;
    }
    changePersonalData(params);
  }

  useEffect(() => {
    if (error?.message) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_rename',
        message: (
          <DefaultMsg text={null}
                      icon="notification_warning"
                      title={error?.message?.detail}
          />
        ),
      });
    }
    if(error) resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  useEffect(() => {
    if (saveResult && !saveResult?.message) {
      if (field) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
                        icon="notification_success"
                        title={`${field} has been successfully changed`}
            />
          ),
        });
        setField('');
        userSaveResultReset();
      }
    }
    if (userError) {
      if (userError?.confirmError?.detail) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
                        icon="notification_warning"
                        title={userError?.confirmError?.detail}
            />
          ),
        });
        userErrorsReset();
      } else if (userError?.userPersonalError) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
                        icon="notification_warning"
                        title={t('Something went wrong, kindly try again')}
            />
          ),
        });
        userErrorsReset();
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, userError, field]);

  return (
    <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          {t('Personal information')}
        </span>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className="account-subpage-block-name">
        <div className="input-wrapper">
          <span className="input-label">
            {t('First name')}
          </span>
          <InputComponent defaultValue={data.current.first_name || ''}
                          onBlur={() => {
                            handleUpdateUserInfo('first_name');
                          }}
                          onChange={(e) => handleSelect(e.target.value, 'first_name')}
          />
        </div>
        <div className="input-wrapper">
          <span className="input-label">
            {t('Last name')}
          </span>
          <InputComponent defaultValue={data.current.last_name || ''}
                          onBlur={() => {
                            handleUpdateUserInfo('last_name');
                          }}
                          onChange={(e) => handleSelect(e.target.value, 'last_name')}
          />
        </div>

      </div>
      <div className="account-subpage-block-email">
        <div className="input-wrapper">
          <span className="input-label">
            {t('Email address')}
          </span>
          <InputComponent placeholder={email}
                          disabled
                          suffix={(
                            <span className='input-tag'>
                              {
                                email_confirmed ?
                                  t('Verified')
                                  :
                                  t('Not verified')
                              }
                            </span>
                          )}
          />
        </div>
        <div className="btn-wrapper">
          {
            email_confirmed ?
              null
              :
              <span onClick={() => {
                if (!disabledVerify) {
                  setDisabledVerify(true);
                  verifyEmail({ new_email: email });
                  setModal('verify_email');
                }
              }}
                    className={cls('verify', {
                      disabled: disabledVerify,
                    })}
              >
                {t('Verify email')}
              </span>
          }
          <span onClick={() => setModal('change_email')}>
            {t('Change email address')}
          </span>
        </div>
      </div>
      <ChangeEmailModal visible={modal === 'change_email'}
                        isMobile={isMobile}
                        setModal={setModal}
                        loading={saveFetching}
                        changeEmails={changeEmails}
                        userError={userError?.detail}
                        userErrorsReset={userErrorsReset}
                        saveResult={saveResult}
                        setSaveConfirm={setSaveConfirm}
                        userSaveResultReset={userSaveResultReset}
      />
      <CheckEmailModal visible={modal === 'check_email'}
                       isMobile={isMobile}
                       setModal={setModal}
                       loading={saveFetching}
                       saveConfirm={saveConfirm}
                       changeEmails={changeEmails}
      />
      <VerifyEmailModal visible={modal === 'verify_email'}
                        isMobile={isMobile}
                        setModal={setModal}
                        loading={saveFetching}
                        changeEmails={verifyEmail}
                        email={email}
                        setDisabledVerify={setDisabledVerify}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  error: state.auth.error,
  userError: state.users.errors,
  saveResult: state.users.saveResult,
  saveFetching: state.users.saveFetching,
});

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
  userErrorsReset: () => dispatch(UserCreators.userErrorsReset()),
  updatePersonalInfo: (data) => dispatch(UserCreators.userSaveRequest(data)),
  changeEmails: (email) => dispatch(UserCreators.changeEmailsRequest(email)),
  verifyEmail: (email) => dispatch(UserCreators.verifyEmailRequest(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
