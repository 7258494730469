import React, { useEffect, useState } from 'react';
import Icon from '../../../Icon';
import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../Components/Button';

const SortModal = (
  {
    options,
    order,
    setSort,
    setModal,
    modal,
    isMobile=true
  }) => {

  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (modal === 'sort_modal' && order) {
      options.some(option => {
        order.includes(option?.key) && setSelected({
          order: order.includes('-') ? 'descend' : 'ascend',
          field: option?.key
        });
      });
    }
  }, [modal]);

  return (
    <Modal className={'change-modal custom-modal portfolio-sort-modal'}
           getContainer={() => document.getElementById('global-wrap')}
           {...(isMobile ? { transitionName: '' } : null)}
           open={modal === 'sort_modal'}
           centered={false}
           closeIcon={
             <Icon role="icon" type="close_modal" />
           }
           footer={null}
           closable="true"
           onCancel={() => {
             setModal(null);
           }}
           destroyOnClose
           width={'100vw'}
    >
      <div className={'custom-modal-content'}>
        <h3 className={'custom-modal-title'}>
          {t('Sorting')}
        </h3>
        <p className={'custom-modal-text'}>
          {t('Select an option to sort your products list.')}
        </p>
        <div className={'search-sort-menu'}>
          {
            options.map(el => (
              <div className={'search-sort-menu-item-wrapper'}
                   key={el?.key}
              >
                <span className={'search-sort-menu-item'}
                      onClick={() => setSelected({
                        order: selected?.field === el?.key && selected?.order === 'ascend' ? null : 'ascend',
                        field: el?.key
                      })}
                >
                  {t(`_name_: Low to High`, {name: el?.name})}
                  {
                    selected?.field === el?.key && selected?.order === 'ascend' ?
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           viewBox="0 0 16 16"
                           fill="none"
                      >
                        <path d="M1.8335 10.0625L6.00016 13.5L14.1668 2.5"
                              stroke="#225AEA"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                      </svg>
                      :
                      null
                  }
                </span>
                <span className={'search-sort-menu-item'}
                      onClick={() => setSelected({
                        order: selected?.field === el?.key && selected?.order === 'descend' ? null : 'descend',
                        field: el?.key
                      })}
                >
                  {t(`_name_: High to Low`, {name: el?.name})}
                  {
                    selected?.field === el?.key && selected?.order === 'descend' ?
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           viewBox="0 0 16 16"
                           fill="none"
                      >
                        <path d="M1.8335 10.0625L6.00016 13.5L14.1668 2.5"
                              stroke="#225AEA"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                      </svg>
                      :
                      null
                  }
                </span>
              </div>
            ))
          }
        </div>
        <Divider type={'horizontal'}
                 style={{margin: '16px auto'}}
        />
        <div className="custom-modal-btn-wrapper">
          <ButtonComponent className={'custom-modal-button cancel'}
                           text={t('Clear')}
                           onClick={() => {
                             setSelected(null);
                             setSort({
                               order: null,
                               field: 'price'
                             });
                             setModal(null);
                           }}
          />
          <ButtonComponent className={'custom-modal-button blue'}
                           text={t('Confirm')}
                           onClick={() => {
                             setModal(null);
                             setSort(selected);
                           }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SortModal;
