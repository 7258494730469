import React from 'react';
import { useSelector } from 'react-redux';

const ArrowTableIn = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="21"
         viewBox="0 0 20 21"
         fill="none"
    >
      <path d="M8.33398 13.8332L11.0781 11.0891C11.4035 10.7637 11.4035 10.236 11.0781 9.91058L8.33398 7.1665"
            stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowTableIn;
