import React from 'react';
import utils from '../../../../Utils/utils';

const UniversityVideoSkeleton = ({isMobile}) => {
  return (
    <div className={'product-database-page university-page university-video-page'}>
      <div className={'page-top-line-wrapper'}>
        <div className="custom-breadcrumbs">
          <span className={'link skeleton'}
                style={{ width: 55, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 20, height: 20 }}
          />
          <span className={'link skeleton'}
                style={{ width: 72, height: 20 }}
          />
        </div>
      </div>
      <div className="university-video-page-player-wrapper">
        <div className="video-wrapper">
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: isMobile ? 0 : 24 }}
          />
        </div>
        {
          isMobile ?
            <div className="university-video-page-content-wrapper">
              <h2 className="university-video-page-title">
          <span className={'link skeleton'}
                style={{ width: 300, height: 28 }}
          />
              </h2>
              <div className="university-video-page-text">
          <span className={'link skeleton'}
                style={{ width: isMobile ? 220 : 520, height: 20 }}
          />
                <span className={'link skeleton'}
                      style={{ width: isMobile ? 180 : 380, height: 20, marginTop: 4 }}
                />
                <span className={'link skeleton'}
                      style={{ width: 100, height: 20, marginTop: 4 }}
                />
              </div>
            </div>
            :
            <div className="playlist-wrapper">
              <p className="title-wrapper">
            <span className="title">
              <span className={'link skeleton'}
                    style={{ width: 140, height: 20 }}
              />
            </span>
                <span className="lessons-quantity">
              <span className={'link skeleton'}
                    style={{ width: 100, height: 20 }}
              />
            </span>
              </p>
              <div className="playlist">
                {
                  [1, 2, 3, 4, 5, 6].map((el) => (
                    <div className={'playlist-item'}
                         key={el}
                    >
                      <div className={'playlist-item-icon'}>
                    <span className={'link skeleton'}
                          style={{ width: '100%', height: '100%' }}
                    />
                      </div>
                      <div className="info-wrapper">
                        <div className="title">
                          <p>
                      <span className={'link skeleton'}
                            style={{ width: utils.randomNum(100, 150), height: 20 }}
                      />
                          </p>
                          <p className="playlist-item-duration">
                        <span className={'link skeleton'}
                              style={{ width: 62, height: 20 }}
                        />
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
        }
      </div>
      {
        isMobile ?
          <div className="playlist-wrapper">
            <p className="title-wrapper">
            <span className="title">
              <span className={'link skeleton'}
                    style={{ width: 140, height: 20 }}
              />
            </span>
              <span className="lessons-quantity">
              <span className={'link skeleton'}
                    style={{ width: 100, height: 20 }}
              />
            </span>
            </p>
            <div className="playlist">
              {
                [1, 2, 3, 4, 5, 6].map((el) => (
                  <div className={'playlist-item'}
                       key={el}
                  >
                    <div className={'playlist-item-icon'}>
                    <span className={'link skeleton'}
                          style={{ width: '100%', height: '100%' }}
                    />
                    </div>
                    <div className="info-wrapper">
                      <div className="title">
                        <p>
                      <span className={'link skeleton'}
                            style={{ width: utils.randomNum(100, 150), height: 20 }}
                      />
                        </p>
                        <p className="playlist-item-duration">
                        <span className={'link skeleton'}
                              style={{ width: 62, height: 20 }}
                        />
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          <div className="university-video-page-content-wrapper">
            <h2 className="university-video-page-title">
          <span className={'link skeleton'}
                style={{ width: 300, height: 28 }}
          />
            </h2>
            <div className="university-video-page-text">
          <span className={'link skeleton'}
                style={{ width: 520, height: 20 }}
          />
              <span className={'link skeleton'}
                    style={{ width: 380, height: 20, marginTop: 4 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 100, height: 20, marginTop: 4 }}
              />
            </div>
          </div>
      }
    </div>
  );
};

export default UniversityVideoSkeleton;
